import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

// Mobx Store
import { observer } from "mobx-react";
import { toJS } from "mobx";
import store from "../../stores/";
import constants from "../../stores/constants";

// Actions
import getContact from "../../actions/getContact";
import ContactEditor from "../../actions/editContact";
import ContactTracker from "../../actions/trackContact";
import { confirmOrDeclineSuggestion } from '../../actions/person'

// Material UI
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import NotFoundIcon from "@material-ui/icons/ErrorOutline";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from "@material-ui/core/IconButton";
import EmptyStarIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import VCardIcon from "@material-ui/icons/PermContactCalendar";
import Button from "@material-ui/core/Button";

// React Internationalization
import { withTranslation } from "react-i18next";

// import QRCode from 'qrcode';

// Components
import AddButton from "../templates/AddButton";
import ContactInfos from "../templates/ContactInfos";
import Addresses from "../templates/Addresses";
import Categories from "../templates/Categories";
// import Relations from "../templates/Relations";
import RelationsGraph from "../relations/RelationsGraph";
import PersonalInformation from "../templates/PersonalInformation";

import Groups from "../templates/Groups";

import HistoryBlock from "../templates/HistoryBlock";

// import OIHHistoryBlock from "../templates/OIHHistoryBlock";

import NoteBlock from "../templates/NoteBlock";

// Actions
import {
  createVCardQRCode,
  getVCardDownloadLink
} from "../../actions/exportSingle";

import { revertEvent } from '../../actions/revert'

// import Graph from '../templates/Graph';
// const Graph =  React.lazy(()=>import(/*webpackChunkName: 'Graph' */ '../templates/Graph'));

const styles = theme => ({
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 60,
    height: 60
  },
  missingIcon: {
    color: constants.color.main,
    width: 40,
    height: 40,
    "margin-top": "14px",
    "margin-left": theme.spacing.unit * 1
  },
  missingTitle: {
    // backgroundColor: 'grey'
    float: "left",
    marginTop: theme.spacing.unit * 1
  },
  root: {
    marginTop: "20px"
  },
  // typography: {
  //     paddingLeft: 20,
  //     paddingTop: 10
  // },
  card: {
    paddingTop: 0,
    paddingBottom: 20
  },
  chip: {
    margin: theme.spacing.unit
  },
  editLink: {
    display: "block",
    // top: 110 + theme.spacing.unit,
    // right: theme.spacing.unit,
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
    // position: "fixed",
    position: "absolute",
  },
  editLinkLocal: {
    display: "block",
    paddingTop: theme.spacing.unit,
    right: theme.spacing.unit,
    position: "fixed",
    zIndex: 5
  },
  editIcon: {
    fontSize: "30px",
    zIndex: 5
  },
  appBar: {
    zIndex: 0,
    boxShadow: "none",
    marginBottom: 20,
    backgroundColor: "rgba(245,245,245,0)"
  },
  appBarScrolled: {
    zIndex: 0,
    boxShadow: "none",
    marginBottom: 20,
    backgroundColor: "rgba(245,245,245,1)"
  },
  outer: {
    position: "relative",
    paddingBottom: 100
  },
  headerArea: {
    backgroundColor: "#fff",
    background:
      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(250,250,250,0.5) 30%, rgba(245,245,245,1) 100%)",
    backgroundPositionY: "-209px",
    paddingTop: 10
  },
  headerPlaceholder: {
    height: 200,
    width: "100%"
  },
  emptyClass: {
    paddingTop: theme.spacing.unit * 3
  },
  spacer: {
    width: "100%",
    height: theme.spacing.unit * 3
  },
  typography: {
    paddingTop: 10,
    paddingLeft: 20,
    fontSize: "14px",
    color: constants.color.heading,
    clear: "both"
  },
  divider: {
    marginBottom: theme.spacing.unit
  },
  lastUpdate: {
    paddingLeft: 20,
    paddingTop: 10,
    color: constants.color.heading,
    fontSize: "14px"
  },
  qrCode: {
    height: 196,
    width: 196,
    display: "inline-block",
    position: "relative !important",
    zIndex: "0 !important"
  },
  vCard: {
    float: "left",
    marginLeft: theme.spacing.unit * 2
  },
  vCardCenter: {},
  vCardText: {
    fontSize: "16px",
    lineHeight: "16px"
  },
  vCardIcon: {
    position: "relative",
    float: "left",
    marginTop: "3px",
    marginRight: theme.spacing.unit
  },
  favoriteIcon: {
    color: constants.color.main
  },
  notFavoriteIcon: {},
  appBarHolder: {}
});

// Determines the personal Attributes to be shown in the "Additional Info" section
const shownAttributes = [
  "middleName",
  "title",
  "salutation",
  "gender",
  "birthday",
  "displayName",
  "language",
  "nickname",
  "jobTitle"
];

const DetailView = class DetailView extends Component {
  _isMounted = false;
  state = {
    value: 0,
    expanded: false,
    deleteDialog: false,
    qr: false,
    avatarError: false,
    events: false,
    revertEventDialog: false,
    revertEventId: false,
  };

  contactType = this.props.entryType
    ? this.props.entryType
    : this.props.match.params.type
      ? this.props.match.params.type
      : "person";
  isProfile = false;
  imageType;
  typeReferences = [];
  cleanObject = {};
  currentRef = "";
  qrInit = false;

  constructor(props) {
    super(props);

    let id = this.props.entryId
      ? this.props.entryId
      : this.props.match.params.id;
    let type = this.props.entryType
      ? this.props.entryType
      : this.props.match.params.type;

    if (!id || id === 0) {
      this.isProfile = true;
      id = 0;
      type = "person";
    } else {
      this.isProfile = false;
    }

    if (!this.props.entry) {
      if (this.contactType === "person") {
        if (this.isProfile) {
          // Get users own contact @todo: needs endpoint
          getContact.populateProfile(false);
        } else {
          // we need current data always
          // Checks whether the requested person is already in the store
          //if (!store.generalStore.person || (store.generalStore.person.uid !== this.props.match.params.id)) {
          getContact.populatePerson(id, true);
          //}
        }
      } else if (this.contactType === "organization") {
        // we need current data always
        //if (!store.generalStore.organization || (store.generalStore.organization.uid !== this.props.match.params.id)) {
        getContact.populateOrganization(id, true);
        //}
      } else {
        // alert('Url malformed!');
        return;
      }
    } else {
      if (type) {
        if (type === "person") {
          store.generalStore.setPerson(this.props.entry);
        } else {
          store.generalStore.setOrganization(this.props.entry);
        }
      }
    }

    this.filterObject()
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this.props.match.params.reference) {
      this.setState({ value: parseInt(this.props.match.params.reference) });
    }


    const entryId = this.props.entryId
      ? this.props.entryId
      : this.props.match.params.id;


    const events = await getContact.getEvents(entryId);
    this.setState({ events: events });

    if (this.props.entry) ContactTracker.trackContactUsage(this.props.entry, 'detail');
    // window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  // componentWillUnmount(){
  //   window.removeEventListener('scroll', this.handleScroll.bind(this));
  // }

  // async handleScroll(event, b) {
  //   if(this.state.events !== false) return;
  //   console.log(event, b);
  //
  //   let y = 0;
  //   if (typeof event.pageY !== 'undefined') {
  //     y = event.pageY;
  //   } else if (typeof document.scrollingElement !== 'undefined') {
  //     y = document.scrollingElement.scrollTop;
  //   } else if (typeof document.documentElement.scrollTop !== 'undefined') {
  //     y = document.documentElement.scrollTop;
  //   } else if (typeof document.body.scrollTop !== 'undefined') {
  //     y = document.body.scrollTop;
  //   }
  //
  //   if(y > 200) {
  //     this.setState({events: 'loading'});
  //     const events = await getContact.getEvents(this.props.match.params.id);
  //     console.log(events)
  //     this.setState({events: events});
  //   }
  // }

  async componentDidUpdate(prevProps) {
    let entryId = this.props.entryId
      ? this.props.entryId
      : this.props.match.params.id;
    let entryType = this.props.entryType
      ? this.props.entryType
      : this.props.match.params.type;

    if (!entryId || entryId === 0) {
      this.isProfile = true;
      this.props.match.params.id = 0;
      this.props.match.params.type = "person";
      entryId = 0;
      entryType = "person";
    } else {
      this.isProfile = false;
    }

    if (
      entryId !== prevProps.match.params.id &&
      entryId !== prevProps.entryId
    ) {
      const events = await getContact.getEvents(entryId);
      this.setState({ events: events });

      this.cleanObject = {}
      this.contactType = entryType;
      this.setState({ value: 0, qr: false });
      this.qrInit = false;

      if (!this.props.entry) {
        if (this.contactType === "person") {
          await getContact.populatePerson(entryId, true);
        } else if (this.contactType === "organization") {
          // we need current data always
          await getContact.populateOrganization(entryId, true);
        } else {
          // alert('Url malformed!');
          return;
        }
      } else {
        if (this.props.entry) {
          if (this.props.entryType === "person") {
            store.generalStore.setPerson(this.props.entry);
          } else {
            store.generalStore.setOrganization(this.props.entry);
          }
        }
      }

      this.filterObject();

      this.setState({ notes: [] });
      const notes = await getContact.getNotes(entryId);
      this.setState({ notes: notes });
      // if (this._isMounted) this.forceUpdate();
    }

    ContactTracker.trackContactUsage(toJS(store.generalStore[this.contactType]), 'detail');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  revertEvent(eventId) {
    console.log(eventId)
    this.setState({
      revertEventDialog: true,
      revertEventId: eventId
    })
  }

  async handleSuggestion(contact, command) {
    const uid = this.props.entryId
      ? this.props.entryId
      : this.props.match.params.id;

    await confirmOrDeclineSuggestion(uid, contact, command);
    await getContact.populatePerson(uid, true);
    this.filterObject();
  }

  handleRevertCancel() {
    this.setState({
      revertEventDialog: false,
      revertEventId: false
    })
  }

  async handleRevertConfirm() {
    if (this.state.revertEventId) {
      await revertEvent(this.state.revertEventId)
    }

    this.setState({
      revertEventDialog: false,
      revertEventId: false
    })

    let id = this.props.entryId
      ? this.props.entryId
      : this.props.match.params.id;

    const events = await getContact.getEvents(id);
    this.setState({ events: events });

    if (this.contactType === "person") {
      await getContact.populatePerson(id, true);
    } else {
      await getContact.populateOrganization(id, true);
    }

    this.filterObject();

  }

  filterObject() {
    if (
      !store.generalStore[this.contactType] ||
      store.generalStore[this.contactType] === 404
    )
      return;
    this.cleanObject = toJS(store.generalStore[this.contactType]);

    // if (this.contactType !== "person") return;

    for (let j = 0; j < this.cleanObject.relations.length; j++) {
      // Check if it's an orgToPers Relation
      if (
        this.cleanObject.relations[j].type === "OrganizationToPerson" ||
        this.cleanObject.relations[j].type === "PersonToOrganization"
      ) {
        this.typeReferences.push(this.cleanObject.relations[j]);
      }
    }

    const value = this.state.value;
    const currentRef =
      this.state.value === 0 || !this.typeReferences[this.state.value - 1].uid
        ? ""
        : this.typeReferences[this.state.value - 1].uid;
    this.currentRef = currentRef;

    let i;
    let length;
    // // Addresses
    // length = this.cleanObject.addresses.length;
    //
    // for(i=0; i<length; i+=1){
    //   if(!this.cleanObject.addresses[i]) continue;
    //   if(value === 0){
    //     if(this.cleanObject.addresses[i].contextRef
    //        && this.cleanObject.addresses[i].contextRef !== ''
    //        && this.cleanObject.addresses[i].contextRef !== null){
    //       this.cleanObject.addresses.splice(i,1);
    //       i-=1;
    //     }
    //   } else {
    //     if(!this.cleanObject.addresses[i].contextRef
    //       || this.cleanObject.addresses[i].contextRef !== currentRef){
    //       this.cleanObject.addresses.splice(i,1);
    //       i-=1;
    //     }
    //   }
    // }

    // ContactData
    length = this.cleanObject.contactData.length;

    for (i = 0; i < length; i += 1) {
      if (!this.cleanObject.contactData[i]) continue;

      if ('type' in this.cleanObject.contactData[i] && this.cleanObject.contactData[i].type.match(/^reference:/u) !== null) {
        this.cleanObject.contactData.splice(i, 1);
        i -= 1;
      } else if (value === 0) {
        if (
          this.cleanObject.contactData[i].contextRef &&
          this.cleanObject.contactData[i].contextRef !== "" &&
          this.cleanObject.contactData[i].contextRef !== null
        ) {
          this.cleanObject.contactData.splice(i, 1);
          i -= 1;
        }
      } else {
        if (
          !this.cleanObject.contactData[i].contextRef ||
          this.cleanObject.contactData[i].contextRef !== currentRef
        ) {
          this.cleanObject.contactData.splice(i, 1);
          i -= 1;
        }
      }
    }

    // Categories
    length = this.cleanObject.categories.length;

    for (i = 0; i < length; i += 1) {
      if (!this.cleanObject.categories[i]) continue;
      if (value === 0) {
        if (
          this.cleanObject.categories[i].contextRef &&
          this.cleanObject.categories[i].contextRef !== "" &&
          this.cleanObject.categories[i].contextRef !== null
        ) {
          this.cleanObject.categories.splice(i, 1);
          i -= 1;
        }
      } else {
        if (
          !this.cleanObject.categories[i].contextRef ||
          this.cleanObject.categories[i].contextRef !== currentRef
        ) {
          this.cleanObject.categories.splice(i, 1);
          i -= 1;
        }
      }
    }

    // Groups

    if(!this.cleanObject.groups) this.cleanObject.groups = [];

    const isUsersProfile =
      this.cleanObject &&
      this.cleanObject.isUser &&
      this.cleanObject.meta &&
      this.cleanObject.meta.user &&
      toJS(store.authStore.userData.user) === this.cleanObject.meta.user;

    if (isUsersProfile) {
      const entryId = this.props.entryId
        ? this.props.entryId
        : this.props.match.params.id;

      if (this.qrInit === false && this.state.qr === false) {
        this.qrInit = true;
        // const entryId = this.props.entryId
        //   ? this.props.entryId
        //   : this.props.match.params.id;
        createVCardQRCode(
          entryId,
          "persons",
          function (url) {
            this.setState({
              qr: url
            });
          }.bind(this)
        );
      }
    }
  }

  avatarError(e) {
    this.setState({
      avatarError: true,
    })
  }

  toggleFavorite() {
    const profileSettings = toJS(store.generalStore.profileSettings);

    let favorites = {};
    if (
      "settings" in profileSettings &&
      "favorites" in profileSettings.settings
    ) {
      favorites = profileSettings.settings.favorites;
    }

    let modus;
    if (this.cleanObject.uid in favorites) {
      delete favorites[this.cleanObject.uid];
      modus = "delete";
    } else {
      if ("name" in this.cleanObject) {
        favorites[this.cleanObject.uid] = { lastUpdate: Date.now() };
      } else {
        favorites[this.cleanObject.uid] = { lastUpdate: Date.now() };
      }
      modus = "add";
    }

    ContactEditor.editFavorites(favorites, true, modus);
    this.forceUpdate();
  }

  editClick=(event)=>{
    if(this.cleanObject.groups.length === 0){
       return true;
    } else if(!store.authStore.userData || !store.authStore.userData.permissions) {
     // No permissions loaded yet
     return false;
    } else if(store.authStore.userData.permissions.indexOf('tenant.all') > -1) {
      return true;
    } else {
      const permissions = toJS(store.authStore.userData.permissions);
      console.log('User-Permissions', permissions);
      for(let i=0;i<this.cleanObject.groups.length; i+=1) {
        const keyEdit = `group.${this.cleanObject.groups[i].uid}.edit`;
        if(permissions.indexOf(keyEdit) > -1) return true;
      }
    }

    store.generalStore.addMessage(404, 'You do not have the permission to edit this entry');
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  render() {
    const desktop = window.innerWidth >= constants.breakpoints.max
    let favorite = false;
    const profileSettings = toJS(store.generalStore.profileSettings);
    const uid = this.cleanObject.uid
      ? this.cleanObject.uid
      : store.generalStore[this.contactType] &&
        store.generalStore[this.contactType].uid
        ? store.generalStore[this.contactType].uid
        : null;

    if (
      "settings" in profileSettings &&
      "favorites" in profileSettings.settings &&
      uid in profileSettings.settings.favorites
    ) {
      favorite = true;
    }

    const { classes } = this.props;
    const { t } = this.props;
    const headerClass = classes.headerArea;

    if (store.generalStore[this.contactType] === null) {
      return (
        <div className={classes.root}>
          <Grid
            container
            spacing={16}
            className={classes.demo}
            justify="space-evenly"
            direction="column"
            alignItems="stretch"
          >
            <Grid item xs={"auto"}>
              <Paper className={headerClass}>
                <Typography variant="h6">
                  <Skeleton circle={true} height={60} width={60} />
                </Typography>
                <Typography variant="h6">
                  <Skeleton width={200} />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    if (store.generalStore[this.contactType] === 404) {
      return (
        <div>
          <Grid item xs={12} key="editGrid">
            <Paper className={classes.control}>
              <Grid container>
                <ListItem>
                  <NotFoundIcon className={classes.missingIcon} />
                  <CardHeader
                    title={t("Contact Not Found Title")}
                    className={classes.missingTitle}
                  />
                </ListItem>
                <Divider style={{ width: "100%" }} />
                <ListItem>
                  <Typography paragraph>
                    {t("Contact Not Found Explanation 1")}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography paragraph>
                    {t("Contact Not Found Explanation 2")}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography paragraph>
                    {t("Contact Not Found Explanation 3")}
                  </Typography>
                </ListItem>
              </Grid>
            </Paper>
          </Grid>
        </div>
      );
    } else {
      this.filterObject();
      const date = new Date(parseInt(this.cleanObject.lastUpdate));
      const momentParsedDate = moment(date);
      const lastUpdate = momentParsedDate.format("HH:mm, DD.MM.YYYY");

      // Custom time format
      // const formattedDate = {
      //     hours: momentParsedDate.format("HH"),
      //     minutes: momentParsedDate.format("mm"),
      //     day: momentParsedDate.format("dd"),
      //     month: momentParsedDate.format("MMMM"),
      //     year: momentParsedDate.format("YYYY")
      // };
      // const lastUpdate = `${formattedDate.hours}:${formattedDate.minutes} ${formattedDate.day} ${formattedDate.month} ${formattedDate.year}`;

      // A list of all owners of addresses, for persons it is filled with all organization relation addresses. Always has "own"
      let addressOwners = ["own"];
      // If there are addresses from relations, add them here with the key matching their owner name
      const addresses = { own: this.cleanObject.addresses };
      let persAttr = {};
      let fullName;
      let initials;

      if (this.contactType === "person") {
        for (let j = 0; j < this.cleanObject.relations.length; j++) {
          // Check if it's an orgToPers Relation
          if (
            this.cleanObject.relations[j].type === "OrganizationToPerson" ||
            this.cleanObject.relations[j].type === "PersonToOrganization"
          ) {
            // If yes, add its name to the addressOwners array if not existent
            if (
              addressOwners.indexOf(
                this.cleanObject.relations[j].partner.name
              ) !== -1
            )
              continue;
            addressOwners.push(this.cleanObject.relations[j].partner.name);
            // Also add the relation's addresses to the addresses object
            addresses[this.cleanObject.relations[j].partner.name] = this
              .cleanObject.relations[j].partner.addresses
              ? this.cleanObject.relations[j].partner.addresses
              : [];
          }
        }

        for (let attr of shownAttributes) {
          if (this.cleanObject[attr]) {
            persAttr[attr] = this.cleanObject[attr];
          }
        }

        fullName = this.cleanObject.firstName + " " + this.cleanObject.lastName;

        const firstNameIntitial = this.cleanObject.firstName[0]
          ? this.cleanObject.firstName[0]
          : "";
        const lastNameIntitial = this.cleanObject.lastName[0]
          ? this.cleanObject.lastName[0]
          : "";
        initials = firstNameIntitial + lastNameIntitial;
      } else if (this.contactType === "organization") {
        fullName = this.cleanObject.name;
        initials = this.cleanObject.name[0];
      }
      let editLink =
        "name" in this.cleanObject
          ? `${constants.path.editOrganization}/${this.cleanObject.uid}`
          : `${constants.path.editPerson}/${this.cleanObject.uid}`;

      if (this.state.value > 0) editLink = `${editLink}/${this.state.value}`;

      // Set image type for avatar
      if (this.contactType === "person") {
        this.imageType = this.cleanObject.photo;
      } else if (this.contactType === "organization") {
        this.imageType = this.cleanObject.logo;
      }

      // const currentEntry = {
      //   name: fullName,
      //   uid: this.cleanObject.uid,
      //   image:
      //     "photo" in this.cleanObject
      //       ? this.cleanObject.photo
      //       : "logo" in this.cleanObject
      //       ? this.cleanObject.logo
      //       : ""
      // };

      const { value } = this.state;

      let tabs = [];
      if (this.contactType === "person") {
        for (let i = 0; i < addressOwners.length; i++) {
          tabs.push(<Tab key={i} label={t(addressOwners[i])} />);
        }
      }

      const headerId =
        this.contactType === "person" ? "personview-header" : "view-header";

      let scrollClass =
        this.contactType === "person" &&
          store.generalStore.scrolled > 140 &&
          !this.props.entryId
          ? "scrolled"
          : "";

      const almostEmpty =
        this.cleanObject.contactData.length <= 0 &&
          this.cleanObject.categories.length <= 0 &&
          this.cleanObject.relations.length <= 0 &&
          Object.keys(persAttr).length <= 0
          ? true
          : false;

      const editLinkStyle = this.props.entryId
        ? classes.editLinkLocal
        : classes.editLink;

      /*
            <Typography className={classes.typography} variant='body1' align='left'>
                {t('Graph')}
            </Typography>
            <React.Suspense fallback={<div>Loading...</div>}>
              <Graph />
            </React.Suspense>
            */

      const premium = (store.authStore.userData && store.authStore.userData.premium);

      return (
        <div className={classes.outer}>
          {!this.props.noEdit && (
            <Link onClick={this.editClick} to={editLink} id="editlink" className={editLinkStyle}>
              <EditIcon className={classes.editIcon} />
            </Link>
          )}
          <Card className={classes.card}>
            {this.contactType === "person" && (
              <div
                className={
                  store.generalStore.scrolled > 140 && !this.props.entryId
                    ? classes.headerPlaceholder
                    : ""
                }
              ></div>
            )}

            <div id={headerId} className={scrollClass + " " + headerClass}>
              <Grid container justify="center">
                {/*<Avatar className={classes.avatar}>{initials}</Avatar> */}
                <Avatar className={classes.avatar} src={this.state.avatarError ? null : this.imageType} onError={this.avatarError.bind(this)}>
                  {initials}
                </Avatar>
              </Grid>

              <Typography variant="h5">
                {fullName}
                <IconButton
                  onClick={this.toggleFavorite.bind(this)}
                  key={`favorite`}
                  title={t("favor")}
                  className={
                    favorite ? classes.favoriteIcon : classes.notFavoriteIcon
                  }
                >
                  {favorite !== true && <EmptyStarIcon />}
                  {favorite === true && <StarIcon />}
                </IconButton>
              </Typography>

              {this.contactType === "person" && (
                <div className={classes.appBarHolder}>
                  <AppBar
                    position="static"
                    color="inherit"
                    className={
                      window.scrollY > 170
                        ? classes.appBarScrolled
                        : classes.appBar
                    }
                  >
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {tabs}
                    </Tabs>
                  </AppBar>
                </div>
              )}
              {this.contactType !== "person" && (
                <div className={classes.spacer}></div>
              )}
            </div>

            {almostEmpty && (
              <div
                className={
                  this.contactType !== "person" ? classes.emptyClass : ""
                }
              >
                {t("There has not been added any further data yet")}
              </div>
            )}
            <Categories categories={this.cleanObject.categories} />

            <ContactInfos contactData={this.cleanObject.contactData} uid={this.cleanObject.uid} contactType={this.contactType} handleSuggestion={this.handleSuggestion.bind(this)} />

            <Addresses
              addresses={addresses}
              owners={addressOwners}
              value={value}
            />

            {/*<Relations relations={this.cleanObject.relations} currentEntry={currentEntry} />*/}

            <RelationsGraph
              entry={this.cleanObject}
              tabValue={this.state.value}
            />

            <PersonalInformation attributes={persAttr} />

            <Typography
              className={classes.typography}
              variant="body1"
              align="left"
            >
              {t("Groups")}
            </Typography>
            <Divider variant="middle" className={classes.divider} />

            <Groups groups={this.cleanObject.groups} />

            {(!this.props.entryId && !desktop) && <AddButton isDetailView={true} />}

            {this.state.qr !== false && (
              <>
                <Typography
                  className={classes.typography}
                  variant="body1"
                  align="left"
                >
                  {t("QR-Code")}
                </Typography>
                <Divider variant="middle" className={classes.divider} />
                <img
                  className={classes.qrCode}
                  alt={t("QR-Code")}
                  src={this.state.qr}
                />
              </>
            )}

            {this.cleanObject && this.cleanObject.uid && (
              <>
                <Typography
                  className={classes.typography}
                  variant="body1"
                  align="left"
                >
                  {t("Export")}
                </Typography>
                <Divider variant="middle" className={classes.divider} />
                <Button
                  className={
                    this.state.qr ? classes.vCardCenter : classes.vCard
                  }
                >
                  <a
                    href={getVCardDownloadLink(
                      this.cleanObject.uid,
                      `${this.contactType}s`
                    )}
                  >
                    <VCardIcon className={classes.vCardIcon} />
                    <span className={classes.vCardText}>{t("vCard")}</span>
                  </a>
                </Button>
              </>
            )}
            <NoteBlock
              entryUid={this.props.entryId
                ? this.props.entryId
                : this.props.match.params.id}
              type={this.contactType}
              entryName={
                this.cleanObject.name
                  ? this.cleanObject.name
                  : `${this.cleanObject.firstName} ${this.cleanObject.lastName}`.trim()
              }
              premium={premium}
            />
          </Card>
          <Typography
            className={classes.lastUpdate}
            variant="body1"
            align="left"
          >
            {t("Last updated")}:{" "}
            <span style={{ fontStyle: "italic" }}>{lastUpdate}</span>
          </Typography>

          <HistoryBlock data={this.state.events} revertEvent={this.revertEvent.bind(this)} />
          {/*<OIHHistoryBlock uid={this.cleanObject.uid} />*/}

          <Dialog
            open={this.state.revertEventDialog}
            onClose={this.handleRevertCancel.bind(this)}
          >

            <DialogTitle id="alert-dialog-title">{t("Revert Change?")}</DialogTitle>
            <DialogActions>
              <Button onClick={this.handleRevertConfirm.bind(this)} color="primary">
                {t('Revert')}
              </Button>
              <Button onClick={this.handleRevertCancel.bind(this)} color="secondary" autoFocus>
                {t('cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }


};

export default withTranslation("translations")(
  withStyles(styles)(withRouter(observer(DetailView)))
);
