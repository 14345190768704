// Renders a single result of type person or organization in a listView

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';

// React Internationalization
import { withTranslation } from 'react-i18next';

import { toJS } from 'mobx';
// Mobx Stores
import constants from '../../stores/constants';
// import store from '../../stores';

const styles = (theme) => ({
	control: {
		padding: theme.spacing.unit,
	},
	avatar: {
		margin: 10,
		marginLeft: 0,
		color: '#fff',
		backgroundColor: constants.color.main,
		width: 50,
		height: 50,
		float: 'left'
	},
	name: {
		marginTop: theme.spacing.unit * 2.2,
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},
	grid: {
		height: theme.spacing.unit * 12
	},
	userProfile: {
		position: 'absolute',
		marginLeft: theme.spacing.unit * 0.5,
		marginTop: theme.spacing.unit * 0.5,
		color: constants.color.main
	},
	fullName: {
		marginLeft: theme.spacing.unit * -2
	},
	userIcon: {
		height: '20px',
		width: '20px',
		marginLeft: theme.spacing.unit * 0.7,
		marginTop: theme.spacing.unit * 0.2
	},
	flexContainer: {
		flexShrink: 0,
		flexGrow: 1,
	},
	checkContainer: {
		maxWidth: 100,
		paddingRight:0,
		color: constants.color.heading,
		fontSize: '14px',
	},
	textContainer: {
		padding:0,
		// marginLeft: theme.spacing.unit*1.5,
		color: constants.color.heading,
		fontSize: '14px',
	},
	matchesContainer: {
		padding:0,
		textAlign: 'center',
		color: constants.color.heading,
		fontSize: '14px',
	},
	scoreContainer: {
		padding:0,
		width: 60,
		maxWidth: 60,
		textAlign: 'center',
		color: constants.color.heading,
		fontSize: '14px',
	},
	explain: {
		fontSize: '12px',
	},
});

class GridItem extends Component {
	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	handleClick() {
		if (
			typeof this.props.redirect !== 'undefined' &&
			this.props.redirect === false &&
			this.props.setSelectedPartner
		) {
			this.props.setSelectedPartner(toJS(this.props.entry));
		}
	}

	handleChange(id, value, element) {
		console.log(id, value, element);
		this.props.setCheckmarkAll(id, value === false);
		this.forceUpdate();
	}

	render() {
		const { classes, t } = this.props;

		const check = (this.props.checked)? this.props.checked : false;
		return (
			<Grid className={classes.grid} item xs={12} key={0}>

				<div className={classes.control}>
					<Grid container>
							<ListItem className={classes.flexContainer} key={this.props.id} selected={this.props.selectedIndex === 0}>
								<ListItemText className={classes.checkContainer} >
								<Checkbox
									checked={check}
									onChange={this.handleChange.bind(this, this.props.id, check)}
									value={'all'}
									inputProps={{
										'aria-label': 'secondary checkbox',
									}}
								/>
								<div className={classes.explain}>{t('Select all')}</div>
								</ListItemText>

								<ListItemText classes={{ primary: classes.textContainer }} className={classes.textContainer} >
									{t('Name and Date')}
								</ListItemText>

								<ListItemText classes={{ primary: classes.matchesContainer }} className={classes.matchesContainer} >
									{t('Nr. of matches')}
								</ListItemText>

								<ListItemText classes={{ primary: classes.scoreContainer }} className={classes.scoreContainer} >
								{t('Score')}
								</ListItemText>
							</ListItem>
					</Grid>
				</div>
			</Grid>
		);
	}
}

export default withStyles(styles)(withTranslation('translations')(withRouter(GridItem)));
