
import moment from 'moment'

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// components
import ResultGridHeaderRelations from '../../templates/ResultGridHeaderRelations';
import ResultGridItemRelations from '../../templates/ResultGridItemRelations';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

import VirtualList from 'react-tiny-virtual-list';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Divider from '@material-ui/core/Divider';

import RelationsIcon from '@material-ui/icons/DeviceHub';
import DeleteIcon from '@material-ui/icons/Cancel';

//

import DetailView from '../../detail/DetailView';

import DoneIcon from '@material-ui/icons/CheckCircle';


// Actions
import relations from '../../../actions/relations';
import getRelations from '../../../actions/getRelations';

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

// let ModuleA = false;

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
      transition: '0.5s all ease-in-out',
    },

    title: {
        margin: theme.spacing.unit * 2,
        paddingTop: '10vh',
        color: constants.color.main
    },
    notFound: {
        marginTop: theme.spacing.unit * 5,
        color: constants.color.main,
        height: '30vw',
        width: '30vw',
        maxWidth: '187px',
        maxHeight: '187px',
    },
    deleteIcon: {
      color: constants.color.delete,
      float: 'right',
      cursor: 'pointer',
    },
    doneIconBig: {
      color: constants.color.success,
      fontSize: '40px',
      marginRight: theme.spacing.unit,
    },
    errorMessage: {
      color: constants.color.error,
      margin: theme.spacing.unit * 2,
    },
});

class Relations extends Component {
    _isMounted = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
        detailDialog: false,
        detailUid: '',
        detailType: '',
        error: false,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        getRelations.populateRelationTypes();
    }

    handleDetailShow(value, entryType){

      const desktop = (window.innerWidth >= constants.breakpoints.max);

      if(desktop) {
        const path = `${constants.path.detailview}/${entryType}/${value}`;
        this.props.history.push(path);
      } else {
        this.setState({
          detailDialog: true,
          detailUid: value,
          detailType: entryType
        });
      }

    }

    handleDetailClose(){
      this.setState({
        detailDialog: false,
      });
    }

    async componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }

        // if(true){
        //   ModuleA = await import(/* webpackChunkName: "my-folder/my-component" */ './moduleA');
        //   import(/* webpackChunkName: "my-folder/my-component" */ './moduleB');
        // }

        this.props.generalStore.setScrollToIndex(-1);

        await this.props.relationsStore.getRelationSuggestions();

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }
    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);
        //
        // this.props.relationsStore.setrelations([]);
        // this.props.relationsStore.setrelationsCount(0);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }

    onClickDelete(uid) {
        // this.props.relationsStore.deletePerson(uid);
    }


    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }

    setCheckmark(id, value) {
      this.props.relationsStore.setChecked(id, value);
    }

    setCheckmarkAll(id, value) {
      this.props.relationsStore.setCheckedAll(value);
      this.forceUpdate();
    }

    handleSwap(id){
      this.props.relationsStore.swapRelation(id);
      this.forceUpdate();
    }

    setRelationType(id, value) {
      this.props.relationsStore.setRelationType(id, value);
    }

    async doAction(action){
      let base = toJS(this.props.relationsStore.relationSuggestions);
      let addIds = [];
      let ignoreIds = [];
      let toRemove = [];
      const length = base.length;

      for(let i=0; i<length;i+=1){
        if(i in base && 'checked' in base[i]) {
          if(base[i].checked === 'add') {
            if('relationType' in base[i] && base[i].relationType !== null) {
              addIds.push({
                id: base[i]._id,
                relationType: {label: base[i].relationType.label, contextRef: base[i].relationType.value},
                from: base[i].incomingObject.uid,
                to: base[i].targetObject.uid,
              });
              toRemove.push(i);
            } else {
              this.setState({
                error: this.props.t('You need to select an type for every relation you want to add!'),
              });
              return false;
            }
          } else if(base[i].checked === 'ignore') {
            ignoreIds.push({
              id: base[i]._id,
              // from: base[i].incomingObject.uid,
              // to: base[i].targetObject.uid,
            });
            toRemove.push(i);
          }
        }
      }

      this.setState({
        error: false,
      });

      let result = false;
      if(addIds.length > 0) result = await relations.resolveBulk('add', addIds);
      if(ignoreIds.length > 0) result = await relations.resolveBulk('ignore', ignoreIds);

      if(result) {
        const removeLength = toRemove.length-1;
        for(let i=removeLength; i>=0; i-=1){
          base.splice(toRemove[i], 1)
        }
        this.props.relationsStore.setRelationSuggestions(base);
      }
    }

    renderRow(data) {

        const realIndex = data.index;

        if (this.props.relationsStore.relationSuggestions[realIndex]) {

            // console.log(this.props.relationsStore.personsIndex);
            const indexed = this.props.relationsStore.relationSuggestionsIndex[realIndex];

            let name = ('name' in this.props.relationsStore.relationSuggestions[realIndex]) ? this.props.relationsStore.relationSuggestions[realIndex].name : this.props.relationsStore.relationSuggestions[realIndex].firstName;
            if (typeof name === 'undefined') name = '';
            let char = name.substr(0, 1).toUpperCase();

            return (
                <div key={data.index} style={data.style} className="row">
                    { window.innerWidth < 600 &&
                      <Divider />
                    }
                    {indexed &&
                        <ClusterLine key={char} text={char} />
                    }
                    <ResultGridItemRelations
                        key={this.props.relationsStore.relationSuggestions[data.index].uid}
                        id={data.index}
                        entry={this.props.relationsStore.relationSuggestions[data.index]}
                        selectedIndex={this.state.selectedIndex}
                        setCheckmark={this.setCheckmark.bind(this)}
                        relationTypes={toJS(this.props.relationsStore.relationTypes)}
                        handleDetailShow={this.handleDetailShow.bind(this)}
                        handleSwap={this.handleSwap.bind(this)}
                        setRelationType={this.setRelationType.bind(this)}
                    />
                    { window.innerWidth < 600 &&
                      <Divider />
                    }

                </div>
            )
        } else {
            return (
                <div key={data.index} />
            )
        }
    }

    itemSizeGetter(i) {

        const indexed = this.props.relationsStore.relationSuggestionsIndex[i];

        if (indexed) {
          if(window.innerWidth < 600) {
            return 250;
          } else {
            return 188;
          }
        } else {
          if(window.innerWidth < 600) {
            return 200;
          } else {
            return 158;
          }
        }

    }

    onScroll(pos) {
        // this.props.generalStore.setScrolled(pos);
        if (pos > 0) {
            if (this.props.generalStore.scrolled <= 0) {
                this.props.generalStore.setScrolled(pos);
            }
        } else {
            if (this.props.generalStore.scrolled > 0) {
                this.props.generalStore.setScrolled(pos);
                this.props.generalStore.setScrollToIndex(0);
            }
        }

    }

    render() {
        const { classes } = this.props;
        const dialogPaper = { maxWidth: '90vw', maxHeight: '85vh', margin: 'auto' };
        const height = calculateVlistHeight();
        if (window.innerWidth > 600) {
          dialogPaper.maxWidth = '600px'
        }

        const { t } = this.props;
        // const { relations } = this.props.relationsStore.relations;

        let counter = 0;

        // if(ModuleA !== false) return (<ModuleA.default />);

        if (this.props.relationsStore.relationSuggestions && this.props.relationsStore.relationSuggestions.length > 0) {

            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] potential relations', { v: this.props.relationsStore.relationSuggestionsCount })}</React.Fragment>} />
                    {this.state.error !== false &&
                      <div className={classes.errorMessage}>{this.state.error}</div>
                    }
                    {this.props.relationsStore.selections > 0 &&
                    <React.Fragment>
                      <Button component="span" onClick={this.doAction.bind(this)} className={classes.button}>
                          <DoneIcon className={classes.doneIconBig} />{t('confirm changes')}
                      </Button>
                      <div>({`${this.props.relationsStore.selections} ${t('entries')}`})</div>
                    </React.Fragment>
                    }
                    <ResultGridHeaderRelations
                      setCheckmarkAll={this.setCheckmarkAll.bind(this)}
                      checked={this.props.relationsStore.allChecked}
                    />

                    <VirtualList
                        width='100%'
                        className={classes.animated}
                        height={height}
                        itemCount={this.props.relationsStore.relationSuggestionsCount || 0}
                        itemSize={this.itemSizeGetter.bind(this)}
                        renderItem={this.renderRow.bind(this)}
                        scrollToIndex={(this.props.generalStore.scrollToIndex < 0) ? null : this.props.generalStore.scrollToIndex}
                        scrollToAlignment={'center'}
                        scrollOffset={0}
                        onScroll={this.onScroll.bind(this)}
                        overscanCount={10}
                    />

                    <Dialog
                        open={this.state.detailDialog}
                        onClose={this.handleDetailClose.bind(this)}
                        aria-labelledby="detail-dialog-title"
                        fullWidth={true}
                        fullScreen={true}
                        style={dialogPaper}
                    >
                        <DialogTitle id="alert-dialog-title"><DeleteIcon className={classes.deleteIcon} onClick={this.handleDetailClose.bind(this)} /></DialogTitle>
                        <DialogContent style={{textAlign: 'center'}}>
                            <DetailView entryId={this.state.detailUid} entryType={this.state.detailType} noEdit={true}  />
                        </DialogContent>
                    </Dialog>
                </div>
            )
        } else if (((this.props.relationsStore.relationSuggestions && this.props.relationsStore.relationSuggestions.length === 0) || !this.props.relationsStore.relationSuggestions) && !this.state.isLoading) {
            const lastRun = moment(new Date(`${moment().format('YYYY-MM-DD')}T01:30:00`));
            const nextRun = moment(new Date(`${moment().add(1, 'days').format('YYYY-MM-DD')}T01:30:00`));
            const current = moment();
            const beforeHours = moment.duration(current.diff(lastRun)).asHours();
            const inHours = moment.duration(nextRun.diff(current)).asHours();

            return (
              <>
              <InfoBar noSorting={true} children={<React.Fragment>{t('Last scan [x] hours ago.', { x: Math.ceil(beforeHours)})}<br/>{t('Next scan in [x] hours.', { x: Math.ceil(inHours)})}</React.Fragment>} />
              <Typography className={classes.title} align="center" paragraph variant="h4">
                  {t('At the moment there are no detected relations')}
              </Typography>
              <RelationsIcon className={classes.notFound} />
              </>
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.relationsStore.relationSuggestions) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div />} />);
        }
    }
}

relations.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('relationsStore', 'generalStore')(observer(Relations)))));
