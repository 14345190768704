import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

// import { toJS } from 'mobx';


// Material UI
import { withStyles } from '@material-ui/core/styles';

// Mobx
import { inject, observer } from 'mobx-react';
import { extendObservable, action, decorate } from 'mobx';

// React Internationalization
import { withTranslation } from "react-i18next";

// Components
import constants from '../../stores/constants';

import Abc from './Abc';
import Person from '../address-list/persons/Person';
import Organization from '../address-list/organizations/Organization';
import PersonsAndOrganizations from '../address-list/all/PersonsAndOrganizations';

import ByCategory from '../address-list/byCategory/ByCategory';

import ByGroup from '../address-list/byGroup/ByGroup';

import Favorites from '../address-list/favorites/Favorites';

import Duplicates from '../address-list/duplicates/Duplicates';

import RelationSuggestions from '../address-list/relations/RelationSuggestions';

import DeleteRequests from '../address-list/deleteRequests/DeleteRequests';

import AddButton from '../templates/AddButton';

import ListJoyride from '../joyride/ListJoyride';

const styles = theme => ({
    root: {
        backgroundColor: '#fff',
        overflow: 'hidden',
        transition: '0.5s all ease-in-out',
        position: 'relative'
    },
    rootScrolled: {
        backgroundColor: '#fff',
        marginTop: '-90px',
        overflow: 'hidden',
        transition: '0.5s all ease-in-out',
        position: 'relative',
    },
});

class Addresslist extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        extendObservable(this, {
            showPersons: true,
            showOrganizations: false,
            showAll: false,
        });

        this.onClickShowPersons = this.onClickShowPersons.bind(this);
        this.onClickShowOrganizations = this.onClickShowOrganizations.bind(this);
        this.onClickShowAll = this.onClickShowAll.bind(this);
    }

    componentDidMount() {
      if(this.props.generalStore.scrollToIndex > 0)
        this.props.generalStore.setScrollToIndex(0);
        this.props.generalStore.setScrolled(0);
      window.scrollTo(0, 0);

        const medium = window.innerWidth >= constants.breakpoints.medium;
        this._isMounted = true;
        if(!medium) document.body.style.marginTop = (this.props.generalStore.scrolled > 0) ? '56px' : '100px';
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
    }

    componentDidUpdate() {
        const medium = window.innerWidth >= constants.breakpoints.medium;
        if(!medium) document.body.style.marginTop = (this.props.generalStore.scrolled > 0) ? '56px' : '100px';
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        const medium = window.innerWidth >= constants.breakpoints.medium;
        const desktop = window.innerWidth >= constants.breakpoints.max;
        this.props.generalStore.setScrolled(0);
        if(!medium) document.body.style.marginTop = '105px';
        if(!desktop) document.body.style.overflowY = 'auto';
        if(!desktop) document.documentElement.style.overflowY = 'auto';
    }

    onClickShowOrganizations() {
        this.props.history.push('/addresslist');
    }
    onClickShowPersons() {
        this.props.history.push('/addresslist');
    }

    onClickShowAll() {
        this.props.history.push('/addresslist');
    }

    onClickShowProfile() {
        this.props.history.push('/profile');
    }

    onClickAddContact(type) {
        this.props.history.push(`/create/${type}`)
    }


    render() {
        let renderComponent;
        let listState = false;
        let showABC = true;
        let showAddButton = true;
        const pathname = this.props.history.location.pathname;

        if (pathname.indexOf(constants.path.listByCategory) !== -1) {
            renderComponent = <ByCategory />
            listState = pathname;
        } else if (pathname.indexOf(constants.path.listByGroup) !== -1) {
            renderComponent = <ByGroup />
            listState = pathname;
        } else {
            switch (pathname) {
                case constants.path.listPersons:
                    renderComponent = <Person />;
                    listState = 'person';
                    break;
                case constants.path.listOrganizations:
                    renderComponent = <Organization />;
                    listState = 'organization';
                    break;
                case constants.path.listAll:
                    renderComponent = <PersonsAndOrganizations />
                    listState = 'personsAndOrganizations';
                    break;
                case constants.path.listDuplicates:
                    renderComponent = <Duplicates />
                    listState = 'duplicates';
                    showABC = false;
                    showAddButton = false;
                    break;
                case constants.path.listFavorites:
                    renderComponent = <Favorites />;
                    listState = 'favorites';
                    break;
                case constants.path.listRelationSuggestions:
                    renderComponent = <RelationSuggestions />
                    listState = 'relationSuggestions';
                    showABC = false;
                    showAddButton = false;
                    break;
                case constants.path.profile:
                    this.props.history.push('/profile');
                    break;
                case constants.path.deleteRequests:
                    showABC = false;
                    showAddButton = false;
                    renderComponent = <DeleteRequests />
                    listState = 'deleteRequests';
                    break;
                default:
                    break;
            }
        }

        if(renderComponent && renderComponent !== false) {
          this.props.generalStore.setListState(listState);
        } else {
          if (this.props.generalStore.listState.indexOf(constants.path.listByCategory) !== -1) {
            renderComponent = <ByCategory />;
          } else if (this.props.generalStore.listState.indexOf(constants.path.listByGroup) !== -1) {
            renderComponent = <ByGroup />;
          } else {
            switch (this.props.generalStore.listState) {
                case 'person':
                    renderComponent = <Person />;
                    break;
                case 'organization':
                    renderComponent = <Organization />;
                    break;
                case 'personsAndOrganizations':
                    renderComponent = <PersonsAndOrganizations />
                    break;
                case 'duplicates':
                    renderComponent = <Duplicates />
                    showABC = false;
                    showAddButton = false;
                    break;
                case 'favorites':
                    renderComponent = <Favorites />;
                    break;
                case 'relationSuggestions':
                    renderComponent = <RelationSuggestions />
                    showABC = false;
                    showAddButton = false;
                    break;
                case 'deleteRequests':
                    renderComponent = <DeleteRequests />
                    break;
                default:
                    break;
            }
          }
        }

        const desktop = (window.innerWidth >= constants.breakpoints.max);

        let limitStyle = { maxHeight: window.innerHeight - ((!desktop && this.props.generalStore.scrolled) ? 30 : 140) };

        return (

           <div className={(!desktop && this.props.generalStore.scrolled) ? this.props.classes.rootScrolled : this.props.classes.root} style={limitStyle}>


                {renderComponent}
                {showABC &&
                  <Abc />
                }
                {showAddButton &&
                <AddButton />
                }
                <ListJoyride />
            </div>

        )
    }
}
Addresslist.propTypes = {
    classes: PropTypes.object.isRequired,
};

decorate(Addresslist, {
    onClickShowPersons: action,
    onClickShowOrganizations: action
})

export default withStyles(styles)(withRouter(withTranslation("translations")(inject('personStore', 'organizationStore', 'generalStore')(observer(Addresslist)))));
