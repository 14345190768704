// Render a list of search results

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/AddCircle';

import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";

// Components


import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  textLine: {
    textAlign: 'left',
    lineHeight: '30px',
  },
  icon: {
  },
  age: {
    lineHeight: '30px',
    textAlign: 'right',
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  lastDivider: {
    width: '100%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class CreateNew extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.data !== this.props.data) this.forceUpdate();
  // }

  render() {
    const { classes, t } = this.props;

    return (
      <div>
        <Grid container className={classes.root} spacing={8}>
          <Grid key={`title`} item xs={12} sm={12} md={12} className={classes.icon}>
            <b>{t('Welcome')}</b><br/>
            {t('It looks like your account ist almost empty')}
          </Grid>
          <Grid key={`a`} item xs={1} sm={1} md={1} className={classes.icon}>
            <Link to={constants.path.createPerson}>
              <AddIcon />
            </Link>
          </Grid>
          <Grid key={`b`} item xs={10} sm={10} md={10} className={classes.textLine}>
            <Grid key={`b1`} item xs={12} sm={12} md={12} className={classes.textLine}>
              <Link to={constants.path.createPerson}>
                {t('Add a person')}
              </Link>
            </Grid>
            <Divider className={classes.divider} />
            <Grid key={`b2`} item xs={12} sm={12} md={12} className={classes.textLine}>
              <Link to={constants.path.createOrganization}>
                {t('Add a organization')}
              </Link>
            </Grid>
            <Divider className={classes.divider} />
            <Grid key={`b3`} item xs={12} sm={12} md={12} className={classes.textLine}>
              <Link to={constants.path.import}>
                {t('Import your contacts')}
              </Link>
            </Grid>
          </Grid>
          <Divider className={classes.lastDivider} />
          <Grid key={`secondTitle`} item xs={12} sm={12} md={12} className={classes.icon}>
            {t('Being alone is boring')}
          </Grid>
          <Grid key={`b4`} item xs={12} sm={12} md={12} className={classes.textLine}>
            <Link to={constants.path.invites}>
              <Button variant="contained" color="primary">{t('Invite your coworkers')}</Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(CreateNew));
