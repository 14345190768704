// General storage for variables that are needed on several pages

import { decorate, observable, action, toJS } from 'mobx'

class OauthStore {
  authClients
  authClientsMap
  secrets

  constructor() {
    this.authClients = [];
    this.secrets = [];
  }

  setAuthClients(list) {
    this.authClients = list;
    const length = list.length;
    const map = {}
    for(let i=0; i< length; i+=1) {
      map[list[i]._id] = list[i];
    }

    this.authClientsMap = map;
  }

  setSecrets(list) {
    this.secrets = list;
  }

  setSecret(data) {
    for(const index in this.secrets) {
        if(this.secrets[index]._id === data.data._id) {
          const entry = toJS(this.secrets[index])
          entry.value = data.data.value;
          this.secrets[index] = entry;
          return;
        }
    }
    this.secrets.push(data.data);
  }

  deleteSecret(index){
    delete this.secrets[index];
  }
}

decorate(OauthStore, {
  authClients: observable,
  authClientsMap: observable,
  secrets: observable,

  setAuthClients: action.bound,
  setSecrets: action.bound,
  setSecret: action.bound,
  deleteSecret: action.bound,
})

export default OauthStore
