import React, { Component } from 'react';

import { HexColorPicker } from "react-colorful";

import TextField from '@material-ui/core/TextField';

// Mobx
import { inject } from 'mobx-react'
// import constants from '../../stores/constants';

// Material UI
import { withStyles } from '@material-ui/core/styles';
// import Chip from '@material-ui/core/Chip';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    clear: 'both',
    // border: '1px solid #ddd',
    position: 'relative',
    display: 'block',
    textAlign: 'left',
    paddingTop: 12,
  },
});

class ColorSelection extends Component {
    state = {
      color: '#ffffff',
      fieldColor: '#ffffff',
    }

    // eslint-disable-next-line
    constructor(props) {
        super(props);

    }

    componentDidMount() {
      if(this.props.color && this.props.color.match(/^\#[0-9]{6}$/) !== null) {
        this.setState({
          color: this.props.color,
          fieldColor: this.props.color,
        });
      }
    }

    setColor = (color) => {
      if(this.props.callback) this.props.callback(color);
      this.setState({
        color,
        fieldColor: color,
      });
    }

    setColorViaText = (event) => {
      let color;
      if(event.target.value.match(/^\#[0-9abcdefABCDEF]{6}$/) !== null) {
        color = event.target.value;
        if(this.props.callback) this.props.callback(color);
      }

      this.setState({
        fieldColor: event.target.value,
        color,
      });
    }

    render() {
        const { classes, t } = this.props;

        return <div className={classes.root}>
          <HexColorPicker color={this.state.color} onChange={this.setColor} />
          <TextField key='hexColor' name={'hexColor'} placeholder={t('#ffffff')} onChange={this.setColorViaText} value={this.state.fieldColor || ''} />
        </div>;

    }
}

export default withStyles(styles)(withTranslation("translations")(inject('generalStore')(ColorSelection)));
