// Handles all actions required for the search

import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { trackPromise } from 'react-promise-tracker';

import { toJS } from "mobx";

// Mobx Stores
import store from "../stores/";
import constants from "../stores/constants";

// Actions
import history from "./history";

// import auth from './auth';
// import { observer, inject } from 'mobx-react';
// import { translate, Trans } from "react-i18next";


class Search extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  translateSearch(searchCommand) {
    let commandString = searchCommand
      .replace(/[\s]+/g, " ")
      .replace(/:[\s]+/g, ":")
      .trim();
    const regex = /"[^"]+"/g;

    const iter = commandString.matchAll(regex);
    const matches = [...iter];
    const length = matches.length;

    let k;
    for (k = 0; k < length; k += 1) {
      const replacement = matches[k][0].replace(/[\s]+/g, "___");
      commandString = commandString.replace(matches[k][0], replacement);
    }

    const tokens = commandString.split(" ");
    const tokensLength = tokens.length;
    let i;

    let query = [];
    for (i = 0; i < tokensLength; i += 1) {
      let parts = tokens[i].split(":");

      if (parts.length === 1) {
        let cleanPart = parts[0].replace(/___/g, " ").trim();

        let done = false;
        if (store.generalStore.language in constants.commandTranslations) {
          if (
            cleanPart in
            constants.commandTranslations[store.generalStore.language]
              .logicOperators
          ) {
            cleanPart =
              constants.commandTranslations[store.generalStore.language]
                .logicOperators[cleanPart];
            done = true;
          }
        }
        if(!done) {
          // Try translation even if string is in different language
          for (let key in constants.commandTranslations) {
            if (key !== store.generalStore.language) {
              if (
                cleanPart in
                constants.commandTranslations[key]
                  .logicOperators
              ) {
                cleanPart =
                  constants.commandTranslations[key]
                    .logicOperators[cleanPart];
              }
            }
          }
        }
        query.push(cleanPart);
      } else {
        parts[0] = parts[0].toLowerCase();
        parts[1] = parts[1].replace(/___/g, " ").trim();
        let cleanPart = parts[0].replace(/^-/, "");
        let done = false;
        if (store.generalStore.language in constants.commandTranslations) {
          if (
            cleanPart in
            constants.commandTranslations[store.generalStore.language]
          ) {
            cleanPart =
              constants.commandTranslations[store.generalStore.language][
                cleanPart
              ];
            done = true;
          }
        }

        if(!done) {
          // Try translation even if string is in different language
          for (let key in constants.commandTranslations) {
            if (key !== store.generalStore.language) {
              if (
                cleanPart in
                constants.commandTranslations[key]
              ) {
                cleanPart =
                  constants.commandTranslations[key][cleanPart];
              }
            }
          }
        }

        if (cleanPart === "timestamp") {
          const date = moment.utc(parts[1].replace(/^(<|>)/, ""));
          if (date.isValid()) {
            let tempValue = date.valueOf();
            if (parts[1][0] === "<" || parts[1][0] === ">")
              tempValue = parts[1][0] + tempValue;
            parts[1] = tempValue;
          }
        }

        if (parts[0].length > 0 && parts[0][0] === "-")
          cleanPart = `-${cleanPart}`;

        query.push(`${cleanPart}:${parts[1]}`);
      }
    }

    return query.join(" ");
  }

  queryContainsCommand(query) {
    if(!query || typeof query !== 'string') return false;
    const r = /([a-z]{2,}):[\s]{0,}[^\s]+/img;
    const match = r.exec(query);
    while (match != null) {
      if (match.length > 1 && match[1] in constants.commandTranslations['en']) {
        return true;
      }
      match = r.exec(query);
    }
    return false;
  }

  // Execute the search by api-call to the backend
  doSearch(rawQuery, redirect, name, doNotUpdateListstate) {
    let query = rawQuery;
    query = query.replace("#", "").replace("?", "");
    if (typeof redirect === "undefined") redirect = true;
    // if (!store.authStore.token || store.authStore.token === '') console.log('no token')
    if (!store.authStore.token || store.authStore.token === "") return false;

    store.generalStore.setQuery(query);
    store.generalStore.setNumberOfResults(0);
    store.generalStore.setSearchResults([]);
    store.generalStore.setSearchPending(true);
    store.generalStore.setSearchType("standard");

    const currentState = store.generalStore.listState.split('/');

    const newState = `search/${query}`;

    let listState = newState;
    if(name !== null && name !== '' && name !== 'undefined') listState += `/${name}`

    if(`search/${currentState[1]}` !== newState && doNotUpdateListstate !== true) store.generalStore.setListState(listState);

    if (query === "") return;

    let apiUrl = constants.apiUrl;
    // if (
    //   window.location.hostname.indexOf("dev") > -1 ||
    //   window.location.hostname.indexOf("localhost") > -1
    // ) {
    //   const res = [
    //     {
    //       uid: "3gbbd1k49r4qwb",
    //       firstName: "Achim",
    //       lastName: "Stahn",
    //       photo:
    //         "https://bildung-sg.de/wp-content/uploads/2018/05/Platzhalter-Frau.jpg"
    //     },
    //     {
    //       uid: "3gbbd1k49qsunp",
    //       name: "Wice GmbH",
    //       logo:
    //         "https://as1.ftcdn.net/jpg/02/62/51/54/500_F_262515445_EC4mqN0sg048AxorU3El4mPXWJAOnkvs.jpg"
    //     }
    //   ];

    //   store.generalStore.setSearchResults(res);
    //   store.generalStore.setNumberOfResults(res.length);
    //   return;
    // }

    let backendQuery = this.translateSearch(query);

    // let params = {term: backendQuery, page: 1, num: 20};
    trackPromise(fetch(
      `${apiUrl}/search?term=${encodeURIComponent(
        unescape(backendQuery)
      )}&page=1&num=100000`,
      {
        method: "get",
        cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${store.authStore.token}`
        },
        credentials: "omit"
      }
    ))
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        store.generalStore.setSearchPending(false);
        if (res.indexOf("No results found") !== -1) {
          store.generalStore.setNumberOfResults(0);
          store.generalStore.setSearchResults([]);
          store.generalStore.setSearchType("standard");

          if (!this.queryContainsCommand(backendQuery)) {
            this.doPhoneticSearch(rawQuery, redirect);
          }
        } else {
          const json = JSON.parse(res);

          if (json.statusCode) {
            store.generalStore.setNumberOfResults(0);
            store.generalStore.setSearchResults([]);
            store.generalStore.setSearchType("standard");

            if (!this.queryContainsCommand(backendQuery)) {
              this.doPhoneticSearch(rawQuery, redirect);
            }
          } else {
            store.generalStore.setNumberOfResults(0);
            store.generalStore.setSearchResults(json[1]);
            store.generalStore.setNumberOfResults(json[0]);
            store.generalStore.setSearchType("standard");
          }
        }

        // const pathName = `/search/${query}`;

        // const currPath = history.location.pathname.replace(/\?/img, ' ').replace(/#/img, ' ');

        // if (redirect && currPath !== pathName && history.location.pathname.replace('?', '') !== unescape(currPath)) history.push(pathName);
      })
      .catch(function(error) {
        store.generalStore.setNumberOfResults(0);
        store.generalStore.setSearchResults([]);
        store.generalStore.setSearchPending(false);
        store.generalStore.setSearchType("standard");

        return history.push("/not-found");
        // alert('Fetching has an error');
        // alert(error);
        // console.log(error);
      });
  }

  // Execute a notes search by api-call to the backend
  doNotesSearch(rawQuery, redirect, name, doNotUpdateListstate) {
    let query = rawQuery;
    query = query.replace("#", "").replace("?", "");
    if (typeof redirect === "undefined") redirect = true;
    // if (!store.authStore.token || store.authStore.token === '') console.log('no token')
    if (!store.authStore.token || store.authStore.token === "") return false;

    store.generalStore.setNotesQuery(query);

    const currentState = store.generalStore.listState.split('/');

    const newState = `search/${query}`;

    let listState = newState;
    if(name !== null && name !== '') listState += `/${name}`

    if(`search/${currentState[1]}` !== newState && doNotUpdateListstate !== true) store.generalStore.setListState(listState);

    if (query === "") return;

    let apiUrl = constants.apiUrl;
    // if (
    //   window.location.hostname.indexOf("dev") > -1 ||
    //   window.location.hostname.indexOf("localhost") > -1
    // ) {
    //   const res = [
    //     {
    //       uid: "3gbbd1k49r4qwb",
    //       firstName: "Achim",
    //       lastName: "Stahn",
    //       photo:
    //         "https://bildung-sg.de/wp-content/uploads/2018/05/Platzhalter-Frau.jpg"
    //     },
    //     {
    //       uid: "3gbbd1k49qsunp",
    //       name: "Wice GmbH",
    //       logo:
    //         "https://as1.ftcdn.net/jpg/02/62/51/54/500_F_262515445_EC4mqN0sg048AxorU3El4mPXWJAOnkvs.jpg"
    //     }
    //   ];
    //
    //   store.generalStore.setNotesSearchResults(res);
    //   store.generalStore.setNumberOfNotesResults(res.length);
    //   return;
    // }

    let backendQuery = this.translateSearch(query);

    // let params = {term: backendQuery, page: 1, num: 20};
    trackPromise(fetch(
      `${apiUrl}/search/notes?term=${encodeURIComponent(
        unescape(backendQuery)
      )}&page=1&num=1000`,
      {
        method: "get",
        cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${store.authStore.token}`
        },
        credentials: "omit"
      }
    ))
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        if (res.indexOf("No results found") !== -1) {
          store.generalStore.setNumberOfNotesResults(0);
          store.generalStore.setNotesSearchResults([]);
          store.generalStore.setNotesSearchType("standard");
          // this.doPhoneticSearch(rawQuery, redirect);
        } else {
          const json = JSON.parse(res);

          if (json.statusCode) {
            store.generalStore.setNumberOfNotesResults(0);
            store.generalStore.setNotesSearchResults([]);
            store.generalStore.setNotesSearchType("standard");
            // this.doPhoneticSearch(rawQuery, redirect);
          } else {
            store.generalStore.setNumberOfNotesResults(0);
            store.generalStore.setNotesSearchResults(json[1]);
            store.generalStore.setNumberOfNotesResults(json[0]);
            store.generalStore.setNotesSearchType("standard");
          }
        }

        // const pathName = `/search/${query}`;

        // const currPath = history.location.pathname.replace(/\?/img, ' ').replace(/#/img, ' ');

        // if (redirect && currPath !== pathName && history.location.pathname.replace('?', '') !== unescape(currPath)) history.push(pathName);
      })
      .catch(function(error) {
        return history.push("/not-found");
        // alert('Fetching has an error');
        // alert(error);
        // console.log(error);
      });
  }

  // Execute the search by api-call to the backend
  doPhoneticSearch(rawQuery, redirect) {
    let query = rawQuery;
    query = query
      .replace("#", "")
      .replace("?", "")
      .replace(/[a-z]+:/g, "")
      .replace(/[\s]+/g, " ")
      .replace(/"/g, "")
      .trim();
    if (typeof redirect === "undefined") redirect = true;

    store.generalStore.setSearchPending(true);
    store.generalStore.setSearchType("phonetic");

    if (!store.authStore.token || store.authStore.token === "") return false;

    if (query === "") return;

    const fields = `&_phonetic_name_=${query}&_phonetic_organizationname_=${query}&_phonetic_street_=${query}&_phonetic_city_=${query}&_phonetic_region_=${query}`;

    let apiUrl = constants.apiUrl;

    fetch(`${apiUrl}/search/fields?condition=OR${fields}&page=1&num=1000`, {
      method: "get",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${store.authStore.token}`
      },
      credentials: "omit"
    })
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        if(!store.generalStore.searchPending
          || (store.generalStore.searchResults && store.generalStore.searchResults.length > 0)
        ) {
          store.generalStore.setSearchPending(false);
          return false;
        }
        store.generalStore.setSearchPending(false);

        if (res.indexOf("No results found") !== -1) {
          store.generalStore.setSearchType("phonetic");
          store.generalStore.setNumberOfResults(0);
          store.generalStore.setSearchResults([]);
        } else {
          const json = JSON.parse(res);

          if (json.statusCode) {
            store.generalStore.setSearchType("phonetic");
            store.generalStore.setNumberOfResults(0);
            store.generalStore.setSearchResults([]);
          } else {
            store.generalStore.setSearchType("phonetic");
            store.generalStore.setQuery(query);
            store.generalStore.setNumberOfResults(0);
            store.generalStore.setSearchResults(json[1]);
            store.generalStore.setNumberOfResults(json[0]);
          }
        }
        // const pathName = `/search/${encodeURIComponent(query)}`;

        // const currPath = history.location.pathname.replace(/\?/img, ' ').replace(/#/img, ' ').replace(/[\s]/img, '%20');

        // removing redirect
        // if (redirect && currPath !== pathName && history.location.pathname.replace('?', '') !== unescape(currPath)) history.push(pathName);
      })
      .catch(function(error) {
        store.generalStore.setNumberOfResults(0);
        store.generalStore.setSearchResults([]);
        store.generalStore.setSearchPending(false);
        store.generalStore.setSearchType("standard");

        return history.push("/not-found");
        // alert('Fetching has an error');
        // alert(error);
        // console.log(error);
      });
  }

  // Does smaller searches purely for typeahead
  async doQuickSearch(query, chipQuery) {
    let fullQuery = `${chipQuery} ${query}`.trim();
    if (fullQuery === "") {
      store.generalStore.setQuickSearchResults([]);
      return;
    }

    // Placeholder for development
    if (
      window.location.hostname.indexOf("dev") > -1 ||
      window.location.hostname.indexOf("localhost") > -1
    ) {
      const response = await axios.get(`${constants.apiUrl}/all`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.authStore.token}`
        }
      });

      if (response.status === 200) {
        let results = response.data.data;
        const regex = new RegExp(`${query}`, "i");
        results = results.filter(
          item =>
            regex.test(item.name) ||
            regex.test(item.firstName) ||
            regex.test(item.lastName)
        );
        return store.generalStore.setQuickSearchResults(results.slice(0, 3));
      } else {
        return store.generalStore.setQuickSearchResults([]);
      }
    }

    try {
      const response = await axios.get(
        `${constants.apiUrl}/search?term=${this.translateSearch(
          encodeURIComponent(fullQuery)
        )}&num=3`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.authStore.token}`
          }
        }
      );

      if (response.status === 200) {
        if (response.data[0] > 0) {
          return store.generalStore.setQuickSearchResults(response.data[1]);
        } else {
          return store.generalStore.setQuickSearchResults([]);
        }
      } else {
        return store.generalStore.setQuickSearchResults([]);
      }
    } catch (e) {
      return store.generalStore.setQuickSearchResults([]);
    }
  }


  // Does smaller searches purely for typeahead
  async doQuickNotesSearch(query, chipQuery) {
    let fullQuery = `${chipQuery} ${query}`.trim();
    if (fullQuery === "") {
      store.generalStore.setQuickNotesSearchResults([]);
      return;
    }

    // Placeholder for development
    // if (
    //   window.location.hostname.indexOf("dev") > -1 ||
    //   window.location.hostname.indexOf("localhost") > -1
    // ) {
    //   const response = await axios.get(`${constants.apiUrl}/all`, {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${store.authStore.token}`
    //     }
    //   });
    //
    //   if (response.status === 200) {
    //     let results = response.data.data;
    //     const regex = new RegExp(`${query}`, "i");
    //     results = results.filter(
    //       item =>
    //         regex.test(item.name) ||
    //         regex.test(item.firstName) ||
    //         regex.test(item.lastName)
    //     );
    //     return store.generalStore.setQuickNotesSearchResults(results.slice(0, 3));
    //   } else {
    //     return store.generalStore.setQuickNotesSearchResults([]);
    //   }
    // }

    try {
      const response = await axios.get(
        `${constants.apiUrl}/search/notes?term=${this.translateSearch(
          encodeURIComponent(fullQuery)
        )}&num=3`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.authStore.token}`
          }
        }
      );

      if (response.status === 200) {
        if (response.data[0] > 0) {
          return store.generalStore.setQuickNotesSearchResults(response.data[1]);
        } else {
          return store.generalStore.setQuickNotesSearchResults([]);
        }
      } else {
        return store.generalStore.setQuickNotesSearchResults([]);
      }
    } catch (e) {
      return store.generalStore.setQuickNotesSearchResults([]);
    }
  }



  // Gets the saved searches and updates the store
  populateSavedSearches() {
    // if (!store.authStore.token || store.authStore.token === '') console.log('no token')
    if (!store.authStore.token || store.authStore.token === "") return false;

    trackPromise(fetch(`${constants.apiUrl}/search/saved`, {
      method: "get",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${store.authStore.token}`
      },
      credentials: "omit"
    }))
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        store.generalStore.setSavedSearches(JSON.parse(res));
      })
      .catch(function(error) {
        console.log(error);
        store.generalStore.addMessage(500, "Could not retrieve saved searches");
      });
  }

  indexFunction(element) {
    if ("name" in element) {
      return element.name === this.saveName;
    }
    return false;
  }
  // Saves a search and updates the store
  saveSearch(name, searchTerm) {
    // if (!store.authStore.token || store.authStore.token === '') console.log('no token')
    if (!store.authStore.token || store.authStore.token === "") return false;

    let saveName = name;
    this.saveName = saveName;
    let pos = 0;
    let c = 2;
    while (pos !== -1 && c < 20) {
      pos = store.generalStore.savedSearches.own.findIndex(
        this.indexFunction.bind(this)
      );

      if (pos > -1) {
        saveName = name + c;
        c++;
      }
    }

    trackPromise(fetch(`${constants.apiUrl}/search/saved`, {
      method: "post",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${store.authStore.token}`
      },
      credentials: "omit",
      body: JSON.stringify({
        name: saveName,
        searchTerm
      })
    }))
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        let data = toJS(store.generalStore.savedSearches);

        if (!("own" in data)) data.own = [];

        data.own = data.own.concat(JSON.parse(res));
        store.generalStore.setSavedSearches(data);
        store.generalStore.addMessage(200, "Search saved");
      })
      .catch(function(error) {
        console.log(error);
        store.generalStore.addMessage(500, "Search could not be saved");
      });
  }

  // Deletes a saved search and updates the store
  deleteSavedSearch(id) {
    // if (!store.authStore.token || store.authStore.token === '') console.log('no token')
    if (!store.authStore.token || store.authStore.token === "") return false;

    trackPromise(fetch(`${constants.apiUrl}/search/saved/${id}`, {
      method: "delete",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${store.authStore.token}`
      },
      credentials: "omit"
    }))
      .then(function(response) {
        return response.text();
      })
      .then(res => {
        store.generalStore.addMessage(200, "Saved search deleted");
      })
      .catch(function(error) {
        console.log(error);
        store.generalStore.addMessage(500, "Saved search could not be deleted");
      });
  }
}

const search = new Search();
export default withRouter(search);
