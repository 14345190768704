import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

// React multilanguage
import { withTranslation } from 'react-i18next';
import constants from '../../stores/constants';

const styles = (theme) => ({
    title: {
        paddingTop: theme.spacing.unit * 10,
        color: constants.color.main
    },
    notFound: {
        marginTop: theme.spacing.unit * 5
    }
});

class NotFound extends React.Component {
    render() {
        const { t } = this.props;
        const { classes } = this.props;

        return (
            <div>
                <Typography className={classes.title} align="center" paragraph variant="h4">
                    {t('Oops... something went wrong!')}
                </Typography>
                <img className={classes.notFound} src={'/assets/404.png'} alt="Not Found" />
            </div>
        );
    }
}


export default withTranslation('translations')(withStyles(styles)(withRouter(NotFound)));