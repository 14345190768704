import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';


// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';

// components
import ResultGridItem from '../../templates/ResultGridItem';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

import { VariableSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
        transition: '0.5s all ease-in-out',
    },
});

class ByGroup extends Component {
    _isMounted = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }
        this.props.generalStore.setScrollToIndex(-1);

        let groupId = this.props.match.params.groupId;
        if (!groupId) {
            groupId = this.props.generalStore.listState.split('/').pop();
        }

        this.props.personStore.setCurrentGroupEntries(0, false, groupId);
        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            let groupId = this.props.match.params.groupId;
            if (!groupId) {
                groupId = this.props.generalStore.listState.split('/').pop();
            }

            if (this.props.personStore.selectedGroup && this.props.personStore.selectedGroup.uid !== groupId) {
                this.props.personStore.setCurrentGroupEntries(0, false, groupId);
            }
        }
    }


    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);

        this.props.personStore.setPersonsAndOrganizations([]);
        this.props.personStore.setPersonsAndOrganizationsCount(0);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }


    onClickDelete(uid) {
        this.props.personStore.deletePerson(uid);
    }

    onClickEntry(entry) {
        if (entry.hasOwnProperty('name')) {
            this.props.history.push(`/detailview/organization/${entry.uid}`)
        } else if (entry.hasOwnProperty('firstName') || entry.hasOwnProperty('lastName')) {
            this.props.history.push(`/detailview/person/${entry.uid}`)
        }
    }

    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }

    isItemLoaded(index) {
        return false;
    }

    async loadMore(startIndex, stopIndex) {
        if (this.scrollUpdateWasRequested) {
            console.log('loadMore scrollUpdateWasRequested asuming data is already there');
        } else {
            console.log('Loading more', startIndex, '-->', stopIndex);
            let groupId = this.props.match.params.groupId;
            if (!groupId) {
                groupId = this.props.generalStore.listState.split('/').pop();
            }
            await this.props.personStore.setCurrentGroupEntries(startIndex, false, groupId);
        }
        // console.log('Loading more', startIndex, '-->', stopIndex);
        // await this.props.personStore.setCurrentPersons(startIndex, this.scrollUpdateWasRequested);
    }

    renderRow(data) {

        const realIndex = data.index;

        if (this.props.personStore.personsAndOrganizations[realIndex]) {

            // console.log(this.props.personStore.personsIndex);
            const indexed = this.props.personStore.personsAndOrganizationsIndex[realIndex];

            let name = (this.props.personStore.personsAndOrganizations[realIndex].name) ? this.props.personStore.personsAndOrganizations[realIndex].name : this.props.personStore.personsAndOrganizations[realIndex].firstName;
            if (typeof name === 'undefined') name = '';
            let char = name.substr(0, 1).toUpperCase();

            return (
                <div key={data.index} style={data.style} className="row">
                    {indexed &&
                        <ClusterLine key={char} text={char} />
                    }

                    <ResultGridItem
                        key={this.props.personStore.personsAndOrganizations[data.index].uid}
                        entry={this.props.personStore.personsAndOrganizations[data.index]}
                        selectedIndex={this.state.selectedIndex}
                    />
                </div>
            )
        } else {
            return (
                <div />
            )
        }
    }

    itemSizeGetter(i) {

        const indexed = this.props.personStore.personsAndOrganizationsIndex[i];

        if (indexed) {
            return 138;
        } else {
            return 108;
        }

    }

    onScroll(scrollEvent) {
        console.log('onScroll', scrollEvent);

        if (typeof scrollEvent === 'object') {
            if (scrollEvent.scrollUpdateWasRequested) {
                this.scrollUpdateWasRequested = true;
            } else {
                this.scrollUpdateWasRequested = false;
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const height = calculateVlistHeight()
        const context = this;
        // const { personsAndOrganizations } = this.props.personStore.personsAndOrganizations;

        let counter = 0;

        if (this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length > 0) {

            const label = (typeof this.props.personStore === 'object' && 'selectedGroup' in this.props.personStore && this.props.personStore.selectedGroup !== null && 'label' in this.props.personStore.selectedGroup) ? this.props.personStore.selectedGroup.label : '';
            const count = (typeof this.props.personStore === 'object' && 'personsAndOrganizationsCount' in this.props.personStore && this.props.personStore.personsAndOrganizationsCount !== null) ? this.props.personStore.personsAndOrganizationsCount : 0;
            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] entries with group [w]', { v: count, w: label })}</React.Fragment>} />

                    <InfiniteLoader
                        isItemLoaded={this.isItemLoaded.bind(this)}
                        itemCount={this.props.personStore.personsAndOrganizationsCount}
                        loadMoreItems={this.loadMore.bind(this)}
                        minimumBatchSize={this.props.personStore.personIncrementSize}
                        threshold={Math.ceil(this.props.personStore.personIncrementSize / 2)}
                        ref={this.listRef}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                height={height}
                                itemCount={this.props.personStore.personsAndOrganizationsCount}
                                isItemLoaded={this.isItemLoaded.bind(this)}
                                itemSize={this.itemSizeGetter.bind(this)}
                                onItemsRendered={onItemsRendered}
                                overscanCount={10}
                                onScroll={this.onScroll.bind(this)}
                                ref={ref}
                            >
                                {this.renderRow.bind(context)}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                </div>
            )
        } else if (((this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length === 0) || !this.props.personStore.personsAndOrganizations) && !this.state.isLoading) {
            const label = ('personStore' in this.props && 'selectedGroup' in this.props.personStore && this.props.personStore.selectedGroup && 'label' in this.props.personStore.selectedGroup) ? this.props.personStore.selectedGroup.label : '';
            return (
                <SingleGridBlock children={<div style={{ height: '100vh' }}>{t('You have neither persons nor organizations in the group [v]', { v: label })}</div>} />
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.personStore.personsAndOrganizations) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div style={{ height: '100vh' }} />} />);
        }
    }
}

ByGroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('personStore', 'generalStore')(observer(ByGroup)))));
