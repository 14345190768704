import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import AccountCircle from "@material-ui/icons/AccountCircle";

// Mobx Store
import { inject, observer } from "mobx-react";

import { toJS } from "mobx";
import { Avatar } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/HelpOutline';

// Templates
import MessagesDisplay from '../../templates/MessagesDisplay'


import Search from "./Search";
import getContact from "../../../actions/getContact";

import constants from "../../../stores/constants";
import store from "../../../stores/";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: 'space-around',
    overflow: "hidden",
    backgroundColor: constants.color.header, //constants.color.dark,
    "border-bottom": "1px solid #fff"
  },
  rootBig: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: 'space-around',
    overflow: "hidden",
    backgroundColor: '#34455e', //constants.color.dark,
    "border-bottom": "1px solid #fff",
    padding: theme.spacing.unit * 2
  },
  gridList: {
    width: 500,
    height: 150
  },
  logo: {
    padding: 6,
    paddingLeft: theme.spacing.unit,
    marginLeft: 11,
    cursor: "pointer",
    height: 30,
    width: "auto"
  },
  growMedium: {
    flexGrow: 1,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit,
    maxWidth: 500
  },
  growBig: {
    flexGrow: 1,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit,
    maxWidth: 570
  },
  menuButton: {
    cursor: "pointer",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    position: "absolute",
    right: theme.spacing.unit * 2
  },
  help: {
    cursor: "pointer",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    position: "absolute",
    right: theme.spacing.unit * 6,
  },
  helpBig: {
    cursor: "pointer",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    position: "absolute",
    right: theme.spacing.unit * 8,
  },
  messages: {
    cursor: "pointer",
    paddingLeft: theme.spacing.unit,
    position: "absolute",
    right: theme.spacing.unit * 10,
  },
  messagesBig: {
    cursor: "pointer",
    paddingLeft: theme.spacing.unit,
    position: "absolute",
    right: theme.spacing.unit * 12,
  },
  photo: {
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 40,
    height: 40,
    cursor: "pointer",
    position: "absolute",
    right: theme.spacing.unit * 2.4,
    filter: "grayscale(1)"
  },
});

class ImageGridList extends React.Component {
  state = {
    photoError: false
  };
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    // this.handleClick = this.handleClick.bind(this);

    window.addEventListener('resize', this.handleResize.bind(this));
    document.addEventListener("fullscreenchange", this.handleResize.bind(this));
    document.addEventListener("visibilitychange", this.handleResize.bind(this));
  }

  handleResize(){
    this.forceUpdate();
  }

  handleClick() {
    this.props.history.push(constants.path.listPersons);
  }

  async onClickShowProfile() {
    if (
      !store.generalStore.profile ||
      !store.generalStore.profile[0] ||
      !store.generalStore.profile[0].uid
    ) {
      await getContact.populateProfile(false);
    }
    const uid = toJS(store.generalStore.profile[0].uid);
    this.props.history.push(`${constants.path.detailviewPerson}/${uid}`);
  }

  onClickHelpMe() {
    const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;
    if(isOSPWA) {
      window.open(constants.path.help, '_system');
    } else if(window.innerWidth > constants.breakpoints.small) {
      window.open(constants.path.help, '_blank');
    } else {
      window.location.href = constants.path.help;
    }
  }

  onAvatarError() {
    this.setState({
      photoError: true
    });
  }

  render() {
    // const desktop = (window.innerWidth >= constants.breakpoints.medium);
    const { classes } = this.props;
    const desktop = window.innerWidth >= constants.breakpoints.max;
    const medium =
      !desktop && window.innerWidth >= constants.breakpoints.medium;

    const profile = toJS(store.generalStore.profile);
    let photo;
    let avatar;

    if (profile && profile.length > 0) {
      if (profile[0].photo) {
        photo = profile[0].photo;
        this.props.generalStore.setProfilePhoto(profile[0].photo);
      }
    }

    let helpStyle;
    let messagesStyle;
    if (photo) {
      avatar = (
        <Avatar
          className={classes.photo}
          onClick={this.onClickShowProfile.bind(this)}
          src={this.state.photoError ? null : photo}
          onError={this.onAvatarError.bind(this)}
        >
          {photo}
        </Avatar>
      );

      helpStyle = classes.helpBig;
      messagesStyle = classes.messagesBig;
    } else {
      avatar = (
        <AccountCircle
          className={classes.menuButton}
          onClick={this.onClickShowProfile.bind(this)}
        ></AccountCircle>
      );

      helpStyle = classes.help;
      messagesStyle = classes.messages;
    }

    return (
      <div
        id="logo"
        onClick={!desktop && !medium ? this.handleClick.bind(this) : null}
        className={
          (desktop || medium ? classes.rootBig : classes.root) +
          (!medium && !desktop && this.props.generalStore.scrolled
            ? " scrolled"
            : " not-scrolled")
        }
        style={desktop || medium ? { paddingLeft: 0 } : {}}
      >
        <img
          src="/assets/logo.png"
          onClick={medium || desktop ? this.handleClick.bind(this) : null}
          alt=""
          className={classes.logo}
        />

        {(store.authStore.hasLogin === true && (desktop || medium)) && (
          <React.Fragment>
            <div className={medium ? classes.growMedium : classes.growBig}>
              <Search />
            </div>
            {/**<AccountCircle
              className={classes.menuButton}
              onClick={this.onClickShowProfile.bind(this)}
            ></AccountCircle>**/}
            <HelpIcon
              className={helpStyle}
              onClick={this.onClickHelpMe.bind(this)}
            />
          <div className={messagesStyle}><MessagesDisplay /></div>
            {avatar}
          </React.Fragment>
        )}
      </div>
    );
  }
}

ImageGridList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  withRouter(inject("generalStore")(observer(ImageGridList)))
);
