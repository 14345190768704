import React from 'react';
import { withRouter } from 'react-router-dom';
import store from '../stores/';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { trackPromise } from 'react-promise-tracker';
import constants from '../stores/constants';
import Amplitude from 'react-amplitude';

const apiUrl = constants.apiUrl;

async function removeGroup(entryUid, type, entryName, groupUid, groupName) {
	let responses = [];
	try {
		const uri = `${apiUrl}/group/${type}/${groupUid}/${entryUid}`;
		const data = { entryUid, entryName, groupUid, groupName };
		let response = await trackPromise(
			axios.delete(uri, {
				data,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				}
			})
		)

		if (response.status === 200) {
			if (type === 'person' || type === 'organization') {
				store.generalStore.addMessage(200, 'Group removed');
			}
			responses.push('Category remove: Success');
		} else {
			if (type === 'person' || type === 'organization') {
				store.generalStore.addMessage(500, 'Group could not be removed');
			}
		}

		return responses;
	} catch (e) {
		if (type === 'person' || type === 'organization') {
			store.generalStore.addMessage(500, 'Group could not be removed');
		}
		console.log(e);
		responses.push('Group remove: Failure');
		return responses;
	}
}

async function addGroup(entryUid, type, entryName, groupUid, groupName) {
	let responses = [];
	try {
			const uri = `${apiUrl}/group/${type}/${groupUid}/${entryUid}`;

			const data = { entryUid, entryName, groupUid, groupName };
			let response = await sendRequest(uri, 'post', data);

			if (response.status === 200) {
				if (type === 'person' || type === 'organization') {
					store.generalStore.addMessage(200, 'Group added');
				}
				responses.push('Group addition: Success');
			} else {
				if (type === 'person' || type === 'organization') {
					store.generalStore.addMessage(500, 'Group could not be added');
				}
			}

				return responses;
	} catch (e) {
		if (type === 'person' || type === 'organization') {
			store.generalStore.addMessage(500, 'Group could not be added');
		}
		console.log(e);
		responses.push('Group addition: Failure');
		return responses;
	}
}

async function removeCategories(id, categories, type, contextRef, entryUid, entryName) {
	let responses = [];
	if (type === 'addresses') type = 'address';
	const length = categories.length;
	let i;
	for (i = 0; i < length; ++i) {
		try {
			const data = {entryUid, entryName, categoryName: categories[i].categoryName}
			let uri;
			if (contextRef && contextRef !== null) {
				uri = `${apiUrl}/category/${type}/${categories[i].uid}/${id}?contextref=${contextRef}`;
			} else {
				uri = `${apiUrl}/category/${type}/${categories[i].uid}/${id}`;
			}

			// const response = await sendRequest(uri, 'delete');
			// specially formatted request because axios is dumb
			const response = await trackPromise(
				axios.delete(uri, {
					data,
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			)

			if (response.status === 200) {
				if (type === 'person' || type === 'organization' || type === 'contactData' || type === 'address') {
					store.generalStore.addMessage(200, 'Category removed');
				}
				responses.push('Category remove: Success');
			} else {
				if (type === 'person' || type === 'organization' || type === 'contactData' || type === 'address') {
					store.generalStore.addMessage(500, 'Category could not be removed');
				}
				// Add error handling
			}
		} catch (e) {
			if (type === 'person' || type === 'organization') {
				store.generalStore.addMessage(500, 'Category could not be removed');
			}
			console.log(e);
			responses.push('Category remove: Failure');
		}
	}
	return responses;
}

async function addCategories(id, categories, type, contextRef, entryUid, entryName) {
	if (!categories || categories.length < 1) return;
	let responses = [];
	const length = categories.length;
	if (type === 'addresses') type = 'address';
	let i;
	for (i = 0; i < length; ++i) {
		try {
			let uri;
			if (contextRef && contextRef !== null) {
				uri = `${apiUrl}/category/${type}/${categories[i].uid}/${id}?contextref=${contextRef}`;
			} else {
				uri = `${apiUrl}/category/${type}/${categories[i].uid}/${id}`;
			}

			const response = await sendRequest(uri, 'post', {
				// msg: 'axios placeholder'
				entryUid, entryName, categoryName: categories[i].categoryName,
			});

			if (response.status === 200) {
				if (type === 'person' || type === 'organization' || type === 'contactData' || type === 'address') {
					store.generalStore.addMessage(200, 'Category added');
				}
				responses.push('Category addition: Success');
			} else {
				if (type === 'person' || type === 'organization' || type === 'contactData' || type === 'address') {
					store.generalStore.addMessage(500, 'Category could not be added');
				}
				// Add error handling
			}
		} catch (e) {
			if (type === 'person' || type === 'organization') {
				store.generalStore.addMessage(500, 'Category could not be added');
			}
			console.log(e);
			responses.push('Category addition: Failure');
		}
	}
	return responses;
}

async function removeRelations(id, relations, deleteContextData) {
	let responses = [];
	const length = relations.length;
	let i;
	for (i = 0; i < length; ++i) {
		try {
			const data = {
				relationType: relations[i].type,
				contextAction: deleteContextData ? 'delete' : 'moveToOwn',
				uids: relations[i].uids,
				label: relations[i].label,
				typeRef: relations[i].typeRef,
				source: relations[i].source,
				target: relations[i].target,
				sourceName: relations[i].sourceName,
				targetName: relations[i].targetName,
			}

			// specially formatted request because axios is dumb
			const response = await trackPromise(
				axios.delete(`${apiUrl}/relation/${relations[i].uid}`, {
					data,
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			)

			if (response.status === 200) {
				store.generalStore.addMessage(200, 'Relation removed');
				responses.push('Relation remove: Success');
			} else {
				store.generalStore.addMessage(500, 'Relation could not be removed');
				// Add error handling
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Relation could not be removed');
			console.log(e);
			responses.push('Relation remove: Failure');
		}
	}
	return responses;
}

async function addRelations(id, relations) {
	let responses = [];
	const length = relations.length;
	let i;
	for (i = 0; i < length; ++i) {
		if (!relations[i].uids[0]) relations[i].uids[0] = id;
		else if (!relations[i].uids[1]) relations[i].uids[1] = id;

		let currentRelation = Object.assign({}, relations[i]);
		if (currentRelation.partner) delete currentRelation.partner;

		try {
			const response = await sendRequest(`${apiUrl}/relation/`, 'post', currentRelation);

			if (response.status === 200) {
				store.generalStore.addMessage(200, 'Relation added');
				Amplitude.logEvent('RelationAdded');
				responses.push('Relation addition: Success');
			} else {
				store.generalStore.addMessage(500, 'Relation could not be added');
				// Add error handling
				// alert(`Response Status: ${response.status}`);
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Relation could not be added');
			console.log(e);
			responses.push('Relation addition: Failure');
		}
	}
	return responses;
}

const diffCategories = function (base, updated) {
	let result = { added: [], removed: [] };

	// Case 1: Category was added
	if (updated) {
		for (let i = 0; i < updated.length; i++) {
			if (updated[i].value) updated[i].uid = updated[i].value;
			const wasAdded = _.findIndex(base, function (c) {
				return c.uid === updated[i].uid;
			});
			if (wasAdded < 0) {
				result.added.push(updated[i]);
			}
		}
	}

	// Case 2: Category was removed
	for (let j = 0; j < base.length; j++) {
		const wasDeleted = _.findIndex(updated, function (c) {
			return c.uid === base[j].uid;
		});
		if (wasDeleted < 0) {
			result.removed.push(base[j]);
		}
	}

	return result;
};

const diffRelations = function (base, updated) {
	let result = { added: [], removed: [] };

	// Case 1: Relation was added
	if (updated) {
		for (let i = 0; i < updated.length; i++) {
			const wasAdded = _.findIndex(base, function (c) {
				return c.uid === updated[i].uid;
			});
			if (wasAdded < 0) {
				result.added.push(updated[i]);
			}
		}
	}

	// Case 2: Relation was removed
	for (let j = 0; j < base.length; j++) {
		const wasDeleted = _.findIndex(updated, function (c) {
			return c.uid === base[j].uid;
		});
		if (wasDeleted < 0) {
			result.removed.push(base[j]);
		}
	}

	return result;
};

const shallowDiff = function (original, changed) {
	// console.log(original)
	// console.log(changed)

	let diff = {};
	for (let attribute in changed) {
		if (!_.isArray(changed[attribute]) && !_.isEqual(original[attribute], changed[attribute])) {
			if (!(original[attribute] === undefined && changed[attribute] === '')) {
				diff[attribute] = changed[attribute];
			}
		}
	}
	return diff;
};

const sendRequest = async function (url, method, data) {
	try {
		let response;
		if (!_.isEmpty(data)) {
			response = await trackPromise(
				axios[method](url, data, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			);
		} else {
			response = await trackPromise(axios[method](url, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				}
			})
			);
		}

		if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}

		if (response.status === 502) {
			store.generalStore.addMessage(502, 'System temporarily unreachable');
		}

		return response;
	} catch (e) {
		console.log(e);
	}
};

class ContactEditor extends React.Component {
	static async createOrganization(values, noRedirect) {
		const organization = {
			name: '',
			logo: ''
		};

		organization.name = values.name ? values.name : '';
		organization.logo = values.logo ? values.logo : '';

		try {
			const response = await sendRequest(`${apiUrl}/organization/`, 'post', organization);

			if (response.status === 200) {
				store.generalStore.changeOrganizationsAmount('increase');
				store.generalStore.addMessage(200, 'Organization created');
				Amplitude.logEvent('OrganizationCreated');
				const amp = Amplitude.amplitude();
				const identify = new amp.Identify().add('CreatedOrganizations', 1);
				amp.getInstance().identify(identify);
				const uid = response.data.payload.uid;
				return uid;
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Organization could not be created');
			console.log(e);
			return false;
		}
	}

	static async createPerson(values, noRedirect) {
		const person = {
			firstName: '',
			lastName: '',
			photo: ''
		};

		person.firstName = values.firstName ? values.firstName : '';
		person.lastName = values.lastName ? values.lastName : '';
		person.photo = values.photo ? values.photo : '';

		try {
			const response = await sendRequest(`${apiUrl}/person/`, 'post', person);

			if (response.status === 200) {
				store.generalStore.changePersonsAmount('increase');
				store.generalStore.addMessage(200, 'Person created');
				Amplitude.logEvent('PersonCreated');
				const amp = Amplitude.amplitude();
				const identify = new amp.Identify().add('CreatedPersons', 1);
				amp.getInstance().identify(identify);
				const uid = response.data.payload.uid;
				return uid;
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Person could not be created');
			console.log(e);
			return false;
		}
	}

	static async editProfile(values) {
		if (!_.isEmpty(values)) {
			try {
				const response = await sendRequest(`${apiUrl}/profile/me`, 'put', values);

				if (response.status === 200) {
					store.generalStore.addMessage(200, 'Profile updated');
					Amplitude.logEvent('ProfileUpdated');
					// ContactGetter.populateProfile(true);
				}
			} catch (e) {
				store.generalStore.addMessage(404, 'Profile could not be updated');
				console.log(e);
			}
		}
	}

	static async editProfileSettings(values, message) {
		if (!_.isEmpty(values)) {
			let newValues = toJS(store.generalStore.profileSettings);
			newValues.settings = Object.assign(newValues.settings, values);
			newValues = JSON.parse(JSON.stringify(newValues));
			try {
				const response = await sendRequest(`${apiUrl}/profile/settings/me`, 'put', newValues);

				if (response.status === 200) {
					if (message === true) store.generalStore.addMessage(200, 'Profile settings updated');
					store.generalStore.setProfileSettings(newValues);
				}
			} catch (e) {
				if (message === true) store.generalStore.addMessage(404, 'Profile settings could not be updated');
				console.log(e);
			}
		}
	}

	static async editFavorites(passedValues, message, modus) {
		const values = { favorites: passedValues };
		if (!_.isEmpty(values)) {
			let newValues = toJS(store.generalStore.profileSettings);
			newValues.settings = Object.assign(newValues.settings, values);
			try {
				const response = await sendRequest(`${apiUrl}/profile/settings/me`, 'put', newValues);

				if (response.status === 200) {
					if (message === true) {
						if (modus) {
							if (modus === 'delete') {
								store.generalStore.addMessage(200, 'Favorite removed');
							} else if (modus === 'add') {
								store.generalStore.addMessage(200, 'Favorite added');
							} else {
								store.generalStore.addMessage(200, 'Favorites updated');
							}
						} else {
							store.generalStore.addMessage(200, 'Favorites updated');
						}
					}
					store.generalStore.setProfileSettings(newValues);
				}
			} catch (e) {
				if (message === true) store.generalStore.addMessage(404, 'Favorites could not be updated');
				console.log(e);
			}
		}
	}

	static async updateStore(values, type) {
		if (!('getRequest' in window) || window.getRequest === false) {
			const base = toJS(store.generalStore[type]);
			const newData = Object.assign(base, values);
			if (type === 'person') {
				store.generalStore.setPerson(newData);
			} else {
				store.generalStore.setOrganization(newData);
			}
		} else {
			setTimeout(
				function () {
					this.updateStore(values, type);
				}.bind(this),
				500
			);
		}
	}

	static async editName(id, values, type, base) {

		// const base = toJS(store.generalStore[type]);
		console.log(values)
		console.log(base)
		console.log(type)
		const dto = shallowDiff(base, values);

		const newProfile = JSON.parse(JSON.stringify(toJS(store.generalStore.profile)));
		if (newProfile && newProfile[0] && id === newProfile[0].uid) {
			newProfile[0] = Object.assign({}, newProfile[0], dto);
			store.generalStore.setProfile(newProfile);
		}

		try {
			if (!_.isEmpty(dto)) {
				const data = {
					uid: id,
					dto
				};
				const response = await sendRequest(`${apiUrl}/${type}/${id}`, 'put', data);
				if (response.status === 200) {
					store.generalStore.addMessage(200, 'Edit successful');
					this.updateStore(values, type);
				}
			}
		} catch (e) {
			console.log(e);
			store.generalStore.addMessage(500, 'Edit unsuccessful');
		}
	}

	static async editGroups(id, newGroups, attrType, contactType, isNew, currentRef, base) {
		console.log('editGroups');
		console.log(id, newGroups, attrType, contactType, isNew, currentRef, base);

		const oldState = {};
    for(let i=0;i<base.groups.length;i++) {
      oldState[base.groups[i].uid] = { status: 0, data: base.groups[i]};
    }

    for(let i=0;i<newGroups.length;i++) {
      if(newGroups[i].uid in oldState) {
        delete oldState[newGroups[i].uid];
      } else {
        oldState[newGroups[i].uid] = { status: 1, data: newGroups[i]};
      }
    }

		const entryName = ('name' in base)? base.name : `${base.firstName} ${base.lastName}`;

    for(const key in oldState) {
      if(oldState[key].status === 0) {
				await removeGroup(base.uid, contactType, entryName, oldState[key].data.uid, oldState[key].data.label);
      } else if(oldState[key].status === 1) {
        await addGroup(base.uid, contactType, entryName, oldState[key].data.uid, oldState[key].data.label);
      }
    }
	}

	static async editCategories(id, categories, attrType, contactType, isNew, currentRef, base) {

		const entryUid = base.uid
		const entryName = ('name' in base)? base.name : `${base.firstName} ${base.lastName}`;

		// If the address/contact data was newly created, all categories can be added without further checks
		if (isNew === true && categories) {
			for (let i = 0; i < categories.length; i++) {
				if (categories[i].value && !categories[i].uid) {
					categories[i].uid = categories[i].value;
				}
			}
			await addCategories(id, categories, attrType, currentRef, entryUid, entryName);
		} else if (categories) {
			// const base = toJS(store.generalStore[contactType]);
			let catChanges = {
				added: [],
				removed: []
			};

			if (attrType === 'person' || attrType === 'organization') {
				catChanges = diffCategories(base.categories, categories);
			} else if (attrType === 'addresses') {
				// Find the existing address in the base
				const oldCats =
					base.addresses[
					_.findIndex(base.addresses, function (a) {
						return a.uid === id;
					})
					];
				catChanges = diffCategories(oldCats.categories, categories);
			} else if (attrType === 'contactData') {
				// Find the existing address in the base
				const oldContacts =
					base.contactData[
					_.findIndex(base.contactData, function (a) {
						return a.uid === id;
					})
					];
				catChanges = diffCategories(oldContacts.categories, categories);
			}
			// Make API calls
			let addedCats = [];
			let removedCats = [];

			for (let i = 0; i < catChanges.added.length; i++) {
				addedCats.push({ uid: catChanges.added[i].uid, categoryName: catChanges.added[i].label });
			}

			addedCats.length > 0 && (await addCategories(id, addedCats, attrType, currentRef, entryUid, entryName));

			for (let i = 0; i < catChanges.removed.length; i++) {
				removedCats.push({ uid: catChanges.removed[i].uid, categoryName: catChanges.removed[i].label });
			}

			removedCats.length > 0 && (await removeCategories(id, removedCats, attrType, currentRef, entryUid, entryName));

			console.log('removedCats', removedCats);
		}
	}

	// Handles enumerable sub-attributes like addresses and contact data
	static async editSubAttribute(id, values, attrType, contactType, currentRef, base) {
		try {
			const entryName = ('name' in base)? base.name : `${base.firstName} ${base.lastName}`.trim();

			// const base = toJS(store.generalStore[contactType]);
			const changes = {
				added: [],
				edited: []
			};

			// Iterate through all entries of the changed form
			for (let i = 0; i < values[attrType].length; i++) {
				// If the entry has no uid, it is new and must be added
				if (!values[attrType][i].uid || values[attrType][i].uid === '') {
					values[attrType][i].contextRef = currentRef;
					changes.added.push(values[attrType][i]);
				} else if (values[attrType][i].uid && values[attrType][i].uid !== '') {
					// If the entry does have a uid, it already exists and may have been edited
					const oldAddress =
						base[attrType][
						_.findIndex(base[attrType], function (a) {
							return a.uid === values[attrType][i].uid;
						})
						];
					const changedAttributes = shallowDiff(oldAddress, values[attrType][i]);

					// If any changes have been made, add the entire object to the edited array
					if (!_.isEmpty(changedAttributes)) {
						changedAttributes['uid'] = values[attrType][i].uid;
						changes.edited.push(changedAttributes);
					}
				}
			}

			const categories = [];

			const data = {
				uid: id,
				entryName,
				dto: {},
			};

			data.dto[attrType] = [];

			for (let j = 0; j < changes.added.length; j++) {
				const value = changes.added[j];
				categories.push({
					uid: '',
					isNew: true,
					categories: value.categories,
					contextRef: currentRef
				});

				delete value.categories;
				data.dto[attrType].push(value);
			}
			for (let k = 0; k < changes.edited.length; k++) {
				const value = changes.edited[k];
				delete value.categories;
				data.dto[attrType].push(value);
			}
			for (let l = 0; l < values[attrType].length; l++) {
				const value = values[attrType][l];
				if (value.uid && value.uid !== '') {
					categories.push({
						uid: value.uid,
						isNew: false,
						categories: value.categories,
						contextRef: currentRef
					});
				}
			}

			// Make API calls
			if (!_.isEmpty(data.dto[attrType])) {
				let uri;
				if (currentRef && currentRef !== null) {
					uri = `${apiUrl}/${contactType}/${id}?contextref=${currentRef}`;
				} else {
					uri = `${apiUrl}/${contactType}/${id}`;
				}

				const response = await sendRequest(uri, 'put', data);

				// Gather the newly generated uids for the created sub attributes from the response
				if (response.status === 200) {
					store.generalStore.addMessage(200, 'Edit successful');
					let shortName = attrType.toLowerCase();
					if (shortName === 'addresses') shortName = 'address';
					const eventName = contactType + shortName + 'created';
					let counter = 0;

					// If the event name matches a 'created' event, fill in the uid of the created address
					for (let i = 0; i < response.data.length; i += 1) {
						if (response.data[i].eventName.toLowerCase() === eventName) {
							categories[counter].uid = response.data[i].payload[attrType][0].uid;
							counter++;
						}
					}
				}
			}

			// Call an editCategories for each edited or created sub attribute
			for (let j = 0; j < categories.length; j++) {
				const category = categories[j];
				await this.editCategories(
					category.uid,
					category.categories,
					attrType,
					contactType,
					category.isNew,
					currentRef,
					base
				);
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Edit unsuccessful');
			console.log(e);
		}
	}

	static async deleteSubAttribute(id, attrId, contactType, attrType, entryName) {
		// let response;
		try {
			let uri;
			if (attrType === 'addresses' && id) {
				uri = `${apiUrl}/${contactType}/address/${id}/${attrId}`;
				// response = await sendRequest(`${apiUrl}/${contactType}/address/${id}/${attrId}`, 'delete');
			} else if (attrType === 'contactData' && attrId) {
				uri = `${apiUrl}/${contactType}/contactData/${id}/${attrId}`;
				// response = await sendRequest(`${apiUrl}/${contactType}/contactData/${id}/${attrId}`, 'delete');
			}
			const response = await trackPromise(
				axios.delete(uri, {
					data: {
						entryName,
					},
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			);


			if (response.status === 200) {
				store.generalStore.addMessage(200, 'Edit successful');
			} else {
				store.generalStore.addMessage(500, 'Edit unsuccessful');
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Edit unsuccessful');
		}
	}

	static async editAdditionalInformation(id, values, type) {
		try {
			const base = toJS(store.generalStore[type]);
			const dto = shallowDiff(base, values);

			if (!_.isEmpty(dto)) {
				const data = {
					uid: id,
					dto
				};

				const response = await sendRequest(`${apiUrl}/${type}/${id}`, 'put', data);
				if (response.status === 200) {
					store.generalStore.addMessage(200, 'Edit successful');
				} else {
					store.generalStore.addMessage(500, 'Edit unsuccessful');
				}
			}
		} catch (e) {
			store.generalStore.addMessage(500, 'Edit unsuccessful');
		}
	}

	static async editRelations(id, values, type, deleteContextData) {
		const base = toJS(store.generalStore[type]);

		const sourceName = ('name' in base)? base.name : `${base.firstName} ${base.lastName}`.trim();

		const l = base.relations.length;
		for(let i=0; i<l; i+=1){
			base.relations[i].sourceName = sourceName;
			base.relations[i].targetName = base.relations[i].partner.name;
		}

		const changes = diffRelations(base.relations, values.relations);
		if (changes.added) await addRelations(id, changes.added);

		if (changes.removed) await removeRelations(id, changes.removed, deleteContextData);
	}
}

export default withRouter(ContactEditor);
