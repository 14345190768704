import { observable, decorate, action } from "mobx";

// import { toJS } from "mobx";

const emptyGraph = {
  parentNode: {
    uid: "",
    name: "",
    kind: "",
    image: ""
  },
  types: {
    organizationToOrganization: {
      isClustered: false,
      position: "ignored",
      nodes: []
    },
    organizationToPerson: {
      isClustered: false,
      position: "ignored",
      nodes: []
    },
    personToOrganization: {
      isClustered: false,
      position: "ignored",
      nodes: []
    },
    personToPerson: {
      isClustered: false,
      position: "ignored",
      nodes: []
    }
  }
};

class GraphStore {
  graph = {
    parentNode: {
      uid: "",
      name: "",
      kind: "",
      image: ""
    },
    types: {
      organizationToOrganization: {
        isClustered: false,
        position: "ignored",
        nodes: []
      },
      organizationToPerson: {
        isClustered: false,
        position: "ignored",
        nodes: []
      },
      personToOrganization: {
        isClustered: false,
        position: "ignored",
        nodes: []
      },
      personToPerson: {
        isClustered: false,
        position: "ignored",
        nodes: []
      }
    }
  };

  setGraph(entry) {
    this.graph = JSON.parse(JSON.stringify(emptyGraph));

    const personOrder = [
      "organizationToPerson",
      "personToOrganization",
      "personToPerson"
    ];

    const organizationOrder = [
      "personToOrganization",
      "organizationToPerson",
      "organizationToOrganization"
    ];

    const positions = ["left", "right", "bottomRight", "ignored"];
    const entryType = "name" in entry ? "organization" : "person";

    const parentNodeName =
      entryType === "organization"
        ? entry.name
        : `${entry.firstName} ${entry.lastName}`.trim();

    const image = entry.photo ? entry.photo : entry.logo;

    this.graph.parentNode.uid = entry.uid;
    this.graph.parentNode.name = parentNodeName;
    this.graph.parentNode.kind = entryType;
    this.graph.parentNode.image = image;

    for (let rel = 0; rel < entry.relations.length; rel++) {
      const relType =
        entry.relations[rel].type.charAt(0).toLowerCase() +
        entry.relations[rel].type.slice(1);

      const newRelation = {
        uid: entry.relations[rel].uid,
        label: entry.relations[rel].label,
        relationType: entry.relations[rel].type,
        source: entry.relations[rel].source,
        target: entry.relations[rel].target,
        typeRef: entry.relations[rel].typeRef,
        name: entry.relations[rel].partner.name,
        kind: entry.relations[rel].partner.kind,
        image: entry.relations[rel].partner.image
      };

      this.graph.types[relType].nodes.push(newRelation);
    }

    let order =
      this.graph.parentNode.kind.toLowerCase() === "organization"
        ? organizationOrder
        : personOrder;

    for (let i = order.length - 1; i >= 0; i--) {
      if (this.graph.types[order[i]].nodes.length <= 0) {
        order.splice(i, 1);
      }
    }

    for (let i = 0; i < order.length; i++) {
      this.graph.types[order[i]].position = positions[i];
    }

    for (let j = 0; j < order.length; j++) {
      this.graph.types[order[j]].position = positions[j];
    }

    for (const currentType in this.graph.types) {
      if (this.graph.types[currentType].nodes.length > 2) {
        this.graph.types[currentType].isClustered = true;
      }
    }

    // console.log(toJS(this.graph));

    // Prevent placement on forbidden positions
    if (this.graph.parentNode.kind.toLowerCase() === "organization") {
      if (this.graph.types["organizationToPerson"].position === "left") {
        this.graph.types["organizationToPerson"].position = "right";
        if (
          this.graph.types["organizationToOrganization"].position === "right"
        ) {
          this.graph.types["organizationToOrganization"].position =
            "bottomRight";
        }
      } else if (
        this.graph.types["organizationToOrganization"].position === "left"
      ) {
        this.graph.types["organizationToOrganization"].position = "right";
      }
    } else {
      if (this.graph.types["personToOrganization"].position === "left") {
        this.graph.types["personToOrganization"].position = "right";
        if (this.graph.types["personToPerson"].position === "right") {
          this.graph.types["personToPerson"].position = "bottomRight";
        }
      } else if (this.graph.types["personToPerson"].position === "left") {
        this.graph.types["personToPerson"].position = "right";
      }
    }

    // console.log(toJS(this.graph));
  }
}

decorate(GraphStore, {
  graph: observable,
  setGraph: action
});

export default GraphStore;
