import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = constants.apiUrl;

// Get all integration templates
export const getIntegrationTemplates = async (token, page, num) => {
	try {

		const response = await trackPromise(
			axios.get(`${apiUrl}/integration/template?num=${num}&page=${page}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
	} catch (e) {
		// console.log(e);
		store.generalStore.addMessage(500, 'Integrations could not be retrieved');

		if ('role' in store.authStore.userData && store.authStore.userData.role === 'ADMIN') return;
		return history.push('/not-found');
	}
};

// Get all integrations for tenant
export const getIntegrations = async (token, page, num) => {
	try {
		const response = await trackPromise(
			axios.get(`${apiUrl}/integration?num=${num}&page=${page}&${Date.now()}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
	} catch (e) {
		// console.log(e);
		store.generalStore.addMessage(500, 'Integrations could not be retrieved');

		if ('role' in store.authStore.userData && store.authStore.userData.role === 'ADMIN') return;
		return history.push('/not-found');
	}
};


// Start flow for creation of oauth2 auth
export const startOauth2AuthFlow = async (id, data) => {
    try {
        const response = await trackPromise(axios.post(
            `${apiUrl}/integration/authFlow/${id}/start?${Date.now()}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, 'Auth flow started');
            if('data' in response.data && 'authUrl' in response.data.data) {
              const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;
              if(isOSPWA) {
                window.open(response.data.data.authUrl, '_system');
              } else {
                window.location.href = response.data.data.authUrl;
              }
            }
            console.log(response.data);
            // getAuthSecrets();

            // return response.data;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
            store.generalStore.addMessage(500, 'Auth flow could not be started');
            // alert(`Response Status: ${response.status}`);
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Auth flow could not be started');
        console.log('ERROR', e);
    }
};

// Save a basic secret
export const saveSecret = async (payload) => {
    try {
      const response = await trackPromise(axios.post(
          `${apiUrl}/integration/secret?${Date.now()}`,
          payload,
          {
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${store.authStore.token}`
              }
          }
      ));

      if (response.status === 200) {
          store.generalStore.addMessage(200, 'Secret saved');
          if('data' in response.data && 'authUrl' in response.data.data) {
            const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;
            if(isOSPWA) {
              window.open(response.data.data.authUrl, '_system');
            } else {
              window.location.href = response.data.data.authUrl;
            }
          }
          console.log(response.data);
          // getAuthSecrets();

          return response.data;
      } else if (response.status === 401) {
          store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
      } else {
          store.generalStore.addMessage(500, 'Secret could not be saved');
          // alert(`Response Status: ${response.status}`);
      }
    } catch (e) {
        store.generalStore.addMessage(500, 'Secret could not be saved');
        console.log('ERROR', e);
    }
};


// Save a new integration based on secrets and templateRef
export const saveIntegration = async (data) => {
    try {
				if (!data.metaUserId) {
					data.metaUserId = store.authStore.userData.user
				}
        const response = await trackPromise(axios.post(
            `${apiUrl}/integration/?${Date.now()}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, 'Integration saved');
            console.log(response.data);
            return response.data;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
            store.generalStore.addMessage(500, 'Integration could not be saved');
            // alert(`Response Status: ${response.status}`);
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Integration could not be saved');
        console.log('ERROR', e);
    }
};


// Save a new integration based on secrets and templateRef
export const deleteIntegration = async (id) => {
    try {
        const response = await trackPromise(axios.delete(
            `${apiUrl}/integration/${id}?${Date.now()}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, 'Integration deleted');
            console.log(response.data);
            // return response.data;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
            store.generalStore.addMessage(500, 'Integration could not be deleted');
            // alert(`Response Status: ${response.status}`);
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Integration could not be deleted');
        console.log('ERROR', e);
    }
};


// Start / stop an integration flow
export const triggerIntegrationFlow = async (id, command) => {
    try {
        const response = await trackPromise(axios.get(
            `${apiUrl}/integration/flow/${id}/${command}?${Date.now()}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, `Integration ${command}`);
            console.log(response.data);
            // return response.data;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
            store.generalStore.addMessage(500, `Could not ${command} the integration flow`);
            // alert(`Response Status: ${response.status}`);
        }
    } catch (e) {
        store.generalStore.addMessage(500, `Could not ${command} the integration flow`);
        console.log('ERROR', e);
    }
};
