// Our Login formular
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import { withTranslation } from "react-i18next";

// Formik is dead - Long live Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GoogleButton from 'react-google-button'
import MaterialLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from "@material-ui/core/Grid";

// Mobx
import { inject, observer } from 'mobx-react';

// Actions
import auth from '../../actions/auth';
import register from '../../actions/register';
import constants from '../../stores/constants'

const styles = theme => ({
  root: {},
  emailField: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  passwordField: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit
  },
  input: {
    WebkitBoxShadow: "0 0 0 30px white inset !important",
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
      '-webkit-box-shadow': '0 0 0 50px white inset',
      '-webkit-text-fill-color': '#333',
    }
  }
});

class Login extends Component {

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  state = {
    email: '',
    password: '',
    emailError: false,
    passwordError: false
  }

  componentDidMount() {
    if (this.props.withToken
      && this.props.match.params.token
    ) {
      register.doOptin(this.props.match.params.token);
    }

    if (this.props.email) {
      this.setState({
        email: this.props.email
      })
    }
  }

  verifyEmail(value) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value)) {
      this.setState({
        emailError: false
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }

  emailChange(event) {
    this.setState({
      email: event.target.value
    })
    this.verifyEmail(event.target.value)
  }

  passwordChange(event) {
    const passwordError = !(event.target.value && event.target.value.length > 0)
    this.setState({
      password: event.target.value,
      passwordError
    })
  }


  togglePasswordVisibility() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }


  async submitLogin(e) {
    e.preventDefault();
    await auth.doLogin(this.state.email, this.state.password)
  }

  render() {
    const { t, classes } = this.props;
    const withToken = (this.props.withToken) ? true : false;

    const canSubmit = !(
      this.state.email &&
      this.state.email.length > 0 &&
      !this.state.emailError &&
      this.state.password &&
      this.state.password.length > 0 &&
      !this.state.passwordError
    )

    let optin = this.props.authStore.optin;

    if (optin && optin.msg &&
      (
        (optin.optin && optin.optin.status && optin.optin.status === 'CONFIRMED')
        ||
        (optin.updatedoptin && optin.updatedoptin.status && optin.updatedoptin.status === 'CONFIRMED')
      )) optin = 'ACTIVE';

    // t('Your Email ie.') + ': ' + t('me@something.com')
    // t('Your Pass ex') + ': ' + t('123456')
    return (
      <div id='logindiv' key="login-form-div">
        <h2>{t('Login')}</h2>
        {this.props.errorMessage !== '' &&
          <div className="error">{this.props.errorMessage}
            <br /><br />
            {this.props.authStore.confirmed && <Link to={constants.path.resend}>{t('Click here to resend email')}</Link>}
          </div>
        }
        {withToken && optin === 'inprogress' &&
          <div>{t('Processing optin...')}</div>
        }
        {withToken && optin === 'ACTIVE' &&
          <div>{t('Optin done you can now login')}</div>
        }
        {withToken && optin.msg &&
          <div>{t('Optin failed')}</div>
        }
        <form key="login-form-key" id="login-form" onSubmit={this.submitLogin.bind(this)} >
          <Grid
            key="login-form-grid"
            id="logingrid"
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={16}
          >
            <Grid item sm={12}>
              <TextField
                key="emailLoginField"
                id="outlined-email-input-login"
                label={t("Email")}
                className={classes.emailField}
                type="text"
                error={this.state.emailError}
                name="username"
                value={this.state.email}
                onChange={this.emailChange.bind(this)}
                margin="normal"
                variant="outlined"
                fullWidth
                helperText={this.state.emailError ? t('Please enter a valid email') : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ width: 48 }} />
                    </InputAdornment>
                  ),
                  key: 'emailLoginInputKey',
                  id: 'emailLoginInput',
                }}
                inputProps={{ className: classes.input }}  // eslint-disable-line
                // eslint lies, there's a difference between InputProps and inputProps
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                key="passwordLoginField"
                id="outlined-password-input-login"
                label={t("Password")}
                className={classes.passwordField}
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                error={this.state.passwordError}
                value={this.state.password}
                onChange={this.passwordChange.bind(this)}
                margin="normal"
                variant="outlined"
                helperText={this.state.passwordError ? t('Please enter a password') : null}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility.bind(this)}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  key: 'passwordLoginInputKey',
                  id: 'passwordLoginInput'
                }}
                inputProps={{ className: classes.input }} // eslint-disable-line
                // eslint lies, there's a difference between InputProps and inputProps
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" className={classes.submitButton} disabled={canSubmit} type="submit" color="primary" >
                {t('Submit')}
              </Button>

            </Grid>
            <Grid item sm={12}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MaterialLink
                  href={`${constants.apiUrl}/oauth/google`}
                >
                  <GoogleButton />
                </MaterialLink>
              </div>
            </Grid>

          </Grid>
        </form>

      </div>
    );
  }
}

// export default withRouter(Login);
export default withStyles(styles)(withTranslation("translations")(inject('authStore')(observer(Login))));
