// Handles all actions required for user registration

import React from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios'

import Amplitude from 'react-amplitude';

// import Cookies from 'universal-cookie';
// import { I18nextProvider } from 'react-i18next';

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';

// Actions
import history from './history';
import auth from './auth';

// React Internationalization
import i18n from '../i18n';
import { withTranslation } from "react-i18next";

withTranslation("translations");

// const cookies = new Cookies();

class registration extends React.Component {
  // make the registration call to the backend
  static doRegistration(username, password, token) {
    store.generalStore.setLoading(true);

    // TODO: we should take the values for `firstname` and `lastname` from user input

    let opts;
    let endpoint;
    if (typeof token !== 'undefined' && token !== '') {
      opts = { email: username, password, firstname: 'new', lastname: 'user', token, language: store.generalStore.language };
      endpoint = `${constants.apiUrl}/iam/user/invite`;
    } else {
      opts = { username, password, firstname: 'new', lastname: 'user', language: store.generalStore.language };
      endpoint = `${constants.apiUrl}/iam/user`;
    }

    fetch(endpoint, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit',
      body: JSON.stringify(opts)
    }).then(function (response) {
      return response.text();
    }).then((res) => {
      let json = {};

      try {
        json = JSON.parse(res);
      } catch (e) {
        console.log(e);
      }
      if (!json.hasError && !json.error) {
        store.authStore.setReturnTo(constants.path.profile);

        if (typeof token !== 'undefined' && token !== '') {
          if (opts.username) {
            history.push(constants.path.registerStep2);
          } else {
            auth.doLogin(opts.email, opts.password);
            Amplitude.logEvent('InvitationAccepted');
            Amplitude.logEvent('MemberCreated');
          }
        } else {
          console.log(constants.path.registerStep2);
          Amplitude.logEvent('UserRegistrationStarted');
          history.push(constants.path.registerStep2);
        }

        // json = {success: res.replace('Created user','')};
      } else if (json.hasError && !json.error) {
        console.log(json);
      }
      if (json.message) {
        if (json.message === 'DUPLICATE_KEY') {
          store.generalStore.setLoading(false);
          store.generalStore.setErrorMessage(i18n.t('You are already registered please login'));
          store.authStore.setReturnTo(constants.path.listPersons);
          history.push(constants.path.login);
        }
      } else {
        store.generalStore.setErrorMessage('');
      }
      store.generalStore.setLoading(false);
    }).catch(function (error) {
      console.log(error);
      store.generalStore.setLoading(false);
    });
  }

  static verifyToken(token) {
    store.generalStore.setErrorMessage('');
    axios.get(`${constants.apiUrl}/iam/invite/${token}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit',
    }).then((res) => {
      const json = res.data
      if (json.invitation) {
        store.generalStore.setErrorMessage('');
        store.authStore.setEmail(json.invitation.email);
        return json.invitation.email
      } else {
        store.generalStore.setErrorMessage('An Error occured. Did you already use the link? Then please login.');
        return false
      }
    });
  }

  static async deleteMe(token) {
    fetch(`${constants.apiUrl}/iam/delete/${token}`, {
      method: 'delete',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit',
    }).then(function (response) {
      return response.text();
    }).then((res) => {
      // const json = JSON.parse(res);
      // store.authStore.setEmail(json.invitation.email);
    });
  }

  static async doOptin(token) {
    if (token === null || token === '') return;
    if (store.authStore.optin === 'inprogress') return;

    store.authStore.setOptin('inprogress');

    fetch(`${constants.apiUrl}/iam/optin/${token}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit',
    }).then(function (response) {
      return response.text();
    }).then((res) => {
      const json = JSON.parse(res);
      store.authStore.setOptin(json);
      if (json.msg && json.msg === 'Optin confirmed!') {
        Amplitude.logEvent('UserRegistrationEnded');
        Amplitude.logEvent('MemberCreated');
      }
    });
  }

  static async resendEmail(email, language) {
    const options = {
      username: email,
      language
    };

    fetch(`${constants.apiUrl}/iam/user/resendoptin`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit',
      body: JSON.stringify(options)
    }).then(function (response) {
      return response;
    }).then((res) => {
      if (res.status === 404) {
        store.generalStore.setErrorMessage(i18n.t('User with this email not found'));
        store.generalStore.addMessage(400, i18n.t('User with this email not found'));
      } else {
        store.generalStore.setErrorMessage(i18n.t('RegisterStep2 Info'));
        store.generalStore.addMessage(200, i18n.t('Email was sent'));
        Amplitude.logEvent('VerificationEmailResent');
      }
    });
  }
}


export default withRouter(registration);
