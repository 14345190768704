import { observable, decorate, action, toJS } from "mobx";
import relationSuggestions from "../actions/relations";

class RelationsStore {
  relationTypes;

  allChecked = false;
  selections = 0;
  relationSuggestion = {};
  relationSuggestions = [];
  relationSuggestionsIndex = {};
  relationSuggestionsHash = {};
  sourceObj = {};
  targetObj = {};
  newRelation = {};
  sorting = "regular";
  relationSuggestionsCount = 0;
  relationCategoryType = "PersonToOrganization";

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.relationTypes = {};

    this.newRelation = {
      label: "",
      typeRef: "",
      type: "",
      // uids: [uid1, uid2]
      source: {
        uid: "",
        name: "",
        image: ""
      },
      target: {
        uid: "",
        initials: "",
        image: ""
      }
    };
  }

  setRelationTypes(list) {
    this.relationTypes = list;
  }

  // Set Relations in mobx store
  setRelationSuggestions = Relations => {
    this.relationSuggestions = Relations;
    if (Relations && Relations.length > 1) {
      this.sortRelationSuggestions(this.sorting);
    }
  };

  setRelationSuggestion = Relation => {
    this.Relation = Relation;
  };

  setChecked(id, value) {
    let oldValue = "none";
    if (this.relationSuggestions[id] && this.relationSuggestions[id].checked) {
      oldValue = this.relationSuggestions[id].checked;
      if (oldValue === value) {
        this.relationSuggestions[id].checked = "none";
      } else {
        this.relationSuggestions[id].checked = value;
      }
    } else {
      this.relationSuggestions[id].checked = value;
    }

    if (oldValue === "none") {
      this.selections += 1;
    } else if (this.relationSuggestions[id].checked === "none") {
      this.selections -= 1;
    }
  }

  swapRelation(id) {
    const oldIncoming = Object.assign(
      {},
      this.relationSuggestions[id].incomingObject
    );
    this.relationSuggestions[id].incomingObject = this.relationSuggestions[
      id
    ].targetObject;
    this.relationSuggestions[id].targetObject = oldIncoming;
  }

  setRelationType(id, value) {
    // console.log(value);
    this.relationSuggestions[id].relationType = value;
    // console.log(this.relationSuggestions[id].relationType);
  }

  setCheckedAll(value) {
    this.allChecked = value;
    let key;
    let c = 0;
    for (key in this.relationSuggestions) {
      this.relationSuggestions[key].checked = value;
      c += 1;
    }

    if (value === true) {
      this.selections = c;
    } else {
      this.selections = 0;
    }
  }

  // Set Relations count in mobx store
  setRelationSuggestionsCount = count => {
    this.relationSuggestionsCount = count;
  };

  // Fetch all Relations
  getRelationSuggestions = async () => {
    const { token } = this.rootStore.authStore;

    let page = 2;
    const num = 10000;
    const res = await relationSuggestions.getRelationSuggestions(token, 1, num);

    if (res) {
      this.setRelationSuggestions(res.data);
      if (res.meta) {
        this.setRelationSuggestionsCount(res.meta.count);
        // (this.sorting !== 'lastUpdate') && this.indexRelationSuggestions();
        // this.indexRelationSuggestions();
      }

      if (res.meta.count > num) {
        for (let i = num + 1; i < res.meta.count; i += num) {
          const nextRes = await relationSuggestions.getRelationSuggestions(
            token,
            page,
            num
          );
          const newList = nextRes.data.concat(toJS(this.relationSuggestions));
          this.setRelationSuggestions(newList);
          page++;
        }
      }
    }

    return this.relationSuggestions;
  };

  indexRelationSuggestions() {
    const sorting = this.sorting;

    const hash = {};
    const shortHash = {};
    const RelationLength = this.relationSuggestions.length;
    let i;
    let j;
    let c;
    let char;
    let code;

    for (i = 0; i < RelationLength; i += 1) {
      if (!this.relationSuggestions[i]) continue;
      char = this.relationSuggestions[i].name.substr(0, 1).toUpperCase();

      if (char === "") continue;
      const currentCode = char.charCodeAt(0);
      if (currentCode < 64 || currentCode > 91) continue;
      code = currentCode;

      // is first starting with this letter
      if (char in hash === false) {
        if (sorting === "regular") {
          //  check if previous anchors exist to close gaps
          for (j = code - 1; j > 64; j -= 1) {
            c = String.fromCharCode(j);
            if (c in hash === false) {
              hash[c] = i;
            }
          }
        } else {
          //  reverse sorting / inverted
          for (j = code + 1; j < 91; j += 1) {
            c = String.fromCharCode(j);
            if (c in hash === false) {
              hash[c] = i;
            }
          }
        }
        hash[char] = i;
        shortHash[char] = i;
      }
    }

    if (i >= RelationLength) i = RelationLength - 1;

    if (sorting === "inverted") {
      if (code > 64) {
        for (i = code - 1; i > 64; i -= 1) {
          c = String.fromCharCode(i);
          hash[c] = i;
        }
      }
    } else {
      if (code < 91) {
        for (i = code + 1; i < 91; i += 1) {
          c = String.fromCharCode(i);
          hash[c] = i;
        }
      }
    }

    this.relationSuggestionsHash = hash;

    let RelationsIndex = {};
    const keys = Object.keys(shortHash);
    const length = keys.length;

    for (i = 0; i < length; i++) {
      if (!RelationsIndex[shortHash[keys[i]]])
        RelationsIndex[shortHash[keys[i]]] = keys[i];
    }

    this.relationSuggestionsIndex = RelationsIndex;
  }

  // Sort Relation Suggestions
  sortRelationSuggestions = order => {
    let sortedOrgs = [];

    if (order === "regular") {
      this.sorting = "regular";
      sortedOrgs = this.relationSuggestions.slice().sort(function(a, b) {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        return 0;
      });
    } else if (order === "inverted") {
      this.sorting = "inverted";
      sortedOrgs = this.relationSuggestions.slice().sort(function(a, b) {
        if (a.score > b.score) {
          return 1;
        }
        if (a.score < b.score) {
          return -1;
        }
        return 0;
      });
    } else if (order === "lastUpdate") {
      this.sorting = "lastChecked";
      sortedOrgs = this.relationSuggestions.slice().sort(function(a, b) {
        return b.lastChecked - a.lastChecked;
      });
    }

    if (order === "lastUpdate") {
      this.relationSuggestions = sortedOrgs;
      this.relationSuggestionsIndex = {};
    } else {
      this.relationSuggestions = sortedOrgs;
      // this.indexRelationSuggestions();
    }
  };

  setSourceObj(obj) {
    this.sourceObj = obj;
  }

  setTargetObj(obj) {
    this.targetObj = obj;
  }

  setNewRelation(relation) {
    this.newRelation = relation;
  }

  swapObjects() {
    const sourceObjCopy = Object.assign({}, this.sourceObj);
    const targetObjCopy = Object.assign({}, this.targetObj);

    this.setSourceObj(targetObjCopy);
    this.setTargetObj(sourceObjCopy);
  }

  swapNewRelation() {
    const relationCopy = Object.assign({}, this.newRelation);

    const temp = relationCopy.source;
    relationCopy.source = relationCopy.target;
    relationCopy.target = temp;

    if (relationCopy.type === "OrganizationToPerson") {
      relationCopy.type = "PersonToOrganization";
      relationCopy.label = "";
      relationCopy.typeRef = "";
    } else if (relationCopy.type === "PersonToOrganization") {
      relationCopy.type = "OrganizationToPerson";
      relationCopy.label = "";
      relationCopy.typeRef = "";
    }
    this.newRelation = relationCopy;
  }

  setRelationCategoryType(relType) {
    this.relationCategoryType = relType;
  }
}

decorate(RelationsStore, {
  relationTypes: observable,
  relationSuggestion: observable,
  relationSuggestions: observable,
  relationSuggestionsCount: observable,
  relationsSuggestionsIndex: observable,
  relationsSuggestionsHash: observable,
  selections: observable,
  sourceObj: observable,
  targetObj: observable,
  newRelation: observable,
  relationCategoryType: observable,

  setRelationTypes: action.bound,
  setRelationSuggestionsCount: action,
  setRelationSuggestions: action,
  setRelationSuggestion: action,
  getRelationSuggestions: action,
  sortRelations: action,
  setChecked: action,
  setCheckedAll: action,
  swapRelation: action,
  setRelationType: action,
  setSourceObj: action,
  setTargetObj: action,
  setNewRelation: action,
  swapNewRelation: action,
  swapObjects: action,
  setRelationCategoryType: action
});

export default RelationsStore;
