// Render a list of search results

import React, { Component } from "react";
import StickyBox from "react-sticky-box";

// Material UI
import { withStyles } from "@material-ui/core/styles";

// Mobx Stores
import { observer, inject } from "mobx-react";

import constants from "../../stores/constants";

// Components

// import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";

import CardHeader from "@material-ui/core/CardHeader";
import TheIcon from "@material-ui/icons/History";
import Divider from "@material-ui/core/Divider";

// React Internationalization
import { withTranslation } from "react-i18next";

// Actions
import getContact from "../../actions/getContact";

import HistoryBlock from "../templates/HistoryBlock";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1
  },
  titleIconHolder: {
    "text-align": "center"
  },
  titleIcon: {
    color: constants.color.main,
    width: 25,
    height: 25,
    "margin-top": theme.spacing.unit,
    "margin-left": theme.spacing.unit * 1
  },

  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  fullTitle: {
    // backgroundColor: 'grey'
    float: "left",
    // marginTop: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: 0
  },
  description: {
    // padding: '10px'
  },
  fieldLine: {
    paddingTop: 0,
    paddingBottom: 0
  },
  firstFieldLine: {
    paddingBottom: 0
  },
  gridContainer: {
    padding: theme.spacing.unit
  },
  gridItem: {
    padding: theme.spacing.unit
  },
  gridItemIcons: {
    padding: theme.spacing.unit,
    height: theme.spacing.unit * 6
  },

  historyHolder: {
    clear: 'both',
    display: 'block',
    position: 'relative',
    textAlign: 'center',
  },
});

class JournalView extends Component {
  _isMounted = false;
  state = {
    events: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  async componentDidMount() {
    this.props.generalStore.setListState('journal');
    this._isMounted = true;

    // const t0 = Date.now();
    const events = await getContact.getAllEvents(100);
    // console.log(events);
    // const timePassed = Date.now() - t0;
    // console.log('get', timePassed);

    if(this._isMounted) this.setState({events: events});
    // console.log(events)
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render() {
    const { t, classes } = this.props;


    return (
      <div className="Journal" style={{ marginBottom: 65 }}>
        <Grid item xs={12} key="journalGrid">
          <StickyBox
            style={{ zIndex: "200", boxShadow: 'none' }}
            offsetTop={0}
            offsetBottom={20}
          >
            <Paper className={classes.control}>
              <Grid container>
                <ListItem
                  key="journalheader"
                  selected={this.props.selectedIndex === 0}
                >
                  <TheIcon className={classes.titleIcon} />
                  <CardHeader
                    title={t('Journal')}
                    className={classes.fullTitle}
                  />
                </ListItem>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </Paper>
          </StickyBox>
            <Paper className={classes.control}>
              <Grid container>
              <ListItem
                key="journallabel"
                selected={this.props.selectedIndex === 0}
                className={classes.firstFieldLine}
              >
                <div className={classes.historyHolder}>
                  <HistoryBlock journal={true} data={this.state.events} revertEvent={false} />
                </div>
              </ListItem>
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default withTranslation("translations")(
  withStyles(styles)(inject("generalStore")(observer(JournalView)))
);
