// Handles file uploads and progress

import React from "react";
import { withRouter } from "react-router-dom";

import axios from 'axios';

import { withTranslation } from "react-i18next";

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';

// Actions
import history from './history';

withTranslation("translations");

class FileUploader extends React.Component {
  static upload(files, uploadType) {
    store.generalStore.setShowUploadDialog(true);
    store.generalStore.setUploading(0);
    store.generalStore.setErrorMessage('');
    store.generalStore.setErrorDataHeader('');
    store.generalStore.setErrorData('');

    let data = new FormData()
    data.append('file', files[0])

    const config = {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        'Authorization': `Bearer ${store.authStore.token}`,
      },
      onUploadProgress: function (event) {
        var completion = Math.round((event.loaded * 100) / event.total);
        store.generalStore.setUploading(completion);
      }
    }

    let url = `${constants.apiUrl}/import/`;
    if (uploadType === 'excel' || uploadType === 'vcard' || uploadType === 'csv') url = `${constants.apiUrl}/import/file/`;

    axios.post(url, data, config)
      .then(res => {
        if (res.error) {
          store.generalStore.setErrorMessage(this.props.t(res.error));

          if (res.missingFields) {
            store.generalStore.setErrorDataHeader('The following fields are missing');
            store.generalStore.setErrorData(res.missingFields.join(", "));
          }

          store.generalStore.setUploading(1000);
          setTimeout(function () {
            if (store.generalStore.uploading >= 100) store.generalStore.setUploading(0);
            store.generalStore.setShowUploadDialog(false);
            store.generalStore.setErrorMessage('');
            store.generalStore.setErrorDataHeader('');
            store.generalStore.setErrorData('');
          }, 20000);
        } else if (uploadType === 'excel' || uploadType === 'vcard' || uploadType === 'csv') {
          store.generalStore.setShowUploadDialog(false);
          store.generalStore.setErrorMessage('');
          store.generalStore.setErrorDataHeader('');
          store.generalStore.setErrorData('');

          store.generalStore.setBackendData(res.data);
          history.push(constants.path.visualMapping);

        } else {
          setTimeout(function () {
            if (store.generalStore.uploading >= 100) store.generalStore.setUploading(0);
            store.generalStore.setShowUploadDialog(false);
            store.generalStore.setErrorMessage('');
            store.generalStore.setErrorDataHeader('');
            store.generalStore.setErrorData('');
          }, 3000);
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {

          if(error.response.data.errno) {
            store.generalStore.setErrorMessage(error.response.data.errno);
          } if(error.response.data.error) {
            store.generalStore.setErrorMessage(error.response.data.error);
          } else {
            store.generalStore.setErrorMessage(JSON.stringify(error.response.data));
          }

          if (error.response.data.missingFields) {
            store.generalStore.setErrorDataHeader('The following fields are missing');
            store.generalStore.setErrorData(error.response.data.missingFields.join(", "));
          }
        } else {
          store.generalStore.setErrorMessage('');
        }
        store.generalStore.setUploading(1000);
        setTimeout(function () {
          if (store.generalStore.uploading >= 100) store.generalStore.setUploading(false);
          store.generalStore.setShowUploadDialog(false);
          store.generalStore.setErrorMessage('');
          store.generalStore.setErrorDataHeader('');
          store.generalStore.setErrorData('');
        }, 20000);
      })
  }

  static continueUpload(fileName, mapping, typeMap, selectedGroups) {

    const config = {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Authorization': `Bearer ${store.authStore.token}`,
      },
    }

    const url = `${constants.apiUrl}/import/file/continue`;

    axios.post(url, { fileName, mapping, typeMap, groups: selectedGroups }, config)
      .then(res => {
        if (res.error) {
          store.generalStore.addMessage(500, 'Continue Import failed');
          return res.data;
        } else {
          store.generalStore.addMessage(200, 'Import continued');
          history.push(constants.path.listPersons);
        }
      })
      .catch(e => {
        store.generalStore.addMessage(500, 'Continue Import failed');

        if (typeof e.response === 'object'
            && 'data' in e.response
            && 'error' in e.response.data
          ) {
            history.push(`${constants.path.importExport}?error=${e.response.data.error}`);
          }
        // if (typeof e.response === 'object' && 'data' in e.response) return e.response.data;
  			// return e.response;
      })
  }
}

export default withRouter(FileUploader);
