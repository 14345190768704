// Render a list of search results

import React, { Component } from "react";

import { Link } from "react-router-dom";

import StickyBox from "react-sticky-box";


import moment from 'moment'

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

// import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";

import CardHeader from "@material-ui/core/CardHeader";

import TheIcon from '@material-ui/icons/Ballot';

import Divider from "@material-ui/core/Divider";

// Mobx Stores
import { observer, inject } from "mobx-react";

import constants from "../../stores/constants";

// React Internationalization
import { withTranslation } from "react-i18next";

// Actions
import history from '../../actions/history';
import governance from "../../actions/governance";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1
  },
  titleIconHolder: {
    "text-align": "center"
  },
  titleIcon: {
    color: constants.color.main,
    width: 25,
    height: 25,
    "margin-top": theme.spacing.unit,
    "margin-left": theme.spacing.unit * 1
  },

  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  fullTitle: {
    // backgroundColor: 'grey'
    float: "left",
    // marginTop: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: 0
  },
  description: {
    // padding: '10px'
  },
  fieldLine: {
    paddingTop: 0,
    paddingBottom: 0
  },
  firstFieldLine: {
    paddingBottom: 0
  },
  gridContainer: {
    padding: theme.spacing.unit
  },
  gridItem: {
    padding: theme.spacing.unit
  },
  gridItemIcons: {
    padding: theme.spacing.unit,
    height: theme.spacing.unit * 6
  },

  formsHolder: {
    clear: 'both',
    display: 'block',
    position: 'relative',
    textAlign: 'center',
  },
});

class FormsList extends Component {
  _isMounted = false;
  state = {
    forms: [],
  };

  // constructor(props) {
  //   super(props);
  // }

  async componentDidMount() {
    this.props.generalStore.setListState('FormsList');
    this._isMounted = true;

    const forms = await governance.getAllForms();

    if(this._isMounted) this.setState({forms});
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  addClick = (event) => {
    history.push(`${constants.path.editForm}/new`);
  }

  render() {
    const { t, classes } = this.props;

    const formPanels = [];
    const length = this.state.forms.length;
    for(let i=0; i<length; i+=1) {
      const form = this.state.forms[i];

      const date = moment(form.updatedAt).format('DD.MM.YYYY');
      formPanels.push(
        <>
        <ListItem key={`li${i}`} className={classes.formPanel}>
          <Grid container spacing={8}>
            <Grid key={`gh1-${i}`} item xs={2}>
              {date}
            </Grid>
            <Grid key={`gh2-${i}`} item xs={2}>
              {form.language}
            </Grid>
            <Grid key={`gh3-${i}`} item xs={8}>
              <Link to={`${constants.path.editForm}/${form._id}`}>
              {form.titleText}
              </Link>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem key={`lid${i}`} className={classes.divider}>
          <Divider style={{ width: '100%' }} />
        </ListItem>
        </>
      );
    }

    if(formPanels.length === 0) {
      formPanels.push(
        <ListItem key={'li1'} className={classes.formPanel}>
          <Grid container spacing={8}>
            <Grid key={'g1'} item xs={12}>
              {t('You have no forms yet')}
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return (
      <div className="FormsList" style={{ marginBottom: 65 }}>
        <Grid item xs={12} key="FormsListGrid">
          <StickyBox
            style={{ zIndex: "200", boxShadow: 'none' }}
            offsetTop={0}
            offsetBottom={20}
          >
            <Paper className={classes.control}>
              <Grid container>
                <ListItem
                  key="FormsListheader"
                  selected={this.props.selectedIndex === 0}
                >
                  <TheIcon className={classes.titleIcon} />
                  <CardHeader
                    title={t('FormsList')}
                    className={classes.fullTitle}
                  />
                </ListItem>
                <ListItem key="ListheaderAdd">
                  <Button variant="contained" className={classes.addButton} onClick={this.addClick} type="button" color="primary" >
                    {t('+ Add form')}
                  </Button>
                </ListItem>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </Paper>
          </StickyBox>
            <Paper className={classes.control}>
              <Grid container>
              <ListItem key="TableHeader" className={classes.formPanel}>
                <Grid container spacing={8}>
                  <Grid key={'gh1'} item xs={2}>
                    {t('date')}
                  </Grid>
                  <Grid key={'gh2'} item xs={2}>
                    {t('language')}
                  </Grid>
                  <Grid key={'gh3'} item xs={8}>
                    {t('title')}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem key="TableHeaderDivider" className={classes.divider}>
                <Divider style={{ width: '100%' }} />
              </ListItem>
              {formPanels}
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default withTranslation("translations")(
  withStyles(styles)(inject("generalStore")(observer(FormsList)))
);
