import React, { Component } from "react";

// Mobx
import constants from "../../stores/constants";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Email from "@material-ui/icons/Email";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Phone from "@material-ui/icons/Phone";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';

// action
// import { confirmOrDeclineSuggestion } from '../../actions/person'

import {
  Linkedin,
  Facebook,
  Twitter,
  Xing,
  Fax,
  Home,
  CellphoneAndroid,
  Instagram,
} from "mdi-material-ui";

// React multilanguage
import { withTranslation } from "react-i18next";

// Phone number formatting
import PhoneNumber from 'awesome-phonenumber'
import { t } from "i18next/dist/commonjs";

const styles = theme => ({
  outer: {
    paddingLeft: 20
    // paddingTop: 12,
  },
  fields: {
    margin: 30
  },
  contactValue: {
    position: "absolute",
    marginLeft: 10
  },
  icon: {
    color: constants.color.main //'#2e8bbe',
  },
  iconDisabled: {
    color: constants.color.heading
  },
  typography: {
    paddingTop: 6,
    // paddingLeft: 20,
    overflowWrap: "break-word",
  },
  typographyBig: {
    paddingTop: 6,
    // paddingLeft: 20,
    overflowWrap: "break-word",
    minWidth: '100px',
    marginLeft: theme.spacing.unit,
  },
  chip: {
    margin: 0,
    maxWidth: "80%",
    overflow: "hidden"
  },
  chipUnconfirmed: {
    margin: 0,
    maxWidth: "80%",
    overflow: "hidden",
    backgroundColor: constants.color.light,
    color: 'white'
  },
  link: {
    cursor: "pointer",
    color: constants.color.main
  },
  linkDisabled: {
    cursor: "pointer",
    color: constants.color.heading
  }
});

const vanityMap = {
  'a': '2',
  'b': '2',
  'c': '2',
  'd': '3',
  'e': '3',
  'f': '3',
  'g': '4',
  'h': '4',
  'i': '4',
  'j': '5',
  'k': '5',
  'l': '5',
  'm': '6',
  'n': '6',
  'o': '6',
  'p': '7',
  'q': '7',
  'r': '7',
  's': '7',
  't': '8',
  'u': '8',
  'v': '8',
  'w': '9',
  'x': '9',
  'y': '9',
  'z': '9',
};

class SingleContact extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  state = {
    confirmDialog: false
  }

  handleConfirmClose() {
    this.setState({
      confirmDialog: false
    })
  }

  async handleContactDelete() {
    await this.props.handleSuggestion(this.props.contact, 'delete')

    this.setState({
      confirmDialog: false
    })
  }

  async handleContactConfirm() {
    await this.props.handleSuggestion(this.props.contact, 'confirm')

    this.setState({
      confirmDialog: false,
      deleted: true
    })
  }


  handleUnconfirmedClick() {
    this.setState({
      confirmDialog: true
    })
  }

  renderSwitch(param) {
    const { classes } = this.props;
    const currentClass = this.props.disabled
      ? classes.iconDisabled
      : classes.icon;

    switch (param.toLowerCase()) {
      case "email":
        return <Email className={currentClass} />;
      case "phone":
        return <Phone className={currentClass} />;
      case "fax":
        return <Fax className={currentClass} />;
      case "facebook":
        return <Facebook className={currentClass} />;
      case "twitter":
        return <Twitter className={currentClass} />;
      case "xing":
        return <Xing className={currentClass} />;
      case "linkedin":
        return <Linkedin className={currentClass} />;
      case "instagram":
        return <Instagram className={currentClass} />;
      // case 'google':
      //     return <Google className={classes.icon} />;
      case "website":
        return <Home className={currentClass} />;
      case "mobil":
        return <CellphoneAndroid className={currentClass} />;
      default:
        return <PermIdentity className={currentClass} />;
    }
  }

  emailClick(event) {
    if (this.email) window.open(`mailto:${this.email}`, "_system");
  }

  phoneClick(event) {
    if (this.phone) window.open(`tel:${this.phone}`, "_system");
  }

  makeLink(param, value, isOSPWA) {
    if (!param || !value) return "";
    let r;
    let matches;
    const currentClass = this.props.disabled
      ? this.props.classes.linkDisabled
      : this.props.classes.link;

    switch (param.toLowerCase()) {
      case "email":
        // we can't use  rel="noopener noreferrer" here because of iOS compactibility problems
        const email = value
          .replace(/[\s]+/gi, " ")
          .replace(/(^[\s]+|[\s]+$)/gi, "");
        this.email = email;
        // eslint-disable-next-line
        if (isOSPWA) {
          return (
            <span className={currentClass} onClick={this.emailClick.bind(this)}>
              {value}
            </span>
          );
        } else {
          return (
            <a className={currentClass} href={`mailto:${email}`}>
              {value}
            </a>
          );
        }
      case "phone":
        // we can't use  rel="noopener noreferrer" here because of compactibility problems
        this.phone = this.vanityMapper(value);
        const formattedPhone = this.formatPhone(this.phone);
        // eslint-disable-next-line
        if (isOSPWA) {
          return (
            <span className={currentClass} onClick={this.phoneClick.bind(this)}>
              {formattedPhone}
            </span>
          );
        } else {
          return (
            <a href={`tel:${this.phone}`} className={currentClass}>
              {formattedPhone}
            </a>
          );
        }
      case "mobil":
        // we can't use  rel="noopener noreferrer" here because of compactibility problems
        const mobil = value.replace(/[^0-9+]/gi, "");
        this.mobil = mobil;
        const formattedMobile = this.formatPhone(mobil);
        // eslint-disable-next-line
        if (isOSPWA) {
          return (
            <span className={currentClass} onClick={this.phoneClick.bind(this)}>
              {formattedMobile}
            </span>
          );
        } else {
          return (
            <a href={`tel:${mobil}`} className={currentClass}>
              {formattedMobile}
            </a>
          );
        }
      case "fax":
        const fax = value.replace(/[^0-9+]/gi, "");
        const formattedFax = this.formatPhone(fax);
        // we can't use  rel="noopener noreferrer" here because of compactibility problems
        // eslint-disable-next-line
        return <a href={`tel:${fax}`}>{formattedFax}</a>;
      case "facebook":
        r = /facebook\.com\/(.*?)(\?|$)/i;
        matches = r.exec(value);
        if (matches && matches.length > 1) {
          value = matches[1];
        } else {
          value = value.replace(/^\//, "");
        }
        return (
          <a
            href={`https://www.facebook.com/${value}`}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case "twitter":
        r = /twitter\.com\/(.*?)(\?|$)/i;
        matches = r.exec(value);
        if (matches && matches.length > 1) {
          value = matches[1];
        } else {
          value = value.replace(/^\//, "");
        }
        return (
          <a
            href={`https://www.twitter.com/${value}`}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case "xing":
        r = /xing\.com\/profile\/(.*?)(\?|$)/i;
        matches = r.exec(value);
        if (matches && matches.length > 1) {
          value = matches[1];
        } else {
          value = value.replace(/^\//, "");
        }
        return (
          <a
            href={`https://www.xing.com/profile/${value}`}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case "linkedin":
        r = /linkedin\.com\/in\/(.*?)(\?|$)/i;
        matches = r.exec(value);
        if (matches && matches.length > 1) {
          value = matches[1];
        } else {
          value = value.replace(/^\//, "");
        }
        return (
          <a
            href={`https://www.linkedin.com/in/${value}`}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case "google":
        return (
          <a href={value} className={currentClass} rel="noopener noreferrer">
            {value}
          </a>
        );

      case "instagram":
        r = /instagram\.com\/(.*?)(\?|$)/i;
        matches = r.exec(value);
        if (matches && matches.length > 1) {
          value = matches[1];
        } else {
          value = value.replace(/^\//, "");
        }
        return (
          <a
            href={`https://www.instagram.com/${value}`}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case "website":
        let linkValue = value.trim();
        matches = linkValue.match(/^(http[s]{0,1}:|)\/\//iu);

        if (matches === null) linkValue = `http://${linkValue}`;
        return (
          <a
            href={linkValue}
            className={currentClass}
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      default:
        let pattern = /^(https|http):/i;
        if (pattern.test(value)) {
          return (
            <a href={value} className={currentClass}>
              {value}
            </a>
          );
        } else {
          return value;
        }
    }
  }

  setFontSize(length) {
    if (length <= 8) {
      return {};
    } else if (length > 8 && length <= 12) {
      return { fontSize: 12 };
    } else {
      return { fontSize: 10 };
    }
  }

  vanityMapper(text) {
    if (!text || typeof text !== 'string') return text;

    const trimmedText = text.trim();
    const length = trimmedText.length;
    const chars = [];
    for (let i = 0; i < length; i++) {
      if (trimmedText[i] in vanityMap) {
        chars.push(vanityMap[trimmedText[i]]);
      } else {
        chars.push(trimmedText[i]);
      }
    }

    return chars.join('');
  }

  formatPhone(number) {
    try {
      if (!number || (number.indexOf('00') !== 0 && number.indexOf('+')) !== 0 || number.length < 6) return number;
      const pn = new PhoneNumber(number, 'DE')

      if (pn.isValid) {
        return pn.getNumber('international')
      }
      return number
    } catch (e) {
      console.log('Error formatting phone number: ' + e)
      return number
    }
  }

  render() {
    const { t, classes, contact } = this.props;
    if (!contact.type) return false;
    let component = this.props.component ? this.props.component : false;

    // check if app is installed as iOS PWA and handle mail link differently
    const isOSPWA =
      "standalone" in window.navigator && window.navigator.standalone
        ? true
        : false;

    let { type } = contact;
    let autoGenerated = false;

    if (type.includes('|auto|')) {
      autoGenerated = true;
      type = type.slice(0, type.indexOf('|auto|'))
    }

    return (
      <div key={`con${contact.uid}`} className={classes.outer}>
        <Grid key="outerGridSC" container spacing={16}>
          <Grid key="grid1SC" item xs={1}>
            <Typography
              className={classes.typography}
              variant="body2"
              align="left"
              key={contact.uid}
            >
              {this.renderSwitch(type)}
            </Typography>
          </Grid>
          <Grid
            key="grid2SC"
            item
            xs={
              component || (contact.categories && contact.categories.length > 0) || autoGenerated
                ? 6
                : 10
            }
          >
            <Typography
              className={classes.typographyBig}
              variant="body2"
              align="left"
              key={contact.uid}
            >
              {this.makeLink(type, contact.value, isOSPWA)}
            </Typography>
          </Grid>
          {contact.categories &&
            contact.categories.length > 0 &&
            component === false && (
              <Grid key="grid3SC" item xs={5}>
                {contact.categories.map(category => (
                  <Chip
                    style={this.setFontSize(
                      "label" in category ? category.label.length : 0
                    )}
                    key={category.uid}
                    label={"label" in category ? category.label : ""}
                    className={classes.chip}
                  />
                ))}
              </Grid>
            )}
          {contact.categories &&
            contact.categories.length > 0 &&
            component !== false && (
              <React.Fragment>
                <Grid key="grid4SC" item xs={3}>
                  {contact.categories.map(category => (
                    <Chip
                      style={this.setFontSize(
                        "label" in category ? category.label.length : 0
                      )}
                      key={category.uid}
                      label={"label" in category ? category.label : ""}
                      className={classes.chip}
                    />
                  ))}
                </Grid>
                <Grid key="grid5SC" item xs={2}>
                  {[component]}
                </Grid>
              </React.Fragment>
            )}
          {autoGenerated && (
            <React.Fragment>
              <Grid key="grid5AG" item xs={5}>
                <Chip
                  style={this.setFontSize(11)}
                  key={`${contact.uid}-unconfirmed`}
                  label={t('unconfirmed')}
                  className={classes.chipUnconfirmed}
                  avatar={<HelpOutlineIcon style={{color: 'white'}} />}
                  onClick={this.handleUnconfirmedClick.bind(this)}
                  clickable={true}
                />
              </Grid>
              <Grid key="grid5SC" item xs={2}>
                {[component]}
              </Grid>
            </React.Fragment>
          )}
          {((!contact.categories || contact.categories.length <= 0) && component !== false) && (
            <Grid key="grid6SC" item xs={5}>
              {component !== false && [component]}
            </Grid>
          )}
        </Grid>

        <Dialog
          open={this.state.confirmDialog}
          onClose={this.handleConfirmClose.bind(this)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Confirm Contact Data")}
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogContent}>{t('Confirm Contact Data Text')}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleContactConfirm.bind(this)} color="primary">
              {t('confirm')}
            </Button>
            <Button onClick={this.handleContactDelete.bind(this)} color="primary">
              {t('delete')}
            </Button>
            <Button onClick={this.handleConfirmClose.bind(this)} color="secondary" autoFocus>
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(SingleContact)
);
