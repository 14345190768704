import { observable, decorate, action, toJS } from 'mobx';
import {
  getOrganizations,
  getCurrentOrganizations,
  getOrganizationsByLetter,
  deleteOrganization
} from '../actions/organization';
import _ from 'lodash';

const accents = {
  'Ä': 'A',
  'Á': 'A',
  'À': 'A',
  'Ã': 'A',
  'Â': 'A',
  'É': 'E',
  'È': 'E',
  'Ê': 'E',
  'Ë': 'E',
  'Í': 'I',
  'Ì': 'I',
  'Î': 'I',
  'Ï': 'I',
  'Ö': 'O',
  'Ó': 'O',
  'Ò': 'O',
  'Ô': 'O',
  'Õ': 'O',
  'Ü': 'U',
  'Ú': 'U',
  'Ù': 'U',
  'Û': 'U',
  'Ç': 'C',
  'Ñ': 'N',
};

class OrganizationStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    organizations = [];
    organizationsIndex = {};
    organizationsHash = {};

    organizationIncrementSize = 50;

    sorting = 'regular';
    organizationCount = 0;

    offset = 0;

    // Set organizations in mobx store
    setOrganizations = organizations => {
        this.organizations = organizations;
        if (organizations && organizations.length > 1) {
            // this.sortOrganizations(this.sorting);
            this.indexOrganizations();
        }
    }

    // Add a newly created organization to the list
    addOrganization = org => {
        const orgs = this.organizations;
        orgs.push(org);
        this.setOrganizationCount(this.organizationCount + 1)
        this.setOrganizations(orgs)
    }

    // Set organizations count in mobx store
    setOrganizationCount = count => {
        this.organizationCount = count;
    }

    // Fetch all organizations
    getOrganizations = async () => {
        const { token } = this.rootStore.authStore;

        let page = 2;
        const num = 10000
        const res = await getOrganizations(token, 1, num);

        if (res) {
            this.setOrganizations(res.data);
            if (res.meta) {
                this.setOrganizationCount(res.meta.count);
                (this.sorting !== 'lastUpdate') && this.indexOrganizations();
                // this.indexOrganizations();
            }

            if (res.meta.count > num) {
                for (let i = (num + 1); i < res.meta.count; i += num) {
                    const nextRes = await getOrganizations(token, page, num)
                    const newList = nextRes.data.concat(toJS(this.organizations))
                    this.setOrganizations(newList)
                    page++;
                }
            }
        }

        // getOrganizations(token)
        //     .then(action(res => {
        //         if (res.data) {
        //             this.setOrganizations(res.data);
        //         } else {
        //             this.setOrganizations([]);
        //         }
        //         if (res.meta) {
        //             this.setOrganizationCount(res.meta.count);
        //         }
        //         // TODO: Add error handling
        //     })).catch(e => console.log('ERROR: ', e));
        return this.organizations;
    }

    // Load new Organizations on a scroll event
    setCurrentOrganizations = async (offset, requested) => {
        let allData = [];

        const { token } = this.rootStore.authStore;
        const res = await getCurrentOrganizations(token, offset, this.organizationIncrementSize, this.sorting)

        if (res && res.data) {
            this.setOrganizationCount(res.meta.count);
            allData = res.data;
            this.setOrganizations(allData);
            if (!requested) this.offset = offset;
        }

        return allData;
    }

    setOrganizationsByLetter = async (letter) => {
      const { token } = this.rootStore.authStore;
      const res = await getOrganizationsByLetter(token, letter, this.organizationIncrementSize, this.sorting)

      if (res && res.data && res.meta && res.meta.count) {
          this.setOrganizationCount(res.meta.count);

          const beforeLength = (res.dataBefore) ? res.dataBefore.length : 0;

          let newData;
          if(beforeLength > 0) {
            newData = res.dataBefore.concat(res.data);
            this.offset = res.meta.offset - beforeLength;
          } else {
            newData = res.data;
            this.offset = res.meta.offset;
          }

          this.setOrganizations(newData);

          return {
            data: res.data,
            offset: res.meta.offset,
            dataBefore: res.dataBefore,
          };
      }

      return false;
    }

    // Delete an organization
    deleteOrganization = uid => {
        const { token } = this.rootStore.authStore;
        let name = '';
        const organization = _.find(this.organizations, org => org.uid === uid);
        if (organization) name = organization.name;
        deleteOrganization(token, uid, name)
            .then(action(res => {
                if (!this.organizations) this.organizations = [];
                if(res && res.payload && res.payload.uid) {
                  const filteredOrganizations = this.organizations.filter(organization => organization.uid !== res.payload.uid);
                  const count = this.Organizations.length;
                  this.organizations.replace(filteredOrganizations);
                  this.setOrganizationCount(count - 1)

                  this.indexOrganizations();
                } else {
                  console.log('ERROR in Res: ', res)
                }
            })).catch(e => console.log('ERROR: ', e));
        return this.organizations;
    }

    indexOrganizations() {
        const sorting = this.sorting;

        const hash = {};
        const shortHash = {};
        const organizationLength = this.organizations.length;
        let i;
        let j;
        let c;
        let char;
        let code;

        for (i = 0; i < organizationLength; i += 1) {
            if (!this.organizations[i]) continue;
            char = this.organizations[i].name.substr(0, 1).toUpperCase();

            if (char === '') continue;
            if(char in accents) char = accents[char];

            const currentCode = char.charCodeAt(0);
            if (currentCode < 64 || currentCode > 91) continue;
            code = currentCode;

            // is first starting with this letter
            if (char in hash === false) {
                if (sorting === 'regular') {
                    //  check if previous anchors exist to close gaps
                    for (j = code - 1; j > 64; j -= 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        }
                    }
                } else {
                    //  reverse sorting / inverted
                    for (j = code + 1; j < 91; j += 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        }
                    }
                }
                hash[char] = i;
                shortHash[char] = i;

            }
        }

        if (i >= organizationLength) i = organizationLength - 1;

        if (sorting === 'inverted') {
            if (code > 64) {
                for (i = code - 1; i > 64; i -= 1) {
                    c = String.fromCharCode(i);
                    hash[c] = i;
                }
            }
        } else {
            if (code < 91) {
                for (i = code + 1; i < 91; i += 1) {
                    c = String.fromCharCode(i);
                    hash[c] = i;
                }
            }
        }

        this.organizationsHash = hash;

        let organizationsIndex = {};
        const keys = Object.keys(shortHash);
        const length = keys.length;

        for (i = 0; i < length; i++) {
            if (!organizationsIndex[shortHash[keys[i]]]) organizationsIndex[shortHash[keys[i]]] = keys[i];
        }

        this.organizationsIndex = organizationsIndex;
    }

    // Sort organizations
    sortOrganizations = (order) => {
        this.sorting = order;
        this.setCurrentOrganizations(0, false)
    }
}

decorate(OrganizationStore, {
    organizations: observable,
    organizationCount: observable,
    organizationsIndex: observable,
    organizationsHash: observable,
    offset: observable,

    setOrganizationCount: action,
    setOrganizations: action,
    getOrganizations: action,
    sortOrganizations: action,
    addOrganization: action,
    deleteOrganization: action,
    setCurrentPersons: action,
    setOrganizationsByLetter: action,
    setOffset: action,
})

export default OrganizationStore;
