import React, { Component } from 'react';

// Mobx
import constants from '../../stores/constants';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import AppBar from '@material-ui/core/AppBar';
// import Grid from '@material-ui/core/Grid';

// React multilanguage
import { withTranslation } from "react-i18next";

// Components
import SingleAddress from './SingleAddress';

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    icon: {
        color: constants.color.main, //'#2e8bbe',
    },
    gridList: {
        height: 120,
        overflow: 'hidden'
    },
    typography: {
        paddingLeft: 20,
        paddingTop: 10,
        color: constants.color.heading,
        fontSize: '14px',
    },
    divider: {
        backgroundColor: `1px solid ${constants.color.heading}`,
    },
    appBar: {
        zIndex: 0,
        boxShadow: 'none'
    }
});

class Addresses extends Component {
    state = {
        value: 0,
    };
    initDone = false;

    handleChange = (event, value) => {
        this.setState({ value });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, addresses, owners, t } = this.props;

        const { value } = this.props; //this.state;
        // let tabs = [];

        // for (let i = 0; i < owners.length; i++) {
        //     tabs.push(
        //         <Tab key={i} label={t(owners[i])} />
        //     )
        // }

        return (
            ((owners.length > 1 || addresses.own.length > 0) &&
                <React.Fragment>
                    <Typography className={classes.typography} variant="body1" align='left'>
                        {t('Addresses')}
                    </Typography>
                    <Divider className={classes.divider} variant="middle" />
                    {
                        (addresses[owners[value]].length > 0 &&
                            (

                                <React.Fragment>
                                    {addresses[owners[value]].map((address, index) => (
                                        <SingleAddress key={index} address={address} />
                                    ))}
                                </React.Fragment>

                            )
                        )
                    }

                    {
                        (!(addresses[owners[value]].length > 0) &&
                            (

                                <React.Fragment>
                                    <Typography className={classes.typography} variant="body2" align='left'>
                                        {t('No address yet')}
                                    </Typography>
                                </React.Fragment>

                            )
                        )

                    }
                </React.Fragment>
            )

        );
    }
}

export default withStyles(styles)(withTranslation("translations")(Addresses));
