// renders a loading spinner while certain promises are pending

import React, { Component } from 'react';

import { withTranslation } from "react-i18next";
import { scanContactData } from '../../actions/person'

// Material UI
import { IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';

const styles = theme => ({
  root: {},
  button: {
    // align: 'right',
    marginBottom: -12,
    marginRight: 8
  }
});

class ScanButton extends Component {
  state = {
    scanDialog: false,
    wasUsed: false
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  onButtonClick() {
    this.setState({
      scanDialog: true,
    });
  }

  handleScanClose() {
    this.setState({
      scanDialog: false,
    });
  }

  async handleScanConfirm() {
    await scanContactData(this.props.uid, false)
    this.setState({
      scanDialog: false,
      wasUsed:true
    });
  }


  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <IconButton
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          disabled={this.state.wasUsed}
          edge="end"
          onClick={this.onButtonClick.bind(this)}
        >
          <SearchIcon />
        </IconButton>

        <Dialog
          open={this.state.scanDialog}
          onClose={this.handleScanClose.bind(this)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Scan Social Media")}
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogContent}>{t('Scan Social Media Text')}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleScanConfirm.bind(this)} color="primary">
              {t('Scan')}
            </Button>
            <Button onClick={this.handleScanClose.bind(this)} color="secondary" autoFocus>
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )



  }
}

export default withStyles(styles)(withTranslation("translations")(ScanButton));
