// General storage for variables that are needed on the webhook pages

import { decorate, observable, action } from 'mobx'

class WebhooksStore {
  webhook
  webhooks

  constructor() {
    this.webhook = {};
    this.webhooks = [];
  }

  setWebhook(list) {
    this.webhook = list;
  }

  setWebhooks(list) {
    this.webhooks = list;
  }
}

decorate(WebhooksStore, {
  webhook: observable,
  webhooks: observable,

  setWebhook: action.bound,
  setWebhooks: action.bound,
})

export default WebhooksStore
