import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  avatarInactive: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.inactive,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  }
});

class RelationsCenterBlock extends Component {
  state = {
    avatarError: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError(){
    this.setState({avatarError: true});
  }

  render() {
    const { classes, center, showCenterBlock } = this.props;

    let initials = center.name ? center.name.split(" ") : ["", ""];

    if (initials[2]) {
      initials = initials[0][0] + initials[2][0];
    } else {
      initials = initials[0][0];
    }

    return (
      <div key={`grid-${center.name}`}>
        {showCenterBlock && (
          <Avatar
            className={`relation-center ${classes.avatarInactive}`}
            src={this.state.avatarError? null : center.image}
            onError={this.avatarError.bind(this)}
          >
            {initials}
          </Avatar>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(RelationsCenterBlock)
);
