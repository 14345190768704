import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';

import CheckIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

// Mobx
import { inject } from 'mobx-react'

// Material UI
import { withStyles } from '@material-ui/core/styles';
// import Chip from '@material-ui/core/Chip';

// React multilanguage
import { withTranslation } from "react-i18next";

// Components

import ColorPicker from './ColorPicker';

import constants from '../../stores/constants';

const styles = theme => ({
  root: {
    clear: 'both',
    // border: '1px solid #ddd',
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  colorDisplay: {
    padding:  theme.spacing.unit,
    height: 18,
    cursor: 'pointer',
  },
  colorDot: {
    width: 18,
    height: 18,
    border: '1px solid #ddd',
    borderRadius: 9,
    float: 'left',
    marginRight: theme.spacing.unit,
  },
  text: {
    float: 'left',
  },
  confirm: {
    color: constants.color.success,
    float: 'right',
    marginRight: -8,
  },
  cancel: {
    color: constants.color.delete,
    float: 'right',
    marginRight: -8,
  },
  dialog: {
  },
  dialogTitle: {
        padding: theme.spacing.unit * 2,
  },
});

class ColorSelection extends Component {
    state = {
      color: '#ffffff',
      selectedColor: '#ffffff',
      dialog: false,
    }

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
      if(this.props.color) {
        this.setState({
          color: this.props.color,
          selectedColor: this.props.color,
        });
      }
    }

    componentDidUpdate() {
      if(this.props.color) {
        if(this.props.color !== this.state.color) {
          this.setState({
            color: this.props.color,
            selectedColor: this.props.color,
          });
        }
      }
    }

    setColor = (color) => {
      this.setState({
        selectedColor: color,
      });
    }

    dialogOpen = () => {
      this.setState({
        dialog: true,
      });
    }

    dialogCancel = () => {
      this.setState({
        dialog: false,
      });
    }

    dialogConfirm = () => {
      this.setState({
        color: this.state.selectedColor,
        dialog: false,
      });

      if(this.props.callback) this.props.callback(this.state.selectedColor);
    }

    render() {
        const { classes, t } = this.props;

        return <div className={classes.root}>
          <div className={classes.colorDisplay} onClick={this.dialogOpen}>
            <div className={classes.colorDot} style={{backgroundColor: this.state.color}}></div>
            <div className={classes.text}>{this.state.color}</div>
          </div>

          <Dialog
            open={this.state.dialog}
            onClose={this.dialogCancel}
          >
            <div style={{width: 245}}>
              <DialogTitle id="dialog-title" className={classes.dialogTitle}>
              {'Color'}
              <IconButton className={classes.cancel} onClick={this.dialogCancel}><CancelIcon /></IconButton>
              <IconButton className={classes.confirm} onClick={this.dialogConfirm}><CheckIcon /></IconButton> </DialogTitle>
              <DialogContent>
                <ColorPicker color={this.state.color} callback={this.setColor} />
              </DialogContent>
              <DialogActions>
              </DialogActions>
            </div>
          </Dialog>
        </div>;

    }
}

export default withStyles(styles)(withTranslation("translations")(inject('generalStore')(ColorSelection)));
