import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    textField: {
        marginTop: 5,
        wordWrap: "break-word",
        fontSize: '14px !important',
        float: 'left',
        marginLeft: theme.spacing.unit * 3,
    }
});

class SinglePersInfo extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, name, value, t } = this.props;
        return (
            (
                <TextField
                    id="standard-read-only-input"
                    key={`standard-read-only-input-${name}`}
                    label={t(name)}
                    defaultValue={value}
                    className={classes.textField}
                    multiline={true}
                    margin="dense"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                />
            )
        )
    }
}

export default withStyles(styles)(withTranslation("translations")(SinglePersInfo));
