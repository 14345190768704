// Render a list of search results

import React, { Component } from "react";
import Select from 'react-select';

import StickyBox from "react-sticky-box";

import { withRouter } from "react-router-dom";

// Material UI
import { withStyles } from "@material-ui/core/styles";

// Mobx Stores
import { observer, inject } from "mobx-react";


import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';

import ArrowDownIcon from '../../icons/baseline-arrow_drop_down-24px.svg';

// Components

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Done';
import BackIcon from '@material-ui/icons/ArrowBack';

import AddImageIcon from '@material-ui/icons/AddPhotoAlternate';

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";

import CardHeader from "@material-ui/core/CardHeader";

import TheIcon from '@material-ui/icons/Settings';

import Divider from "@material-ui/core/Divider";

import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// React Internationalization
import { withTranslation } from "react-i18next";


import constants from "../../stores/constants";

// Actions
import history from '../../actions/history';
import governance from "../../actions/governance";

// Components
import ColorSelector from '../templates/ColorSelector';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1
  },
  form: {
    textAlign: 'left',
    padding:  theme.spacing.unit * 4,
  },
  titleIconHolder: {
    "text-align": "center"
  },
  titleIcon: {
    color: constants.color.main,
    width: 25,
    height: 25,
    "margin-top": theme.spacing.unit * 2,
    "margin-left": theme.spacing.unit * 1,
    float: 'left',
  },

  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  fullTitle: {
    // backgroundColor: 'grey'
    float: "left",
    // marginTop: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: 0
  },
  description: {
    // padding: '10px'
  },
  fieldLine: {
    paddingTop: 0,
    paddingBottom: 0
  },
  firstFieldLine: {
    paddingBottom: 0
  },
  gridContainer: {
    padding: theme.spacing.unit
  },
  gridItem: {
    padding: theme.spacing.unit
  },
  gridItemIcons: {
    padding: theme.spacing.unit,
    height: theme.spacing.unit * 6
  },

  formHolder: {
    clear: 'both',
    display: 'block',
    position: 'relative',
    textAlign: 'center',
  },
  checkButton: {
      float: 'right',
      backgroundColor: constants.color.success,
      '&:hover': {
          backgroundColor: '#43a047'
      },
      boxShadow: 'none',
      marginRight: '10px'
  },
  backButton: {
      float: 'left',
      backgroundColor: constants.color.main,
      '&:hover': {
          backgroundColor: '#1565c0'
      },
      boxShadow: 'none',
      marginLeft: '10px'
  },
  inputVisible: {
    border: '2px solid #ddd',
    borderRadius: 5,
    width: 250,
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  inputRemoved: {
    border: '2px solid #fff',
    width: 250,
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },

  input: {
    display: 'flex',
    padding: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },

  selectError: {
    'width': '100%',
    'max-width': '420px',
    margin: 0,
    'border-bottom': '1px solid #f44336',
  },
  inputLabel: {
    position: 'absolute',
    top: 0,
    'font-size': '1rem',
    'line-height': '1px',
    margin: 0,
  },
  selectControl: {
    '& input': {
      color: 'red !important',
    },
  },
  languageSelect: {
    'width': '100%',
    'max-width': '260px',
    margin: 0,
    marginBottom: theme.spacing.unit * 1,
  },
  logoImage: {
    cursor: 'pointer',
    maxWidth: 300,
    maxHeight: 120,
    marginBottom: theme.spacing.unit * 1,
  },
  editLogo: {
    marginBottom: theme.spacing.unit * 1,
  },
  titleText: {
    width: 260,
    marginBottom: theme.spacing.unit * 1,
  },
  textBefore: {
    width: 260,
    marginBottom: theme.spacing.unit * 1,
    fontSize: '14px !important',
  },
  textAfter: {
    width: 260,
    marginBottom: theme.spacing.unit * 1,
    fontSize: '14px !important',
  },
  colorSelector: {
    marginBottom: theme.spacing.unit * 1,
  },
  colorText: {
    marginBottom: theme.spacing.unit * 1,
  },
  deleteIcon: {
      color: constants.color.delete
  },
});


function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <div className="with-placeholder">
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
            style: { fontSize: 16, marginLeft: '-10px', width: 'calc(100% + 12px)' },
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    </div>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const DropdownIndicator = props => {
  return <img src={ArrowDownIcon} style={{ padding: 8 }} alt="▾" />;
};

function CustomMenu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  DropdownIndicator,
  Control,
  CustomMenu,
  NoOptionsMessage,
  Option,
};



class EditForm extends Component {
  _isMounted = false;
  state = {
    id: 'new',
    form: {
      fields: ['firstName', 'lastName', 'phone', 'email'],
      textColor: '#333333',
      bodyColor: '#ffffff',
      buttonColor: '#1F7EB3',
      buttonTextColor: '#ffffff',
    },
    logoDialog: false,
    selectedLogo: null,
    deleteDialog: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  async componentDidMount() {
    this.props.generalStore.setListState('EditForm');
    this._isMounted = true;

    const id = (this.props.match && this.props.match.params && this.props.match.params.id)? this.props.match.params.id : 'new';

    if(id !== 'new') {
      if(this._isMounted) this.setState({id});
      const form = await governance.getSingleForm(id);
      if(this._isMounted) this.setState({form});
    }
  }

  async componentDidUpdate() {
    const id = (this.props.match && this.props.match.params && this.props.match.params.id)? this.props.match.params.id : 'new';

    if(id !== this.state.id) {
      let form = this.state.form;
      if(id !== 'new') {
        form = await governance.getSingleForm(id);
      }
      this.setState({form, id});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  saveClick = async () => {
    if(this.state.id === 'new') {
      await governance.createForm(this.state.form);
    } else {
      await governance.updateForm(this.state.id, this.state.form);
    }

    history.push(constants.path.listForms);
  }

  backClick = () => {
    history.push(constants.path.listForms);
  }

  handleChange = name => event => {
    console.log(name);
    console.log(event.target.value);

    const form = this.state.form;
    form[name] = event.target.value;
    console.log(form);
    this.setState({
      form,
    });
  }

  handleFieldChange = name => event => {
    console.log(event);
    console.log(name);
    console.log(event.target);
    let form = this.state.form;
    if(!form.fields) form.fields = [];
    const index = form.fields.indexOf(name);
    if(index > -1) {
      form.fields.splice(index, 1);
    } else {
      form.fields.push(name);
    }
    console.log(form);
    this.setState({
      form,
    });
  }

  handleSelectChange = (data) => {
    let form = this.state.form;
    form.language = data.value;
    this.setState({
      form,
    });
  }

  selectTextColor = (color) => {
    let form = this.state.form;
    form.textColor = color;
    this.setState({
      form,
    });
  }

  selectBackgroundColor = (color) => {
    let form = this.state.form;
    form.bodyColor = color;
    this.setState({
      form,
    });
  }

  selectButtonColor = (color) => {
    let form = this.state.form;
    form.buttonColor = color;
    this.setState({
      form,
    });
  }

  selectButtonTextColor = (color) => {
    let form = this.state.form;
    form.buttonTextColor = color;
    this.setState({
      form,
    });
  }

  showLogoDialog = () => {
    this.setState({
      selectedLogo: this.state.form.logo,
      logoDialog: true,
    });
  }

  dialogClose = () => {
    this.setState({
      selectedLogo: null,
      logoDialog: false,
    });
  }


  handleConfirm = () => {
    let form = this.state.form;
    form.logo = this.state.selectedLogo;
    this.setState({
      logoDialog: false,
      form,
      selectedLogo: null,
    });
  }

  selectLogo = (e) => {
    console.log('url', e.target.value);
    this.setState({
      selectedLogo: e.target.value,
    });
  }

  handleDeleteOpen = () => {
      this.setState({ deleteDialog: true });
  };


  handleDeleteCancel = () => {
      this.setState({ deleteDialog: false });
  };

  handleDeleteConfirm = async () => {
      this.setState({ deleteDialog: false });
      await governance.deleteForm(this.state.id);

      this.props.history.push(constants.path.listForms);
  };

  render() {
    console.log(this.state.form);
    const { t, classes, theme } = this.props;

    const backButton = <Grid item xs={2}>
        <Fab onClick={this.backClick} color="primary" size="small" aria-label="Add" className={classes.backButton}>
            <BackIcon />
        </Fab>
    </Grid>;

    const saveButton = <Grid item xs={2}>
        <Fab onClick={this.saveClick} color="primary" size="small" aria-label="Add" id='save' className={classes.checkButton}>
            <SaveIcon />
        </Fab>
    </Grid>;

    let fields = {
      "firstName": false,
      "lastName": false,
      "phone": false,
      "email": false,
    };

    if(this.state.form && this.state.form.fields) {
      for(let i=0;i<this.state.form.fields.length;i+=1) {
        fields[this.state.form.fields[i]] = true;
      }
    }

    const selectStyles = {
         input: base => ({
           ...base,
           color: theme.palette.text.primary,
           "& input": {
             font: "inherit"
           }
         })
       };

    const allLanguages = [
      {
        label: t('language de'),
        value: 'de',
      },
      {
        label: t('language en'),
        value: 'en',
      },
    ];

    const currentLanguage = this.state.form.language ?
          {
            label: t(`language ${this.state.form.language}`),
            value: this.state.form.language,
          }
          :
          {
            label: t(`language en`),
            value: 'en',
          }
          ;


    const formUrl = `${constants.clientApiUrl}/governance/management/forms/${this.state.id}/index.html`;

    const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;
    const linkTarget = isOSPWA ? '_system' : '_blank';

    return (
      <div className="Form" style={{ marginBottom: 65 }}>
        <Grid item xs={12} key="FormGrid">
          <Paper className={classes.control}>
            <Grid container>
              <ListItem
                key="Formheader"
                selected={this.props.selectedIndex === 0}
              >
                {backButton}
                <Grid item xs={8}>
                <TheIcon className={classes.titleIcon} />
                <CardHeader
                  title={t('Form')}
                  className={classes.fullTitle}
                />
                </Grid>
                {saveButton}
              </ListItem>
              <Divider style={{ width: '100%' }} />
            </Grid>
          </Paper>
          <Paper className={classes.form}>
            <Grid container>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                  {this.state.form.logo &&
                    <img onClick={this.showLogoDialog} className={classes.logoImage} src={this.state.form.logo} />
                  }
                  {!this.state.form.logo &&
                    <Fab onClick={this.showLogoDialog} color="primary" size="small" className={classes.editLogo}>
                        <AddImageIcon />
                    </Fab>
                  }

                  <Dialog
                   open={this.state.logoDialog}
                   onClose={this.dialogClose}
                   aria-labelledby="form-dialog-title"
                  >
                   <DialogTitle id="form-dialog-title">{t('Logo Url')}</DialogTitle>
                   <DialogContent>
                       <TextField className={classes.logoUrl} value={this.state.selectedLogo} onChange={this.selectLogo.bind(this)} name={'selectLogo'} />
                       <br/>{t('Expected size')} 300x120px
                   </DialogContent>
                   <DialogActions>
                     <Button onClick={this.dialogClose} color="primary">
                         {t('cancel')}
                     </Button>
                     <Button onClick={this.handleConfirm} color="primary">
                         {t('confirm')}
                     </Button>
                   </DialogActions>
                  </Dialog>
                </Grid>

                <Grid item xs={12}>
                  <TextField onChange={this.handleChange('titleText')} className={classes.titleText} value={this.state.form.titleText || ''} name='titleText' id='titleText' placeholder={t('titleText')} />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    onChange={this.handleChange('textBefore')}
                    className={classes.textBefore}
                    value={this.state.form.textBefore || ''}
                    name='textBefore'
                    id='textBefore'
                    placeholder={t('textBefore')}
                    multiline
                    rows={3}
                    maxRows={10}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      }
                    }}
                    inputProps={{
                      style: {
                        fontSize: 18,
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={fields['firstName'] ? classes.inputVisible : classes.inputRemoved}>
                    <Checkbox onChange={this.handleFieldChange('firstName')} checked={fields['firstName']}/>
                    <TextField className={classes.textDisabled} value={''} name='firstName' placeholder={t('firstName')} disabled={true}/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={fields['lastName'] ? classes.inputVisible : classes.inputRemoved}>
                    <Checkbox onChange={this.handleFieldChange('lastName')} checked={fields['lastName']}/>
                    <TextField className={classes.textDisabled} value={''} name='lastName' placeholder={t('lastName')} disabled={true}/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={fields['phone'] ? classes.inputVisible : classes.inputRemoved}>
                    <Checkbox onChange={this.handleFieldChange('phone')} checked={fields['phone']}/>
                    <TextField className={classes.textDisabled} value={''} name='phone' placeholder={t('phone')} disabled={true}/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={fields['email'] ? classes.inputVisible : classes.inputRemoved}>
                    <Checkbox onChange={this.handleFieldChange('email')} checked={fields['email']}/>
                    <TextField className={classes.textDisabled} value={''} name='email' placeholder={t('email')} disabled={true}/>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange('textAfter')}
                    className={classes.textAfter}
                    value={this.state.form.textAfter || ''}
                    name='textAfter'
                    id='textAfter'
                    placeholder={t('textAfter')}
                    multiline
                    rows={3}
                    maxRows={10}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      }
                    }}
                    inputProps={{
                      style: {
                        fontSize: 18,
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                     error={false}
                     className={classes.languageSelect}
                     classes={classes}
                     styles={selectStyles}
                     options={allLanguages}
                     value={currentLanguage}
                     onChange={this.handleSelectChange}
                     placeholder={t('Select a language')}
                     components={components}
                     isClearable={false}
                     isSearchable={false}
                   />
                </Grid>

                <Grid item xs={12}><ColorSelector className={classes.colorSelector} callback={this.selectTextColor} color={this.state.form.textColor || '#333333'} /> <div className={classes.colorText}>{t('Text color')}</div></Grid>
                <Grid item xs={12}><ColorSelector callback={this.selectBackgroundColor} color={this.state.form.bodyColor || '#ffffff'} /> <div className={classes.colorText}>{t('Background color')}</div></Grid>

                <Grid item xs={12}><ColorSelector callback={this.selectButtonColor} color={this.state.form.buttonColor || '#1F7EB3'} /> <div className={classes.colorText}>{t('Button color')}</div></Grid>
                <Grid item xs={12}><ColorSelector callback={this.selectButtonTextColor} color={this.state.form.buttonTextColor || '#ffffff'} /> <div className={classes.colorText}>{t('Button text color')}</div></Grid>

                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange('textBeforeStatus')}
                    className={classes.textBeforeStatus}
                    value={this.state.form.textBeforeStatus || ''}
                    name='textBeforeStatus'
                    id='textBeforeStatus'
                    placeholder={t('textBeforeStatus')}
                    multiline
                    rows={3}
                    maxRows={10}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      }
                    }}
                    inputProps={{
                      style: {
                        fontSize: 18,
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <br/><br/>
                </Grid>

                <Grid item xs={12}>
                  { this.state.id !== 'new' &&
                    <Button className={classes.previewButton} type="button" color="primary" >
                      <a href={formUrl} target={linkTarget}>{t('Preview form')}</a>
                    </Button>

                  }
                </Grid>

                {this.state.id !== 'new' &&
                <Grid item xs={12}>
                    <Grid className={classes.deleteButton} >
                        <div className={classes.deleteHolder}>
                            <IconButton onClick={this.handleDeleteOpen} key={`delete`} title={t('delete')} className={classes.deleteIcon}  >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                }

                <Dialog
                    open={this.state.deleteDialog}
                    onClose={this.handleDeleteClose}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">{t("Confirm Form Delete")}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleDeleteConfirm} color="secondary">
                            {t('Delete')}
                        </Button>
                        <Button onClick={this.handleDeleteCancel} color="primary" autoFocus>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

          </Grid>
        </Paper>
        </Grid>
      </div>
    );
  }
}

export default withTranslation("translations")(
  withStyles(styles)(inject("generalStore")(withRouter(observer(EditForm))))
);
