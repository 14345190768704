// Handles all authentication actions except for the registration

import React from "react";
import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
import { withTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";

// Mobx Stores
import store from "../stores/";
import constants from "../stores/constants";
// import history from './history';

import axios from "axios";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

withTranslation("translations");

class Dashboard extends React.Component {
  static async populateDashboard() {
    try {
  		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

      const response = await trackPromise(
        axios.get(`${constants.apiUrl}/profile/dashboard`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.authStore.token}`
          }
        })
      );

      if (response.status === 200) {
        store.dashboardStore.setDashboard(response.data);
      } else {
    		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

        store.dashboardStore.setDashboard(404);
      }
    } catch (e) {
      console.log(e);

  		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

      store.dashboardStore.setDashboard(404);

      store.generalStore.addMessage(500, "Dashboard could not be fetched");
    }
  }
}

export default withRouter(Dashboard);
