import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Grid from '@material-ui/core/Grid';

import TimeIcon from '@material-ui/icons/AccessTime';

import Collapse from "@material-ui/core/Collapse";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import HistoryItem from './HistoryItem';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 32,
  },
  clusterCounter: {
    top: -5,
    right: -5,
    width: 18,
    color: '#fff',
    height: 18,
    position: 'absolute',
    fontSize: 12,
    textAlign: 'center',
    background: constants.color.main,
    lineHeight: '18px',
    fontWeight: 'bold',
    borderRadius: 5,
  },
  avatarCluster: {
    margin: 10,
    color: constants.color.main,
    backgroundColor: '#fff',
    border: `2px solid ${constants.color.main}`,
    // backgroundColor: constants.color.inactive,
    width: 40,
    height: 40,
    // lineHeight: "30px",
    textAlign: 'center',
    // float: 'left',
    display: "inline-block",
    fontSize: '36px',
    cursor: 'pointer',
    overflow: 'visible',
  },
  avatarClusterInactive: {
    margin: 10,
    // color: "#fff",
    // backgroundColor: constants.color.main,
    color: constants.color.main,
    backgroundColor: '#fff',
    border: `2px solid ${constants.color.main}`,
    width: 40,
    height: 40,
    // lineHeight: "34px",
    // float: 'left',
    display: "inline-block",
    fontSize: 32,
    cursor: 'pointer',
  },
  addIcon: {
    fontSize: '30px',
    position: 'absolute',
    left: 5,
    top: 6,
  },
  removeIcon: {
    fontSize: '26px',
    position: 'absolute',
    left: 7,
    top: 8,
  },
  historyItem: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 50,
    height: 'auto',
    clear: 'both',
    borderLeft: `5px double rgba(46, 139, 190, 0.7)`,
    marginLeft: theme.spacing.unit * 2,
  },
  historyItemLast: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 30,
    height: 'auto',
    clear: 'both',
    borderLeft: '5px solid transparent',
    marginLeft: theme.spacing.unit * 2,
  },
  historyItemClustered: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 50,
    height: 'auto',
    clear: 'both',
    borderLeft: `5px solid #eee`,
    marginLeft: theme.spacing.unit * 2,
  },
  historyItemLastClustered: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 30,
    height: 'auto',
    clear: 'both',
    borderLeft: '5px solid transparent',
    marginLeft: theme.spacing.unit * 2,
  },
  avatarHolder: {
    position: 'absolute',
    left: (theme.spacing.unit * 2 + 18) * -1,
    top: theme.spacing.unit * -2,
  },
  contentHolder: {
    width: '80%',
    float: 'left',
    marginTop: -16,
    marginLeft: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 6,
  },
  headInfo: {
    color: constants.color.heading,
    fontSize: '14px',
    textAlign: 'left',
    margin: 0,
    marginBottom: theme.spacing.unit,
    overflow: 'hidden',
  },
  mainInfo: {
    textAlign: 'left',
    margin: 0,
  },
  timeInfo: {
    marginTop: 20,
  },
  infoBubble: {
    backgroundColor: '#eee',
    borderRadius: 5,
    padding: theme.spacing.unit,
    borderBottom: '4px double #ddd',
  },
  infoBubbleActive: {
    backgroundColor: '#eee',
    borderRadius: 5,
    padding: theme.spacing.unit,
    // borderBottom: '4px double #ddd',
  },
  timeIcon: {
    fontSize: '14px',
    marginBottom: -2,
    marginRight: 2,
  },
  cssFix: {
    width: '100%',
    height: 10,
    clear: 'both',
  },
  clusterItems: {
  },
});

class HistoryCluster extends Component {
  state = {
    avatarError: false,
    clustered: true,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError(){
    this.setState({avatarError: true});
  }

  handleClusterToggle(){
    this.setState({
      clustered: !this.state.clustered,
    });
  }

  // handleClickAway(){
  //   if(this.state.clustered === true) {
  //     this.setState({
  //       clustered: false,
  //     });
  //   }
  // }

  render() {
    const { classes, t, data, journal, revertEvent } = this.props;

    const items = [];

    let i;
    const length = data.length;
    let users = {};
    for(i=0;i<length;i+=1) {
      items.push(<HistoryItem contained={this.props.contained} key={`kci${i}`} inCluster={true} journal={journal} data={data[i]} revertEvent={revertEvent ? revertEvent.bind(this) : false} />);
      users[data[i].user] = 1;
    }

    users = Object.keys(users).join(',');

    const itemsCount = (items.length < 100)? items.length : '99+';


    // ('lastItem' in data && this.state.clustered)? classes.historyItemLast : classes.historyItem

    let itemClass;
    if(this.state.clustered) {
      itemClass = ('lastItem' in data)? classes.historyItemLastClustered : classes.historyItemClustered;
    } else {
      itemClass = ('lastItem' in data)? classes.historyItem : classes.historyItem;
    }

    let blockStyle = (this.props.contained)? {width: '100%'} : {};

    let timeInfo = {};
    let contentHolderStyle = {};
    let headInfo = {};

    if(window.innerWidth < constants.breakpoints.small) {
      timeInfo = { fontSize: '12px' };
      headInfo = { fontSize: '12px' };

      contentHolderStyle = { width: '85%' };
      blockStyle = { width: '95vw' };
    }

    const hint = (this.props.displayType && this.props.displayType === 'oih')? t('used by') : t('changed by');
    return (
      <>
        <div key={`grid-${data.user}`} className={itemClass} style={blockStyle}>
          <div className={classes.avatarHolder} onClick={this.handleClusterToggle.bind(this)}>
            <Avatar
              className={`relation-center ${(!this.state.clustered)? classes.avatarClusterInactive :  classes.avatarCluster}`}
              src={null}
            >
            {this.state.clustered && <AddIcon className={classes.addIcon} />}
            {!this.state.clustered && <RemoveIcon className={classes.removeIcon} />}
            {this.state.clustered && <div className={classes.clusterCounter} style={items.length > 99 ? {fontSize: '10px'} : {}}>{itemsCount}</div>}
            </Avatar>
          </div>
          <div className={classes.contentHolder} style={contentHolderStyle} onClick={this.handleClusterToggle.bind(this)}>
            <Grid container className={classes.allInfo}>
              <Grid item xs={4} className={classes.timeInfo} style={timeInfo}>
                <TimeIcon className={classes.timeIcon} />
                {journal && journal === true &&
                  <>{data[0].formatedDate.split(' ')[0]}</>
                }
                {!journal &&
                  <>~{data[0].formatedDate.split(':')[0]}h</>
                }
              </Grid>
              <Grid item xs={8} className={this.state.clustered ? classes.infoBubble : classes.infoBubbleActive}>
                <Grid container className={classes.headInfo} style={headInfo}>
                    <Grid item xs={4} >
                      {(data.formatedDate === '')? '' : hint}
                    </Grid>
                    <Grid item xs={8} >
                      {users}
                    </Grid>
                </Grid>
                <Grid container className={classes.mainInfo}>
                  {t('several changes')}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cssFix}></div>
        </div>
        <Collapse in={!this.state.clustered} timeout={500}>
        <div className={classes.clusterItems}>
          {items}
        </div>
        </Collapse>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(HistoryCluster)
);
