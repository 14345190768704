// renders a infobar above listView
import React from "react";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Person from "@material-ui/icons/Person";
import Organization from "@material-ui/icons/Business";
import Contact from "@material-ui/icons/ContactMail";
import Address from "@material-ui/icons/Home";
import constants from "../../stores/constants";

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop: 10,
    paddingBottom: 0,
    backgroundColor: "#fff",
    marginTop: 5
  },
  inner: {
    // paddingTop: theme.spacing.unit * 2,
    paddingLeft: 0,
    paddingRight: theme.spacing.unit,
    margin: "0px",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    "border-bottom": "1px solid #333"
  },
  headText: {
    color: "#333",
    fontWeight: "bold"
  },
  headTextLight: {
    color: "#333"
  },
  icons: {
    color: constants.color.main,
    position: "relative",
    top: theme.spacing.unit - 3,
    right: theme.spacing.unit - 3
  },
  categoryType: {
    marginLeft: theme.spacing.unit * 1.4
  }
});

function InfoBar(props) {
  const textClass = props.lessVisible
    ? props.classes.headTextLight
    : props.classes.headText;
  const inCategories = props.text.includes("Type: ");
  let icon;

  switch (props.type) {
    case "person":
      icon = <Person className={props.classes.icons} />;
      break;
    case "organization":
      icon = <Organization className={props.classes.icons} />;
      break;
    case "contact":
      icon = <Contact className={props.classes.icons} />;
      break;
    case "address":
      icon = <Address className={props.classes.icons} />;
      break;
    default:
      break;
  }

  return (
    <div className={props.classes.root} elevation={1}>
      <div className={props.classes.inner} elevation={1}>
        <Typography
          align="left"
          className={textClass}
          color="primary"
          variant="subtitle1"
        >
          {icon}
          <span className={inCategories ? props.classes.categoryType : ""}>
            {props.text}
          </span>
          {"component" in props && props.component}
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(InfoBar);
