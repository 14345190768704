// Stores all variables for auth related things

import { decorate, observable, action } from 'mobx'

class Store {
  hasLogin
  token
  userData
  returnTo
  email
  optin
  confirmed

  constructor() {
    this.hasLogin = false;
    this.returnTo = '';
    this.token = '';
    this.userData = {};
    this.email = '';
    this.optin = '';
    this.confirmed = false;
  }

  setHasLogin(isLoggedIn) {
    this.hasLogin = isLoggedIn;
  }

  setToken(token) {
    this.token = token;
  }

  setUserData(userData) {
    this.userData = userData;
  }

  setReturnTo(path) {
    this.returnTo = path;
  }

  setEmail(email) {
    this.email = email;
  }

  setOptin(optin) {
    this.optin = optin;
  }

  setConfirmed(confirmed) {
    this.confirmed = confirmed;
  }
}

decorate(Store, {
  hasLogin: observable,
  token: observable,
  userData: observable,
  returnTo: observable,
  email: observable,
  optin: observable,
  confirmed: observable,

  setHasLogin: action.bound,
  setToken: action.bound,
  setUserData: action.bound,
  setReturnTo: action.bound,
  setEmail: action.bound,
  setOptin: action.bound,
  setConfirmed: action.bound,
})

export default Store
