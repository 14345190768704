import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import TextField from '@material-ui/core/TextField';

import marked from 'marked';
import insane from 'insane';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  noteItem: {
    position: 'relative',
    // width: '100vw',
    maxWidth: 590,
    height: 'auto',
    clear: 'both',
    marginLeft: theme.spacing.unit * 2 + 2,
  },
  noteItemLast: {
    position: 'relative',
    // width: '100vw',
    maxWidth: 590,
    minHeight: 30,
    height: 'auto',
    clear: 'both',
    marginLeft: theme.spacing.unit * 2 + 2,
    // marginTop: -12,
  },
  headlineHolder: {
    position: 'relative',
    left: 0,
    color: 'gray',
    overflow: 'hidden',
    fontSize: '14px',
    marginBottom: theme.spacing.unit * 0.5,
  },
  contentHolder: {
    width: '100%',
    float: 'left',
    marginTop: 0,
    marginLeft: 0,
    marginBottom: theme.spacing.unit,
  },
  headInfo: {
    color: constants.color.heading,
    fontSize: '14px',
    textAlign: 'left',
    margin: 0,
    marginBottom: theme.spacing.unit,
  },
  mainInfo: {
    textAlign: 'left',
    margin: 0,
    wordBreak: 'break-word',
  },
  timeInfo: {
    // marginTop: 20,
    textAlign: 'left',
  },
  nameInfo: {
    // marginTop: 20,
    textAlign: 'right',
  },
  infoBubble: {
    backgroundColor: '#fff',
    borderRadius: 3,
    padding: theme.spacing.unit,
    boxShadow: '1px 1px 1px 2px whitesmoke',
    borderColor: constants.color.light,
    borderStyle: 'hidden hidden hidden solid',
    minHeight: 60,
  },
  cssFix: {
    width: '100%',
    height: 10,
    clear: 'both',
  },
  valueItem: {
    overflowWrap: 'break-word'
  },
  editHeadLine: {
    maxHeight: 20,
    marginTop: -10,
    marginBottom: 0,
  },
  check: {
    color: constants.color.success,
    float: 'right',
    marginRight: -8,
  },
  cancel: {
    color: constants.color.error,
    float: 'right',
    marginRight: -8,
  },
  edit: {
    color: constants.color.main,
    position: 'absolute',
    right: 0,
    top: theme.spacing.unit * 3.5,
  },
  delete: {
    float: 'right',
    color: constants.color.error,
    marginRight: theme.spacing.unit * -1,
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
  },
  input: {
    minHeight: '24px',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
    }
  },
});

class NoteItem extends Component {
  state = {
    avatarError: false,
    content: ''
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError() {
    this.setState({ avatarError: true });
  }

  componentDidMount() {
    this.setState({
      content: this.props.data.content || ''
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editMode !== this.props.editMode) {
      this.setState({
        content: this.props.data.content,
      });
    }
  }

  cancelEdit() {
    this.setState({
      content: this.props.data.content
    })

    this.props.cancelEdit()
  }

  itemClick() {
    if (this.props.journal && this.props.journal === true) {
      if (this.props.data && 'uid' in this.props.data && this.props.data.uid !== '') {
        // const person = true;
        if (this.props.data.objectType && this.props.data.objectType !== '') {
          const path = (this.props.data.objectType === 'person') ? constants.path.detailviewPerson : constants.path.detailviewOrganization;
          this.props.history.push(`${path}/${this.props.data.uid}`);
        }
      }
    }
  }


  render() {
    const { classes, t, data, journal, editMode } = this.props;

    let items = [];

    let formatted = insane(data.content, {
      allowedTags: [],
    });

    // On small screens, limit the maximum heading level
    if (window.innerWidth <= constants.breakpoints.small) {
      const renderer = {
        heading(text, level) {
          const adjustedLevel = Math.min(level+1, 4)
          return `
                <h${adjustedLevel}>
                  ${text}
                </h${adjustedLevel}>`;
        }
      };

      marked.use({ renderer })
    }

    formatted = marked(formatted);

    formatted = insane(formatted, {
      allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'span', 'p', 'li', 'ul', 'strong', 'i', 'em', 'a'],
    });


    if (editMode) {
      items.push(
        <React.Fragment>
          <Grid key={'g1'} item xs={8} className={classes.editHeadLine}>
          </Grid>
          <Grid key={'g2'} item xs={4} className={classes.editHeadLine}>
            <IconButton key={'b1'} className={classes.cancel} onClick={this.cancelEdit.bind(this)}>
              <CancelIcon />
            </IconButton>
            <IconButton key={'b2'} className={classes.check} onClick={() => this.props.confirmChanges(this.state.content, this.props.data.uid || false)}>
              <CheckCircleIcon />
            </IconButton>
          </Grid>
          <Grid key={'g3'} item xs={12} >
            <TextField
              className={classes.textField}
              multiline
              InputProps={{ disableUnderline: true, style: { fontSize: '17px' }, classes: { input: classes.input } }}
              value={this.state.content}
              onChange={e => this.setState({ content: e.target.value })}
              fullWidth={true}
              placeholder={t('Enter your note here')}
            />
          </Grid>
        </React.Fragment>
      );

      if (!data.isNew && data.uid) {
        items.push(
          <Grid item xs={12} >
            <IconButton className={classes.delete} onClick={() => this.props.deleteNote(this.state.content, data.uid)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )
      }
    } else {
      items.push(
        <Grid key={'someItem'} item xs={12} >
          <div dangerouslySetInnerHTML={{ __html: formatted }} />
        </Grid>
      );
    }

    const itemClass = ('lastItem' in data) ? classes.noteItemLast : classes.noteItem;

    return (
      <Grid container key={`grid-${data.meta.username}-${data.uid}`} className={itemClass}>
        <Grid item xs={12} className={classes.noteItemGridItem}>
          <Grid container className={classes.headlineHolder}>
            <Grid item xs={6} className={classes.timeInfo}>
              {journal && journal === true &&
                <>{data.formatedDate.split(' ')[0]}</>
              }
              {!journal &&
                <>{data.formatedDate}</>
              }
            </Grid>
            <Grid item xs={6} className={classes.nameInfo}>
              {data.meta.username && t('changed by')} {data.meta.username}
            </Grid>
          </Grid>
          <div className={classes.contentHolder}>
            <Grid key={'ch'} container className={classes.allInfo}>
              <Grid item xs={12} className={classes.infoBubble}
                onClick={this.itemClick.bind(this)}>
                <Grid container className={classes.mainInfo}>
                  {items}
                </Grid>
              </Grid>
            </Grid>
            {!editMode && data.uid !== '' &&
              <IconButton key={'eb'} className={classes.edit} onClick={this.props.enterEditMode.bind(this)}>
                <EditIcon />
              </IconButton>
            }
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(
  withRouter(withTranslation("translations")(NoteItem))
);
