import React from "react";
import { withRouter } from "react-router-dom";
import store from '../stores/';
import axios from 'axios';
import constants from '../stores/constants';
import { trackPromise } from 'react-promise-tracker';
import { formatEvents } from '../utils/helpers';

import i18n from '../i18n';
// import { withTranslation } from "react-i18next";

const apiUrl = constants.apiUrl;

function contactDataOrder(data) {
    if (data.contactData && data.contactData.length > 1) {
        let sortedContactData = data.contactData.slice().sort(function (a, b) {
            if (a.type === 'email' && b.type !== 'email') {
                return -1;
            }
            if (a.type !== 'email' && b.type === 'email') {
                return 1;
            }
            if (a.type === 'phone' && b.type !== 'phone') {
                return -1;
            }
            if (a.type !== 'phone' && b.type === 'phone') {
                return 1;
            }
            if (a.type === 'mobil' && b.type !== 'mobil') {
                return -1;
            }
            if (a.type !== 'mobil' && b.type === 'mobil') {
                return 1;
            }
            if (a.type === 'fax' && b.type !== 'fax') {
                return -1;
            }
            if (a.type !== 'fax' && b.type === 'fax') {
                return 1;
            }
            return 0;
        });
        data.contactData = sortedContactData;
    }
    return data;
}

class ContactGetter extends React.Component {

    // Set forceRefresh to true to ignore local store and force a get from the API

    static async populateOrganization(id, forceRefresh) {
        forceRefresh = true; // we need to refresh always otherwise there can be conflicts
        // First check whether the organization is in the current list
        // const hasOrganization = store.generalStore.organizationInList(id);

        if (forceRefresh) { store.generalStore.setOrganization(null); }

        // If yes, simply set the organization to that list object
        // if (hasOrganization && !forceRefresh) {
        //     // console.log('in hasOrganization');
        //     store.generalStore.setOrganization(hasOrganization);
        // }
        // If not, send a get request to the API

        // console.log('Sending get organization request');
        try {
            window.getRequest = true;
            const response = await trackPromise(axios.get(
                `${apiUrl}/organization/${id}?${Date.now()}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));
            window.getRequest = false;
            if (response.status === 200) {
                const data = contactDataOrder(response.data);
                store.generalStore.setOrganization(data);
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setOrganization(404);
            } else {
                store.generalStore.setOrganization(404);
            }
        } catch (e) {
            window.getRequest = false;
            store.generalStore.setOrganization(404);
            console.log(e);
        }
    }

    static async populatePerson(id, forceRefresh) {
        forceRefresh = true; // we need to refresh always otherwise there can be conflicts
        // First check whether the person is in the current list
        // const hasPerson = store.generalStore.personInList(id);

        if (forceRefresh) { store.generalStore.setPerson(null); }

        // If yes, simply set the person to that list object
        // if (hasPerson && !forceRefresh) {
        //     store.generalStore.setPerson(hasPerson);
        // }

        // If not, send a get request to the API
        // console.log('Sending get person request');

        try {
            window.getRequest = true;
            const response = await trackPromise(axios.get(
                `${apiUrl}/person/${id}?${Date.now()}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));
            window.getRequest = false;
            if (response.status === 200) {
                const data = contactDataOrder(response.data);
                store.generalStore.setPerson(data);
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setPerson(404);
            } else {
                store.generalStore.setPerson(404);
            }
        } catch (e) {
            window.getRequest = false;
            store.generalStore.setPerson(404);
            console.log(e);
        }
    }

    static async populateProfile(forceRefresh) {
        if (!store.authStore.token || store.authStore.token === '') return false;
        forceRefresh = true; // we need to refresh always otherwise there can be conflicts
        // First check whether the person is in the current list
        const hasProfile = (store.generalStore.profile) ? store.generalStore.profile : false;

        if (forceRefresh) { store.generalStore.setProfile(null); }

        // If yes, simply set the person to that list object
        if (hasProfile && !forceRefresh) {
            store.generalStore.setPerson(hasProfile);
        }

        // If not, send a get request to the API
        else {
            // console.log('Sending get me request');
            // console.log(`With token: ${store.authStore.token}`);
            try {
                window.getRequest = true;
                const response = await axios.get(
                    `${apiUrl}/profile/me?${Date.now()}`,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${store.authStore.token}`
                        }
                    }
                );
                window.getRequest = false;
                if (response.status === 200) {
                    const data = contactDataOrder(response.data);
                    store.generalStore.setProfile(data);
                } else if (response.status === 401) {
                    store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                    store.generalStore.setProfile(404);
                } else {
                    store.generalStore.addMessage(404, 'Profile could not be retrieved');
                    store.generalStore.setProfile(404);
                }
            } catch (e) {
                // window.getRequest = false;
                // return history.push('/not-found');

                // store.generalStore.addMessage(404, 'Profile could not be retrieved');
                // store.generalStore.setProfile(404);
                // console.log(e);
            }
        }
    }

    static async populateProfileSettings() {
        if (!store.authStore.token || store.authStore.token === '') return false;
        try {
            const response = await axios.get(
                `${apiUrl}/profile/settings/me?${Date.now()}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            );

            if (response.status === 200) {
                if (!('settings' in response.data)) response.data.settings = {};
                store.generalStore.setProfileSettings(response.data);

                const language = (store.generalStore.profileSettings &&
                    store.generalStore.profileSettings.settings &&
                    store.generalStore.profileSettings.settings.language) ?
                    store.generalStore.profileSettings.settings.language : false;

                if (language !== false) {
                    store.generalStore.setLanguage(language);

                    i18n.changeLanguage(language);
                }

            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setProfile(404);
            } else {
                store.generalStore.setProfileSettings({});
            }
        } catch (e) {
            store.generalStore.setProfileSettings({});
        }
    }

    static async getEvents(uid) {
        if (!store.authStore.token || store.authStore.token === '') return false;

        try {
            const response = await axios.get(
                `${apiUrl}/event/${uid}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            );


            if (response.status === 200) {
                if (!response.data || !Array.isArray(response.data)) {
                    return false
                }

                const formattedEvents = await formatEvents(response.data, uid);

                return formattedEvents;

            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setProfile(404);
            }
        } catch (e) {
            console.log(e)
        }
    }

    static async getAllEvents(num) {
        if (!store.authStore.token || store.authStore.token === '') return false;

        try {
            const url = (num && num > 0) ? `${apiUrl}/event?num=${num}` : `${apiUrl}/event`
            const response = await trackPromise(axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));


            if (response.status === 200) {
                if (!response.data || !Array.isArray(response.data)) {
                    return false
                }

                const t0 = Date.now();
                const formattedEvents = await formatEvents(response.data)
                const passed = Date.now() - t0;
                console.log('Response formating', passed);

                return formattedEvents;

            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setProfile(404);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not retrieve history');
            console.log(e)
        }
    }

    static async getNotes(uid, page, num) {
        if (!store.authStore.token || store.authStore.token === '') return false;

        if(!page) page = 1;
        if(!num) num = 10;

        try {
            const response = await axios.get(
                `${apiUrl}/note/byEntryUid/${uid}?num=${num}&page=${page}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            );


            if (response.status === 200) {
                if (!response.data || !Array.isArray(response.data.notes)) {
                    return false
                }

                return response.data;

            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
                store.generalStore.setProfile(404);
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export default withRouter(ContactGetter);
