import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Group from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/Business";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
// import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";

// React multilanguage
import { withTranslation } from "react-i18next";

// Components
import SingleContact from "./SingleContact";

import constants from '../../stores/constants';

const styles = theme => ({
  leftCluster: {
    margin: 10,
    color: constants.color.main,
    backgroundColor: "#fff",
    width: 30,
    height: 30,
    lineHeight: "30px",
    // display: "inline-block",
    float: "left",
    clear: "both",
    display: "block",
    fontSize: 16,
    // boxShadow: "0px 0px 1px 4px #ededed",
    cursor: "pointer",
    border: `2px solid ${constants.color.main}`,
    overflow: "visible",
    marginTop: 8
  },
  rightCluster: {
    margin: 10,
    color: constants.color.main,
    backgroundColor: "#fff",
    width: 30,
    height: 30,
    lineHeight: "30px",
    // display: "inline-block",
    float: "right",
    clear: "both",
    display: "block",
    fontSize: 16,
    // boxShadow: "0px 0px 1px 4px #ededed",
    cursor: "pointer",
    border: `2px solid ${constants.color.main}`,
    overflow: "visible",
    marginTop: 8
  },
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // display: "inline-block",
    float: "left",
    clear: "both",
    display: "block",
    fontSize: 16
  },
  avatarRight: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    // display: "inline-block",
    float: "right",
    clear: "both",
    display: "block",
    fontSize: 16
  },
  avatarInactive: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.inactive,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block"
  },
  relationsBlock: {
    marginBottom: theme.spacing.unit * 3,
    display: "block",
    float: "left",
    width: "100%"
  },
  chip: {
    // margin: theme.spacing.unit,
    float: "left",
    border: "none",
    fontSize: 13,
    background: "transparent",
    position: "relative",
    lineHeight: "20px",
    userSelect: "none"
  },
  chipRight: {
    // margin: theme.spacing.unit,
    float: "right",
    border: "none",
    fontSize: 13,
    background: "transparent",
    position: "relative",
    lineHeight: "20px",
    userSelect: "none"
  },
  label: {
    display: "block",
    position: "absolute",
    zIndex: 1000,
    left: 0
  },
  labelRight: {
    display: "block",
    position: "absolute",
    zIndex: 1000,
    right: theme.spacing.unit * 2
  },
  labelMobile: {
    display: "block",
    position: "absolute",
    zIndex: 1000,
    left: theme.spacing.unit * 0.5,
    fontSize: "12px"
  },
  labelMobileRight: {
    display: "block",
    position: "absolute",
    zIndex: 1000,
    right: theme.spacing.unit * 0.5,
    fontSize: "12px"
  },
  container: {
    // display: "flex"
  },
  paper: {
    // margin: theme.spacing.unit * 1
    boxShadow: "none"
  },
  collapseContainer: {
    display: "block",
    float: "left",
    width: "100%"
  },
  right: {
    float: "right",
    display: "block",
    clear: "both",
    width: 160,
    height: 45
  },
  left: {
    // float: 'left',
    // display: 'block',
    // clear: 'both',
    width: 160,
    height: 45,
    display: "block"
  },
  maxLineHeight: {
    lineHeight: "50px"
  },
  clusterIcon: {
    marginTop: 7,
    marginLeft: -3,
    fontSize: "18px"
  },
  clusterCount: {
    position: "absolute",
    right: -5,
    top: -5,
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "bold",
    width: 16,
    height: 16,
    textAlign: "center",
    background: constants.color.main, // "#2e8bbe",
    borderRadius: "5px",
    color: "#fff"
  },
  toolTip: {
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
    width: 200,
    height: "auto",
    zIndex: 1000
  },
  toolTipMobile: {
    left: "35px !important",
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
    marginTop: -38,
    width: 200,
    height: "auto",
    zIndex: 1000
  },
  toolTipMobileRight: {
    left: "-35px !important",
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
    marginTop: -38,
    width: 200,
    height: "auto",
    zIndex: 1000
  },
  tooltipPaper: {
    padding: theme.spacing.unit
    // fontSize: '14px',
  }
});

class RelationsBlock extends Component {
  start = 0;
  position = 0;

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  state = {
    checked: false,
    activeElement: null,
    content: "",
    avatarErrors: {}
  };

  avatarError(uid) {
    let avatarErrors = this.state.avatarErrors;
    avatarErrors[uid] = 1;
    this.setState({
      avatarErrors
    });
  }

  handleSwipeStart(index, event) {
    if ("touches" in event) {
      if (event.touches[0]) this.start = event.touches[0].clientX;
    } else {
      this.start = event.pageX;
    }

    this.position = 0;
  }

  handleSwipeMove(index, event) {
    if ("touches" in event && event.touches[0])
      this.position = event.touches[0].clientX;
  }

  handleSwipeEnd(index, event) {
    if (this.start > 0) {
      const x = this.position > 0 ? this.position : event.pageX;
      const diff = Math.abs(x - this.start);
      if (diff > 20) this.handleAvatarHover(index, event);
    }
  }

  handleAvatarHover(index, event) {
    const content = [<div key={"n0"}>{this.props.entries[index].name}</div>];

    if (
      "phone" in this.props.entries[index] &&
      this.props.entries[index].phone.trim() !== ""
    ) {
      const contact = {
        type: "phone",
        value: this.props.entries[index].phone,
        description: ""
      };
      content.push(<SingleContact key={"c1"} contact={contact} />);
    }

    if (
      "email" in this.props.entries[index] &&
      this.props.entries[index].email.trim() !== ""
    ) {
      const contact = {
        type: "email",
        value: this.props.entries[index].email,
        description: ""
      };
      content.push(<SingleContact key={"c2"} contact={contact} />);
    }

    this.setState({
      activeElement: event.currentTarget,
      content
    });

    if ("setActivePosition" in this.props)
      this.props.setActivePosition(this.props.position);
  }

  handleAvatarLeave() {
    this.setState({ activeElement: null, content: "" });
    if ("setActivePosition" in this.props) this.props.setActivePosition("");
  }

  handleClusterClick() {
    this.setState({ checked: !this.state.checked });
    if ("callback" in this.props) {
      var interval = setInterval(
        function() {
          this.props.callback();
        }.bind(this),
        20
      );

      setTimeout(
        function() {
          clearInterval(interval);
          if ("redraw" in this.props) this.props.redraw();
        }.bind(this),
        500
      );
    }
  }

  handleClickAway() {
    this.setState({ checked: false });
    if ("callback" in this.props) {
      var interval = setInterval(
        function() {
          this.props.callback();
        }.bind(this),
        20
      );

      setTimeout(
        function() {
          clearInterval(interval);
          if ("redraw" in this.props) this.props.redraw();
        }.bind(this),
        500
      );
    }
  }

  onAvatarClick(entry) {
    const pathType =
      entry.kind === "Person"
        ? constants.path.detailviewPerson
        : constants.path.detailviewOrganization;

    this.setState({ link: `${pathType}/${entry.source}` });
  }

  componentDidUpdate() {
    if (
      this.props.activePosition !== "" &&
      this.props.activePosition !== this.props.position &&
      this.state.activeElement !== null
    ) {
      this.setState({
        activeElement: null
      });
      // this.forceUpdate();
    }
  }

  render() {
    const { classes, position, isClustered, center } = this.props;
    let entries = [];
    let list = [];
    let labelClass = "";

    if (window.innerWidth < 600) {
      labelClass =
        position === "right" || position === "bottomRight"
          ? classes.labelMobileRight
          : classes.labelMobile;
    } else {
      labelClass =
        position === "right" || position === "bottomRight"
          ? classes.labelRight
          : classes.label;
    }

    if (this.props.entries && Array.isArray(this.props.entries)) {
      entries = this.props.entries;
      entries.sort(function(a, b){
        const aName = (a && 'name' in a && a.name) ? a.name : '';
        const bName = (b && 'name' in b && b.name) ? b.name : '';
        return aName.localeCompare(bName);
      });

      for (const index in entries) {
        let initials = entries[index].name
          ? entries[index].name.split(" ")
          : ["", ""];

        if (initials[2]) {
          initials = initials[0][0] + initials[2][0];
        } else {
          initials = initials[0][0];
        }
        entries[index].initials = initials;
      }
    }

    let c = 0;
    for (const entry of entries) {
      const link =
        entry.kind === "Person"
          ? `${constants.path.detailviewPerson}/${
              center.uid === entry.source ? entry.target : entry.source
            }`
          : `${constants.path.detailviewOrganization}/${
              center.uid === entry.source ? entry.target : entry.source
            }`;

      const currentEntry = (
        <div
          className={`${
            position === "right" || position === "bottomRight"
              ? classes.right
              : classes.left
          }`}
          key={`l${entry.uid}`}
          onTouchStart={this.handleSwipeStart.bind(this, c)}
          onMouseDown={this.handleSwipeStart.bind(this, c)}
          onTouchEnd={this.handleSwipeEnd.bind(this, c)}
          onTouchMove={this.handleSwipeMove.bind(this, c)}
          onMouseUp={this.handleSwipeEnd.bind(this, c)}
        >
          <Link to={link}>
            <Avatar
              className={`${
                !isClustered ? "relation-avatar" : ""
              } relation-${position} ${
                position === "right" || position === "bottomRight"
                  ? classes.avatarRight
                  : classes.avatar
              }`}
              src={entry.uid in this.state.avatarErrors ? null : entry.image}
              onMouseEnter={this.handleAvatarHover.bind(this, c)}
              onError={this.avatarError.bind(this, entry.uid)}
            >
              {entry.initials}
            </Avatar>
          </Link>
          <div
            className={`relation-label-${position} ${
              position === "right" || position === "bottomRight"
                ? classes.chipRight
                : classes.chip
            } ${isClustered ? classes.maxLineHeight : ""}`}
          >
            <div className={labelClass}>{entry.label}</div>
          </div>
        </div>
      );
      list.push(currentEntry);
      c += 1;
    }

    const clusterType =
      this.props.clusterType === "person" ? (
        <Group className={classes.clusterIcon} />
      ) : (
        <BusinessIcon className={classes.clusterIcon} />
      );

    const entriesCount = entries.length > 9 ? "9+" : entries.length;

    return (
      <div key={`grid-${position}`} className={classes.relationsBlock}>
        {isClustered && (
          <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
            <div className={classes.container}>
              <Avatar
                className={`relation-avatar relation-${position}  ${
                  position === "right" || position === "bottomRight"
                    ? classes.rightCluster
                    : classes.leftCluster
                }`}
                src={""}
                onClick={this.handleClusterClick.bind(this)}
              >
                {clusterType}
                {/*{center.kind === "organization" ? (
                  <Group className={classes.clusterIcon} />
                ) : (
                  <BusinessIcon className={classes.clusterIcon} />
                )}*/}
                <div className={classes.clusterCount}>{entriesCount}</div>
              </Avatar>
              <div className={classes.collapseContainer}>
                <Collapse in={this.state.checked} timeout={500}>
                  <Paper elevation={4} className={classes.paper}>
                    {list}
                  </Paper>
                </Collapse>
              </div>
            </div>
          </ClickAwayListener>
        )}

        {isClustered === false && list}

        <Popper
          open={this.state.activeElement !== null}
          anchorEl={this.state.activeElement}
          placement={window.innerWidth < 600 ? "bottom" : "right"}
          className={
            window.innerWidth < 600
              ? this.props.position === "right" ||
                this.props.position === "bottomRight"
                ? classes.toolTipMobileRight
                : classes.toolTipMobile
              : classes.toolTip
          }
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={this.handleAvatarLeave.bind(this)}
                >
                  <div className={classes.tooltipPaper}>
                    {this.state.content}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(withTranslation("translations")(RelationsBlock))
);
