import { observable, decorate, action, toJS } from 'mobx';
import {
    getPersons,
    getCurrentPersons,
    getPersonsByLetter,
    getPersonsAndOrganizations,
    getCurrentGroupEntries,
    getCurrentPersonsAndOrganizations,
    getByCategory,
    getCurrentCategoryMembers,
    getCategoryMembersByLetter,
    getPersonsAndOrganizationsByLetter,
    getByGroup,
    getContactsByIds,
    deletePerson,
} from '../actions/person';
import _ from 'lodash';

const accents = {
  'Ä': 'A',
  'Á': 'A',
  'À': 'A',
  'Ã': 'A',
  'Â': 'A',
  'É': 'E',
  'È': 'E',
  'Ê': 'E',
  'Ë': 'E',
  'Í': 'I',
  'Ì': 'I',
  'Î': 'I',
  'Ï': 'I',
  'Ö': 'O',
  'Ó': 'O',
  'Ò': 'O',
  'Ô': 'O',
  'Õ': 'O',
  'Ü': 'U',
  'Ú': 'U',
  'Ù': 'U',
  'Û': 'U',
  'Ç': 'C',
  'Ñ': 'N',
};

class PersonStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    persons = [];
    personsAndOrganizations = [];
    personsIndex = {};
    personsAndOrganizationsIndex = {};
    personsHash = {};
    personsAndOrganizationsHash = {};

    personIncrementSize = 50;

    sorting = ['firstName', 'lastName', 'lastUpdate'];
    sortingAll = 'nameAsc';
    personCount = 0;
    personsAndOrganizationsCount = 0;
    windowSize = 50;

    selectedCategory = null;
    selectedGroup = null;

    offset = 0;
    offsetPerson = 0;
    offsetOrganization = 0;

    // Set persons in mobx store
    setPersons = persons => {
        this.persons = persons;
        if (this.persons && this.persons.length > 1) {
            // this.sortPersons(this.sorting[0], this.sorting[1]);
            this.indexPersons();
        } else this.persons = [];
    }

    // Add a newly created person to the list
    addPerson = person => {
        const persons = this.persons;
        persons.push(person);
        this.setPersonCount(this.personCount + 1)
        this.setPersons(persons);
    }

    // Set persons and organizations in mobx store
    setPersonsAndOrganizations = data => {
        this.personsAndOrganizations = data;
        if (this.personsAndOrganizations && this.personsAndOrganizations.length > 1) {
            // this.sortPersonsAndOrganizations(this.sortingAll);
            this.indexPersonsAndOrganizations()
        }
    }

    // Set persons and organizations count in mobx store
    setPersonsAndOrganizationsCount = count => {
        this.personsAndOrganizationsCount = count;
    }

    // Set persons count in mobx store
    setPersonCount = count => {
        this.personCount = count;
    }

    setSelectedCategory(obj) {
        this.selectedCategory = obj;
    }

    // Fetch all persons and organizations with given category
    getByCategory = async (categoryId) => {
        const { token } = this.rootStore.authStore;
        let page = 2;
        const num = 10000;

        let entries;
        if (!this.personsAndOrganizations || this.personsAndOrganizations.length === 0) {
            const res = await getPersonsAndOrganizations(token, 1, num);

            if (res) {
                entries = res.data;
                if (res.meta.count > num) {
                    for (let i = (num + 1); i < res.meta.count; i += num) {
                        const nextRes = await getPersonsAndOrganizations(token, page, num)
                        if (nextRes.data && Array.isArray(nextRes.data)) entries = entries.concat(nextRes.data);
                        page++;
                    }
                }
            }
        } else {
            entries = this.personsAndOrganizations;
        }

        const res = await getByCategory(categoryId, token, page, num);

        this.setSelectedCategory({ label: res.label, description: res.description, uid: res.uid, type: res.categoryType });

        let list = (res && res.members && Array.isArray(res.members)) ? Object.assign(res.members) : [];
        const listLength = (list && Array.isArray(list)) ? list.length : 0;
        let i;

        for (i = 0; i < listLength; i += 1) {
            if (list[i].aggrType === 'Person' && 'name' in list[i]) {
                list[i].firstName = list[i].name;
                list[i].lastName = '';

                const found = entries.find(person => person.uid === list[i].uid);
                if (found) {
                    list[i].contactData = found.contactData;
                }
                delete list[i].name;
            } else if (list[i].aggrType === 'Organization') {
                const found = entries.find(organization => organization.uid === list[i].uid);
                if (found) {
                    list[i].contactData = found.contactData;
                }
            } else if (list[i].aggrType !== 'Person' && list[i].aggrType !== 'Organization') {
                list.splice(i, 1);
                i = i - 1;
            }
        }

        this.setPersonsAndOrganizations(list);
        // if (res.meta) {
        this.setPersonsAndOrganizationsCount(list.length);
        this.indexPersonsAndOrganizations();
        // }


        return this.personsAndOrganizations;
    }

    // Fetch all persons and organizations with given category from an offset and sorted
    setCurrentCategoryMembers = async (categoryId, offset, requested) => {
        const { token } = this.rootStore.authStore;

        let allData = [];

        const res = await getCurrentCategoryMembers(categoryId, token, offset, this.personIncrementSize, this.sorting[0]);

        this.setSelectedCategory({ label: res.label, description: res.description, uid: res.uid, type: res.categoryType });

        if (res && res.members) {
            this.setPersonsAndOrganizationsCount(res.meta.count);
            allData = res.members;
            this.setPersonsAndOrganizations(allData);
            if (!requested) this.offset = offset;
        }

        return allData;
    }

    // Fetch all persons and organizations with given category starting from given letter
    setCategoryMembersByLetter = async (categoryId, letter, requested) => {
        const { token } = this.rootStore.authStore;

        const res = await getCategoryMembersByLetter(categoryId, token, letter, this.personIncrementSize, this.sorting[0]);

        this.setSelectedCategory({ label: res.label, description: res.description, uid: res.uid, type: res.categoryType });

        if (res && res.members) {
            this.setPersonsAndOrganizationsCount(res.meta.count);

            const beforeLength = (res.dataBefore) ? res.dataBefore.length : 0;

            let newData;
            if (beforeLength > 0) {
                newData = res.dataBefore.concat(res.members); //list
                this.offset = res.meta.offset - beforeLength;
            } else {
                newData = res.members;
                this.offset = res.meta.offset;
            }

            this.setPersonsAndOrganizations(newData);

            return {
                data: newData,
                offset: res.meta.offset,
                dataBefore: res.dataBefore,
            };
        }

        return false;
    }

    setSelectedGroup(obj) {
        this.selectedGroup = obj;
    }

    // Fetch all persons and organizations with given group
    getByGroup = async (groupId) => {
        const { token } = this.rootStore.authStore;
        let page = 2;
        const num = 10000;

        let entries;
        if (!this.personsAndOrganizations || this.personsAndOrganizations.length === 0) {
            const res = await getPersonsAndOrganizations(token, 1, num);

            if (res) {
                entries = res.data;
                if (res.meta.count > num) {
                    for (let i = (num + 1); i < res.meta.count; i += num) {
                        const nextRes = await getPersonsAndOrganizations(token, page, num)
                        if (nextRes.data && Array.isArray(nextRes.data)) entries = entries.concat(nextRes.data);
                        page++;
                    }
                }
            }
        } else {
            entries = this.personsAndOrganizations;
        }

        // const res = await getByGroup(groupId, token, 1, num);

        const res = await getByGroup(groupId, token);

        this.setSelectedGroup({ label: res.label, description: res.description, uid: res.uid });

        const list = (res && res.members && Array.isArray(res.members)) ? Object.assign(res.members) : [];

        const listLength = (list && Array.isArray(list)) ? list.length : 0;
        let i;

        for (i = 0; i < listLength; i += 1) {
            if (list[i].aggrType.toLowerCase() === 'person') {
                if ('name' in list[i] && (!list[i].firstName || !list[i].lastName)) {
                    list[i].firstName = list[i].name;
                    list[i].lastName = '';
                }

                const found = entries.find(person => person.uid === list[i].uid);
                if (found) {
                    list[i].contactData = found.contactData;
                }
                delete list[i].name;
            } else if (list[i].aggrType.toLowerCase() === 'organization') {
                const found = entries.find(organization => organization.uid === list[i].uid);
                if (found) {
                    list[i].contactData = found.contactData;
                }
            } else {
                list.splice(i, 1);
                i = i - 1;
            }
        }

        this.setPersonsAndOrganizations(list);
        this.setPersonsAndOrganizationsCount(list.length);
        this.indexPersonsAndOrganizations();

        return this.personsAndOrganizations;
    }

    // Fetch all persons and organizations
    getPersonsAndOrganizations = async () => {
        const { token } = this.rootStore.authStore;
        let page = 2;
        const num = 10000
        const res = await getPersonsAndOrganizations(token, 1, num);

        if (res) {
            this.setPersonsAndOrganizations(res.data);
            if (res.meta) {
                this.setPersonsAndOrganizationsCount(res.meta.count);
                (this.sortingAll !== 'lastUpdate') && this.indexPersonsAndOrganizations();
            }

            if (res.meta.count > num) {
                for (let i = (num + 1); i < res.meta.count; i += num) {
                    const nextRes = await getPersonsAndOrganizations(token, page, num)
                    const newList = nextRes.data.concat(toJS(this.personsAndOrganizations))
                    this.setPersonsAndOrganizations(newList)
                    page++;
                }
            }
        }
        // getPersonsAndOrganizations(token)
        //     .then(action(res => {
        //         this.setPersonsAndOrganizations(res.data);

        //         if (res.meta) {
        //             this.setPersonsAndOrganizationsCount(res.meta.count);
        //         }
        //         // TODO: Add error handling
        //     })).catch(e => console.log('ERROR: ', e));

        return this.personsAndOrganizations;
    }

    // Fetch all persons
    getPersons = async () => {
        const { token } = this.rootStore.authStore;
        // let page = 2;
        const res = await getPersons(token, 1, this.personIncrementSize, this.sorting[0]);

        if (res) {
            this.setPersons(res.data);
            if (res.meta) {
                this.setPersonCount(res.meta.count);
                (this.sorting[0] !== 'lastUpdate') && this.indexPersons();
            }

            // if (res.meta.count > num) {
            //     for (let i = (num + 1); i < res.meta.count; i += num) {
            //         const nextRes = await getPersons(token, page, num)
            //         const newList = nextRes.data.concat(toJS(this.persons))
            //         this.setPersons(newList)
            //         page++;
            //     }
            // }
        }

        return this.persons;
    }

    // Add additional persons on a scroll event
    appendPersons = async (offset) => {

        const { token } = this.rootStore.authStore;
        const res = await getPersons(token, 0, this.personIncrementSize, this.sorting[0], offset)
        if (res && res.data) {
            this.setPersons(this.persons.concat(res.data))
        }
    }


    // Load new persons on a scroll event
    setCurrentPersons = async (offset, requested) => {
        let allData = [];

        const { token } = this.rootStore.authStore;
        const res = await getCurrentPersons(token, offset, this.personIncrementSize, this.sorting[0])

        if (res && res.data) {
            this.setPersonCount(res.meta.count);
            allData = res.data;
            this.setPersons(allData);
            if (!requested) this.offset = offset;
        }

        return allData;
    }

    // Load new persons and organization on a scroll event
     setCurrentPersonsAndOrganizations = async (offset, requested) => {
        let allData = [];

        const { token } = this.rootStore.authStore;
        const res = await getCurrentPersonsAndOrganizations(token, offset, this.personIncrementSize, this.sortingAll);

        if (res && res.data) {
            this.setPersonsAndOrganizationsCount(res.meta.count);
            allData = res.data;
            this.setPersonsAndOrganizations(allData);
            if (!requested) this.offset = offset;
        }

        return allData;
    }

    // Load new group entries on a scroll event
    setCurrentGroupEntries = async (offset, requested, group) => {
        let allData = [];

        const { token } = this.rootStore.authStore;
        const res = await getCurrentGroupEntries(token, offset, this.personIncrementSize, this.sortingAll, group)


        if (res && res.members) {
            this.setPersonsAndOrganizationsCount(res.meta.count);
            allData = res.members;
            this.setPersonsAndOrganizations(res.members);
            if (!requested) this.offset = offset;
        }

        return allData;
    }

    setPersonsByLetter = async (letter) => {
        const { token } = this.rootStore.authStore;
        const res = await getPersonsByLetter(token, letter, this.personIncrementSize, this.sorting[0])

        if (res && res.data && res.meta && res.meta.count) {
            this.setPersonCount(res.meta.count);

            const beforeLength = (res.dataBefore) ? res.dataBefore.length : 0;

            let newData;
            if (beforeLength > 0) {
                newData = res.dataBefore.concat(res.data);
                this.offset = res.meta.offset - beforeLength;
            } else {
                newData = res.data;
                this.offset = res.meta.offset;
            }

            this.setPersons(newData);

            return {
                data: res.data,
                offset: res.meta.offset,
                dataBefore: res.dataBefore,
            };
        }

        return false;
    }

    setPersonsAndOrganizationsByLetter = async (letter) => {
        const { token } = this.rootStore.authStore;
        const res = await getPersonsAndOrganizationsByLetter(token, letter, this.personIncrementSize, this.sortingAll);

        if (res && res.data && res.meta && res.meta.count) {
            this.setPersonsAndOrganizationsCount(res.meta.count);

            const beforeLength = (res.dataBefore) ? res.dataBefore.length : 0;

            let newData;
            if (beforeLength > 0) {
                newData = res.dataBefore.concat(res.data);
                this.offset = res.meta.offset - beforeLength;
            } else {
                newData = res.data;
            }

            this.setPersonsAndOrganizations(newData);

            return {
                data: res.data,
                offset: res.meta.offset,
                dataBefore: res.dataBefore,
            };
        }

        return false;
    }

    // Load current data of favorites
    setFavorites = async (uids, offset, requested) => {
        let allData = [];

        const { token } = this.rootStore.authStore;
        const res = await getContactsByIds(token, uids, offset, 1000, this.sorting[0])

        if (res && res.data) {
            this.setPersonsAndOrganizationsCount(res.meta.count);
            allData = res.data;
            this.setPersonsAndOrganizations(allData);

            if (!requested) this.offset = offset;
        }

        this.sortPersonsAndOrganizations(this.sortingAll);

        return allData;
    }

    // Delete a person
    deletePerson = uid => {
        const { token } = this.rootStore.authStore;
        let fullName = '';
        const person = _.find(this.persons, person => person.uid === uid);
        if (person) fullName = `${person.firstName} ${person.lastName}`.trim();
        deletePerson(token, uid, fullName)
            .then(action(res => {
                if (!this.persons) this.persons = [];
                if (res && res.payload && res.payload.uid) {
                    const filteredPersons = this.persons.filter(person => person.uid !== res.payload.uid);
                    const count = this.persons.length;
                    this.persons.replace(filteredPersons);
                    this.setPersonCount(count - 1)

                    this.indexPersons();
                } else {
                    console.log('ERROR in Res: ', res)
                }
            })).catch(e => console.log('ERROR: ', e));
        return this.persons;
    }

    indexPersons() {
        const sorting = this.sorting[0];

        const hash = {};
        const shortHash = {};
        const personLength = this.persons.length;
        let i;
        let j;
        let c;
        let char;
        let code;

        for (i = 0; i < personLength; i += 1) {

            if (sorting === 'lastName') {
                char = this.persons[i].lastName.substr(0, 1).toUpperCase();
            } else if (sorting === 'firstName') {
                char = this.persons[i].firstName.substr(0, 1).toUpperCase();
            } else {
                char = this.persons[i].firstName.substr(0, 1).toUpperCase();
            }

            if(char in accents) char = accents[char];

            if (char === '') continue;
            const currentCode = char.charCodeAt(0);
            if (currentCode < 64 || currentCode > 91) continue;
            code = currentCode;

            // is first starting with this letter
            if (char in hash === false) {
                // console.log(char);

                //  check if previous anchors exist to close gaps
                for (j = code - 1; j > 64; j -= 1) {
                    c = String.fromCharCode(j);

                    if (c in hash === false) {
                        // console.log('-- ' + c);
                        hash[c] = i;
                    } else {
                        break;
                    }
                }

                hash[char] = i;
                shortHash[char] = i;
            }
        }

        if (i >= personLength) i = personLength - 1;

        if (code < 90) {
            for (j = code + 1; j < 91; j += 1) {
                c = String.fromCharCode(j);
                hash[c] = i;
            }
        }

        this.personsHash = hash;
        let personsIndex = {};
        const keys = Object.keys(shortHash);
        const length = keys.length;

        for (i = 0; i < length; i++) {
            if (!personsIndex[shortHash[keys[i]]]) {
                personsIndex[shortHash[keys[i]]] = keys[i]
            };
        }

        this.personsIndex = personsIndex;
    }

    indexPersonsAndOrganizations() {
        const sorting = this.sortingAll;

        const hash = {};
        const shortHash = {};
        const Length = this.personsAndOrganizations.length;

        let i;
        let j;
        let c;
        let char;
        let code;
        let name;

        for (i = 0; i < Length; i += 1) {
            name = ('name' in this.personsAndOrganizations[i]) ? this.personsAndOrganizations[i].name : this.personsAndOrganizations[i].firstName;
            if (typeof name === 'undefined') name = '';
            char = name.substr(0, 1).toUpperCase();

            if(char in accents) char = accents[char];

            if (char === '') continue;
            const currentCode = char.charCodeAt(0);
            if (currentCode < 64 || currentCode > 91) continue;
            code = currentCode;

            // is first starting with this letter
            if (char in hash === false) {
                if (sorting === 'nameAsc' || sorting === 'regular') {
                    //  check if previous anchors exist to close gaps
                    for (j = code - 1; j > 64; j -= 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        } else {
                            break;
                        }
                    }
                } else {
                    //  reverse sorting / inverted
                    for (j = code + 1; j < 91; j += 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        } else {
                            break;
                        }
                    }
                }
                hash[char] = i;
                shortHash[char] = i;
            }
        }
        if (i >= Length) i = Length - 1;
        if (sorting === 'inverted') {
            if (code > 64) {
                for (j = code - 1; j > 64; j -= 1) {
                    hash[code] = i;
                }
            }
        } else {
            if (code < 91) {
                for (j = code + 1; j < 91; j += 1) {
                    c = String.fromCharCode(j);
                    hash[c] = i;
                }
            }
        }

        this.personsAndOrganizationsHash = hash;

        let personsAndOrganizationsIndex = {};
        const keys = Object.keys(shortHash);
        const length = keys.length;

        for (i = 0; i < length; i++) {
            if (!personsAndOrganizationsIndex[shortHash[keys[i]]]) personsAndOrganizationsIndex[shortHash[keys[i]]] = keys[i];
        }

        this.personsAndOrganizationsIndex = personsAndOrganizationsIndex;
    }

    // Sort persons
    sortPersons = (primaryCriterion, secondaryCriterion) => {
        this.sorting = [primaryCriterion, secondaryCriterion];
        this.setCurrentPersons(0, false)
    }

    // Sort persons and organizations
    sortPersonsAndOrganizations = (order, local) => {
        let sortedPersonsAndOrgs = [];

        if (local) {
            console.log('local sort');
            if (order === 'regular') {
                this.sortingAll = 'nameAsc';
                sortedPersonsAndOrgs = this.personsAndOrganizations.slice().sort(function (a, b) {
                    const name1 = ('name' in a) ? a.name.toUpperCase() : ((a.firstName !== '') ? a.firstName.toUpperCase() : '');
                    const name2 = ('name' in b) ? b.name.toUpperCase() : ((b.firstName !== '') ? b.firstName.toUpperCase() : '');

                    if (name1 > name2) {
                        return 1;
                    }
                    if (name1 < name2) {
                        return -1;
                    }
                    return 0;
                });
            } else if (order === 'inverted') {
                this.sortingAll = 'nameDesc';
                sortedPersonsAndOrgs = this.personsAndOrganizations.slice().sort(function (a, b) {
                    const name1 = ('name' in a) ? a.name.toUpperCase() : ((a.firstName !== '') ? a.firstName.toUpperCase() : '');
                    const name2 = ('name' in b) ? b.name.toUpperCase() : ((b.firstName !== '') ? b.firstName.toUpperCase() : '');

                    if (name1 > name2) {
                        return -1;
                    }
                    if (name1 < name2) {
                        return 1;
                    }
                    return 0;
                });
            } else {
                this.sortingAll = 'lastUpdate';
                sortedPersonsAndOrgs = this.personsAndOrganizations.slice().sort(function (a, b) {
                    return b.lastUpdate - a.lastUpdate;
                });
            }

            this.personsAndOrganizations = sortedPersonsAndOrgs;
        } else {
            if (order === 'regular') {
                this.sortingAll = 'nameAsc';
            } else if (order === 'inverted') {
                this.sortingAll = 'nameDesc';
            } else {
                this.sortingAll = 'lastUpdate';
            }
            this.setCurrentPersonsAndOrganizations(0, false)
        }

        if (order === 'lastUpdate') {
            this.personsAndOrganizationsIndex = {}
        } else {
            this.indexPersonsAndOrganizations();
        }
    }

    setOffset(offset) {
        this.offset = offset;
    }
}

decorate(PersonStore, {
    persons: observable,
    personsAndOrganizations: observable,
    personCount: observable,
    personsAndOrganizationsCount: observable,
    personsIndex: observable,
    personsAndOrganizationsIndex: observable,
    personsHash: observable,
    personsAndOrganizationsHash: observable,
    selectedCategory: observable,
    selectedGroup: observable,
    offset: observable,
    offsetPerson: observable,
    offsetOrganization: observable,
    sortingAll: observable,

    indexPersons: action,
    indexPersonsAndOrganizations: action,
    setPersons: action,
    setPersonCount: action,
    getPersons: action,
    sortPersons: action,
    setPersonsAndOrganizationsCount: action,
    setPersonsAndOrganizations: action,
    sortPersonsAndOrganizations: action,
    getPersonsAndOrganizations: action,
    setSelectedCategory: action,
    getByCategory: action,
    setCurrentCategoryMembers: action,
    setCategoryMembersByLetter: action,
    addPerson: action,
    deletePerson: action,
    setSelectedGroup: action,
    getByGroup: action,
    appendPersons: action,
    setCurrentPersons: action,
    setPersonsByLetter: action,
    setOffset: action,
    setCurrentGroupEntries: action,
    setCurrentPersonsAndOrganizations: action,
    setPersonsAndOrganizationsByLetter: action,
    setFavorites: action,
})

export default PersonStore;
