// The main wrapper of every application page
import React, { Component, Suspense } from 'react';
import {Helmet} from "react-helmet";

import { Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import history from './actions/history';

// Mobx dependencies
import { observer, Provider } from 'mobx-react';
// import { toJS } from 'mobx';

// Material theme
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Cookies from 'universal-cookie';

// Stores
import store from './stores/';
import constants from './stores/constants';

// React Internationalization
import { withTranslation } from 'react-i18next';

// Actions
import auth from './actions/auth';
import { counter } from './actions/counter';

// Components
import Offline from './components/messaging/offline';
import Banner from './components/messaging/banner';
import SnackBar from './components/messaging/snackBar';

import Login from './components/auth/Login';

// import Register from './components/auth/Register';
// import Resend from './components/auth/Resend';
//
// import Search from './components/search/Search';

// import EditForm from './components/edit/editForm';

// import EditProfile from './components/edit/editProfile';

import DetailView from './components/detail/DetailView';
// import DetailViewDuplicate from './components/detail/DetailViewDuplicate';

import DetailEmpty from './components/detail/DetailEmpty';

import DetailMe from './components/detail/Me';

import Addresslist from './components/address-list/AddressList';
// import Profile from './components/profile/Profile';
// import MemberList from './components/member-list/memberProfiles';
import TopMenu from './components/navigation/top/Bar';
import FooterMenu from './components/navigation/bottom/FooterMenu';
// import Settings from './components/settings/Settings';
// import CombinedSettings from './components/settings/CombinedSettings';

// import Adminsettings from './components/adminsettings/Adminsettings';

// import Invites from './components/invites/Invites';
// import EditCategories from './components/categories/EditCategories';
// import EditRelations from './components/relations/EditRelations';

// import SavedSearches from './components/search/Saved';

// import UploadProgress from './components/upload/UploadProgress';

// import ChangePassword from './components/settings/ChangePassword';

// import DeleteMe from './components/settings/DeleteMe';

// import Import from './components/import/Import';
// import Export from './components/export/Export';
// import ImportExport from './components/import-export/ImportExport';

// import Mapping from './components/import/Mapping';
import NotFound from './components/templates/NotFound';
import Spinner from './components/templates/Spinner';

// import Scan from './components/templates/Scan';
// import RelationsScan from './components/templates/RelationsScan';

// import Marketplace from './components/marketplace/Handler';
// import VisualMapping from './components/import/VisualMapping';

// import EditWebhook from './components/webhooks/EditWebhook';
//
// import EditWebhookUrl from './components/webhooks/EditWebhookUrl';

// import Sidebar from './components/navigation/side/Sidebar';

// import Payment from './components/payment/Handler';

import Journal from './components/journal/Journal';

// import WebhooksList from './components/templates/WebhooksList';

// import Keys from './components/adminsettings/Keys'

// import IntegrationsList from './components/integrations/IntegrationsList';
// import DetailViewIntegration from './components/integrations/DetailViewIntegration';

import Dashboard from './components/dashboard/Dashboard';
// import DashboardEditor from './components/dashboard/DashboardEditor';

// import GovernanceDashboard from './components/governanceDashboard/Dashboard';

import FormEditor from './components/form/EditForm';
import FormsList from './components/form/ListForms';

import { messageHandler, startMessageListener } from './actions/messages';

import './App.css';

// import Sidebar from './components/navigation/side/Sidebar';
const Sidebar = React.lazy(() => import('./components/navigation/side/Sidebar'));

// import UploadProgress from './components/upload/UploadProgress';
const UploadProgress = React.lazy(() => import('./components/upload/UploadProgress'));

// import Register from './components/auth/Register';
const Register = React.lazy(() => import('./components/auth/Register'));

// import Resend from './components/auth/Resend';
const Resend = React.lazy(() => import('./components/auth/Resend'));

// import Search from './components/search/Search';
const Search = React.lazy(() => import('./components/search/Search'));

const EditForm = React.lazy(() => import('./components/edit/editForm'));

// import DetailViewDuplicate from './components/detail/DetailViewDuplicate';
const DetailViewDuplicate = React.lazy(() => import('./components/detail/DetailViewDuplicate'));

// import MemberList from './components/member-list/memberProfiles';
const MemberList = React.lazy(() => import('./components/member-list/memberProfiles'));

// import CombinedSettings from './components/settings/CombinedSettings';
const CombinedSettings = React.lazy(() => import('./components/settings/CombinedSettings'));

// import Adminsettings from './components/adminsettings/Adminsettings';
const Adminsettings = React.lazy(() => import('./components/adminsettings/Adminsettings'));

// import Invites from './components/invites/Invites';
const Invites = React.lazy(() => import('./components/invites/Invites'));

// import EditCategories from './components/categories/EditCategories';
const EditCategories = React.lazy(() => import('./components/categories/EditCategories'));

const EditGroups = React.lazy(() => import('./components/groups/EditGroups'));

// import EditRelations from './components/relations/EditRelations';
const EditRelations = React.lazy(() => import('./components/relations/EditRelations'));

// import SavedSearches from './components/search/Saved';
const SavedSearches = React.lazy(() => import('./components/search/Saved'));

// import ChangePassword from './components/settings/ChangePassword';
const ChangePassword = React.lazy(() => import('./components/settings/ChangePassword'));

// import DeleteMe from './components/settings/DeleteMe';
const DeleteMe = React.lazy(() => import('./components/settings/DeleteMe'));

// import Import from './components/import/Import';
const Import = React.lazy(() => import('./components/import/Import'));

// import Export from './components/export/Export';
const Export = React.lazy(() => import('./components/export/Export'));

// import ImportExport from './components/import-export/ImportExport';
const ImportExport = React.lazy(() => import('./components/import-export/ImportExport'));

// import Mapping from './components/import/Mapping';
const Mapping = React.lazy(() => import('./components/import/Mapping'));

// import Scan from './components/templates/Scan';
const Scan = React.lazy(() => import('./components/templates/Scan'));

// import RelationsScan from './components/templates/RelationsScan';
const RelationsScan = React.lazy(() => import('./components/templates/RelationsScan'));

// import Marketplace from './components/marketplace/Handler';
const Marketplace = React.lazy(() => import('./components/marketplace/Handler'));

// import VisualMapping from './components/import/VisualMapping';
const VisualMapping = React.lazy(() => import('./components/import/VisualMapping'));

// import EditWebhook from './components/webhooks/EditWebhook';
const EditWebhook = React.lazy(() => import('./components/webhooks/EditWebhook'));

// import EditWebhookUrl from './components/webhooks/EditWebhookUrl';
const EditWebhookUrl = React.lazy(() => import('./components/webhooks/EditWebhookUrl'));

// import Payment from './components/payment/Handler';
const Payment = React.lazy(() => import('./components/payment/Handler'));

// import WebhooksList from './components/templates/WebhooksList';
const WebhooksList = React.lazy(() => import('./components/templates/WebhooksList'));

// import Keys from './components/adminsettings/Keys'
const Keys = React.lazy(() => import('./components/adminsettings/Keys'));

// import IntegrationsList from './components/integrations/IntegrationsList';
const IntegrationsList = React.lazy(() => import('./components/integrations/IntegrationsList'));

// import DetailViewIntegration from './components/integrations/DetailViewIntegration';
const DetailViewIntegration = React.lazy(() => import('./components/integrations/DetailViewIntegration'));

// import DashboardEditor from './components/dashboard/DashboardEditor';
const DashboardEditor = React.lazy(() => import('./components/dashboard/DashboardEditor'));

const GovernanceDashboardEditor = React.lazy(() => import('./components/governanceDashboard/DashboardEditor'));


const switchWidth = !(window.innerWidth >= constants.breakpoints.max)
	? window.innerWidth >= constants.breakpoints.medium
		? { float: 'left', width: 'calc(100vw - 280px)' }
		: {}
	: { width: 1280, float: 'left' };
const suspenseFallback = <Spinner scrolled={store.generalStore.scrolled} width={switchWidth.width} />;

const cookies = new Cookies();

let Admin = false;
let AdminFooter = false;
let entryUrl = history.location.pathname;

const theme = createMuiTheme({
	palette: {
		primary: {
			main: constants.color.main,
			light: constants.color.light,
			dark: constants.color.main,
			contrastText: constants.color.contrastText
		}
	},
	typography: {
		useNextVariants: true,
		fontSize: 16,
		fontWeight: 300
	}
});

const styles = (theme) => ({
	root: {},
	displayPanel: {
		width: 640,
		minHeight: 'calc(100vh - 75px)',
		float: 'left',
		maxHeight: 'calc(100vh - 75px)',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	displayPanelSimple: {},
	panelHolder: {
		backgroundColor: '#fff'
	}
	// bottomNavLogin: {
	//   display: 'block',
	//   clear: 'both',
	//   width: 100,
	//   textAlign: 'center',
	//   marginLeft: 'auto',
	//   marginRight:'auto',
	//   paddingTop: theme.spacing.unit * 3,
	// },
	// bottomNavButton:{
	//   height: 40,
	//   // lineHeight: '40px',
	//   width: 100,
	//   // textAlign: 'center',
	//   // display: 'inline-block',
	// },
	// bottomNavLink:{
	//   height: 40,
	//   lineHeight: '40px',
	//   width: 100,
	//   textAlign: 'center',
	//   display: 'inline-block',
	// },
});

let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
	visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
	visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
	visibilityChange = 'webkitvisibilitychange';
}

class App extends Component {
	state = {
		lang: cookies.get('lang') || false,
	};

	constructor(props) {
		super(props);
		auth.check();
		this.handleScroll = this.handleScroll.bind(this);
		counter(store.authStore.token);
	}

	async componentDidMount() {
		if(this.state.lang === false) {
			const lang = cookies.get('lang');
			this.setState({ lang });
		}

		document.addEventListener(visibilityChange, this.onVisibilityChange, false);
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize.bind(this));
		document.addEventListener('fullscreenchange', this.handleResize.bind(this));
		document.addEventListener('visibilitychange', this.handleResize.bind(this));

		if ('role' in store.authStore.userData && store.authStore.userData.role === 'ADMIN') {
			console.log(cookies.get('admin'));
			if(cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) {
				console.log('is SuperAdmin');

				delete require.cache[require.resolve('./components/admin/Handler')];
				delete require.cache[require.resolve('./components/admin/Footer')];

				Admin = await import(
					/* webpackChunkName: "admin/admin.[contenthash]" */
					/* webpackPrefetch: 0 */
					'./components/admin/Handler'
				);
				AdminFooter = await import(
					/* webpackChunkName: "admin/admin.[contenthash]" */
					/* webpackPrefetch: 0 */
					'./components/admin/Footer'
				);

				if (entryUrl.indexOf(constants.path.admin) > -1) {
					history.push(entryUrl);
				} else {
					history.push(constants.path.admin);
				}
			}

			this.forceUpdate();
		}

		if (store.authStore.token !== '' && messageHandler === null) startMessageListener();
	}

	async componentDidUpdate() {
		if(this.state.lang === false) {
			const lang = cookies.get('lang');
			this.setState({ lang });
		}

		if (Admin === false && 'role' in store.authStore.userData && store.authStore.userData.role === 'ADMIN') {
			if(cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) {

				console.log('is SuperAdmin');

				delete require.cache[require.resolve('./components/admin/Handler')];
				delete require.cache[require.resolve('./components/admin/Footer')];

				Admin = await import(
					/* webpackChunkName: "admin/admin.updated.[contenthash]" */
					/* webpackPrefetch: 0 */
					'./components/admin/Handler'
				);
				AdminFooter = await import(
					/* webpackChunkName: "admin/admin.updated.[contenthash]" */
					/* webpackPrefetch: 0 */
					'./components/admin/Footer'
				);
				if (entryUrl.indexOf(constants.path.admin) > -1) {
					history.push(entryUrl);
				} else {
					history.push(constants.path.admin);
				}
				this.forceUpdate();
			}

		} else {
			Admin = await import(
				/* webpackChunkName: "admin/admin.updated2.[contenthash]" */
				/* webpackPrefetch: 0 */
				'./components/admin/Handler'
			);
			AdminFooter = await import(
				/* webpackChunkName: "admin/admin.updated2.[contenthash]" */
				/* webpackPrefetch: 0 */
				'./components/admin/Footer'
			);
		}

		if (store.authStore.token !== '' && messageHandler === null) startMessageListener();

		// Slightly hacky way to stop scrolled being falsely reset on refresh
		if (!store.generalStore.scrolled) this.handleScroll({});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event) {
		// if(window.innerWidth >= constants.breakpoints.max) return false;
		let y = 0;
		if (typeof event.pageY !== 'undefined') {
			y = event.pageY;
		} else if (typeof document.scrollingElement !== 'undefined') {
			y = document.scrollingElement.scrollTop;
		} else if (typeof document.documentElement.scrollTop !== 'undefined') {
			y = document.documentElement.scrollTop;
		} else if (typeof document.body.scrollTop !== 'undefined') {
			y = document.body.scrollTop;
		} else {
			// Do we really need this else? -> We are still have to do Browser testing
			// console.log('Missing scroll pos!');
			// console.log(event);
		}
		if (store.authStore.hasLogin === true) store.generalStore.setScrolled(y);
	}

	handleResize(event) {
		if (store.authStore.hasLogin === true) this.forceUpdate();
	}

	onVisibilityChange() {
		auth.check(true);
	}

	switchLanguage(language) {
		store.generalStore.setLanguage(language);
		this.props.i18n.changeLanguage(language);
	}

	render() {
		const { t, classes } = this.props;

		const isAdmin =
			store.authStore.userData.tenantRole && store.authStore.userData.tenantRole === 'TENANT_ADMIN'
				? true
				: false;
		try {
			const desktop = window.innerWidth >= constants.breakpoints.max;
			const medium = window.innerWidth >= constants.breakpoints.medium;

			const currentSwitchWidth = !desktop
				? window.innerWidth >= constants.breakpoints.medium
					? { float: 'left', width: 'calc(100vw - 280px)' }
					: {}
				: { width: 1280, float: 'left' };

			const search = store.generalStore.listState.indexOf('search/') > -1;

			let lang = this.state.lang;
			if(lang === false) lang = 'en';

			let manifest;
			if (lang === 'de') {
				manifest = <link rel="manifest" hreflang="de-DE" href="/de-manifest.json" crossorigin="use-credentials" />;
			} else {
				manifest = <link rel="manifest" hreflang="en-EN" href="/manifest.json" crossorigin="use-credentials" />;
			}

			return (
				<Router history={history}>
					<Provider {...store}>
						<>
							<Helmet>
	                <title>SnazzyContacts {lang.toUpperCase()}</title>
									{manifest}
	            </Helmet>
							<div history={history} className={'App' + (store.generalStore.loading ? ' loading' : ' not-loading')}>
								<MuiThemeProvider theme={theme}>
									{store.authStore.hasLogin === true &&
									window.innerWidth >= constants.breakpoints.medium &&
										<Suspense fallback={suspenseFallback}>
											<Sidebar />
										</Suspense>
									}

									<TopMenu store={store.data} className='menu' />

									{!store.authStore.hasLogin && (
										<div id='authdiv' style={{ marginTop: 75 }} key='authdivkey'>
											<Tabs
												id='authtabs'
												key='authtabskey'
												value={window.location.href.indexOf(constants.path.register) > -1 ? 1 : 0}
												indicatorColor='primary'
												textColor='primary'
												onChange={() => {
													this.forceUpdate();
												}}
												aria-label='login and register'
												style={medium ? {} : { marginTop: -60 }}
											>
												<Tab
													label={t('Login')}
													component={Link}
													to={constants.path.login}
													id='logintab'
													key='logintabkey'
												/>
												<Tab
													label={t('Register')}
													component={Link}
													to={constants.path.register}
													id='registertab'
													key='registertabkey'
												/>
											</Tabs>
										</div>
									)}

									{
										// store.authStore.hasLogin !== true && (
										// <div id="main-placeholder"></div>
										//)
									}
									<Offline />
									<Banner />
									<SnackBar />
									<Spinner scrolled={store.generalStore.scrolled} width={currentSwitchWidth.width} />
									{store.generalStore.showUploadDialog !== false &&
										<Suspense fallback={suspenseFallback}>
											<UploadProgress />
										</Suspense>
									}
									<div className={classes.panelHolder} style={currentSwitchWidth}>
										<Switch>
											<Route exact path={constants.path.dashboard}>
												<React.Fragment>
													<div
														className={classes.displayPanelSimple}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Dashboard />
													</div>
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.dashboardEditor}>
												<React.Fragment>
													<div
														className={classes.displayPanelSimple}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Suspense fallback={suspenseFallback}>
															<DashboardEditor />
														</Suspense>
													</div>
												</React.Fragment>
											</Route>
											{/*
											<Route exact path={constants.path.governanceDashboard}>
												<React.Fragment>
													<div
														className={classes.displayPanelSimple}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<GovernanceDashboard />
													</div>
												</React.Fragment>
											</Route>
											
											<Route exact path={constants.path.governanceDashboardEditor}>
												<React.Fragment>
													<div
														className={classes.displayPanelSimple}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Suspense fallback={suspenseFallback}>
															<GovernanceDashboardEditor />
														</Suspense>
													</div>
												</React.Fragment>
											</Route>
											*/}		
											<Route exact path={constants.path.listPersons}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.listOrganizations}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.listAll}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={`${constants.path.listByCategory}/:categoryId`}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={`${constants.path.listByGroup}/:groupId`}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.listDuplicates}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.deleteRequests}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.scan} component={props =>
													<Suspense fallback={suspenseFallback}>
														<Scan {...props}/>
													</Suspense>
											} />
											<Route exact path={constants.path.listRelationSuggestions}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.relationsScan}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<RelationsScan />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>

											{/*<Route exact path={constants.path.profile} component={Profile} />
	                  <Route exact path={constants.path.editProfile} component={EditProfile} />*/}
											<Route exact path={constants.path.listFavorites}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
														style={desktop ? { overflow: 'hidden' } : {}}
													>
														<Addresslist />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.memberList}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<MemberList />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>

											<Route exact path={constants.path.import}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<Import />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.export}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<Export />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.importExport}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<ImportExport />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.mapping}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Mapping />
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.visualMapping}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<VisualMapping />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route exact path={constants.path.savedSearches}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<SavedSearches />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route path={`${constants.path.detailviewDuplicate}/:id`}>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															<Addresslist />
														</div>
													)}
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<DetailViewDuplicate />
														</Suspense>
													</div>
												</React.Fragment>
											</Route>
											<Route path={`${constants.path.detailview}/:type/:id/:reference`}>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															{(!search && store.generalStore.listState !== 'journal') && <Addresslist />}
															{search && <Suspense fallback={suspenseFallback}>
																<Search />
															</Suspense>}
															{store.generalStore.listState === 'journal' && <Journal />}
														</div>
													)}
													<div
														id='DV1'
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<DetailView />
													</div>
												</React.Fragment>
											</Route>
											<Route path={`${constants.path.detailview}/:type/:id`}>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															{(!search && store.generalStore.listState !== 'journal') && <Addresslist />}
															{search && <Suspense fallback={suspenseFallback}><Search /></Suspense>}
															{store.generalStore.listState === 'journal' && <Journal />}
														</div>
													)}
													<div
														id='DV2'
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<DetailView />
													</div>
												</React.Fragment>
											</Route>

											{/*<Route path={`${constants.path.profile}`} component={DetailView} />*/}

											<Route path={`${constants.path.edit}/:type/:id/:reference`}>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															{!search && <Addresslist />}
															{search && <Suspense fallback={suspenseFallback}><Search /></Suspense>}
														</div>
													)}
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<EditForm history={history} />
														</Suspense>
													</div>
												</React.Fragment>
											</Route>
											<Route path={`${constants.path.edit}/:type/:id`}>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															{!search && <Addresslist />}
															{search && <Suspense fallback={suspenseFallback}><Search /></Suspense>}
														</div>
													)}
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<EditForm history={history} />
														</Suspense>
													</div>
												</React.Fragment>
											</Route>
											<Route
												path={`${constants.path.create}/:type`}
												componentOld={(props) => <Suspense fallback={suspenseFallback}><EditForm {...props} new='true' /></Suspense>}
											>
												<React.Fragment>
													{desktop && (
														<div
															className={classes.displayPanel}
															style={desktop ? { overflow: 'hidden' } : {}}
														>
															<Addresslist />
														</div>
													)}
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<EditForm history={history} new='true'/>
														</Suspense>
													</div>
												</React.Fragment>
											</Route>

											<Route
												path={constants.path.login}
												exact
												component={function() {
													return <Login errorMessage={store.generalStore.errorMessage} />;
												}}
											/>
											<Route
												path={constants.path.register}
												exact
												component={function() {
													return <Suspense fallback={suspenseFallback}>
															<Register errorMessage={store.generalStore.errorMessage} />
														</Suspense>;
												}}
											/>
										<Route path={constants.path.resend} component={ props =>
												<Suspense fallback={suspenseFallback}>
													<Resend {...props} />
												</Suspense>}
											/>
											<Route
												path={`${constants.path.register}/:token`}
												component={(props) => {
													return (
														<Suspense fallback={suspenseFallback}>
															<Register
																{...props}
																withToken={true}
																errorMessage={store.generalStore.errorMessage}
															/>
													</Suspense>
													);
												}}
											/>
											<Route
												path={constants.path.registerStep2}
												exact
												component={function() {
													return (
														<Suspense fallback={suspenseFallback}>
															<Register
																registerStep2={true}
																errorMessage={store.generalStore.errorMessage}
															/>
														</Suspense>
													);
												}}
											/>

											<Route
												path={`${constants.path.login}/:token`}
												component={(props) => {
													return (
														<Login
															{...props}
															withToken={true}
															errorMessage={store.generalStore.errorMessage}
														/>
													);
												}}
											/>

											<Route path={constants.path.search}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<Search />
														</Suspense>
													</div>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<DetailEmpty />
													</div>
												</React.Fragment>
											</Route>
											<Route path={constants.path.settings}>
												<React.Fragment>
													<div
														className={
															desktop ? classes.displayPanel : classes.displayPanelSimple
														}
													>
														<Suspense fallback={suspenseFallback}>
															<CombinedSettings />
														</Suspense>
													</div>
													{desktop && (
														<div className={classes.displayPanel}>
															<DetailEmpty />
														</div>
													)}
												</React.Fragment>
											</Route>
											<Route path={constants.path.changePassword} component={props =>
													<Suspense fallback={suspenseFallback}>
														<ChangePassword {...props} />
											</Suspense>} />
										<Route path={`${constants.path.deleteMe}/:token`} component={props=>
													<Suspense fallback={suspenseFallback}><DeleteMe {...props}/>
												</Suspense>} />
											<Route path={constants.path.notFound} component={NotFound} />

											<Route path={`${constants.path.marketplace}/`}>
												<div className={null}>
													<Suspense fallback={suspenseFallback}>
														<Marketplace />
													</Suspense>
												</div>
											</Route>

											<Route path={`${constants.path.payment}/`}>
												<div className={null}>
													<Suspense fallback={suspenseFallback}>
														<Payment />
													</Suspense>
												</div>
											</Route>

											{store.authStore.userData.role === 'ADMIN' &&
											Admin !== false && (
												<Route path={`${constants.path.admin}/`} component={Admin.default} />
											)}

											{isAdmin && [
												<Route key='r1' path={constants.path.adminsettings}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<Adminsettings />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r2' path={constants.path.invites}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<Invites />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r3' path={constants.path.editCategories}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<EditCategories />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r4' path={constants.path.editRelations}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<EditRelations />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r4g' path={constants.path.editGroups}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<EditGroups />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r5' path={constants.path.webhookEdit}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<EditWebhook />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r5x' path={constants.path.webhookUrlEdit}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<EditWebhookUrl />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r6' path={constants.path.journal}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Journal />
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r7' path={constants.path.webhook}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<WebhooksList />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r8' path={constants.path.apikeys}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<Keys />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r9' path={`${constants.path.integrationsList}/:id`}>
													<React.Fragment>
														{desktop && (
															<div
																className={classes.displayPanel}
																style={desktop ? { overflow: 'hidden' } : {}}
															>
																<Suspense fallback={suspenseFallback}>
																	<IntegrationsList />
																</Suspense>
															</div>
														)}
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<DetailViewIntegration />
															</Suspense>
														</div>
													</React.Fragment>
												</Route>,
												<Route key='r10' path={constants.path.integrationsList}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<IntegrationsList />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r11' path={constants.path.listForms}>
													<React.Fragment>
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<FormsList />
															</Suspense>
														</div>
														{desktop && (
															<div className={classes.displayPanel}>
																<DetailEmpty />
															</div>
														)}
													</React.Fragment>
												</Route>,
												<Route key='r12' path={`${constants.path.editForm}/:id`}>
													<React.Fragment>
														{desktop && (
															<div
																className={classes.displayPanel}
																style={desktop ? { overflow: 'hidden' } : {}}
															>
																<Suspense fallback={suspenseFallback}>
																	<FormsList />
																</Suspense>
															</div>
														)}
														<div
															className={
																desktop ? classes.displayPanel : classes.displayPanelSimple
															}
														>
															<Suspense fallback={suspenseFallback}>
																<FormEditor />
															</Suspense>
														</div>
													</React.Fragment>
												</Route>,
												<Route key='r13final' path={constants.path.me}>
													<DetailMe />
												</Route>,
											]}

											{store.authStore.userData.role !== 'ADMIN' && (
												<Redirect path='/' to={constants.path.dashboard} />
											)}

										</Switch>
									</div>

									{store.authStore.hasLogin &&
									store.authStore.userData.role !== 'ADMIN' && <FooterMenu />}
									{store.authStore.userData.role === 'ADMIN' &&
									AdminFooter !== false && <AdminFooter.default />}
								</MuiThemeProvider>
							</div>
						</>
					</Provider>
				</Router>
			);
		} catch (error) {
			return history.push('/not-found');
		}
	}
}

export default withStyles(styles)(withTranslation('translations')(observer(App)));
