// Configuration for internationalization

import i18n from "i18next";
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';


import store from '../stores/';

const lng = (store && store.generalStore && store.generalStore.language)? store.generalStore.language : 'en';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: lng,
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    // ns: ["translations","joyride"],
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
