import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// React multilanguage
import { withTranslation } from "react-i18next";

// Mobx Stores
import constants from '../../stores/constants';

// Components
import SingleContact from './SingleContact';
import ScanButton from './ScanButton';

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    typography: {
        paddingTop: 10,
        paddingLeft: 20,
        fontSize: '14px',
        color: constants.color.heading,
    },
    divider: {
        marginBottom: theme.spacing.unit,
    },
    contactInfos: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ContactInfos extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, contactData, t } = this.props;
        return (
            ((contactData && contactData.length > 0) &&
                <div className={classes.contactInfos}>
                    <Grid
                        container
                    >
                        <Grid item xs={6}>
                            <Typography className={classes.typography} variant='body1' align='left'>
                                {t('Contact Data')}

                            </Typography>

                        </Grid>
                        {this.props.contactType === 'person' &&
                            <Grid container item xs={6} justify="flex-end">
                                <ScanButton uid={this.props.uid} />
                            </Grid>
                        }
                    </Grid>
                    <Divider variant="middle" className={classes.divider} />
                    {
                        contactData.map((contact, index) => (
                            <SingleContact key={index} contact={contact} handleSuggestion={this.props.handleSuggestion} />
                        ))
                    }


                </div >
            )
        );
    }
}

export default withStyles(styles)(withTranslation("translations")(ContactInfos));
