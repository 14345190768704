import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// Mobx stores
import constants from '../../../stores/constants';
import store from '../../../stores/';

// Actions
import auth from '../../../actions/auth.js'

// Material UI
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Divider from '@material-ui/core/Divider';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolTip: {
    left: '5px',
    top: '60px',
    'min-width': '120px'
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  link: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    lineHeight: theme.spacing.unit * 6 + 'px',
  },
  menuItem: {
    margin: 0,
    padding: 0,
    height: theme.spacing.unit * 6,
  },
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  handleLogOut = async () => {
    this.setState({ open: false })
    await auth.doLogout();
    this.forceUpdate();
    window.location.href = (constants.path.logout)
  }

  refresh() {
    //eslint-disable-next-line
    location.reload(true);
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    const { open } = this.state;

    const isAdmin = (store.authStore.userData.tenantRole && store.authStore.userData.tenantRole === 'TENANT_ADMIN') ? true : false;

    // <MenuItem key="m1" onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.invites} className={classes.link}>{t('Invites')}</Link></MenuItem>,

    // <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.marketplace} className={classes.link}>{t('marketplace')}</Link></MenuItem>

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleToggle} className={classes.menuButton} aria-owns={open ? 'menu-list-grow' : undefined} aria-haspopup="true" color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} className={classes.toolTip} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <MenuItem onClick={this.handleClose} className={classes.menuItem} ><Link to={constants.path.dashboard} className={classes.link}>{t('Dashboard')}</Link></MenuItem>

                    {/* eslint-disable-next-line*/}
                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.savedSearches} className={classes.link}>{t('Saved searches')}</Link></MenuItem>

                    #
                    {isAdmin &&
                      [
                      <Divider />,
                      <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.importExport} className={classes.link}>{t('Import and Export')}</Link></MenuItem>,
                      <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.integrationsList} className={classes.link}>{t('Integrations')}</Link></MenuItem>,
                      ]
                    }


                    <Divider />

                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.journal} className={classes.link}>{t('Journal')}</Link></MenuItem>

                    {isAdmin &&
                      [
                        <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.listDuplicates} className={classes.link}>{t('Duplicates')}</Link></MenuItem>,
                        <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.listRelationSuggestions} className={classes.link}>{t('Relation suggestions')}</Link></MenuItem>,



                      ]
                    }

                    <Divider />

                    {isAdmin &&
                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.payment} className={classes.link}>{t('SNAZZY Premium')}</Link></MenuItem>
                    }

                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><Link to={constants.path.settings} className={classes.link}>{t('Settings')}</Link></MenuItem>

                    {/*
                    <MenuItem onClick={this.handleClose}><Link to={constants.path.help}>{t('Help')}</Link></MenuItem>
                    */}
                    {/*<MenuItem onClick={this.handleClose}><Link to={constants.path.logout}>{t('Log out!')}</Link></MenuItem>*/}
                    <MenuItem onClick={this.handleLogOut.bind(this)} className={classes.menuItem}><Link to={constants.path.logout} className={classes.link}>{t('Logout')}</Link></MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter((withTranslation("translations")(MenuListComposition))));
