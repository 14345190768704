// Handles all authentication actions except for the registration

import React from "react";
import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker'

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';
// import history from './history';

import axios from 'axios';

withTranslation("translations");

class Governance extends React.Component {
    static async getGovernanceGraph(start, end, num) {
        num = (num) ? num : 5000;
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/graph?from=${end}&until=${start}&number=${num}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                if (
                    response.data.meta
                    && response.data.meta.total
                    && response.data.meta.total >= num
                ) {
                    store.generalStore.addMessage(500, 'Selected range has too many entries, some might not be displayed');
                }

                store.governanceStore.setGraph(response.data);
            } else {
                // store.generalStore.addMessage(500, 'Governance graph could not be retrieved');
                // console.log(response);
                store.governanceStore.setGraph({});
            }
        } catch (e) {
            // store.generalStore.addMessage(500, 'Governance graph could not be retrieved');
            // console.log(e);
            store.governanceStore.setGraph({});
        }
    }

    static async getDashboardData(suppressWarning) {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/dashboard`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.governanceStore.setDashboard(response.data);
            } else {
                if (!suppressWarning) {
                    store.generalStore.addMessage(500, 'Governance dashboard data could not be retrieved');
                }
                console.log(response);
                store.governanceStore.setGraph({});
            }
        } catch (e) {
            if (!suppressWarning) {
                store.generalStore.addMessage(500, 'Governance dashboard data could not be retrieved');
            }
            console.log(e);
            store.governanceStore.setGraph({});
        }
    }

    static async getSingleEntry(uid) {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/objectStatus/${uid}?externalId=true`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                // store.governanceStore.setEntryHistory(response.data);
                return response.data;
            } else if (response.status === 204) {
                // store.governanceStore.setEntryHistory({ oihUid: false });
                return { oihUid: false };
            } else {
                // store.generalStore.addMessage(500, 'Governance objectStatus could not be retrieved');
                console.log(response);
                // store.governanceStore.setEntryHistory({});
                return false;
            }
        } catch (e) {
            // store.generalStore.addMessage(500, 'Governance objectStatus could not be retrieved');
            console.log(e);
            // store.governanceStore.setEntryHistory({});
            return false;
        }
    }

    static async getDeleteRequests(status) {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/management/request/all?status=${status}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.governanceStore.setDeleteRequests(response.data);
            } else if (response.status === 204) {
                store.governanceStore.setDeleteRequests([]);
            } else {
                store.generalStore.addMessage(500, 'Delete requests could not be retrieved');
                console.log(response);
                store.governanceStore.setGraph({});
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Delete requests could not be retrieved');
            console.log(e);
            store.governanceStore.setGraph({});
        }
    }

    static async markDeleteRequestClosed(id, appUid, alias) {
        try {
            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/governance/management/request/reference/close/${id}/${appUid}/${encodeURIComponent(alias)}`,
                {},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Successfully marked as closed!');
                return true;
            } else {
                store.generalStore.addMessage(500, 'Could not be marked as closed!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not be marked as closed!');
            return false;
        }
    }

    static async markEntireDeleteRequestClosed(id) {
        try {
            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/governance/management/request/close/${id}`,
                {},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Successfully marked as closed!');
                return true;
            } else {
                store.generalStore.addMessage(500, 'Could not be marked as closed!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not be marked as closed!');
            return false;
        }
    }

    static async getAllForms() {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/management/forms`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                return response.data;
            } else if (response.status === 204) {
                return [];
            } else {
                store.generalStore.addMessage(500, 'Could not get forms!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not get forms!');
            return false;
        }
    }

    static async getSingleForm(id) {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/governance/management/forms/${id}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                return response.data;
            } else {
                store.generalStore.addMessage(500, 'Could not get forms!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not get forms!');
            return false;
        }
    }

    static async createForm(form) {
        try {
            if (form.fields) {
                form.fields = form.fields.join(',');
            }

            const response = await trackPromise(axios.post(
                `${constants.apiUrl}/governance/management/forms`,
                form,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Form added successfully!');
                return true;
            } else {
                store.generalStore.addMessage(500, 'Could not add form!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not add form!');
            return false;
        }
    }

    static async updateForm(id, form) {
        try {
            delete form._id;
            delete form.__v;
            delete form.createdAt;
            delete form.updatedAt;
            if (form.fields) {
                form.fields = form.fields.join(',');
            }

            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/governance/management/forms/${id}`,
                form,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Form updated successfully!');
                return true;
            } else {
                store.generalStore.addMessage(500, 'Could not update form!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not update form!');
            return false;
        }
    }

    static async deleteForm(id) {
        try {
            const response = await trackPromise(axios.delete(
                `${constants.apiUrl}/governance/management/forms/${id}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Form deleted successfully!');
                return true;
            } else {
                store.generalStore.addMessage(500, 'Could not delete form!');
                return false;
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Could not delete form!');
            return false;
        }
    }
}

export default withRouter(Governance);
