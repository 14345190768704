// General storage for variables that are needed on several pages

import { decorate, observable, action } from 'mobx'

class CategoriesStore {
  categories

  constructor() {
    this.categories = {};
  }

  setCategories(list) {
    this.categories = list;
  }
}

decorate(CategoriesStore, {
  categories: observable,

  setCategories: action.bound,
})

export default CategoriesStore
