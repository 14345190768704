import React from "react";

import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import { trackPromise } from 'react-promise-tracker'

const apiUrl = constants.apiUrl;

class Relations extends React.Component {

  // Get all sugested relations
  static async getRelationSuggestions(token, page, num) {
      try {
          const response = await trackPromise(axios.get(
              `${apiUrl}/suggestion`,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  }
              }
          ));

          if (response.status === 200) {
              return response.data;
          }else if(response.status === 204){
            return {data:[], meta:{count: 0}};
          } else if (response.status === 401) {
              store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
          }
      } catch (e) {
          // console.log(e);
          store.generalStore.addMessage(500, 'Suggested relations could not be retrieved');
          return history.push('/not-found');
      }
  };


  static async populateRelationSuggestion(id, forceRefresh) {
      forceRefresh = true; // we need to refresh always otherwise there can be conflicts

      if (forceRefresh) { store.relationsStore.setRelation(null); }

      try {
          const response = await trackPromise(axios.get(
              `${apiUrl}/suggestion/${id}`,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${store.authStore.token}`
                  }
              }
          ));

          if (response.status === 200) {
              store.relationsStore.setRelation(response.data);
          } else if (response.status === 401) {
              store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
              store.relationsStore.setRelation(404);
          } else {
              store.relationsStore.setRelation(404);
          }
      } catch (e) {
          store.relationsStore.setRelation(404);
          console.log(e);
      }
  }

  static async resolve(action, id, incomingUid, targetUid, type, contextRef, label) {

    const values = {
      action,
      incomingUid,
      targetUid,
      result: {
        type,
        contextRef,
        label,
      }
    };

    try {
        const response = await trackPromise(axios.put(
            `${apiUrl}/suggestion/${id}`,
            values,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            history.push(constants.path.listRelations);
            store.generalStore.addMessage(200, 'Changes saved');
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
          store.generalStore.addMessage(500, 'Error: Resolution could not be applied');
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Error: '+e);
        console.log(e);
    }
  }

  static async resolveBulk(action, entries) {
    const values = { action, entries };

    try {
        const response = await trackPromise(axios.put(
            `${apiUrl}/suggestion/bulk`,
            values,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.relationsStore.setCheckedAll(false);
            store.generalStore.addMessage(200, 'Changes saved');
            return true;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
          store.generalStore.addMessage(500, 'Error: Resolutions could not be applied');
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Error: '+e);
        console.log(e);
    }
    return false;
  }
}

export default Relations;
