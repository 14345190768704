// Renders a single block if there is only one block shown in listView

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  control: {
    padding: theme.spacing.unit * 2
  }
});

class GridItem extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  onClickEntry(entry) {
    if (entry.hasOwnProperty("name")) {
      this.props.history.push(`/detailview/organization/${entry.uid}`);
    } else if (
      entry.hasOwnProperty("firstName") ||
      entry.hasOwnProperty("lastName")
    ) {
      this.props.history.push(`/detailview/person/${entry.uid}`);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Paper className={classes.control}>
          <Grid container>
            <ListItem>
              <ListItemText>{this.props.children}</ListItemText>
            </ListItem>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(withRouter(GridItem))
);
