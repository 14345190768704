// Constants for admin api calls and  page urls
const constants = {
	path: {
		listTenants: '/admin-dashboard',
		tenantDetail: '/admin-dashboard/detail/tenant',
		userDetail: '/admin-dashboard/detail/user',
	},
};

export default constants;
