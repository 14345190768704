// Render a list of search results

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from "@material-ui/core/Button";

import { Doughnut } from 'react-chartjs-2';

import { withRouter } from "react-router-dom";


// React Internationalization
import { withTranslation } from "react-i18next";

// Actions

import search from '../../../actions/search';

import constants from '../../../stores/constants';

const styles = theme => ({
  root: {
    paddingTop: 20
  },

});

class Incomplete extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  // componentDidUpdate() {
  // }
  completeClick() {
    search.doSearch(this.props.t('-Incomplete query-'), true);
    this.props.history.push(`${constants.path.search}/${this.props.t('-Incomplete query-')}`)
  }

  render() {
    const { classes, t, data } = this.props;

    let graphData = {};

    if (data) {
      if (!data.incompletePersons) data.incompletePersons = 0;
      if (!data.incompleteOrganizations) data.incompleteOrganizations = 0;

      const completePersons = (data.totalPersons) ? data.totalPersons - data.incompletePersons : 0;
      const completeOrganizations = (data.totalOrganizations) ? data.totalOrganizations - data.incompleteOrganizations : 0;

      graphData = {
        labels: [
          `${data.incompletePersons} ${t('dashboard incomplete persons')}`,
          `${completePersons} ${t('dashboard persons')}`,
          `${data.incompleteOrganizations} ${t('dashboard incompete organizations')}`,
          `${completeOrganizations} ${t('dashboard organizations')}`,
        ],
        datasets: [
          {
            label: '#',
            data: [
              data.incompletePersons,
              completePersons,
              data.incompleteOrganizations,
              completeOrganizations,
            ],
            backgroundColor: [
              '#fff',
              // '#2e8bbe',
              constants.color.main,
              '#fff',
              '#98cbe6',
              // 'rgba(255, 0, 0, 0.5)',
              // 'rgba(31, 126, 179, 1)',
              // 'rgba(255, 0, 0, 0.5)',
              // 'rgba(109, 206, 128, 1)',
            ],
            borderColor: [
              // '#2e8bbe',
              // '#2e8bbe',
              constants.color.main,
              constants.color.main,
              '#98cbe6',
              '#98cbe6',
              // 'rgba(31, 126, 179, 1)',
              // 'rgba(31, 126, 179, 0.8)',
              // 'rgba(109, 206, 128, 1)',
              // 'rgba(109, 206, 128, 0.8)',
            ],
            borderWidth: 2,
          },
        ],
        position: 'right',
      };
    }

    const graphOptions = {
      legend: {
        position: 'right',
      }
    };

    return (
      <div>
        <b>{t('Incomplete Entries')}</b><br />
        <Grid container direction="column" className={classes.root} spacing={8}>
          <Grid item xs={12} sm={12} md={12}>
              <Doughnut height={window.innerWidth >= constants.breakpoints.medium ? 75 : 50} data={graphData} options={graphOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <br />
            <Button onClick={this.completeClick.bind(this)} variant="contained" color="primary" id="incompleteData">
              {t("Complete data now")}
            </Button>
            <br /><br />
            ({t("Search for incomplete data")})
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(withRouter(Incomplete)));
