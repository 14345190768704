import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Measure from 'react-measure'
// Mobx
import { inject } from 'mobx-react'
import { toJS } from 'mobx'

import { Link } from "react-router-dom";


import moment from "moment";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';

import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';

import CloseIcon from '@material-ui/icons/Close';

// React multilanguage
import { withTranslation } from "react-i18next";

// Stores
import constants from '../../stores/constants';

// Actions
import MessageHandler from '../../actions/handleMessage';

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    icon: {
        color: constants.color.main, //'#2e8bbe',
    },
    typography: {
        paddingTop: 10,
        paddingLeft: 20
    },
    badge: {
      cursor: 'pointer',
      '& > *': {
        margin: theme.spacing.unit,
      },
    },
    root: {

    },
    rootFoldout: {

    },
    messagesBox: {
      backgroundColor: 'aliceblue',
      boxShadow: '0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.24)',
      // borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 5,
      display: 'flex',
      'flex-grow': 1,
      width: menuWidth,
      marginRight: theme.spacing.unit * 2,
    },
    messageDate: {
      fontSize: '10px',
      color: '#777',
      float: 'left',
      // paddingTop: theme.spacing.unit,
      // paddingBottom: theme.spacing.unit,
    },
    messageClose: {
      float: 'right',
      fontSize: 16,
      padding: theme.spacing.unit,
      color: '#777',
    },
    notification: {
      display: 'block',
      padding: theme.spacing.unit,
      textAlign: 'left',
      height: 50,
    },
    notificationBig: {
      display: 'block',
      padding: theme.spacing.unit,
      textAlign: 'left',
      height: 70,
    },
    notificationNone: {
      display: 'block',
      padding: theme.spacing.unit,
      textAlign: 'center',
      height: 25,
    },
    popper: {
      zIndex: 1300,
    },
});

const menuWidth = 300;

class MessagesDisplay extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
          open: false,
        };
        this.messageButton = React.createRef();
        this.inputContainer = React.createRef();
    }

    setFontSize(length) {
        if (length <= 8) {
            return {}
        } else if (length > 8 && length <= 12){
            return {fontSize: 12}
        } else {
            return {fontSize: 10}
        }
    }

    handleClick = () => {
      this.setState({
        open: !this.state.open,
      })
    }

    handleClickAway = () => {
      this.setState({
        open: false,
      })
    }

    markMessageAsSeen(data) {
      console.log('Message seen', data);

      if (data.id) {
        MessageHandler.markMessageAsSeen(data.id);
      } else if(data.body.messageId) {
        MessageHandler.markMessageAsSeen(data.body.messageId);
      }

      const settings = (
        this.props.generalStore.profileSettings
      )? toJS(this.props.generalStore.profileSettings) : {};

      console.log('settings', settings);

      if(settings.notifications) {
        for(let i=0; i<settings.notifications.length; i+=1) {
          if(settings.notifications[i].id === data.id) {
            settings.notifications[i].seen = true;
          }
        }

        console.log('Changed settings', settings);
        this.props.generalStore.setProfileSettings(settings);
        this.forceUpdate();
      }
    }

    render() {
        const { classes, t } = this.props;

        const defaultBadgeProps = {
          color: 'secondary',
          children: <MailIcon />,
        };

        let notifications = (
          this.props.generalStore.profileSettings
          && this.props.generalStore.profileSettings.notifications
        )? toJS(this.props.generalStore.profileSettings.notifications) : [];

        // if(notifications.length === 0) {
        //   notifications = [
        //     {
        //       seen: false,
        //       timestamp: '123456789000',
        //       body: {
        //         id: '1',
        //         eventName: 'SomeEvent',
        //       },
        //     },
        //     {
        //       seen: false,
        //       timestamp: '144567890000',
        //       body: {
        //         id: '2',
        //         eventName: 'SomeEvent2',
        //       },
        //     },
        //     {
        //       seen: false,
        //       timestamp: '164567890000',
        //       body: {
        //         id: '2',
        //         uid: 'uid1',
        //         eventName: 'SomeEvent3',
        //         type: 'person',
        //         firstName: 'Joe',
        //         lastName: 'Doe',
        //       },
        //     },
        //   ];
        // }

        let newNotifications = notifications.filter(entry => !entry.seen);

        newNotifications.sort((a, b) => parseInt(b.timestamp, 10) - parseInt(a.timestamp, 10));

        // console.log(newNotifications);

        const { open } = this.state;

        const rootClass = open ? classes.rootFoldout : classes.root



        const content = []

        const length = newNotifications.length;
        if(length > 0) {
          for(let i=0; i<length;i+=1) {
            if(newNotifications[i].body) {
              let linkData = false;
              const data = newNotifications[i].body;
              let messageContent = '';
              let notificationClass = classes.notification;

              if(typeof data === 'object') {
                if ('error' in data) {
                  messageContent = t(data.error);
                } else if('uid' in data && 'type' in data && 'firstName' in data) {
                  linkData = {
                    url: `${constants.path.detailviewPerson}/${data.uid}`,
                    label: `${data.firstName} ${data.lastName}`,
                  };

                  if('newContactDataCount' in data && data.newContactDataCount === 0) {
                     data.eventName = 'NoNewSocialMediaEntriesFound';
                  }

                  messageContent = <>{t(data.eventName)}<br/><Link to={linkData.url}>{linkData.label}</Link></>;
                notificationClass = classes.notificationBig;
              } else {
                  messageContent = t(data.eventName);
                }
              } else {
                messageContent = t(data);
              }

              const dateTime = moment(parseInt(newNotifications[i].timestamp, 10)).format('DD.MM.YY HH:mm:ss')
              content.push(<MenuItem key={`message${i}`} className={notificationClass}>
                <Typography>
                  {messageContent}
                  <CloseIcon onClick={this.markMessageAsSeen.bind(this, newNotifications[i])} className={classes.messageClose} />
                </Typography>
                <span className={classes.messageDate}>{dateTime}</span>
              </MenuItem>);
            }
          }
        } else {
          content.push(<MenuItem key={'noNew'} className={classes.notificationNone}>
            <Typography >{t('No new notifications at the moment')}</Typography>
          </MenuItem>);
        }

        return (
          <>
            <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
              <div className={rootClass} ref={(item) => { this.inputContainer = item; }}>
                <Measure
                  bounds
                  onResize={contentRect => {
                    this.setState({ searchFieldWidth: contentRect.bounds.width })
                  }}
                >
                  {({ measureRef }) => (
                    <div ref={measureRef} className={rootClass}>
                      <div className={this.props.classes.badge}>
                        <Badge onClick={this.handleClick} badgeContent={length} max={999} {...defaultBadgeProps} />
                      </div>
                    </div>
                  )}
                </Measure>
                <Popper
                      open={open}
                      transition={true}
                      anchorEl={this.inputContainer}
                      disablePortal={true}
                      placement="bottom-start"
                      className={classes.popper}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <div className={classes.messagesBox} style={{ width: menuWidth }}>
                            <MenuList style={{ width: menuWidth, maxWidth: menuWidth }}>
                              {content}
                            </MenuList>
                          </div>
                        </Fade>
                      )}
                  </Popper>
              </div>
            </ClickAwayListener>

          </>
        );

    }
}

export default withStyles(styles)(withTranslation("translations")(inject('generalStore')(MessagesDisplay)));
