import React from 'react';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import Collapse from '@material-ui/core/Collapse';

import { withStyles } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';
// import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

// React Internationalization
import { withTranslation } from "react-i18next";

// Constants
import constants from '../../stores/constants';

// Mobx
import { inject, observer } from 'mobx-react';

const styles = theme => ({
  all:{
    clear: 'both',
    position: 'fixed',
    zIndex: 100,
    width: '100%',
    maxWidth: 1545,
    background: '#fff',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  paper: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  // errorIcon: {
  //   color: constants.color.error,
  //   fontSize: '45px',
  //   margin: theme.spacing.unit,
  //   marginTop: theme.spacing.unit*2,
  // },
  // infoIcon: {
  //   color: constants.color.main,
  //   fontSize: '45px',
  //   margin: theme.spacing.unit,
  //   marginTop: theme.spacing.unit*2,
  // },
  warningIcon: {
    color: constants.color.error,
    fontSize: '30px',
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  text: {
    textAlign: 'left',
    flexGrow: 3,
  },
  textStyle: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    lineHeight: '30px',
  },
  button: {
    float: 'right',
    // margin: theme.spacing.unit*2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    textAlign: 'right'
  },
  buttonLogin: {
    float: 'right',
    color: constants.color.main,
  },

  gridButton: {
    float: 'right',
  },
});

class CustomizedBanner extends React.Component {
  _isMounted = false;
  state = {
    offline: false,
  };
  constructor(props) {
    super(props);

    window.addEventListener('offline', this.offline.bind(this));
    window.addEventListener('online', this.online.bind(this));

  }

  offline(){
    this.setState({
      offline: true,
    });
  }

  online(){
    this.setState({
      offline: false,
    });
  }

  handleClose = (event) => {
    this.setState({
      offline: false,
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    clearTimeout(this.hideMe);
    // this.hideMe = setTimeout(this.handleClose.bind(this),25000);

    const { classes, t } = this.props;

    let message = '';
    let currentIcon;

    message = t('offline message');
    currentIcon = <WarningIcon className={classes.warningIcon} />;

    let action;

    // if(this.state.offline === false) return null;

    return (
      <div id="offlineBanner" className={classes.all}>
      <Collapse in={this.state.offline}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container >
            <Grid item xs={2}>
              {currentIcon}
            </Grid>
            <Grid item xs={8} className={classes.text}>
              <Typography className={classes.textStyle}>
              {t(message)}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.gridButton} >
              <IconButton onClick={this.handleClose.bind(this)} className={classes.button}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
              {action}
            </Grid>
          </Grid>
        </Paper>
        <Divider />
      </Collapse>
      </div>
    );
  }
}



export default withTranslation("translations")(withStyles(styles)(inject('generalStore')(observer(CustomizedBanner))));
