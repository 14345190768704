import React from 'react';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Collapse from '@material-ui/core/Collapse';

import { withStyles } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

// React Internationalization
import { withTranslation } from "react-i18next";

// Constants
import constants from '../../stores/constants';

// Mobx
import { inject, observer } from 'mobx-react';

const styles = theme => ({
  all:{
    clear: 'both',
    position: 'fixed',
    zIndex: 100,
    width: '100%',
    maxWidth: 1545,
    background: '#fff',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit
  },
  errorIcon: {
    color: constants.color.error,
    fontSize: '45px',
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit*2,
  },
  infoIcon: {
    color: constants.color.main,
    fontSize: '45px',
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit*2,
  },
  warningIcon: {
    color: constants.color.error,
    fontSize: '45px',
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit*2,
  },
  text: {
    textAlign: 'left',
  },
  textStyle: {
    marginTop: theme.spacing.unit*2,
  },
  button: {
    float: 'right',
    margin: theme.spacing.unit*2
  },
  buttonLogin: {
    float: 'right',
    color: constants.color.main,
  },
});

class CustomizedBanner extends React.Component {
  _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }

  handleClose = (event) => {
    this.props.generalStore.hideBanner();
  };

  handleReload = (event) => {
    this.props.generalStore.hideBanner();
    window.location.reload(true);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    clearTimeout(this.hideMe);
    // this.hideMe = setTimeout(this.handleClose.bind(this),25000);

    const { classes, t } = this.props;

    let message = '';
    let currentIcon;

    if(this.props.generalStore.bannerContent.text){
      message = this.props.generalStore.bannerContent.text;

      let code = this.props.generalStore.bannerContent.code;

      if(code === 200){
        currentIcon = <ErrorIcon className={classes.errorIcon} />;
      } else if(code < 200) {
        currentIcon = <InfoIcon className={classes.infoIcon} />;
      } else if(code >=400) {
        currentIcon = <ErrorIcon className={classes.errorIcon} />;
      } else {
        currentIcon = <WarningIcon className={classes.warningIcon} />;
      }
    }

    let action;

    if(this.props.generalStore.bannerContent.type){
      if(this.props.generalStore.bannerContent.type === 'Redirect to Login'){
        action =  <Button onClick={this.handleClose.bind(this)} className={classes.button}>
                    <a href={constants.path.login} >{t('Go to login')}</a>
                  </Button>;
      }

      if(this.props.generalStore.bannerContent.type === 'reload'){
        action =  <Button onClick={this.handleReload.bind(this)} className={classes.button}>
                    {t('reload')}
                  </Button>;
      }
    }

    return (
      <div id="banner" className={classes.all}>
      <Collapse in={this.props.generalStore.showBanner}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container >
            <Grid item xs={2}>
              {currentIcon}
            </Grid>
            <Grid item xs={9} className={classes.text}>
              <Typography className={classes.textStyle}>
              {t(message)}
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Button onClick={this.handleClose.bind(this)} className={classes.button}>
                {t('Dismiss')}
              </Button>
              {action}
            </Grid>
          </Grid>
        </Paper>
        <Divider />
      </Collapse>
      </div>
    );
  }
}



export default withTranslation("translations")(withStyles(styles)(inject('generalStore')(observer(CustomizedBanner))));
