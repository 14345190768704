import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// React multilanguage
import { withTranslation } from "react-i18next";

// Mobx
import constants from '../../stores/constants';

// Components
import SinglePersInfo from './SinglePersInfo';

const styles = theme => ({
    outer: {
      paddingTop: 5,
    },
    firstGrid: {
    },
    secondGrid: {},
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    typography: {
        paddingLeft: 20,
        paddingTop: 10,
        color: constants.color.heading,
        fontSize: '14px',
    },
    divider: {
      backgroundColor: `1px solid ${constants.color.heading}`,
    },
});

class PersonalInformation extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, attributes, t } = this.props;
        const attrNames = Object.keys(attributes);
        let leftColumn = [];
        let rightColumn = [];

        if (attrNames.length === 1) {
            leftColumn.push(attrNames[0]);
        }
        else if (attrNames.length > 0) {
            leftColumn = attrNames.slice(0, (Math.ceil(attrNames.length / 2)))
            rightColumn = attrNames.slice(Math.ceil(attrNames.length / 2), (attrNames.length))
        }


        return (
            (attrNames.length > 0 &&
                (
                    <React.Fragment>
                        <Typography className={classes.typography} variant='body1' align='left'>
                            {t('Additional Info')}

                        </Typography>
                        <Divider variant="middle" className={classes.divider} />
                        <Grid container spacing={24} className={classes.outer}>
                            <Grid item xs={6} className={classes.firstGrid}>
                                {leftColumn.map(attribute => (
                                    <React.Fragment key={attribute}>
                                        <SinglePersInfo key={attribute} name={attribute} value={attributes[attribute]} /> <br />
                                    </React.Fragment>
                                ))}
                            </Grid>
                            <Grid item xs={6} className={classes.secondGrid}>
                                {(rightColumn.length > 0) &&
                                    rightColumn.map(attribute => (
                                        <React.Fragment key={attribute}>
                                            <SinglePersInfo key={attribute} name={attribute} value={attributes[attribute]} /> <br />
                                        </React.Fragment>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            )
        );
    }
}

export default withStyles(styles)(withTranslation("translations")(PersonalInformation));
