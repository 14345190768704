// import Geocode from "react-geocode";
import React, { Component } from "react";

// Mobx
import constants from "../../stores/constants";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import LocationCity from "@material-ui/icons/LocationCity";
import Pin from "@material-ui/icons/PinDrop";

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  outer: {
    paddingLeft: 20
  },
  fields: {
    margin: 30
  },
  contactValue: {
    position: "absolute",
    marginLeft: 10
  },
  icon: {
    color: constants.color.main,
    paddingTop: 10,
    paddingLeft: 0
  },
  iconDisabled: {
    color: constants.color.heading,
    paddingTop: 10,
    paddingLeft: 0
  },
  gridList: {
    height: 120,
    overflow: "hidden"
  },
  typography: {
    paddingLeft: 0,
    paddingTop: 10,
    overflowWrap: "break-word"
  },
  typographyDisabled: {
    paddingLeft: 0,
    paddingTop: 10,
    overflowWrap: "break-word",
    color: constants.color.heading
  },
  chip: {
    margin: theme.spacing.unit,
    maxWidth: "80%",
    overflow: "hidden"
  },
  addressBlock: {
    width: "80%"
  },
  pinWrapper: {
    width: "100%",
    float: "left",
    right: theme.spacing.unit * 3,
    position: "relative",
    marginTop: theme.spacing.unit * 0.7
  },
  pin: {
    width: "30px",
    height: "30px"
  },
  pinSpan: {
    color: constants.color.main
  }
});

class SingleAddress extends Component {
  state = {
    map: ""
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
    // this.generateMap();
  }

  setFontSize(length) {
    if (length <= 8) {
      return {};
    } else if (length > 8 && length <= 12) {
      return { fontSize: 12 };
    } else {
      return { fontSize: 10 };
    }
  }

  onPinClick(map) {
    console.log(map);
    window.open(map);
  }

  // generateMap() {
  //   const { address } = this.props;
  //   const input = `${address.street} ${address.streetNumber} ${address.zipcode}, ${address.city}, ${address.country}`;

    // Geocode.setApiKey(constants.credentials.googleGeolocationAPI);
    //
    // Geocode.fromAddress(input).then(
    //   response => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     const map = `http://maps.google.com/?q=${lat},${lng}`;
    //     this.setState({ map });
    //   },
    //   error => {
    //     this.setState({ map: false })
    //   }
    // );
  // }

  render() {
    const { classes, address } = this.props;
    const isOSPWA =
      "standalone" in window.navigator && window.navigator.standalone
        ? true
        : false;

    const currentIconClass = this.props.disabled
      ? classes.iconDisabled
      : classes.icon;
    const currentClass = this.props.disabled
      ? classes.typographyDisabled
      : classes.typography;
    let component = this.props.component ? this.props.component : false;

    let map = false;
    if(address.street && address.street.length > 2 &&
      (
        (address.zipcode &&address.zipcode.length > 3)
        ||
        (address.city &&address.city.length > 2)
      )
    ) {
      const input = `${address.street} ${address.streetNumber} ${address.zipcode}, ${address.city}, ${address.country}`;
      map = `http://maps.google.com/?q=${encodeURIComponent(input)}`;
      // console.log(map);

      // console.log(this.state.map);
    }

    return (
      <div key={`adr${address.uid}`} className={classes.outer}>
        <Grid container spacing={24}>
          <Grid item xs={1}>
            <LocationCity className={currentIconClass} />
          </Grid>
          <Grid item xs={6} className={classes.addressBlock}>
            <Typography
              className={currentClass}
              variant="body2"
              align="left"
              key={address.uid}
              data-uid={address.uid}
            >
              {address.street} {address.streetNumber} {address.unit} <br />
              {address.zipcode} {address.city} {address.district} <br />
              {address.countryCode} {address.country} {address.region}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {map !== false &&
              <div className={classes.pinWrapper}>
                {isOSPWA ? (
                  <span
                    className={classes.pinSpan}
                    onClick={this.onPinClick.bind(this, map)}
                  >
                    <Pin className={classes.pin} />
                  </span>
                ) : (
                    <a
                      target="_blank"
                      href={map}
                      rel="noopener noreferrer"
                    >
                      <Pin className={classes.pin} />
                    </a>
                  )}
              </div>
            }
          </Grid>
          {address.categories &&
            address.categories.length > 0 &&
            component === false && (
              <Grid item xs={4}>
                {address.categories.map(category => (
                  <React.Fragment key={category.uid}>
                    {" "}
                    <Chip
                      style={this.setFontSize(category.label.length)}
                      key={category.uid}
                      label={category.label}
                      className={classes.chip}
                    />{" "}
                    <br />{" "}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          {address.categories &&
            address.categories.length > 0 &&
            component !== false && (
              <React.Fragment>
                <Grid item xs={3}>
                  {address.categories.map(category => (
                    <React.Fragment key={category}>
                      {" "}
                      <Chip
                        style={this.setFontSize(category.label.length)}
                        key={category}
                        label={category.label}
                        className={classes.chip}
                      />{" "}
                      <br />{" "}
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={2}>
                  {[component]}
                </Grid>
              </React.Fragment>
            )}
          {(!address.categories || address.categories.length <= 0) && (
            <Grid item xs={5}>
              {component !== false && [component]}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(SingleAddress)
);
