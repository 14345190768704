// Handles all authentication actions except for the registration

// import React from "react";
// import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
// import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker'

// Mobx Stores
import store from '../stores';
import constants from '../stores/constants';
// import history from './history';

import axios from 'axios';

    
 export const revertEvent = async (eventId) => {
    try {
        const token = store.authStore.token;
        const response = await trackPromise(axios.post(
            `${constants.apiUrl}/revertEvent/${eventId}`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, 'Change reverted');
        } else {
            store.generalStore.addMessage(500, 'Change could not be reverted');
            console.log(response);
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Change could not be reverted');
        console.log(e);
    }
}
