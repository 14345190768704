// Storage for variables that are needed for the tutorials
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
// let lang = cookies.get('lang', { path: '/', domain: '.snazzycontacts.com' });

import { decorate, observable, action, toJS } from 'mobx'

class Store {
  activeTutorial
  tutorialStep
  tutorialState

  constructor() {
    // this.language = (lang) ? lang : 'de';

    this.activeTutorial = '';
    this.tutorialStep = 0;
    this.tutorialState = {};
  }

  setActiveTutorial(tutorialName) {
    this.activeTutorial = tutorialName;
  }

  setTutorialStep(step) {
    this.tutorialStep = step;
  }

  setTutorialState(obj) {
    let key;
    let tutorialState = toJS(this.tutorialState);
    for (key in obj) { tutorialState[key] = obj[key]; }
    this.tutorialState = tutorialState;
  }

  getValue(key) {
    let tutorialState = toJS(this.tutorialState);

    if (key in tutorialState) return tutorialState[key];
    return false;
  }

}

decorate(Store, {
  activeTutorial: observable,
  tutorialStep: observable,
  tutorialState: observable,

  setActiveTutorial: action.bound,
  setTutorialStep: action.bound,
  setTutorialState: action.bound,
})

export default Store
