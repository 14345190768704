// Render a list of search results

import React, { Component } from 'react';

import { Link } from "react-router-dom";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from "@material-ui/core/Button";

import EditIcon from "@material-ui/icons/Edit";

// import { Line } from 'react-chartjs-2';

// Components

import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    paddingTop: 30
  },
  integrationIcon: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    padding: theme.spacing.unit * 2,
  },
  recent: {
    textAlign: 'left',
  },
  logos: {
    maxWidth: '100%',
    maxHeight: 90,
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
  },
  logoLink: {
  },
  integrationsNumber: {
    fontSize: 40,
    width: 40,
    position: 'absolute',
    marginLeft: -30,
    marginTop: -18,
  },
  integrationsNumberHolder: {
    position: 'relative',
    width: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noActive: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  line: {
    paddingTop: theme.spacing.unit,
    // paddingBottom: theme.spacing.unit,
  },
});

class Integrations extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  render() {
    const { classes, t, data, statisticData } = this.props;

    const integrations = [];

    let length = 0;
    if (data) {
      length = data.length;
      for (let i = 0; i < length; i += 1) {
        integrations.push(
          <Link key={`link-${data[i].name}${i}`} className={classes.logoLink} to={`${constants.path.integrationsList}/${data[i]._id}`}>
            <img
              key={`${data[i].name}${i}`}
              className={classes.integrationIcon}
              alt={data[i].name[0]}
              title={data[i].name}
              src={data[i].logo}
            />
          </Link>
        );
      }
      if (integrations.length === 0) {
        integrations.push(
          <div key={'noActive'} className={classes.noActive}>
            {t('No active integrations yet')}<br /><br />
            <Link key={'noActiveLink'} to={`${constants.path.integrationsList}`}>
              <Button variant="contained" color="primary" id="integrationData">
                <EditIcon className={classes.editIcon} /> {t("Manage integrations")}
              </Button>
            </Link>
          </div>
        )
      }
    }


    let lines = [];
    if (statisticData) {
      let key;
      for (key in statisticData) {
        lines.push(<Grid key={key} className={classes.line} item xs={12} sm={12} md={12}>{t('x new entries from', { x: statisticData[key] })} {`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</Grid>)
      }
    }

    if (lines.length === 0) {
      lines.push(<Grid key={'noNew'} className={classes.line} item xs={12} sm={12} md={12}>{t('Currently no new entries from integrations')}</Grid>);
    }

    return (
      <div key={'grid'} >
        <b>{t('Active Integrations')}</b><br />
        <Grid className={classes.root} key={'g1'} item xs={12} sm={12} md={12}>
          {length > 0 && <div key={'title'} className={classes.integrationsNumberHolder}><div className={classes.integrationsNumber}>{length}</div>
            {t('Integrations')}</div>}
        </Grid>
        <Grid key={'g2'} item xs={12} sm={12} md={12}>
          <div key={'g2d'} className={classes.logos}>
            {integrations}
          </div>
        </Grid>
        <Grid key={'g3'} className={classes.recent} item xs={12} sm={12} md={12}>
          <b>{t('Recent')}:</b><br />
          {lines}
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(Integrations));
