// General storage for variables that are needed on webhookUrl pages

import { decorate, observable, action } from 'mobx'

class WebhookUrlsStore {
  webhookUrl
  webhookUrls

  constructor() {
    this.webhookUrl = {};
    this.webhookUrls = [];
  }

  setWebhookUrl(data) {
    this.webhookUrl = data;
  }

  setWebhookUrls(list) {
    this.webhookUrls = list;
  }
}

decorate(WebhookUrlsStore, {
  webhookUrl: observable,
  webhookUrls: observable,

  setWebhookUrl: action.bound,
  setWebhookUrls: action.bound,
})

export default WebhookUrlsStore
