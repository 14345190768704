// The outermost wrapper of our application, which also includes the multilanguage capabilities
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import updateServiceWorker from './actions/updateSW'

import { I18nextProvider } from "react-i18next";

import './index.css';

import i18n from "./i18n";

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<I18nextProvider i18n={i18n}>
                  <App />
                 </I18nextProvider>,
                 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

serviceWorker.register({onUpdate: updateServiceWorker});
