
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import { toJS } from 'mobx';

import { inject, observer } from "mobx-react";


// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import AccountCircle from '@material-ui/icons/AccountCircle';

import HelpIcon from '@material-ui/icons/HelpOutline';

// import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';

// React Internationalization
import { withTranslation } from "react-i18next";

// Components
import Menu from './Menu';
// import ContextMenu from './ContextMenu';
import Logo from './Logo';
import Search from './Search';

// Templates
import MessagesDisplay from '../../templates/MessagesDisplay'


import getContact from '../../../actions/getContact';

// Mobx Store
import constants from '../../../stores/constants';
import store from '../../../stores/';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: constants.color.header, // constants.color.main, //'#2e8bbe',
        maxWidth: '640px',
        left: 'auto',
        right: 'auto',
    },
    appBarNoShadow: {
        backgroundColor: constants.color.header, //constants.color.main, //'#2e8bbe',
        'box-shadow': 'none',
        maxWidth: '640px',
        left: 'auto',
        right: 'auto',
    },
    grow: {
        flexGrow: 1,
    },
    growBig: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    menuButton: {
        cursor: "pointer",
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 1,
    },
    menuToolbar: {
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 1,
    },


    drawerHeader: {
      backgroundColor: constants.color.dark,
      // background: 'radial-gradient(circle, rgba(31,126,179,1) 0%, rgba(31,126,179,1) 18%, rgba(21,106,159,1) 100%)',
      display: 'block',
      alignItems: 'center',
      padding: theme.spacing.unit,
      textAlign: 'center',
      maxHeight: 90,
      height: 90,
      overflow:'hidden',
      color: '#fff',
    },
    avatar: {
      display: 'inline-block',
      marginTop: theme.spacing.unit,
      lineHeight: '50px',
      backgroundColor: '#fff',
      color: constants.color.main,
      height: 50,
      width: 50,
      // filter: 'grayscale(50%)',
    },
    name: {
      width: '100%',
      textAlign: 'center',
      marginTop: theme.spacing.unit,
      color: '#fff',
    },
    icon: {
      marginRight: theme.spacing.unit * 2,
      display: 'block',
      marginRop: 2,
      float: 'left',
    },
    gridItemLeft: {
      minWidth: 256,
      maxWidth: 256,
    },
    gridItem: {
      flexShrink: 3,
      flexGrow: 3,
      maxWidth: '100vw',
    },
});

class ButtonAppBar extends Component {
    state = {
      avatarError: false,
    };

    constructor(props) {
        super(props);
        this.onClickShowProfile = this.onClickShowProfile.bind(this);
        getContact.populateProfile();

        window.addEventListener('resize', this.handleResize.bind(this));
        document.addEventListener("fullscreenchange", this.handleResize.bind(this));
        document.addEventListener("visibilitychange", this.handleResize.bind(this));
    }

    handleResize(){
      this.forceUpdate();
    }

    async onClickShowProfile() {
        if (!store.generalStore.profile || !store.generalStore.profile[0] || !store.generalStore.profile[0].uid){
        await getContact.populateProfile(false);
        }
        const uid = toJS(store.generalStore.profile[0].uid);
        this.props.history.push(`${constants.path.detailviewPerson}/${uid}`);
        // this.props.history.push('/profile');
    }

    onClickHelpMe() {
      const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;
      if(isOSPWA) {
        window.open(constants.path.help, '_system');
      } else if(window.innerWidth > constants.breakpoints.small) {
        window.open(constants.path.help, '_blank');
      } else {
        window.location.href = constants.path.help;
      }
    }

    avatarError(e) {
      this.setState({
        avatarError: true,
      })
    }

    render() {
        let shadow;
        switch (this.props.history.location.pathname) {
            // case constants.path.listPersons:
            //     shadow = false;
            //     break;
            // case constants.path.listOrganizations:
            //     shadow = false;
            //     break;
            // case constants.path.listAll:
            //     shadow = false;
            //     break;
            default:
                shadow = true;
        }

        const { classes } = this.props;

        const desktop = (window.innerWidth >= constants.breakpoints.max);
        const medium = (!desktop && window.innerWidth >= constants.breakpoints.medium);

        // let initials = '';
        // let firstName = '';
        // let lastName = '';
        // let image = null;


        // let profile = toJS(this.props.generalStore.profile);
        // if(Array.isArray(profile) && profile[0]) profile = profile[0];

        // if (profile && profile && 'firstName' in profile) {
        //   initials = `${profile.firstName[0].toUpperCase()}${profile.lastName[0].toUpperCase()}`;
        //   firstName = profile.firstName;
        //   lastName = profile.lastName;
        //   image = profile.photo;
        // }

        // { (desktop || medium) &&
        // <Grid item xs={2}  sm={2} className={classes.gridItemLeft}>
        //   <div className={classes.drawerHeader}>
        //     <Link to={profile ? `${constants.path.detailviewPerson}/${profile.uid}` : ''}>
        //       <Avatar className={classes.avatar} src={null} onError={this.avatarError.bind(this)}>
        //         {initials}
        //       </Avatar>
        //       <div className={classes.name}>{firstName} {lastName}</div>
        //     </Link>
        //   </div>
        // </Grid>
        // }

        // this.state.avatarError? null : image
        const currentMax = (desktop)? '1545px' : (window.innerWidth >= constants.breakpoints.medium? '100vw': '640px');

        // maxWidth: 'calc(100vw - 270px)'
        return (
            <div className={classes.root + ' ' + this.props.className}>
                <AppBar position="fixed" className={shadow ? classes.appBar : classes.appBarNoShadow}
                style={{maxWidth: currentMax}}
                >
                    <Grid container className={classes.gridContainer}>

                      <Grid item xs={12}  sm={(medium)? 12 : 12} className={classes.gridItem} style={(desktop || medium)? {}:{}}>
                        <Logo />
                        {store.authStore.hasLogin === true && !desktop && !medium &&
                        <Toolbar className={classes.menuToolbar} >
                            {!desktop && !medium && <Menu />}

                            <div className={(desktop || medium)? classes.growBig : classes.grow}>
                                <Search />
                            </div>
                            {/* <ContextMenu></ContextMenu> */}
                            {/* <IconButton aria-haspopup="true" color="inherit" aria-label="Menu">*/}
                            <MessagesDisplay />
                            <HelpIcon className={classes.menuButton} onClick={this.onClickHelpMe.bind(this)} />
                            {!desktop && !medium &&
                            <AccountCircle className={classes.menuButton} onClick={this.onClickShowProfile.bind(this)} ></AccountCircle>
                            }
                            {/* </IconButton>*/}

                        </Toolbar>
                        }
                      </Grid>
                    </Grid>
                </AppBar>
            </div>
        )
    }
}


ButtonAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(ButtonAppBar);
export default withStyles(styles)(withTranslation("translations")(withRouter(inject('generalStore')(observer(ButtonAppBar)))));
