import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import BusinessIcon from '@material-ui/icons/Business';
import PremiumIcon from '@material-ui/icons/LocalActivity';
import Contacts from '@material-ui/icons/Contacts';
import AddIcon from '@material-ui/icons/AddCircle';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

// Mobx
import { inject, observer } from 'mobx-react';
import { action, decorate } from 'mobx';
import history from '../../actions/history';

// React Internationalization
import { withTranslation } from 'react-i18next';

// Components
import constants from '../../stores/constants';

const styles = (theme) => ({
	paper: {
		background: 'transparent',
		'box-shadow': 'none',
		overflow: 'hidden'
	},
	button: {
		margin: theme.spacing.unit,
		minWidth: '280px',
		justifyContent: 'left',
		'&:disabled': {
			backgroundColor: constants.color.inactive
		}
	},
	iconText: {
		marginLeft: theme.spacing.unit * 1
	},
	fabstyle: {
		margin: 0,
		top: 'auto',
		right: 50,
		bottom: 75,
		left: 'auto',
		// position: 'fixed',
		position: 'absolute',
		zIndex: 50,
		backgroundColor: constants.color.main
	},
	fabstyleDisabled: {
		margin: 0,
		top: 'auto',
		right: 50,
		bottom: 75,
		left: 'auto',
		// position: 'fixed',
		position: 'absolute',
		zIndex: 50,
		backgroundColor: '#eee',
		'pointer-events': 'all !important'
	},
	fabstyleFixed: {
		margin: 0,
		top: 'auto',
		right:
			window.innerWidth > 640 && window.innerWidth < constants.breakpoints.medium
				? (window.innerWidth - 640) / 2 + 40
				: 40,
		bottom: 90,
		left: 'auto',
		position: 'fixed',
		// position: 'absolute',
		zIndex: 50,
		backgroundColor: constants.color.main
	}
});

class EditButton extends Component {
	_isMounted = false;

	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	state = {
		// value: 0,
		anchorEl: null,
		value: 'persons',
		errorDialog: false
	};

	componentDidMount() {
		this._isMounted = true;
	}

	handleChange = (event, value) => {
		if (this._isMounted) this.setState({ value });
	};

	onClickShowProfile() {
		this.showComponent = 'profile';
		if (this._isMounted) this.setState({ value: 'me' });
		this.props.history.push(constants.path.profile);
	}

	onClickAddContact(type) {
		if (type === 'person') this.props.tutorialStore.setTutorialState({ addContact: true });

		this.setState({
			anchorEl: null
		});

		this.props.history.push(`${constants.path.create}/${type}`);
	}

	onClickImport() {
		this.setState({
			anchorEl: null
		});

		this.props.history.push(constants.path.importExport);
	}

	onClickFab = (event) => {
		const disabled =
			window.location.href.indexOf(constants.path.edit) > -1 ||
			window.location.href.indexOf(constants.path.create) > -1;
		if (disabled) return false;

		if (this._isMounted)
			this.setState({
				anchorEl: event.currentTarget
			});
		this.props.tutorialStore.setTutorialState({ fabOpen: true });
	};

	onClickFabClose = () => {
		if (this._isMounted)
			this.setState({
				anchorEl: null
			});
		this.props.tutorialStore.setTutorialState({ fabOpen: false });
	};

	onPremiumClick = () => {
		history.push(constants.path.payment);
	};

	// openFileDialog = () => {
	//   this.refs.fileUpload.click();
	// }

	// fileSelected = (event) => {
	//   const filename = event.target.value.match(/\.csv$/im);
	//   if(filename === null){
	//     this.setState({
	//         errorDialog: true,
	//     });
	//     return false;
	//   }
	//   FileUploader.upload(event.target.files);
	//   if (this._isMounted)
	//       this.setState({
	//           anchorEl: null,
	//       });
	// }

	handleErrorDialogClose = () => {
		if (this._isMounted)
			this.setState({
				errorDialog: false
			});
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const { classes, isDetailView } = this.props;
		const { personsAmount, organizationsAmount } = this.props.generalStore;
		const { premium } = this.props.authStore.userData;
		let activeButtonPersons = false;
		let activeButtonOrganizations = false;
		let activeButton = false;

		if (personsAmount >= 500 && !premium) {
			activeButtonPersons = true;
			activeButton = true;
		}

		if (organizationsAmount >= 500 && !premium) {
			activeButtonOrganizations = true;
			activeButton = true;
		}

		const { t } = this.props;
		let className = classes.fabstyle;
		let disabled = false;

		if (isDetailView) {
			className = classes.fabstyleFixed;
		} else if (
			window.location.href.indexOf(constants.path.edit) > -1 ||
			window.location.href.indexOf(constants.path.create) > -1
		) {
			disabled = true;
			className = classes.fabstyleDisabled;
		}

		// const canUpload = (this.props.generalStore.uploading === false || this.props.generalStore.uploading >= 100) ? true : false;
		const canUpload = true;
		return (
			<div>
				<Fab
					id='addbutton'
					disabled={disabled}
					className={className}
					aria-owns={open ? 'addContactPopover' : undefined}
					aria-haspopup='true'
					aria-label='Add a new entry'
					onClick={this.onClickFab.bind(this)}
					color='primary'
				>
					<AddIcon />
				</Fab>
				<Popover
					open={open}
					anchorEl={anchorEl}
					onClose={this.onClickFabClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					PaperProps={{
						classes: {
							root: classes.paper
						}
					}}
				>
					<Button
						disabled={activeButtonPersons}
						variant='contained'
						id='fabOpen'
						color='primary'
						className={classes.button}
						onClick={this.onClickAddContact.bind(this, 'person')}
					>
						<PersonPinIcon className={classes.leftIcon} />
						<span className={classes.iconText}>{t('Add Person')}</span>
					</Button>
					<br />
					<Button
						disabled={activeButtonOrganizations}
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.onClickAddContact.bind(this, 'organization')}
					>
						<BusinessIcon className={classes.leftIcon} />
						<span className={classes.iconText}>{t('Add Organization')}</span>
					</Button>
					<br />
					<Button
						disabled={!canUpload || activeButton}
						variant='contained'
						onClick={this.onClickImport.bind(this)}
						color='primary'
						className={classes.button}
					>
						<Contacts className={classes.leftIcon} />
						<span className={classes.iconText}>{t('Import')}</span>
						<input ref='fileUpload' type='file' style={{ display: 'none' }} onChange={this.fileSelected} />
					</Button>
					<br />
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.onPremiumClick.bind(this)}
					>
						<PremiumIcon className={classes.leftIcon} />
						<span className={classes.iconText}>{t('SNAZZY Premium')}</span>
					</Button>
				</Popover>
				<Dialog open={this.state.errorDialog} aria-labelledby='alert-dialog-title'>
					<DialogTitle id='alert-dialog-title'>
						{t('You can only upload CSV-Files at the Moment.')}
					</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleErrorDialogClose} color='primary' autoFocus>
							{t('Ok')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
EditButton.propTypes = {
	classes: PropTypes.object.isRequired
};

decorate(EditButton, {
	onClickShowPersons: action,
	onClickShowOrganizations: action
});

export default withStyles(styles)(
	withRouter(
		withTranslation('translations')(
			inject('personStore', 'organizationStore', 'generalStore', 'tutorialStore', 'authStore')(
				observer(EditButton)
			)
		)
	)
);
