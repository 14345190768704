// Render a list of search results

import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import store from '../../../stores'

import Dropzone from 'react-dropzone';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';


// React Internationalization
import { withTranslation } from "react-i18next";


// Components

import constants from '../../../stores/constants';

// Actions

import FileUploader from '../../../actions/fileUpload';


const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  dropIcon: {
    color: constants.color.main,
    opacity: 0.5,
    width: 100,
    height: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    paddingTop: 15
  }

});



class ImportDrop extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  async handleFiles(acceptedFiles) {

    if (acceptedFiles.length !== 1 || !(/\.(xlsx|xls|ods|csv|xlsm|xlsb|uos|vcf|csv)$/im).test(acceptedFiles[0].name)) {
      store.generalStore.addMessage(500, 'Invalid File Format!')
    }

    let type = 'excel';
    if (acceptedFiles[0].name.endsWith('csv')) type = 'csv'
    else if (acceptedFiles[0].name.endsWith('vcf')) type = 'vcard'

    await FileUploader.upload([acceptedFiles[0]], type)
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div id="importDrop">
        <b>{t('Quick Import')}</b><br />
        <Grid container className={classes.root} spacing={8}>
          <Grid item xs={12} sm={12} md={12}>

            <Dropzone onDrop={this.handleFiles.bind(this)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <GetAppIcon className={classes.dropIcon} />
                    <p style={{ textAlign: 'center' }}>{t('Drop a file here')}</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(ImportDrop));
