import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

// Material UI
import { withStyles } from '@material-ui/core/styles';
// import Grade from '@material-ui/icons/Grade';
// import Group from '@material-ui/icons/Group';
// import BusinessIcon from '@material-ui/icons/Business';
import Contacts from '@material-ui/icons/Contacts';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// Mobx
import { observer } from 'mobx-react';
import { extendObservable, action, decorate } from 'mobx';

// React Internationalization
import { withTranslation } from "react-i18next";

import constants from '../../stores/constants';
import adminConstants from './constants';

const styles = theme => ({
    root: {
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        height: '65px',
        maxWidth: '640px',
        left: 'auto',
        right: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    menuButton: {
        // color: '#1f7eb3 !important',
        color: constants.color.main,
        colorPrimary: constants.color.main,
    },
    icon: {
        color: constants.color.main,
        colorPrimary: constants.color.main,
    },
    menueLabel: {
      fontSize: 12,
    },
});

class FooterMenu extends Component {
    constructor(props) {
        super(props);

        extendObservable(this, {
            showAll: false,
        });

        this.onClickShowAll = this.onClickShowAll.bind(this);
    }

    state = {
        anchorEl: null,
        value: ''
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentDidMount(){
      let value = this.props.history.location.pathname;
      value = value.split('/');
      if(value[value.length - 1] === 'confirmed' || value[value.length - 1] === 'unconfirmed'){
        value = `${value[value.length - 2]}/${value[value.length - 1]}`;
      } else {
        value = value[value.length - 1];
      }

      this.setState({ value: `/${value}` });

    }

    componentDidUpdate(){
      let value = this.props.history.location.pathname;
      value = value.split('/');
      if(value[value.length - 1] === 'confirmed' || value[value.length - 1] === 'unconfirmed'){
        value = `${value[value.length - 2]}/${value[value.length - 1]}`;
      } else {
        value = value[value.length - 1];
      }

      value = `/${value}`;

      if(this.state.value !== value) this.setState({ value });
    }

    onClickShowAll() {
      this.props.history.push(adminConstants.path.listTenants);
    }

    onClickShowConfirmed(){
      this.props.history.push(`${adminConstants.path.listTenants}/confirmed`);
    }

    onClickShowUnconfirmed(){
      this.props.history.push(`${adminConstants.path.listTenants}/unconfirmed`);
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;


        return (
            <div className={classes.root}>
                <BottomNavigation value={this.state.value} onChange={this.handleChange} showLabels className={classes.stickToBottom}>
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowAll.bind(this)} label={<span className={classes.menueLabel}>{t('All tenants')}</span>} value={adminConstants.path.listTenants} icon={<Contacts />} />
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowConfirmed.bind(this)} label={<span className={classes.menueLabel}>{t('Confirmed')}</span>} value={`${adminConstants.path.listTenants}/confirmed`} icon={<Contacts />} />
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowUnconfirmed.bind(this)} label={<span className={classes.menueLabel}>{t('Unconfirmed')}</span>} value={`${adminConstants.path.listTenants}/unconfirmed`} icon={<Contacts />} />
                </BottomNavigation>
            </div>
        )
    }
}
FooterMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

decorate(FooterMenu, {
    onClickShowAll: action,
    onClickShowConfirmed: action,
    onClickShowUnconfirmed: action,
})

export default withStyles(styles)(withRouter(withTranslation("translations")(observer(FooterMenu))));
