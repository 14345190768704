// General storage for variables that are needed on the dashboard

import { decorate, observable, action } from 'mobx'

class GovernanceStore {
  data
  graph
  deleteRequests
  itemSizes
  offset
  filter

  constructor() {
    this.data = {};
    this.graph = {};
    this.deleteRequests = [];
    this.offset = 0;
    this.filter = 'open';
    this.itemSizes = {}
  }

  setDashboard(data) {
    this.data = data;
  }

  setGraph(data) {
    if (data.data) {
      this.graph = data.data;
    } else {
      this.graph = data;
    }
  }

  setDeleteRequests(data) {
    this.deleteRequests = data;
  }

  setOffset(x) {
    this.offset = x;
  }

  setFilter(data) {
    this.filter = data;
  }

  setItemSize(id, x) {
    this.itemSizes[id] = x;
  }
}

decorate(GovernanceStore, {
  data: observable,
  graph: observable,
  deleteRequests: observable,
  offset: observable,
  filter: observable,
  itemSizes: observable,

  setDashboard: action.bound,
  setGraph: action.bound,
  setDeleteRequests: action.bound,
  setOffset: action.bound,
  setFilter: action.bound,
  setItemSize: action.bound,
})

export default GovernanceStore
