import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure'

// Material UI
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import DirectionsIcon from '@material-ui/icons/Directions';

// Mobx
import history from '../../../actions/history';
import constants from '../../../stores/constants';
import store from '../../../stores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react'

// Actions
import search from '../../../actions/search';

// Templates
import SingleSearchDisplay from '../../templates/SingleSearchDisplay'

// React Internationalization
import { withTranslation } from "react-i18next";

import insane from 'insane';

const styles = theme => ({
  root: {
    // padding: '0px 1px', // 2px 4px
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    'flex-grow': 1,
    // 'background-color': constants.color.light,
    backgroundColor: 'aliceblue',
    borderRadius: '6px',
    border: 'none',
  },
  rootFoldout: {
    // padding: '0px 1px', // 2px 4px
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    'flex-grow': 1,
    // 'background-color': constants.color.light,
    backgroundColor: 'aliceblue',
    borderRadius: '6px 6px 0px 0px',
    border: 'none',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    // color: '#fff',
    color: '#333',
    border: 'none',
    width: '100%',
    zIndex: '1100'

  },
  iconButton: {
    padding: 8,
    // color: '#fff',
    color: constants.color.dark,
  },
  suggestionBox: {
    backgroundColor: 'aliceblue',
    boxShadow: '0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.24)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    'flex-grow': 1
  },
  search: {
    // color: '#fff',
    color: '#333',
    border: 'none',
    width: '100%', // 80%
    height: '40px',
    margin: '5px',
    padding: '8px',
    'line-height': '40px',
    'font-size': '16px',
    'border-radius': '6px',
    'box-sizing': 'border-box',

    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      // color: 'rgba(255,255,255,0.5)'
      color: 'rgba(255,255,255,0.9)'
    },

    '&:focus': {
      border: 'none',
      outline: 'none',
      '-webkit-box-shadow': 'none',
      'box-shadow': 'none',
    }
  },

  paper: {
    marginRight: theme.spacing.unit * 2,
  },
});

const language = store.generalStore.language === 'de' ? 'de' : 'en'

let commandList
if (language === 'de') {
  commandList = Object.keys(constants.commandTranslations.de);
  commandList = commandList.concat(Object.keys(constants.commandTranslations.de.logicOperators));
} else {
  commandList = Object.keys(constants.commandTranslations.en)
  commandList = commandList.concat(Object.keys(constants.commandTranslations.en.logicOperators));
}
commandList.splice(commandList.indexOf('logicOperators'), 1)


function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value, chipValues) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const escapedChipValues = escapeRegexCharacters(chipValues.trim())

  if (escapedValue === '') {
    return {};
  }
  const regex = new RegExp('^' + escapedValue, 'i');

  // Get search results
  search.doQuickSearch(escapedValue, escapedChipValues);

  // Get notes search results
  search.doQuickNotesSearch(escapedValue, escapedChipValues);

  // get saved Searches by name
  const savedSearches = toJS(store.generalStore.savedSearches)
  let allSearches = []
  if (savedSearches && 'own' in savedSearches) {
    allSearches = savedSearches.own.concat(savedSearches.tenant)
  }
  allSearches = allSearches.filter(item => regex.test(item.name))
  if (allSearches.length > 2) allSearches = allSearches.slice(0, 2)

  // Get commands
  // Grab only the last term
  let commandValue = (escapedValue.match(/(^| |-)\w*$/) || [])[0]
  if (commandValue) {
    commandValue = commandValue.replace(/^[-]/, '').trim()
  } else {
    commandValue = 'xxxxxxx'
  }
  const commandRegex = new RegExp('^' + commandValue, 'i')

  let commandResults = commandList.filter(item => commandRegex.test(item))
  if (commandResults.length > 3) commandResults = commandResults.slice(0, 3)

  const result = {
    'commands': commandResults,
    'savedSearches': allSearches
  }

  return result
}

const comRegex = new RegExp(`^(([^\\s]+:(([\\s]?([^\\s"])+ )|"[^"]+"))|${Object.keys(constants.commandTranslations[language].logicOperators)[0]} |${Object.keys(constants.commandTranslations[language].logicOperators)[1]} )`)

class MenuListComposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      value: '',
      suggestions: [],
      open: false,
      searchFieldWidth: -1,
      chipValue: ''
    };
    this.searchInput = React.createRef();
    this.inputContainer = React.createRef();
  }

  onSuggestionSelected(type, value) {
    if (type === 'searchResult') {
      this.setState({
        searchValue: '',
        open: false
      });

      if ('firstName' in value || 'lastName' in value) {
        history.push(constants.path.detailview + '/person/' + value.uid)
      } else {
        history.push(constants.path.detailview + '/organization/' + value.uid)
      }
    }

    if (type === 'notesSearchResult') {
      this.setState({
        notesSearchValue: '',
        open: false
      });

      if (value.type === 'person') {
        history.push(constants.path.detailview + '/person/' + value.entryUid + '#' + value.uid)
      } else {
        history.push(constants.path.detailview + '/organization/' + value.entryUid + '#' + value.uid)
      }
    }

    if (type === 'command') {
      if (value in constants.commandTranslations[language].logicOperators) {
        const newValue = `${this.state.chipValue} ${value}`.trim()
        this.setState({
          searchValue: '',
          open: false,
          chipValue: newValue
        })
      } else {
        let tempValue
        if (this.state.searchValue[0] === '-') {
          tempValue = `-${value}:`
        } else {
          tempValue = `${value}:`
        };
        this.setState({
          searchValue: tempValue.trim(),
          open: false
        })
      }
      this.searchInput.focus();
    }

    if (type === 'savedSearch') {
      this.setState({
        searchValue: '',
        open: false,
        chipValue: value.searchTerm
      })
      this.searchInput.focus();
    }
  }


  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  triggerSearch() {
    let value = `${this.state.chipValue} ${this.state.searchValue}`.trim()
    value = value.replace(/\?/img, ' ').replace(/#/img, ' ');
    if (value.replace(/[\s]+/gis) === '') return false;

    history.push(`/search/${value}`);
    const redirect = (typeof this.props.redirect === 'undefined') ? true : this.props.redirect;
    search.doSearch(this.state.searchValue, redirect);
    this.setState({
      searchValue: '',
      chipValue: '',
      open: false
    });
  }

  handleClick = () => {
    this.triggerSearch();
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.triggerSearch();
    }
  };

  handleKeyDown = event => {
    if (event.key === 'Backspace' && !this.state.searchValue && this.state.chipValue) {
      const reggie = new RegExp(`(^| )(${(Object.keys(constants.commandTranslations[language].logicOperators))[0]}|${(Object.keys(constants.commandTranslations[language].logicOperators))[1]}|(-?\\w+:\\s?(([^\\s]+)|(".*")))|)$`);
      let tempChipValue = this.state.chipValue;
      tempChipValue = tempChipValue.replace(reggie, '');
      this.setState({
        chipValue: tempChipValue
      })
    } else if(event.key === 'Tab') {
      const quickSearchResults = toJS(store.generalStore.quickSearchResults);

      const quickNotesSearchResults = toJS(store.generalStore.quickNotesSearchResults);

      let notesResult;
      if(Array.isArray(quickNotesSearchResults) && quickNotesSearchResults.length === 1){
        notesResult = quickNotesSearchResults[0].content;
      }

      let result;
      if(Array.isArray(this.state.suggestions.commands) && this.state.suggestions.commands.length >= 1) {
        const parts = this.state.searchValue.split(':');
        if(parts.length > 1) {
          for(const index in this.state.suggestions.commands) {
            const intIndex = parseInt(index, 10);
            if(this.state.suggestions.commands[index] === parts[0]) {
              if(intIndex < (this.state.suggestions.commands.length - 1)) {
                result = `${this.state.suggestions.commands[intIndex + 1]}:`;
              } else {
                result = `${this.state.suggestions.commands[0]}:`;
              }
              break;
            }
          }
        } else {
          result = `${this.state.suggestions.commands[0]}:`;
        }
      } else if(Array.isArray(quickSearchResults) && quickSearchResults.length === 1){
        if('name' in quickSearchResults[0]) {
          result = quickSearchResults[0].name;
        } else {
          result = `${quickSearchResults[0].firstName} ${quickSearchResults[0].lastName}`;
        }
      }

      if(result === 'UND:') {
        result = 'UND';
      } else if(result === 'AND:') {
        result = 'AND';
      }
      if(result) {
        this.setState({
          searchValue: result,
          notesSearchValue: notesResult,
        });

        setTimeout(()=>{this.searchInput.focus()}, 300);
      }

    }
  };

  handleChipClick = (commandString, data) => {
    this.setState({
      chipValue: commandString
    })
  }

  updateSearchValue(evt) {
    let value = evt.target.value
    let chipValue;
    const match = value.match(comRegex);

    if (match) {
      value = '';
      chipValue = `${this.state.chipValue} ${match[0]}`
    }

    if (chipValue) {
      this.setState({
        chipValue: chipValue.trim()
      })
    }

    const suggestions = getSuggestions(value, this.state.chipValue);
    const open = (suggestions.searchResults || suggestions.commands || suggestions.savedSearches) ? true : false

    this.setState({
      searchValue: value,
      suggestions,
      open,
    });


  };

  handleClickAway = () => {
    this.setState({
      open: false
    })
  };

  highlite(regex, length, s) {
    if (typeof s !== 'string') return s;

    let ret = insane(s, {
      allowedTags: [],
    });

    // let length = matches.length;
    let i;
    for (i = 0; i < length; ++i) {
      ret = String.prototype.replace.call(ret, regex, '[$1]');
    }
    // eslint-disable-next-line
    ret = String.prototype.replace.call(ret, /[\[]+/img, '<b>');
    // eslint-disable-next-line
    ret = String.prototype.replace.call(ret, /[\]]+/img, '</b>');

    const pos = ret.indexOf('<b>');
    if(pos > 5) ret = ret.substr(pos - 5);

    return <div dangerouslySetInnerHTML={{ __html: ret }} />;
  }

  render() {

    const { t } = this.props;
    const { classes } = this.props;
    const { suggestions, open } = this.state

    const rootClass = open ? classes.rootFoldout : classes.root

    let content = []

    if (store.generalStore.quickSearchResults && store.generalStore.quickSearchResults.length > 0) {
      content.push(<li key="searchResultHeader">
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"

        >
          {t('search results')}
        </Typography>
      </li>)
      content.push(<Divider key="searchResultSub" variant="middle" />)
      const searchResults = store.generalStore.quickSearchResults.map(item => { return <MenuItem onClick={this.onSuggestionSelected.bind(this, 'searchResult', item)} key={item.uid} >{(item.name) ? item.name : (`${item.firstName} ${item.lastName}`).trim()}</MenuItem> })
      content = content.concat(searchResults);
    }

    if (store.generalStore.quickNotesSearchResults && store.generalStore.quickNotesSearchResults.length > 0) {
      const quickNotesSearchResults = toJS(store.generalStore.quickNotesSearchResults);

      const searchValue = this.state.searchValue.trim().replace(/[\s]+/g,'|');
      const length = searchValue.split('|').length;
      const regex = new RegExp('(' + searchValue + ')', 'img');

      content.push(<li key="searchResultHeaderNotes">
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"

        >
          {t('notes search results')}
        </Typography>
      </li>)
      content.push(<Divider key="searchResultSubNotes" variant="middle" />)
      const searchResults = quickNotesSearchResults.map(item => {
        const content = this.highlite(regex, length, item.content);
        return <MenuItem onClick={this.onSuggestionSelected.bind(this, 'notesSearchResult', item)} key={`notes${item.entryUid}-${Math.random()}`} >{content}</MenuItem>
      })
      content = content.concat(searchResults);
    }

    if (suggestions.commands && suggestions.commands.length > 0) {

      content.push(<li key="commandHeader">
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"

        >
          {t('commands')}
        </Typography>
      </li>)
      content.push(<Divider key="commandSub" variant="middle" />)
      const commands = suggestions.commands.map(item => { return <MenuItem onClick={this.onSuggestionSelected.bind(this, 'command', item)} key={item} >{item}</MenuItem> })
      content = content.concat(commands);
    }

    if (suggestions.savedSearches && suggestions.savedSearches.length > 0) {
      content.push(<li key="savedSearchHeader">
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"
        >
          {t('Saved searches')}
        </Typography>
      </li>)
      content.push(<Divider key="savedSearchSub" variant="middle" />)
      const savedSearches = suggestions.savedSearches.map(item => { return <MenuItem onClick={this.onSuggestionSelected.bind(this, 'savedSearch', item)} key={item.name}>{item.name}</MenuItem> })
      content = content.concat(savedSearches);
    }

    return (
      <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
      <div className={rootClass} ref={(item) => { this.inputContainer = item; }}>
        <Measure
          bounds
          onResize={contentRect => {
            this.setState({ searchFieldWidth: contentRect.bounds.width })
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className={rootClass}>
              <Paper className={rootClass} elevation={1}>
                <IconButton onClick={this.handleClick} className={classes.iconButton} aria-label="Menu">
                  <MenuIcon />
                </IconButton>
                <InputBase
                  id="MainSearch"
                  inputRef={(input) => { this.searchInput = input; }}
                  autoComplete="off"
                  onKeyPress={this.handleKeyPress}
                  onKeyDown={this.handleKeyDown.bind(this)}
                  value={this.state.searchValue}
                  onChange={evt => this.updateSearchValue(evt)}
                  className={classes.input}
                  placeholder={this.state.chipValue ? '' : t('type to search...')}
                  startAdornment={
                    <InputAdornment position="start">
                      <SingleSearchDisplay searchCommand={this.state.chipValue} deleteCallback={this.handleChipClick.bind(this)} maxWidth={(this.state.searchFieldWidth/2)} />
                    </InputAdornment>
                  }
                />
              </Paper>
            </div>
          )}
        </Measure>
        <Popper
          open={open}
          transition={true}
          anchorEl={this.inputContainer}
          disablePortal={true}
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={classes.suggestionBox} style={{ width: this.state.searchFieldWidth }}>
                <MenuList style={{ width: this.state.searchFieldWidth, maxWidth: this.state.searchFieldWidth }}>
                  {content}
                </MenuList>
              </div>
            </Fade>
          )}
        </Popper>

        {/* <input
          placeholder={t('type to search...')}
          onKeyPress={this.handleKeyPress}
          value={this.state.searchValue}
          onChange={evt => this.updateSearchValue(evt)}
          className={classes.search} />

         <IconButton onClick={this.handleClick} className={classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>*/}
      </div>
      </ClickAwayListener>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(observer(MenuListComposition)));
//rgb(66,159,210)
