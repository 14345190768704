import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// Mobx
import { inject } from 'mobx-react'
import constants from '../../stores/constants';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    icon: {
        color: constants.color.main, //'#2e8bbe',
    },
    typography: {
        paddingTop: 10,
        paddingLeft: 20
    },
    chip: {
        margin: theme.spacing.unit,
        cursor: 'pointer',
        maxWidth: '80%',
        overflow: 'hidden',
        minWidth: '80px',
        height: 28,
        marginRight: 0,
        borderRadius: 5,
    },
    condChip: {
        margin: theme.spacing.unit,
        cursor: 'pointer',
        maxWidth: '80%',
        overflow: 'hidden',
        color: '#fff',
        backgroundColor: constants.color.main,
        minWidth: '30px',
        height: 28,
        marginRight: 0,
        borderRadius: 5,
    },
    chipDisabled: {
        margin: theme.spacing.unit,
        cursor: 'pointer',
        maxWidth: '80%',
        overflow: 'hidden',
        opacity: 0.4,
        height: 28,
        marginRight: 0,
        borderRadius: 5,
    },
    chipNotSelected: {
      margin: theme.spacing.unit,
      cursor: 'normal',
      maxWidth: '80%',
      overflow: 'hidden',
      textDecoration: 'line-through',
      height: 28,
      marginRight: 0,
      borderRadius: 5,
    },
    chipNotSelectedEditable: {
      margin: theme.spacing.unit,
      cursor: 'pointer',
      maxWidth: '80%',
      overflow: 'hidden',
      textDecoration: 'line-through',
      height: 28,
      marginRight: 0,
      borderRadius: 5,
    },
    searchCommand: {
      display: 'inline-block',
      lineHeight: '30px',
      // marginLeft: 10,
      marginRight: 10,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.color.main,
    },
    searchCommandHolder: {
      display: 'flex',
      overflow: 'hidden',
    },
    searchCommandHolderRight: {
      display: 'flex',
      flexDirection: 'flex-end',
      overflow: 'hidden',
      justifyContent: 'flex-end'
    },
});

class SingleSearch extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);

    }

    setFontSize(length) {
        if (length <= 8) {
            return {}
        } else if (length > 8 && length <= 12){
            return {fontSize: 12}
        } else {
            return {fontSize: 10}
        }
    }

    handleClick(data){
      if('deleteCallback' in this.props) {
        let commandString = this.props.searchCommand.replace(/[\s]+/g,' ').replace(/:[\s]+/g,':');
        commandString = commandString.replace(data,'').replace(/[\s]+/g,' ').trim();
        this.props.deleteCallback(commandString, data);

      }
    }

    render() {
        const { classes, searchCommand } = this.props;

        const editable = ('editable' in this.props)? this.props.editable : false;

        if(!searchCommand || searchCommand === '') return <></>;

        let currentClass = classes.chip;
        if (this.props.disabled) {
          currentClass = classes.chipDisabled;
        } else if('selected' in this.props && this.props.selected === false) {
          if(editable === false) {
            currentClass = classes.chipNotSelected;
          } else {
            currentClass = classes.chipNotSelectedEditable;
          }
        }

        let commandString = searchCommand.replace(/[\s]+/g,' ').replace(/:[\s]+/g,':').trim();
        const regex = /"[^"]+"/g;

        const iter = commandString.matchAll(regex);
        const matches = [...iter];
        const length = matches.length;

        let k;
        for(k=0; k<length; k+=1){
          const replacement = matches[k][0].replace(/[\s]+/g,'___');
          commandString = commandString.replace(matches[k][0], replacement);
        }


        const tokens = commandString.split(' ');
        const tokensLength = tokens.length;
        let i;

        let outputs = [];

        for(i=0; i<tokensLength; i+=1) {

          let parts = tokens[i].split(':');

          if(parts.length === 1) {
            parts[0] = parts[0].replace(/___/g,' ').trim();
            let outputClass = currentClass;
            let cleanPart = parts[0];
            if(this.props.generalStore.language in constants.commandTranslations) {
              if(cleanPart in constants.commandTranslations[this.props.generalStore.language].logicOperators){
                cleanPart = constants.commandTranslations[this.props.generalStore.language].logicOperators[cleanPart];
              }
            }

            if(cleanPart === 'AND' || cleanPart === 'OR') outputClass = classes.condChip;
            outputs.push(<Chip size="small" key={i} onClick={this.handleClick.bind(this, tokens[i])} style={this.setFontSize(parts[0].length)} label={parts[0]} className={outputClass} />);

          } else {
            parts[0] = parts[0].toLowerCase();
            parts[1] = parts[1].replace(/___/g,' ').trim();
            tokens[i] = tokens[i].replace(/___/g,' ').trim();
            // @todo: back-translate to english
            // check in allowed commands
            let cleanPart = parts[0].replace(/^-/,'');
            if(this.props.generalStore.language in constants.commandTranslations) {
              if(cleanPart in constants.commandTranslations[this.props.generalStore.language]){
                cleanPart = constants.commandTranslations[this.props.generalStore.language][cleanPart];
              }
            }

            if(cleanPart in constants.allowedCommands) {
              // token with command
              // outputs.push(<Chip size="small" avatar={<div className={classes.searchCommand}>{t(parts[0])}:</div>} style={this.setFontSize(parts[1].length)} key={key} label={parts[1]} className={currentClass} />);
              outputs.push(<Chip size="small" key={i} onClick={this.handleClick.bind(this, tokens[i])} style={this.setFontSize(tokens[i].length)} label={<><div className={classes.searchCommand}>{parts[0]}:</div><span>{parts[1]}</span></>} className={currentClass} />);

            } else {
              outputs.push(<Chip size="small" key={i} onClick={this.handleClick.bind(this, tokens[i])} style={this.setFontSize(tokens[i].length)} label={tokens[i]} className={currentClass} />);
            }

          }
        }

        return (
            <div className={('maxWidth' in this.props)? classes.searchCommandHolderRight : classes.searchCommandHolder} style={{maxWidth: this.props.maxWidth}}>
              {outputs}
            </div>
        )

    }
}

export default withStyles(styles)(withTranslation("translations")(inject('generalStore')(SingleSearch)));
