// Constants for api calls and  page urls

let apiUrl;
let messageApiUrl;
let redirectPage;
let clientApiWebhookUrl;
let clientApiUrl;

if (window.location.hostname.indexOf("canary") > -1) {
  // console.log(`ENV: canary`);
  apiUrl = "https://canary-api.snazzycontacts.com/api";
  messageApiUrl = "https://canary-message.snazzycontacts.com/api";
  redirectPage = "https://canary-app.snazzycontacts.com/";
  clientApiWebhookUrl = "https://canary-client-api.snazzycontacts.com/api/v1/webhooks/inbound/";

  clientApiUrl = "https://canary-client-api.snazzycontacts.com/api/v1";
} else if (window.location.hostname.indexOf("localhost") > -1) {
  // console.log(`ENV: localhost`);
  // apiUrl = 'https://canary-api.snazzycontacts.com/api';
  apiUrl = "https://dev-api.snazzyapps.de/api";
  messageApiUrl = "https://dev-message.snazzyapps.de/api";
  redirectPage = "https://dev-app.snazzyapps.de";

  clientApiWebhookUrl = "https://dev-client-api.snazzyapps.de/api/v1/webhooks/inbound/";

  clientApiUrl = "https://dev-client-api.snazzyapps.de/api/v1";
} else if (window.location.hostname.indexOf("dev") > -1) {
  // console.log(`ENV: dev`);
  apiUrl = "https://dev-api.snazzyapps.de/api";
  messageApiUrl = "https://dev-message.snazzyapps.de/api";
  redirectPage = "https://dev-app.snazzyapps.de";

  clientApiWebhookUrl = "https://dev-client-api.snazzyapps.de/api/v1/webhooks/inbound/";

  clientApiUrl = "https://dev-client-api.snazzyapps.de/api/v1";
} else {
  // console.log(`ENV: stable`);
  apiUrl = "https://api.snazzycontacts.com/api";
  messageApiUrl = "https://message.snazzycontacts.com/api";
  redirectPage = "https://snazzycontacts.com/login/";

  clientApiWebhookUrl = "https://client-api.snazzycontacts.com/api/v1/webhooks/inbound/";

  clientApiUrl = "https://client-api.snazzycontacts.com/api/v1";
}

const constants = {
  version: "1.0.244",
  apiUrl,
  messageApiUrl,
  clientApiWebhookUrl,
  clientApiUrl,
  credentials: {
    googleClientId:
      "9860308906-do4c0dnenc5njqher7vsmkqrhv8i6l2g.apps.googleusercontent.com",
    // googleGeolocationAPI: "AIzaSyD5d3xtHv5sz6EbfqY-BuXUIagP_fZGOtsREMOVETHISTEXT"
  },
  roles: {
    tenantAdmin: "TENANT_ADMIN",
    tenantGuest: "TENANT_GUEST",
    snazzyAdmin: "ADMIN"
  },
  breakpoints: {
    max: '1550',
    medium: '900',
    small: '640',
  },
  path: {
    login: "/login",
    logout: redirectPage,
    register: "/register",
    registerStep2: "/register-step2",
    profile: '/me',
    resend: "/resend",
    memberList: "/list/members",
    settings: "/settings",
    changePassword: "/change/password",
    deleteMe: "/delete",
    adminsettings: "/admin",
    help: "https://docs.snazzycontacts.com/",
    list: "/addresslist",
    listPersons: "/list/persons",
    listOrganizations: "/list/organizations",
    listAll: "/list/all",
    listByCategory: "/category",
    listByGroup: "/group",
    listFavorites: "/favorites",
    listDuplicates: "/list/duplicates",
    listRelationSuggestions: "/list/relations",
    scan: "/list/duplicates/scan",
    relationsScan: "/list/relations/scan",
    search: "/search",
    debug: "/debug",
    example: "/example",
    detailview: "/detailview",
    detailviewPerson: "/detailview/person",
    detailviewOrganization: "/detailview/organization",
    detailviewDuplicate: "/detailview/duplicate",
    detailviewRelation: "/detailview/relation",
    edit: "/edit",
    editPerson: "/edit/person",
    editOrganization: "/edit/organization",
    editCategories: "/edit/categories",
    editRelations: "/edit/relations",
    editGroups: "/edit/groups",
    // editProfile: '/edit/profile/me',
    create: "/create",
    createPerson: "/create/person",
    createOrganization: "/create/organization",
    invites: "/invites",
    invitations: "/invitations",
    import: "/import",
    export: "/export",
    importExport: "/import-export",
    downloadUrl: "/export/download",
    mapping: "/import/mapping",
    visualMapping: "/import/visualMapping",
    admin: "/admin-dashboard",
    savedSearches: "/saved-searches",
    notFound: "/not-found",
    marketplace: "/inappstore",
    webhookEdit: "/webhooks/edit",
    webhookUrlEdit: "/webhook/urls/edit",
    payment: "/payment",
    doPayment: '/payment/manage',
    invoices: '/payment/invoices',
    journal: '/journal',
    webhook: '/webhooks',
    apikeys: '/apikeys',
    integrationsList: '/integrations',
    dashboard: '/dashboard',
    dashboardEditor: '/dashboard/edit',
    governanceDashboard: '/governance',
    governanceDashboardEditor: '/governance/edit',
    deleteRequests: '/governance/deletes',
    listForms: '/governance/form/list',
    editForm: '/governance/form/edit',
  },
  color: {
    header: '#34556e', // '#34455e',
    main: '#1F7EB3', //'#34455e', //#2e8bbe",
    light: "#429FD2",
    dark: "#1f7eb3",
    contrastText: "#fff",
    text: "#333",
    warning: "#ff0000",
    error: "#ff0000",
    delete: "#ff0000",
    success: "#66bb6a",
    inactive: "#ddd",
    heading: "gray"
  },
  allowedCommands: {
    organization: 1,
    name: 1,
    fullname: 1,
    firstname: 1,
    lastname: 1,
    middlename: 1,
    street: 1,
    streetnumber: 1,
    zipcode: 1,
    city: 1,
    country: 1,
    countrycode: 1,
    email: 1,
    tel: 1,
    facebook: 1,
    twitter: 1,
    xing: 1,
    linkedin: 1,
    webseite: 1,
    phone: 1,
    mobil: 1,
    fax: 1,
    categories: 1,
    category: 1,
    addresses: 1,
    contactdata: 1,
    gender: 1,
    title: 1,
    salutation: 1,
    language: 1,
    nickname: 1,
    jobtitle: 1,
    photo: 1,
    birthday: 1,
    displayname: 1,
    timestamp: 1,
    groups: 1,
  },
  commandTranslations: {
    de: {
      logicOperators: {
        UND: "AND",
        ODER: "OR"
      },
      organisation: "organization",
      gesamtername: "fullname",
      vorname: "firstname",
      nachname: "lastname",
      mittlerername: "middlename",
      straße: "street",
      hausnummer: "streetnumber",
      plz: "zipcode",
      ort: "city",
      land: "country",
      // countrycode: 1,
      email: "email",
      facebook: "facebook",
      twitter: "twitter",
      xing: "xing",
      linkedin: "linkedin",
      website: "website",
      // tel: 1,
      telefon: "phone",
      mobil: "mobil",
      fax: "fax",
      kategorie: "category",
      adresse: "addresses",
      kontaktdaten: "contactdata",
      geschlecht: "gender",
      titel: "title",
      anrede: "salutation",
      sprache: "language",
      spitzname: "nickname",
      jobtitel: "jobtitle",
      foto: "photo",
      geburtstag: "birthday",
      anzeigename: "displayname",
      zeit: "timestamp",
      gruppe: "groups",
    },
    en: {
      logicOperators: {
        AND: "AND",
        OR: "OR"
      },
      organization: "organization",
      fullname: "fullname",
      firstname: "firstname",
      lastname: "lastname",
      middlename: "middlename",
      street: "street",
      streetnumber: "streetnumber",
      zipcode: "zipcode",
      city: "city",
      country: "country",
      email: "email",
      phone: "phone",
      mobile: "mobil",
      fax: "fax",
      facebook: "facebook",
      twitter: "twitter",
      xing: "xing",
      linkedin: "linkedin",
      website: "website",
      category: "category",
      addresses: "addresses",
      contactdata: "contactdata",
      gender: "gender",
      title: "title",
      salutation: "salutation",
      language: "language",
      nickname: "nickname",
      jobtitle: "jobtitle",
      photo: "photo",
      birthday: "birthday",
      displayname: "displayname",
      time: "timestamp",
      groups: "groups",
    }
  },
  relationTypes: [
    {
      type: "PersonToPerson",
      value: "Partner"
    },
    {
      type: "PersonToPerson",
      value: "Friend"
    },
    {
      type: "PersonToPerson",
      value: "Manager"
    },
    {
      type: "PersonToOrganization",
      value: "Advisor"
    },
    {
      type: "PersonToOrganization",
      value: "Client"
    },
    {
      type: "PersonToOrganization",
      value: "Member"
    },
    {
      type: "OrganizationToOrganization",
      value: "Branch"
    },
    {
      type: "OrganizationToOrganization",
      value: "Parent Company"
    },
    {
      type: "OrganizationToOrganization",
      value: "Subsidiary Company"
    },
    {
      type: "OrganizationToPerson",
      value: "Supplier"
    },
    {
      type: "OrganizationToPerson",
      value: "Employer"
    },
    {
      type: "OrganizationToPerson",
      value: "Accountant"
    }
  ]
};

export default constants;
