// Render a list of search results

import React, { Component } from 'react';

import { observer, inject } from "mobx-react";

import { toJS } from "mobx";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

// Components
import ResultGridItem from '../../templates/ResultGridItem';

// React Internationalization
import { withTranslation } from "react-i18next";

import {
  getContactsByIds,
} from '../../../actions/person';

const styles = theme => ({
  root: {
    textAlign: 'left',
  },

});


class LastVisited extends Component {
  // _isMounted = false;
  state = {
      loaded: false,
      entries: [],
  };


  constructor(props) {
    super(props);

    this.props.personStore.setPersonsAndOrganizationsCount(0);
  }

  async loadData(update) {
    let { data } = this.props;
    data = data.sort((a, b)=>a.timestamp<b.timestamp);

    const uids = [];
    const uidTimestamps = {};
    for(let i=0; i < data.length && i < 10; i+=1) {
      uids.push(data[i].uid);

      uidTimestamps[data[i].uid] = data[i].timestamp;
    }

    if(uids.length === 0) return false;

    const { token } = this.props.authStore;

    const uidsKey = uids.join(',');
    if(this.state.loaded === uidsKey) {
      return false;
    }

    let newEntries = await getContactsByIds(token, uids, 0, 50, 'regular');
    if(!newEntries) return false;
    newEntries = newEntries.data;

    for(let i=0; i<newEntries.length && i<5; i+=1) {
      newEntries[i].timestamp = uidTimestamps[newEntries[i].uid];
    }

    newEntries = newEntries.sort((a, b)=>a.timestamp<b.timestamp);

    this.setState({
      loaded: uidsKey,
      entries: newEntries,
    });

  }

  async componentDidMount() {
    this._isMounted = true;

    this.loadData();
  }

  async componentDidUpdate() {
    if(this.state.loaded === false) {
      this.loadData();
    }
  }

  render() {
    const { t } = this.props;

    const items = [];

    let length = (this.state.entries)? this.state.entries.length : 0;

    if (length > 5) length = 5;

    const hash = {};

    for(let i=0; i<length; i+=1) {
      items.push(
        <ResultGridItem
            key={this.state.entries[i].uid}
            entry={this.state.entries[i]}
            selectedIndex={false}
        />
      );
    }

    return (
      <div id="lastVisited">
        <Grid item xs={12} sm={12} md={12}>
          <b key={'head1'}>{t('Last visited')}</b><br/>
          {items}
          {items.length === 0 &&
            <>{t('Contacts you have used recently will show up here')}</>
          }
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(inject('personStore', 'authStore')(observer(LastVisited))));
