// Handles all authentication actions except for the registration

import React from "react";
import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker'

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';
import history from './history';

import axios from 'axios';

withTranslation("translations");

class Relations extends React.Component {
    static async populateRelationTypes() {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/relation/type`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                function catSorter(a, b) {
                    // label
                    // categoryType
                    const x = a.label.toLowerCase();
                    const y = b.label.toLowerCase();

                    if (a.type > b.type) {
                        return -1;
                    } else if (a.type === b.type) {
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    } else {
                        return 1;
                    }
                }

                let data = response.data;
                data.sort(catSorter);


                store.relationsStore.setRelationTypes(data);
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
            } else {
                store.relationsStore.setRelationTypes(404);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Relationtypes could not be retrieved');
            store.relationsStore.setRelationTypes(404);
            return history.push('/not-found');
            // console.log(e);
        }
    }

    static async addRelationType(label, selection) {
        const values = { type: selection.value, label };
        try {
            const response = await trackPromise(axios.post(
                `${constants.apiUrl}/relation/type`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Relationtype created');
                this.populateRelationTypes();
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
            } else {
                store.generalStore.addMessage(500, 'Relationtype could not be created');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Relationtype could not be created');
            console.log(e);
        }
    }

    static async updateRelationType(uid, label, selection) {
        const values = { label, type: selection.value };
        try {
            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/relation/type/${uid}`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Relationtype updated');
                this.populateRelationTypes();
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
            } else {
                store.generalStore.addMessage(500, 'Relationtype could not be updated');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Relationtype could not be updated');
            console.log(e);
        }
    }

    static async mergeAintoB(type, a, b, aName, bName) {

      const data = {
        sourceUid: a,
        targetUid: b,
        sourceName: aName,
        targetName: bName, 
      };

      try {
          const response = await trackPromise(axios.post(
              `${constants.apiUrl}/relation/bulk/${type}/mergeIntoRelationType`,
              data,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${store.authStore.token}`
                  }
              }
          ));

          if (response.status === 200) {
              store.generalStore.addMessage(200, 'Relation type will be merged');
              this.populateRelationTypes();
          } else {
              store.generalStore.addMessage(500, 'Relation type could not be merged');
              console.log(response);
          }
      } catch (e) {
          store.generalStore.addMessage(500, 'Relation type could not be merged');
          console.log(e);
      }
    }

    static async getRelationsByRelationType(uid) {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/relation/relationType/${uid}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                return response.data;
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
            } else {
                store.generalStore.addMessage(500, 'Can not fetch relations by relation type');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Can not fetch relations by relation type');
            console.log(e);
        }
    }

    static async deleteRelationType(uid, label) {
        try {
            const response = await trackPromise(axios.delete(
                `${constants.apiUrl}/relation/type/${uid}`,
                {
                    data: {
                      label,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Relationtype deleted');
                this.populateRelationTypes();
            } else if (response.status === 401) {
                store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
            } else {
                store.generalStore.addMessage(500, 'Relationtype could not be deleted');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Relationtype could not be deleted');
            console.log(e);
        }
    }
}

export default withRouter(Relations);
