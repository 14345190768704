import React, { Component } from "react";
import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  fields: {
    margin: 30
  },
  contactValue: {
    position: "absolute",
    marginLeft: 10
  },
  icon: {
    color: constants.color.main //'#2e8bbe',
  },
  typography: {
    paddingTop: 10,
    paddingLeft: 20
  },
  chip: {
    margin: theme.spacing.unit,
    cursor: "pointer",
    maxWidth: "80%",
    overflow: "hidden"
  },
  chipDisabled: {
    margin: theme.spacing.unit,
    cursor: "pointer",
    maxWidth: "80%",
    overflow: "hidden",
    opacity: 0.4
  },
  chipNotSelected: {
    margin: theme.spacing.unit,
    cursor: "pointer",
    maxWidth: "80%",
    overflow: "hidden",
    textDecoration: "line-through"
  },
  chipSelected: {
    margin: theme.spacing.unit,
    cursor: "pointer",
    maxWidth: "80%",
    overflow: "hidden",
    color: 'white',
    backgroundColor: constants.color.main
  }
});

class SingleGroup extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  setStyle(length, uid) {
    const response = {};
    if (length <= 12) {
      response.fontSize = 12;
    } else {
      response.fontSize = 10;
    }
    if (!uid) response.cursor = "default";

    return response;
  }

  handleClick(e, uid) {
    if (!uid) e.preventDefault();
  }

  render() {
    const { classes, group, toggle } = this.props;
    const key = Math.random();
    let currentClass = classes.chip;
    if (this.props.disabled) {
      currentClass = classes.chipDisabled;
    } else if (("selected" in this.props && this.props.selected === false) || (toggle && this.props.selected === undefined)) {
      currentClass = classes.chipNotSelected;
    } else if ('selected' in this.props && this.props.selected === true) {
      currentClass = classes.chipSelected
    }

    return (
      <>
      { toggle &&
        <Chip
          style={this.setStyle(
            "label" in group ? group.label.length : 0,
            group.uid
          )}
          onClick={this.props.callback.bind(this, group.uid)}
          key={key}
          label={"label" in group ? group.label : ""}
          className={currentClass}
        />
      }
      { !toggle &&
      <Link
        to={`${constants.path.listByGroup}/${group.uid}`}
        onClick={e => this.handleClick(e, group.uid)}
      >
        <Chip
          style={this.setStyle(
            "label" in group ? group.label.length : 0,
            group.uid
          )}
          key={key}
          label={"label" in group ? group.label : ""}
          className={currentClass}
        />
      </Link>
      }
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(SingleGroup)
);
