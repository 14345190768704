import { observable, decorate, action } from 'mobx';
import { getIntegrationTemplates, getIntegrations } from '../actions/integration';

class IntegrationStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    integrations = [];
    createdIntegrations = {};
    rawIntegrations = [];
    integrationsIndex = {};
    integrationsHash = {};

    filter = 'all';

    sorting = ['name', 'name'];
    sortingAll = 'regular';

    integrationCount = 0;

    windowSize = 50;

    selectedCategory = null;

    // Set integrations in mobx store
    setIntegrations = integrations => {
        this.integrations = integrations;
        if (this.integrations && this.integrations.length > 1) {
            this.sortIntegrations(this.sorting[0], this.sorting[1]);
        }
    }

    // Set integrations created by tenant in mobx store
    setCreatedIntegrations = integrations => {
      const integrationsMap = {};

      for(const index in integrations) {
          if(integrations[index].templateRef in integrationsMap) {
            integrationsMap[integrations[index].templateRef].push(integrations[index]);
          } else {
            integrationsMap[integrations[index].templateRef] = [integrations[index]];
          }
      }

      this.createdIntegrations = integrationsMap;
    }

    // Set integrations count in mobx store
    setIntegrationCount = count => {
        this.integrationCount = count;
    }


    // Fetch all possible integrations
    getIntegrationTemplates = async () => {
        const { token } = this.rootStore.authStore;
        let page = 2;
        const num = 10000;

        const res = await getIntegrationTemplates(token, 1, page, num);

        if (res) {
            this.setIntegrations(res);
            this.rawIntegrations = res;
            this.filterIntegrations()
            this.setIntegrationCount(res.length);

            // if (res.meta) {
            //     this.setIntegrationCount(res.meta.count);
            //     (this.sorting[0] !== 'lastUpdate') && this.indexIntegrations();
            // }
            //
            // if (res.meta.count > num) {
            //     for (let i = (num + 1); i < res.meta.count; i += num) {
            //         const nextRes = await getIntegrationTemplates(token, page, num)
            //         const newList = nextRes.data.concat(toJS(this.integrations))
            //         this.setIntegrations(newList)
            //         page++;
            //     }
            // }
        }

        return this.integrations;
    }


    // Fetch all created integrations for tenant
    getIntegrations = async () => {
        const { token } = this.rootStore.authStore;
        let page = 2;
        const num = 10000
        const res = await getIntegrations(token, 1, page, num);

        if (res) {
            this.setCreatedIntegrations(res);
        }

        return this.createdIntegrations;
    }

    indexIntegrations() {
        const sorting = this.sorting[0];

        const hash = {};
        const shortHash = {};
        const integrationLength = this.integrations.length;
        let i;
        let j;
        let c;
        let char;
        let code;

        for (i = 0; i < integrationLength; i += 1) {

            if (sorting === 'name') {
                char = this.integrations[i].name.substr(0, 1).toUpperCase();
            }
            // else if (sorting === 'x') {
            //     char = this.integrations[i].name.substr(0, 1).toUpperCase();
            // } else {
            //     char = this.integrations[i].name.substr(0, 1).toUpperCase();
            // }

            if (char === '') continue;
            const currentCode = char.charCodeAt(0);
            if (currentCode < 64 || currentCode > 91) continue;
            code = currentCode;

            // is first starting with this letter
            if (char in hash === false) {
                // console.log(char);

                //  check if previous anchors exist to close gaps
                for (j = code - 1; j > 64; j -= 1) {
                    c = String.fromCharCode(j);

                    if (c in hash === false) {
                        // console.log('-- ' + c);
                        hash[c] = i;
                    } else {
                        break;
                    }
                }

                hash[char] = i;
                shortHash[char] = i;
            }
        }

        if (i >= integrationLength) i = integrationLength - 1;

        if (code < 90) {
            for (j = code + 1; j < 91; j += 1) {
                c = String.fromCharCode(j);
                hash[c] = i;
            }
        }

        this.integrationsHash = hash;
        let integrationsIndex = {};
        const keys = Object.keys(shortHash);
        const length = keys.length;

        for (i = 0; i < length; i++) {
            if (!integrationsIndex[shortHash[keys[i]]]) {
                integrationsIndex[shortHash[keys[i]]] = keys[i]
            };
        }

        this.integrationsIndex = integrationsIndex;
    }

    setFilter(filterType) {
      this.filter = filterType;
      this.filterIntegrations();
    }


    filterIntegrations() {
      if(this.filter === 'all') {
        this.setIntegrations(this.rawIntegrations);
      } else {
        const length = this.rawIntegrations.length;
        const selectedIntegrations = [];
        for(let i=0; i<length; i+=1) {
          if(
            this.rawIntegrations[i].flowDirection === this.filter
          ) selectedIntegrations.push(this.rawIntegrations[i]);
        }

        this.setIntegrations(selectedIntegrations);
      }
    }


    // Sort integrations
    sortIntegrations = (primaryCriterion, secondaryCriterion) => {
        this.sorting = [primaryCriterion, secondaryCriterion];

        const sortedIntegrations = this.integrations.slice().sort(function (a, b) {
            if (!secondaryCriterion) {
                return b.lastUpdate - a.lastUpdate;
            }

            if (primaryCriterion === 'name' && (!a[primaryCriterion] || a[primaryCriterion] === '')) primaryCriterion = secondaryCriterion; // sort next criteria if first is not set

            if (a[primaryCriterion].toUpperCase() > b[primaryCriterion].toUpperCase()) {
                return 1;
            }
            if (a[primaryCriterion].toUpperCase() < b[primaryCriterion].toUpperCase()) {
                return -1;
            }

            if (a[secondaryCriterion].toUpperCase() > b[secondaryCriterion].toUpperCase()) {
                return 1;
            }
            if (a[secondaryCriterion].toUpperCase() < b[secondaryCriterion].toUpperCase()) {
                return -1;
            }
            return 0;
        });
        this.integrations = sortedIntegrations;

        if (secondaryCriterion) {
            this.indexIntegrations();
        } else {
            this.integrationsIndex = {};
        }
    }
}

decorate(IntegrationStore, {
    integrations: observable,
    createdIntegrations: observable,
    integrationCount: observable,
    integrationsIndex: observable,
    integrationsHash: observable,

    indexIntegrations: action,
    setIntegrations: action,
    setCreatedIntegrations: action,
    setIntegrationCount: action,
    getIntegrationTemplates: action,
    getIntegrations: action,
    sortIntegrations: action,
    setFilter: action,
    filterIntegrations: action,
})

export default IntegrationStore;
