
import moment from 'moment'

import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import DeleteRequestItem from './DeleteRequestItem';
import DeleteRequestCluster from './DeleteRequestCluster';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  avatarInactive: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.inactive,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  DeleteRequestBlock: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    padding: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: 100,
  },
  avatarHolder: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  contentHolder: {
    width: '85vw',
    maxWidth: '620px',
    float: 'right',
    textAlign: 'left',
  },
  upArrow: {
    width: 0,
    height: 0,
    // borderLeft: '10px solid transparent',
    // borderRight: '10px solid transparent',
    // borderBottom: '20px solid #eee',

    position: 'absolute',
    left: (theme.spacing.unit + 5) * -1,
    top: theme.spacing.unit * -2,
  },
  arrowHead: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 50,
    // borderLeft: '5px solid #eee',
    marginLeft: theme.spacing.unit * 2,
  },
});

class DeleteRequestBlock extends Component {
  state = {
    avatarError: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError(){
    this.setState({avatarError: true});
  }

  setItemSize = (id, state, length) => {
    this.props.setItemSize(id, state, length);
  }

  render() {
    const { classes, journal } = this.props;
    const passedData = this.props.entry;

    let data = passedData;

    if(passedData === false || passedData === 'loading') {
      data = [
        {
          user: '',
          operation: '',
          value: '',
          date: "",
        },
      ];

      if(journal && journal === true) {
        let i;
        for(i=0;i<5;i++) {
          data.push({
                    user: '',
                    operation: '',
                    value: '',
                    date: "",
                  });
        }
      }
    }

    const items = [];
    let length = (data)? data.aliases.length : 0;

    if(length === 0) {
      data.aliases.push({
        appUid: 'snazzy',
        alias: data.status === 'incomplete' ? 'pending confirm' : 'not found',
        status: this.props.entry.status,
      });
      length = 1;
    }

    if(length > 0) data.aliases[data.aliases.length -1].lastItem = true;


    // const t0 = Date.now();
    let i;
    let lastDate;
    let lastResult;

    const entryDate = moment(data.createdAt);

    for(i=0; i<length; i++){
      if(passedData === false || passedData === 'loading') {
        data.aliases[i].date = '';
        data.aliases[i].formatedDate = '';
      } else {
          data.aliases[i].date = entryDate.toDate();
          data.aliases[i].formatedDate = entryDate.format("DD.MM.YYYY HH:mm");
      }
    }



    let clusters = [];

    let clusterId = 0;
    let lastClusterDate;

    const lowRange = 360000;
    let currentRange = lowRange;

    clusters.push([]);
    for(i=0; i<length; i++){
      clusters[clusterId].push(data.aliases[i]);
    }

    const clusterLength = clusters.length;
    for(i=0; i<clusterLength; i++){
      if(clusters[i].length === 0) {
        items.push(<DeleteRequestItem entry={this.props.entry} displayType={this.props.displayType} contained={this.props.contained} journal={journal} key={`h${i}`} data={{ lastItem: true }} revertEvent={this.props.revertEvent ? this.props.revertEvent.bind(this) : false} />);
      } else if(clusters[i].length === 1) {
        items.push(<DeleteRequestItem entry={this.props.entry} displayType={this.props.displayType} contained={this.props.contained} journal={journal} key={`h${i}`} data={clusters[i][0]} revertEvent={this.props.revertEvent ? this.props.revertEvent.bind(this) : false} />);
      } else {
        items.push(<DeleteRequestCluster callbackItemSize={this.setItemSize} entry={this.props.entry} displayType={this.props.displayType} contained={this.props.contained} journal={journal} key={`hc${i}`} data={clusters[i]} revertEvent={this.props.revertEvent ? this.props.revertEvent.bind(this) : false} />);
      }
    }
    if(clusterLength > 0) clusters[clusterLength - 1].lastItem = true;

    let blockStyle = (this.props.contained)? {width: '100%'} : {};

    if(window.innerWidth < constants.breakpoints.small) {
      blockStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        width: '95vw',
      };
    }

    return (
      <div key={`grid-${data.user}`} className={classes.DeleteRequestBlock} style={blockStyle}>
        <div className={classes.arrowHead}>
          <div className={classes.upArrow}></div>
        </div>
        {items}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(DeleteRequestBlock)
);
