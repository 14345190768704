// General storage for variables that are needed on several pages

import { decorate, observable, action } from 'mobx'

class GroupsStore {
  groups

  constructor() {
    this.groups = [];
  }

  setGroups(list) {
    this.groups = list;
  }
}

decorate(GroupsStore, {
  groups: observable,

  setGroups: action.bound,
})

export default GroupsStore
