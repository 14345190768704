// Render a list of search results

import React, { Component } from 'react';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { Doughnut, Line } from 'react-chartjs-2';

// Components


// React Internationalization
import { withTranslation } from "react-i18next";

// Stores

import constants from '../../../stores/constants';

const styles = theme => ({
  root: {
    paddingTop: 10
  },

});

const weekdays = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su'
];

const currentDay = moment().isoWeekday() - 1;

class Stats extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  render() {
    const { classes, t, data } = this.props;

    const weekDayLabels = []

    for (let i = 0; i < 7; i += 1) {

      let day = currentDay - i;
      if (day < 0) day = 7 + day;

      weekDayLabels.unshift(t(weekdays[day]));
    }

    let newPersons = 0;
    let newOrganizations = 0;
    let persons = 0;
    let organizations = 0;
    if (data.recentActivity && data.recentActivity.newPersons) {
      newPersons = data.recentActivity.newPersons;
      newOrganizations = data.recentActivity.newOrganizations;
      if (data.incompleteContacts && data.incompleteContacts.totalPersons) {
        persons = data.incompleteContacts.totalPersons - data.recentActivity.newPersons;
        organizations = data.incompleteContacts.totalOrganizations - data.recentActivity.newOrganizations;
      }
    }

    const chartData = {
      labels: [t('New Persons'), t('Persons'), t('New organizations'), t('Organizations')],
      datasets: [
        {
          label: '#',
          data: [
            newPersons,
            persons,
            newOrganizations,
            organizations,
          ],
          backgroundColor: [
            '#5bacd7',
            // '#2e8bbe',
            constants.color.main,
            '#d6eaf5',
            '#98cbe6',
            // 'rgba(31, 126, 179, 0.5)',
            // 'rgba(31, 126, 179, 1)',
            // 'rgba(109, 206, 128, 0.5)',
            // 'rgba(109, 206, 128, 1)',
          ],
          borderColor: [
            '#3377ff',
            '#004de6',
            '#ccddff',
            '#80aaff',
            // 'rgba(31, 126, 179, 0.5)',
            // 'rgba(31, 126, 179, 1)',
            // 'rgba(109, 206, 128, 0.5)',
            // 'rgba(109, 206, 128, 1)',
            // 'rgba(31, 126, 179, 0.2)',
            // 'rgba(31, 126, 179, 0.8)',
            // 'rgba(109, 206, 128, 0.2)',
            // 'rgba(109, 206, 128, 0.8)',
          ],
          borderWidth: 0,
        },
      ],
      position: 'right',
    };

    const options = {
      legend: {
        position: 'right',
      },
    };

    let activity = [0, 0, 0, 0, 0, 0, 0];

    if (data && data.recentActivity) {
      if (data.recentActivity.eventCounts) {
        activity = data.recentActivity.eventCounts;
      } else if (Array.isArray(data.recentActivity)) {
        activity = data.recentActivity;
      }
    }

    const lineData = {
      labels: weekDayLabels,
      datasets: [
        {
          label: t('actions'),
          data: activity,
          pointBackgroundColor: ['#efefef', '#efefef', '#efefef', '#efefef', '#efefef', '#efefef', '#1F7EB3'],
          pointRadius: [4, 4, 4, 4, 4, 4, 5],
          min: 0,
          // backgroundColor: [
          //   'rgba(31, 126, 179, 0.5)',
          //   'rgba(31, 126, 179, 1)',
          //   'rgba(109, 206, 128, 0.5)',
          //   'rgba(109, 206, 128, 1)',
          // ],
          // borderColor: [
          //   'rgba(31, 126, 179, 0.2)',
          //   'rgba(31, 126, 179, 0.8)',
          //   'rgba(109, 206, 128, 0.2)',
          //   'rgba(109, 206, 128, 0.8)',
          // ],
          // borderWidth: 5,
        },
      ],
      position: 'right',
    };

    const lineOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (items, data) {
            const today = moment();
            const dateOfDay = today.subtract(6 - items[0].index, "days");
            // console.log(items);
            return `${items[0].label} ${dateOfDay.format("DD.MM.YYYY")}`;
          }
        }
      },
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            // suggestedMin: 0,
            suggestedMax: 5,
            beginAtZero: true,
          }
        }]
      }
    };

    return (
      <div key={'chartswrapper'} id='statsData'>
        <b>{t('Recent Activity')}</b><br />
        <Grid key={'charts'} container className={classes.root} spacing={8}>
          <Grid key={'chart'} item xs={12} sm={6} md={6}>
            <Doughnut data={chartData} options={options} />
          </Grid>
          <Grid key={'linechart'} item xs={12} sm={6} md={6}>
            <Line data={lineData} options={lineOptions} />
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(Stats));
