import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {toJS} from 'mobx';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Mobx Store
import { inject, observer } from 'mobx-react';
import store from '../../stores/';
// import constants from '../../stores/constants';
import adminConstants from './constants';


const styles = theme => ({
    root: {
    },
});

class Handler extends React.Component {
    state = {
        currentModule: false,
        currentPath: '',
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);

        console.log(window.location.pathname);
    }
    async componentDidMount() {
        // this.props.match.params

        console.log(toJS(store.authStore.userData));
        console.log(store.authStore.userData.role);
        console.log(store.authStore.userData.user);

        const path = window.location.pathname;

        if(path === adminConstants.path.listTenants) {
          console.log('list module...');
          this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/ListView') });
        } else if(path.indexOf(adminConstants.path.tenantDetail) > -1) {
          console.log('detail module...');
          this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/DetailViewAdmin') });
        } else {
          console.log('default module...', path);
          this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/ListView') });
        }

    }

    async componentDidUpdate() {
        const path = window.location.pathname;

        if (this.state.currentPath !== path) {

          if(path === adminConstants.path.listTenants) {
            console.log('list module...');
            this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/ListView') });
          } else if(path.indexOf(adminConstants.path.tenantDetail) > -1) {
            console.log('detail module...');
            this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/DetailViewAdmin') });
          } else if(path.indexOf(adminConstants.path.userDetail) > -1) {
            console.log('detail module...');
            this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/DetailViewAdmin') });

          } else {
            console.log('default module...', path);
            this.setState({currentPath: path, currentModule: await import(/* webpackChunkName: "admin/admin" */ './modules/ListView') });
          }
        }

    }

    render() {
      console.log('Rendering handler');
        // const { classes } = this.props;
        return (
            <div>
                {this.state.currentModule !== false && <this.state.currentModule.default />}
            </div>
        );
    }
}

Handler.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(inject('generalStore')(observer(Handler))));
