import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';

// components
import ResultGridItem from '../../templates/ResultGridItem';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

import { VariableSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
        transition: '0.5s all ease-in-out',
    },
});

class PersonAndOrganization extends Component {
    _isMounted = false;
    _windowScroller: ?WindowScroller;
    scrollUpdateWasRequested = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    async componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }
        this.props.generalStore.setScrollToIndex(-1);

        await this.props.personStore.setCurrentPersonsAndOrganizations(0);

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }

    componentDidUpdate(prevProps) {
      console.log('in up!');
        if(this.props.generalStore.scrollToIndex !== -1){
          console.log('scrolling!');
        //   console.log('scrollToIndex', this.props.generalStore.scrollToIndex);
        //   console.log('this.listRef', this.listRef);
          if(this.listRef.current) {
            console.log('Scrolling to', this.props.generalStore.scrollToIndex);
            this.listRef.current._listRef.scrollToItem(this.props.generalStore.scrollToIndex, 'start');
            // this.listRef.current._listRef.resetAfterIndex(0);
            this.props.generalStore.setScrollToIndex(-1)
          }
        }
        // this.forceUpdate();
      }

    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);
        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }

    onClickDelete(uid) {
        this.props.personStore.deletePerson(uid);
    }

    onClickEntry(entry) {
        if (entry.hasOwnProperty('name')) {
            this.props.history.push(`/detailview/organization/${entry.uid}`)
        } else if (entry.hasOwnProperty('firstName') || entry.hasOwnProperty('lastName')) {
            this.props.history.push(`/detailview/person/${entry.uid}`)
        }
    }

    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    isItemLoaded(index) {
      let offsetIndex = index - this.props.personStore.offset;
      if (
        this.props.personStore.personsAndOrganizations
        && offsetIndex in this.props.personStore.personsAndOrganizations
      ) return true;

      return false;
    }

    async loadMore(startIndex, stopIndex) {
        if (this.scrollUpdateWasRequested) {
            console.log('loadMore scrollUpdateWasRequested asuming data is already there');
        } else {
            console.log('Loading more', startIndex, '-->', stopIndex);
            await this.props.personStore.setCurrentPersonsAndOrganizations(startIndex);
        }
    }

    resize() {
        this.forceUpdate();
    }

    renderRow(data) {

        const realIndex = data.index;

        let offsetIndex = realIndex - this.props.personStore.offset;

        if (this.props.personStore.personsAndOrganizations && offsetIndex in this.props.personStore.personsAndOrganizations) {
            const indexed = this.props.personStore.personsAndOrganizationsIndex[offsetIndex];

            return (
                <div key={data.index} style={data.style} className="row">
                    {indexed &&
                        <ClusterLine key={indexed} text={indexed} />
                    }

                    <ResultGridItem
                        key={this.props.personStore.personsAndOrganizations[offsetIndex].uid}
                        entry={this.props.personStore.personsAndOrganizations[offsetIndex]}
                        selectedIndex={this.state.selectedIndex}
                    />
                </div>
            )
        } else {
            return (
              <div key={data.index} style={data.style} className="row">
                <ResultGridItem
                  key={data.index}
                  entry={{ firstName: '..', lastName: '..' }}
                  selectedIndex={this.state.selectedIndex}
                />
              </div>
            )
        }
    }

    itemSizeGetter(i) {
      const indexed = this.props.personStore.personsAndOrganizationsIndex[i - this.props.personStore.offset];

      if (indexed) {
          return 138;
      } else {
          return 108;
      }
    }

    onScroll(scrollEvent) {
        if (typeof scrollEvent === 'object') {
            if (scrollEvent.scrollUpdateWasRequested) {
                this.scrollUpdateWasRequested = true;
            } else {
                this.scrollUpdateWasRequested = false;
            }
        }
    }

    render() {
        const scrollToIndex = this.props.generalStore.scrollToIndex; // Make react react

        const { classes } = this.props;
        const { t } = this.props;
        const height = calculateVlistHeight();
        const context = this;

        const { personsAndOrganizations } = this.props.personStore.personsAndOrganizations;

        let counter = 0;

        if (this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length > 0) {

            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] entries', { v: this.props.personStore.personsAndOrganizationsCount })}</React.Fragment>} />

                    <InfiniteLoader
                        isItemLoaded={this.isItemLoaded.bind(this)}
                        itemCount={this.props.personStore.personsAndOrganizationsCount}
                        loadMoreItems={this.loadMore.bind(this)}
                        minimumBatchSize={this.props.personStore.personIncrementSize}
                        threshold={Math.ceil(this.props.personStore.personIncrementSize / 2)}
                        ref={this.listRef}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                height={height}
                                itemCount={this.props.personStore.personsAndOrganizationsCount}
                                isItemLoaded={this.isItemLoaded.bind(this)}
                                itemSize={this.itemSizeGetter.bind(this)}
                                onItemsRendered={onItemsRendered}
                                overscanCount={10}
                                onScroll={this.onScroll.bind(this)}
                                ref={ref}
                            >
                                {this.renderRow.bind(context)}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                </div>
            )
        } else if (((this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length === 0) || !this.props.personStore.personsAndOrganizations) && !this.state.isLoading) {
            return (
                <SingleGridBlock children={<div style={{ height: '100vh' }}>{t('You have neither persons nor organizations saved!')}</div>} />
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.personStore.personsAndOrganizations) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 500);

            return (<SingleGridBlock children={<div />} />);
        }
    }
}

PersonAndOrganization.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('personStore', 'generalStore')(observer(PersonAndOrganization)))));
