// Renders a single result of type person or organization in a listView

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Group from "@material-ui/icons/Group";

// React Internationalization
import { withTranslation } from 'react-i18next';

import { toJS } from 'mobx';

// Mobx Stores
import constants from "../../stores/constants";
import store from "../../stores";

// Actions
import ContactTracker from "../../actions/trackContact";

const styles = theme => ({
  control: {
    padding: theme.spacing.unit
  },
  controlSelected: {
    padding: theme.spacing.unit,
    background: '#EBEBEB',
  },
  avatar: {
    margin: 10,
    marginLeft: 0,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 50,
    height: 50,
    float: "left"
  },
  subTitles: {
    float: "left",
    display: "block",
    width: "60%",
    padding: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: theme.spacing.unit * 1,
    maxHeight: 60
  },
  link: {
    width: "80%",
    flexGrow: "1"
  },
  actions: {
    // float: 'right',
    display: "block",
    position: "absolute",
    // width: '70px',
    width: theme.spacing.unit * 8.8,
    // paddingRight: 20
    minWidth: 100,
    maxWidth: 105,
    right: 0,
    marginTop: theme.spacing.unit * -1
  },
  actionLink: {
    outline: 0,
    color: constants.color.main
  },
  actionDivider: {
    "border-right": `1px solid ${constants.color.main}`,
    display: "inline-block",
    marginLeft: "5px",
    marginRight: "5px",
    height: "24px"
  },
  actionDividerInactive: {
    "border-right": `1px solid ${constants.color.main}`,
    display: "inline-block",
    marginLeft: "5px",
    marginRight: "5px",
    height: "24px",
    borderColor: constants.color.inactive
  },
  inactive: {
    color: constants.color.inactive
  },
  listOrganizations: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  name: {
    marginTop: theme.spacing.unit * 2.2,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  nameMobile: {
    marginTop: theme.spacing.unit * 2.2,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // width: '34vw',
    maxWidth: "33vw"
  },
  nameSearch: {
    marginTop: theme.spacing.unit * 1.5,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  nameSearchMobile: {
    marginTop: theme.spacing.unit * 1.5,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "33vw"
  },
  organizationName: {
    position: "relative",
    bottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
    paddingTop: 7,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
    // overflow: 'hidden'
  },
  grid: {
    height: theme.spacing.unit * 12
  },
  gridMultiSelect: {
    height: theme.spacing.unit * 16,
  },
  userProfile: {
    position: "absolute",
    marginLeft: theme.spacing.unit * 0.5,
    marginTop: theme.spacing.unit * 0.5,
    color: constants.color.main
  },
  fullName: {
    marginLeft: theme.spacing.unit * -2
  },
  fullNameSearch: {
    marginLeft: 0
  },
  namePlaceholder: {
    color: '#efefef',
    minWidth: 200,
    marginLeft: theme.spacing.unit * -2,
  },
  userIcon: {
    height: "20px",
    width: "20px",
    marginLeft: theme.spacing.unit * 0.7,
    marginTop: theme.spacing.unit * 0.2
  }
});


const vanityMap = {
  'a': '2',
  'b': '2',
  'c': '2',
  'd': '3',
  'e': '3',
  'f': '3',
  'g': '4',
  'h': '4',
  'i': '4',
  'j': '5',
  'k': '5',
  'l': '5',
  'm': '6',
  'n': '6',
  'o': '6',
  'p': '7',
  'q': '7',
  'r': '7',
  's': '7',
  't': '8',
  'u': '8',
  'v': '8',
  'w': '9',
  'x': '9',
  'y': '9',
  'z': '9',
};

class GridItem extends Component {
  state = {
    avatarError: false
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  handleClick(event) {
    if (
      typeof this.props.redirect !== "undefined" &&
      this.props.redirect === false &&
      this.props.setSelectedPartner
    ) {
      this.props.setSelectedPartner(toJS(this.props.entry));
    }
  }

  handleActionsClick(event) {
    event.stopPropagation();
  }

  trackClick(action) {
    ContactTracker.trackContactUsage(toJS(this.props.entry), action);
  }

  emailClick(event) {
    this.trackClick('email');
    if (this.email) window.open(`mailto:${this.email}`, "_system");
  }

  phoneClick(event) {
    this.trackClick('phone');
    if (this.phone) window.open(`tel:${this.phone}`, "_system");
  }

  avatarError() {
    this.setState({
      avatarError: true,
    });
  }

  vanityMapper(text) {
    if(!text || typeof text !== 'string') return text;

    const trimmedText = text.trim();
    const length = trimmedText.length;
    const chars = [];
    for(let i=0;i<length;i++){
      if(trimmedText[i] in vanityMap) {
        chars.push(vanityMap[trimmedText[i]]);
      } else {
        chars.push(trimmedText[i]);
      }
    }

    return chars.join('');
  }

  render() {
    let search = ('match' in this.props && 'path' in this.props.match && this.props.match.path === constants.path.search) ? true : false
    if (!search && store.generalStore.listState.indexOf('search/') > -1) search = true;

    const { classes, entry } = this.props;

    // console.log(toJS(entry));
    const relations = toJS(entry.relations);
    let personOrganizations = [];
    let formattedResponse;
    let fullName;
    let imageUrl;
    // check if app is installed as iOS PWA and handle mail link diffrently
    const isOSPWA = 'standalone' in window.navigator && window.navigator.standalone ? true : false;

    entry.logo ? (imageUrl = entry.logo) : (imageUrl = entry.photo);

    if ("name" in entry) {
      if (
        "nameModified" in entry &&
        typeof entry.nameModified !== "undefined"
      ) {
        fullName = <React.Fragment>{entry.nameModified}</React.Fragment>;
      } else {
        fullName = entry.name;
      }
    } else {
      if (
        entry.firstNameModified &&
        typeof entry.firstNameModified !== "undefined" &&
        entry.lastNameModified &&
        typeof entry.lastNameModified !== "undefined"
      ) {
        const fullHTML =
          entry.firstNameModified.props.dangerouslySetInnerHTML.__html +
          " " +
          entry.lastNameModified.props.dangerouslySetInnerHTML.__html;
        fullName = <div dangerouslySetInnerHTML={{ __html: fullHTML }} />;
        // fullName = <div name='test'>{entry.firstNameModified}  {entry.lastNameModified}</div>
      } else if (
        entry.firstNameModified &&
        typeof entry.firstNameModified !== "undefined"
      ) {
        const fullHTML =
          entry.firstNameModified.props.dangerouslySetInnerHTML.__html;
        fullName = <div dangerouslySetInnerHTML={{ __html: fullHTML }} />;
        // fullName = <div name='test'>{entry.firstNameModified}  {entry.lastNameModified}</div>
      } else if (
        entry.lastNameModified &&
        typeof entry.lastNameModified !== "undefined"
      ) {
        const fullHTML =
          entry.lastNameModified.props.dangerouslySetInnerHTML.__html;
        fullName = <div dangerouslySetInnerHTML={{ __html: fullHTML }} />;
        // fullName = <div name='test'>{entry.firstNameModified}  {entry.lastNameModified}</div>
      } else {
        fullName = `${entry.firstName} ${entry.lastName}`;
      }
    }

    // Take only relation from type 'OrganizationToPerson'
    if (relations && relations.length > 0) {
      relations.forEach(rel => {
        if (
          (rel.type === "OrganizationToPerson" ||
            rel.type === "PersonToOrganization") &&
          !personOrganizations.includes(rel.partner.name)
        ) {
          // if (!personOrganizations.includes(rel.partner.name)) {
          return personOrganizations.push(rel.partner.name);
          // }
        }
      });
    }

    // Show only the first two relations
    const personOrganizationsLength = personOrganizations.length;
    if (personOrganizationsLength > 2) {
      personOrganizations = personOrganizations.slice(0, 2);
    }
    formattedResponse = personOrganizations.join(", ");
    if (personOrganizationsLength > 2)
      formattedResponse = `${formattedResponse} ...`;

    let email = false;
    let phone = false;

    if (entry.contactData && entry.contactData.length > 0) {
      let length = entry.contactData.length;
      let i;
      for (i = 0; i < length; i += 1) {
        if (phone === false && entry.contactData[i].type === "phone") {
          phone = entry.contactData[i].value;
          phone = this.vanityMapper(phone);
          this.phone = phone;
        } else if (phone === false && entry.contactData[i].type === "mobil") {
          phone = entry.contactData[i].value;
          phone = this.vanityMapper(phone);
          this.phone = phone;
          // we can't use  rel="noopener noreferrer" in the html code below because of compactibility problems
        } else if (email === false && entry.contactData[i].type === "email") {
          email = entry.contactData[i].value;
          email = email
            .replace(/[\s]+/gi, " ")
            .replace(/(^[\s]+|[\s]+$)/gi, "");
          this.email = email;
        }
        if (phone !== false && email !== false) break;
      }
    }

    let link;
    const redirect =
      typeof this.props.redirect === "undefined" ? true : this.props.redirect;

    if (redirect) {
      if ('entryUid' in entry) {
        link = entry.type === 'organization'
        ? `${constants.path.detailviewOrganization}/${entry.entryUid}#${entry.uid}`
        : `${constants.path.detailviewPerson}/${entry.entryUid}#${entry.uid}`;
      } else if ('aggrType' in entry) {
        link =
          entry.aggrType === 'Organization'
            ? `${constants.path.detailviewOrganization}/${entry.uid}`
            : `${constants.path.detailviewPerson}/${entry.uid}`;
      } else {
        link =
          "name" in entry
            ? `${constants.path.detailviewOrganization}/${entry.uid}`
            : `${constants.path.detailviewPerson}/${entry.uid}`;
      }
    } else {
      link = false;
    }

    let phoneLink;
    let emailLink;
    if (isOSPWA) {
      phoneLink = (
        <span
          onClick={this.phoneClick.bind(this)}
          className={classes.actionLink}
        >
          <Phone />
        </span>
      );
      emailLink = (
        <span
          onClick={this.emailClick.bind(this)}
          className={classes.actionLink}
        >
          <Email />
        </span>
      );
    } else {
      phoneLink = (
        <a href={`tel:${phone}`} onError={this.trackClick.bind(this, 'phone')} onClick={this.trackClick.bind(this, 'phone')} className={classes.actionLink}>
          <Phone />
        </a>
      );
      emailLink = (
        <a href={`mailto:${email}`} onClick={this.trackClick.bind(this, 'email')} className={classes.actionLink}>
          <Email />
        </a>
      );
    }

    const badge =
      entry.meta &&
        entry.meta.user &&
        store.authStore.userData.user === entry.meta.user ? (
          <AccountCircle className={classes.userIcon} />
        ) : (
          <Group className={classes.userIcon} />
        );

    let nameClass;
    if (search) {
      if ("innerWidth" in window && window.innerWidth <= 400) {
        nameClass = classes.nameSearch;
      } else {
        nameClass = classes.nameSearchMobile;
      }
    } else {
      if ("innerWidth" in window && window.innerWidth <= 400) {
        nameClass = classes.nameMobile;
      } else {
        nameClass = classes.name;
      }
    }

    const controlStyle = {};
    if(this.props.multiSelect){
      controlStyle.cursor = 'pointer';
    }

    const classFullName = ('uid' in entry)? classes.fullName : classes.namePlaceholder;

    if (!('uid' in entry)) link = false;

    return (
      <Grid className={('multiSelect' in this.props)? classes.gridMultiSelect : classes.grid} item xs={12} key={entry.uid}>
        <div
          className={this.props.selectedIndex === 1? classes.controlSelected: classes.control}
          data-uid={entry.uid}
          onClick={this.handleClick.bind(this)}
          style={controlStyle}
        >
          <Grid container>
            {link === false && (
              <ListItem
                key={entry._id}
                button={this.props.multiSelect? false : true}
                selected={this.props.selectedIndex === 0}
              >
                <Avatar
                  className={this.state.avatarError ? null : classes.avatar}
                  src={imageUrl}
                  onError={this.avatarError.bind(this)}
                >
                  {entry.firstName ? entry.firstName[0] : ""}
                  {entry.lastName ? entry.lastName[0] : ""}
                  {(entry.name && (!entry.aggrType || entry.aggrType !== 'Person')) && entry.name[0]}
                </Avatar>

                <ListItemText>
                  <span className={(search) ? classes.fullNameSearch : classFullName}>
                    {('uid' in entry) && fullName}
                    {!('uid' in entry) && <Skeleton classes={classFullName} width={200} />}
                  </span>
                  {entry.isUser === true && <small><AccountCircle className={classes.userIcon} />)</small>}
                  {/*{entry.isUser === true && <small>&nbsp;({this.props.t('User')})</small>}*/}
                  {this.props.data && <div className={classes.subTitles}>{this.props.data}</div>}
                </ListItemText>
              </ListItem>
            )}
            {link !== false && (
              <ListItem key={entry._id} button selected={this.props.selectedIndex === 0}>
                <Link to={link} className={classes.link}>
                  <Avatar className={classes.avatar} src={this.state.avatarError ? null : imageUrl} onError={this.avatarError.bind(this)}>
                    {entry.firstName ? entry.firstName[0] : ''}
                    {entry.lastName ? entry.lastName[0] : ''}
                    {(entry.name && (!entry.aggrType || entry.aggrType !== 'Person')) && entry.name[0]}
                  </Avatar>

                  <ListItemText className={nameClass}>
                    <span
                      className={
                        search ? classes.fullNameSearch : classFullName
                      }
                    >
                      {('uid' in entry) && fullName}
                      {!('uid' in entry) && <Skeleton classes={classFullName} width={200} />}
                    </span>
                    {entry.isUser === true && (
                      <small className={classes.userProfile}>{badge}</small>
                    )}
                    {/*{entry.isUser === true && <small>&nbsp;({this.props.t('User')})</small>}*/}
                    {this.props.data && (
                      <div className={classes.subTitles}>{this.props.data}</div>
                    )}
                  </ListItemText>
                  <ListItemText className={classes.organizationName}>
                    {entry.name ? (
                      ""
                    ) : (
                        <span className={classes.listOrganizations}>
                          {formattedResponse}
                        </span>
                      )}
                  </ListItemText>
                </Link>
                <div
                  className={classes.actions}
                  onClick={this.handleActionsClick.bind(this)}
                >
                  {phone !== false &&
                    // eslint-disable-next-line
                    phoneLink}
                  {phone === false && <Phone className={classes.inactive} />}
                  <div
                    className={
                      phone !== false || email !== false
                        ? classes.actionDivider
                        : classes.actionDividerInactive
                    }
                  />
                  {email !== false &&
                    // we can't use  rel="noopener noreferrer" here because of iOS compactibility problems
                    // eslint-disable-next-line
                    emailLink}
                  {email === false && <Email className={classes.inactive} />}
                </div>
              </ListItem>
            )}
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation('translations')(withRouter(GridItem)));
