import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';


// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';

// components
import ResultGridItem from '../../templates/ResultGridItem';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

// import VirtualList from 'react-tiny-virtual-list';

import {VariableSizeList} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
      transition: '0.5s all ease-in-out',
    },
});

class ByCategory extends Component {
    _isMounted = false;
    _windowScroller: ?WindowScroller;
    scrollUpdateWasRequested = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    async componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }
        this.props.generalStore.setScrollToIndex(-1);

        let categoryId = this.props.match.params.categoryId;
        if(!categoryId) {
          categoryId = this.props.generalStore.listState.split('/').pop();
        }

        // this.persons = this.props.personStore.getByCategory(categoryId);

        console.log('Fetching', categoryId, 0)
        await this.props.personStore.setCurrentCategoryMembers(categoryId, 0);

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }

    async componentDidUpdate(prevProps){
      if(prevProps.location.pathname !== this.props.location.pathname) {
        let categoryId = this.props.match.params.categoryId;
        if(!categoryId) {
          categoryId = this.props.generalStore.listState.split('/').pop();
        }

        if(this.props.personStore.selectedCategory && this.props.personStore.selectedCategory.uid !== categoryId) {
          // this.persons = this.props.personStore.getByCategory(categoryId);
          await this.props.personStore.setCurrentCategoryMembers(categoryId, 0);
        }
      }

      if(this.props.generalStore.scrollToIndex !== -1){
        // console.log('scrollToIndex', this.props.generalStore.scrollToIndex);
        // console.log('this.listRef', this.listRef);
        if(this.listRef.current) {
          console.log('Scrolling to', this.props.generalStore.scrollToIndex);
          this.listRef.current._listRef.scrollToItem(this.props.generalStore.scrollToIndex, 'start');
          // this.listRef.current._listRef.resetAfterIndex(0);
          this.props.generalStore.setScrollToIndex(-1)
        }
      }
      // this.forceUpdate();
    }


    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);

        this.props.personStore.setPersonsAndOrganizations([]);
        this.props.personStore.setPersonsAndOrganizationsCount(0);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }


    onClickDelete(uid) {
        this.props.personStore.deletePerson(uid);
    }

    onClickEntry(entry) {
        if (entry.hasOwnProperty('name')) {
            this.props.history.push(`/detailview/organization/${entry.uid}`)
        } else if (entry.hasOwnProperty('firstName') || entry.hasOwnProperty('lastName')) {
            this.props.history.push(`/detailview/person/${entry.uid}`)
        }
    }

    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }

    // renderRow(data) {
    //
    //     const realIndex = data.index;
    //
    //     if (this.props.personStore.personsAndOrganizations[realIndex]) {
    //
    //         // console.log(this.props.personStore.personsIndex);
    //         const indexed = this.props.personStore.personsAndOrganizationsIndex[realIndex];
    //
    //         let name = (this.props.personStore.personsAndOrganizations[realIndex].name) ? this.props.personStore.personsAndOrganizations[realIndex].name : this.props.personStore.personsAndOrganizations[realIndex].firstName;
    //         if (typeof name === 'undefined') name = '';
    //         let char = name.substr(0, 1).toUpperCase();
    //
    //         return (
    //             <div key={data.index} style={data.style} className="row">
    //                 {indexed &&
    //                     <ClusterLine key={char} text={char} />
    //                 }
    //
    //                 <ResultGridItem
    //                     key={this.props.personStore.personsAndOrganizations[data.index].uid}
    //                     entry={this.props.personStore.personsAndOrganizations[data.index]}
    //                     selectedIndex={this.state.selectedIndex}
    //                 />
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <div />
    //         )
    //     }
    // }

    renderRow(data) {
      const realIndex = data.index;

      let offsetIndex = realIndex - this.props.personStore.offset;

      if (this.props.personStore.personsAndOrganizations && offsetIndex in this.props.personStore.personsAndOrganizations) {
        const indexed = this.props.personStore.personsAndOrganizationsIndex[offsetIndex];

        return (
          <div key={data.index} style={data.style} className="row">
            {indexed && <ClusterLine key={indexed} text={indexed} />}
            <ResultGridItem
              key={this.props.personStore.personsAndOrganizations[offsetIndex].uid}
              entry={this.props.personStore.personsAndOrganizations[offsetIndex]}
              selectedIndex={this.state.selectedIndex}
            />
          </div>
        );
      } else {
        return (
          <div key={data.index} style={data.style} className="row">
            <ResultGridItem
              key={data.index}
              entry={{ firstName: '..', lastName: '..' }}
              selectedIndex={this.state.selectedIndex}
            />
          </div>
        );
      }
    }

    itemSizeGetter(i) {
      const indexed = this.props.personStore.personsAndOrganizationsIndex[i - this.props.personStore.offset];

      if (indexed) {
        return 138;
      } else {
        return 108;
      }
    }

    async loadMore(startIndex, stopIndex) {
      if(this.scrollUpdateWasRequested) {
        console.log('loadMore scrollUpdateWasRequested asuming data is already there');
      } else {
        let categoryId = this.props.match.params.categoryId;
        if(!categoryId) {
          categoryId = this.props.generalStore.listState.split('/').pop();
        }
        console.log(categoryId + ' Loading more', startIndex, '-->', stopIndex);
        await this.props.personStore.setCurrentCategoryMembers(categoryId, startIndex);
      }
      // console.log('Loading more', startIndex, '-->', stopIndex);
      // await this.props.personStore.setCurrentPersons(startIndex, this.scrollUpdateWasRequested);
    }

    isItemLoaded(index) {
      return false;
    }

    onScroll(scrollEvent) {
      if(typeof scrollEvent === 'object') {
        if(scrollEvent.scrollUpdateWasRequested) {
          this.scrollUpdateWasRequested = true;
        } else {
          this.scrollUpdateWasRequested = false;
        }
      }
    }

    render() {
        const scrollToIndex = this.props.generalStore.scrollToIndex; // Make react react
        const { classes } = this.props;
        const { t } = this.props;
        const height = calculateVlistHeight()
        const items = this.props.personStore.personsAndOrganizations;

        let counter = 0;

        const context = this;
        if (this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length > 0) {

          const label = (typeof this.props.personStore === 'object' && 'selectedCategory' in this.props.personStore && this.props.personStore.selectedCategory !== null && 'label' in this.props.personStore.selectedCategory)? this.props.personStore.selectedCategory.label : '';
          const count = (typeof this.props.personStore === 'object' && 'personsAndOrganizationsCount' in this.props.personStore && this.props.personStore.personsAndOrganizationsCount !==null)? this.props.personStore.personsAndOrganizationsCount : 0;
            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] entries with category [w]', { v: count, w: label })}</React.Fragment>} />

                      <InfiniteLoader
                        isItemLoaded={this.isItemLoaded.bind(this)}
                        itemCount={this.props.personStore.personsAndOrganizationsCount}
                        loadMoreItems={this.loadMore.bind(this)}
                        minimumBatchSize={this.props.personStore.personIncrementSize}
                        threshold={Math.ceil(this.props.personStore.personIncrementSize / 2)}
                        ref={this.listRef}
                      >
                      {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                          height={height}
                          itemCount={this.props.personStore.personsAndOrganizationsCount}
                          isItemLoaded={this.isItemLoaded.bind(this)}
                          itemSize={this.itemSizeGetter.bind(this)}
                          onItemsRendered={onItemsRendered}
                          overscanCount={10}
                          onScroll={this.onScroll.bind(this)}
                          ref={ref}
                        >
                          {this.renderRow.bind(context)}
                        </VariableSizeList>
                        )}
                      </InfiniteLoader>
                </div>
            )
        } else if (((this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length === 0) || !this.props.personStore.personsAndOrganizations) && !this.state.isLoading) {
            const label = ('personStore' in this.props && 'selectedCategory' in this.props.personStore && this.props.personStore.selectedCategory && 'label' in this.props.personStore.selectedCategory)? this.props.personStore.selectedCategory.label : '';
            return (
                <SingleGridBlock children={<div style={{height: '100vh'}}>{t('You have neither persons nor organizations in the category [v]', { v: label })}</div>} />
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.personStore.personsAndOrganizations) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div style={{height: '100vh'}} />} />);
        }
    }
}

ByCategory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('personStore', 'generalStore')(observer(ByCategory)))));
