import React from 'react';
import store from '../stores/';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import constants from '../stores/constants';

const apiUrl = constants.apiUrl;

let lastTracked = false;
let lastAction = false;
let lastTimestamp = false;

class ContactTracker extends React.Component {
	static async deleteContact(uid) {
		if (uid) {
			let newValues = toJS(store.generalStore.profileSettings);

			if(!newValues.settings) return false;

			if(newValues.settings.lastVisited) {
				let index
				for(index in newValues.settings.lastVisited) {
					if(newValues.settings.lastVisited[index].uid === uid) {
						newValues.settings.lastVisited.splice(index, 1);
					}
				}

				try {
					const response = await sendRequest(`${apiUrl}/profile/settings/me`, 'put', newValues);

					if (response.status === 200) {
						store.generalStore.setProfileSettings(newValues);
					}
				} catch (e) {
					console.log(e);
				}
			}

		}
	}

	static async trackContactUsage(entry, action) {
		if (!_.isEmpty(entry)) {
			// console.log(entry.uid);
			const timestamp = Date.now();

			if(
				lastTracked && lastTracked.uid === entry.uid
				&& lastAction && lastAction === action
				&& (lastTimestamp === false || timestamp - lastTimestamp < 360000)
			) {
				// console.log('Already tracked');
				return false;
			}

			let newValues = toJS(store.generalStore.profileSettings);
			if(!newValues.settings) return false;

			lastTracked = entry;
			lastAction = action;
			lastTimestamp = timestamp;

			const values = {
				uid: entry.uid,
				name: entry.name,
				firstName: entry.firstName,
				lastName: entry.lastName,
				// contactData: entry.contactData,
				action,
				timestamp,
			};

			if(newValues.settings.lastVisited) {
				newValues.settings.lastVisited.push(values)
			} else {
				newValues.settings.lastVisited = [values];
			}

			if(newValues.settings.lastVisited.length > 200) {
				newValues.settings.lastVisited.shift();
			}

			try {
				// console.log('newValues', newValues);
				const response = await sendRequest(`${apiUrl}/profile/settings/me`, 'put', newValues);

				if (response.status === 200) {
					store.generalStore.setProfileSettings(newValues);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}
}

const sendRequest = async function (url, method, data) {
	try {
		let response;
		if (!_.isEmpty(data)) {
			response = await axios[method](url, data, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				}
			});
		} else {
			response = await axios[method](url, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				}
			});
		}

		if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}

		if (response.status === 502) {
			store.generalStore.addMessage(502, 'System temporarily unreachable');
		}

		return response;
	} catch (e) {
		console.log(e);
	}
};

export default (ContactTracker);
