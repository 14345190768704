import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// React multilanguage
import { withTranslation } from "react-i18next";

// Components
import SingleGroup from './SingleGroup';

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    typography: {
        paddingTop: 10,
        paddingLeft: 20
    },
});

class Groups extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { groups } = this.props;
        return (
            (groups.length >= 0 &&
                (
                    <React.Fragment>
                        {groups.map(group => (
                            <SingleGroup key={group.uid} group={group} />
                        ))}
                    </React.Fragment>
                )
            )
        );
    }
}

export default withStyles(styles)(withTranslation("translations")(Groups));
