// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';
import auth from './auth';
import Amplitude from 'react-amplitude';
import { trackPromise } from 'react-promise-tracker';
const apiUrl = constants.apiUrl;

// Get all tenant member contacts
export const requestMembers = (token, permissions) => {
    const permissionFlag = (permissions)? '?permissions=1' : ''
    return trackPromise(fetch(`${apiUrl}/user${permissionFlag}`, {
        // return fetch(`${apiUrl}/profile`, {
        method: 'get',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        }
        return res.json();
    }).catch((e) => {
        store.generalStore.addMessage(500, 'Tenantmembers could not be retrieved');
        console.log('ERROR: ', e)
    });
};

// Get all tenant member profiles
export const getProfiles = (token, tenant) => {
    return fetch(`${apiUrl}/iam/tenant/${tenant}`, {
        // return fetch(`${apiUrl}/profile`, {
        method: 'get',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    }).then(function (res) {
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        }
        return res.json();
    }).catch((e) => {
        store.generalStore.addMessage(500, 'Tenantmembers could not be retrieved');
        console.log('ERROR: ', e)
    });
};

// Disable a tenant member
export const disableMember = (token, id) => {
    return trackPromise(fetch(`${apiUrl}/iam/user/disable/${id}`, {
        method: 'PATCH',    // For future reference, PATCH value for fetch method is case sensitive and must be all caps to work
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else if (res.status === 200) {
            store.generalStore.addMessage(200, 'Tenantmember disabled');
        } else {
            store.generalStore.addMessage(500, 'Tenantmember could not be disabled');
        }
        return res.json();
    }).catch((e) => {
        console.log('ERROR: ', e)
        store.generalStore.addMessage(500, 'Tenantmember could not be disabled');
    });
};


// Delete a tenant member
export const deleteMember = (token, uid) => {
    return trackPromise(fetch(`${apiUrl}/iam/user/${uid}`, {
        method: 'delete',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else if (res.status === 200) {
            store.generalStore.addMessage(200, 'Tenantmember deleted');
            Amplitude.logEvent('MemberDeleted');
        } else {
            store.generalStore.addMessage(500, 'Tenantmember could not be deleted');
        }
        //TODO: Add error handling
        return res.text;
    }).catch((e) => {
        console.log('ERROR: ', e);
        store.generalStore.addMessage(500, 'Tenantmember could not be deleted');
    });
};


// Promote a tenant member
export const promoteMember = (token, uid) => {

    return trackPromise(fetch(`${apiUrl}/iam/user/promote/${uid}`, {
        method: 'PATCH',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else if (res.status === 200) {
            store.generalStore.addMessage(200, 'Tenantmember promoted');
        } else {
            store.generalStore.addMessage(500, 'Tenantmember could not be promoted');
        }
        return res.text;
    }).catch((e) => {
        console.log('ERROR: ', e)
        store.generalStore.addMessage(500, 'Tenantmember could not be promoted');
    });
};

// Demote a tenant member
export const demoteMember = (token, uid) => {
    return trackPromise(fetch(`${apiUrl}/iam/user/demote/${uid}`, {
        method: 'PATCH',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else if (res.status === 200) {
            store.generalStore.addMessage(200, 'Tenantmember demoted');
        } else {
            store.generalStore.addMessage(500, 'Tenantmember could not be demoted');
        }
        return res.text;
    }).catch((e) => {
        console.log('ERROR: ', e)
        store.generalStore.addMessage(500, 'Tenantmember could not be demoted');
    });
};


// Request a delete of the tenant and own account (starts email-link confirmation process)
export const requestDeleteSelf = (token) => {
    return trackPromise(fetch(`${apiUrl}/iam/tenant/deleteRequest`, {
        method: 'POST',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        body: JSON.stringify({ language: store.generalStore.language }),
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        if (res.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else if (res.status === 200) {
            store.generalStore.addMessage(200, 'Tenant Deletion requested');
        } else {
            store.generalStore.addMessage(500, 'Tenant Deletion could not be requested');
        }
        return res.text;
    }).catch((e) => console.log('ERROR: ', e));
};

// Delete tenant and own account
export const deleteSelf = (token, deleteToken) => {
    return trackPromise(fetch(`${apiUrl}/iam/tenant/${store.generalStore.language}/${deleteToken}`, {
        method: 'DELETE',
        cache: 'no-store',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,

        },
        credentials: 'omit',
    })).then(function (res) {
        //TODO: Add error handling
        Amplitude.logEvent('TenantDeleted');
        Amplitude.logEvent('MemberDeleted');
        auth.doLogout();
        return res.text;
    }).catch((e) => {
        console.log('ERROR: ', e)
        store.generalStore.addMessage(500, 'Tenant could not be deleted');
    });
};
