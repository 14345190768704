// General storage for variables that are needed on the dashboard

import { decorate, observable, action } from 'mobx'

class DashboardStore {
  data

  constructor() {
    this.data = {};
  }

  setDashboard(data) {
    this.data = data;
  }
}

decorate(DashboardStore, {
  data: observable,

  setDashboard: action.bound,
})

export default DashboardStore
