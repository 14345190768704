import store from '../stores/';
import axios from 'axios';
import constants from '../stores/constants';
import Amplitude from 'react-amplitude';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = constants.apiUrl;

// Generate an invitation
export const generateInvitation = async (email, contactUid) => {
    try {
        const response = await trackPromise(axios.post(
            `${apiUrl}/iam/invite`,
            { email, language: store.generalStore.language, contactUid },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.generalStore.addMessage(200, 'Invitation sent');
            Amplitude.logEvent('InvitationSent');
            return response.data;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
            store.generalStore.addMessage(500, 'Invitation could not be sent');
            // alert(`Response Status: ${response.status}`);
        }
        return false;
    } catch (e) {
        store.generalStore.addMessage(500, 'Invitation could not be sent');
        console.log('ERROR', e);
    }
};
