// Render a list of search results

import React, { Component } from 'react';

import { observer, inject } from "mobx-react";

import { toJS } from "mobx";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Components
import Stats from './widgets/Stats';
import Premium from './widgets/Premium';

import Integrations from './widgets/Integrations';

import Current from './widgets/Current';
import Found from './widgets/Found';
import Incomplete from './widgets/Incomplete';

import LastVisited from './widgets/LastVisited';

import Journal from './widgets/Journal';

import StartEdit from './widgets/StartEdit';

import CreateNew from './widgets/CreateNew';

import ImportDrop from './widgets/ImportDrop';

// Actions
import dashboard from '../../actions/dashboard';

// React Internationalization
import { withTranslation } from "react-i18next";

// Stores

import constants from '../../stores/constants';

// Joyride Tutorials
import DashboardJoyride from '../joyride/DashboardJoyride';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit,
    overflowY: 'auto',
    maxHeight: '85vh',
    // marginBottom: 60,
    overflowX: 'hidden',
  },

  widgetWrapper: {
    margin: theme.spacing.unit,
    padding: window.innerWidth >= constants.breakpoints.small ? theme.spacing.unit * 3 : theme.spacing.unit,
    paddingTop: window.innerWidth >= constants.breakpoints.small ? theme.spacing.unit * 3 : theme.spacing.unit * 2,
  },

});

class Dashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    dashboard.populateDashboard();
  }

  componentDidMount() {
      this._isMounted = true;
  }

  componentDidUpdate() {

  }


  render() {
    const { classes } = this.props;

    const profileSettings = toJS(this.props.generalStore.profileSettings);

    const data = toJS(this.props.dashboardStore.data);

    const lastVisited = (profileSettings && profileSettings.settings && profileSettings.settings.lastVisited)? profileSettings.settings.lastVisited : [];

    const { personsAmount, organizationsAmount } = this.props.generalStore;
		const { premium } = this.props.authStore.userData;

    const dashboardElements = {
      // createNew: <CreateNew data={data.incompleteContacts} />,
      // current: <Current data={data.birthdays} />,
      lastVisited: <LastVisited data={lastVisited} />,
      found: <Found data={data.suggestions} />,
      incomplete: <Incomplete data={data.incompleteContacts}  />,
      premium: <Premium data={{premium, personsAmount, organizationsAmount}} />,
      stats: <Stats data={{recentActivity: data.recentActivity, incompleteContacts: data.incompleteContacts}} />,
      integrations: <Integrations data={data.activeIntegrations} statisticData={data.integrationsStatistic} />,
      journal: <Journal data={data.events} />,
      startEdit: <StartEdit />,
      importDrop: <ImportDrop />,
    }

    if(data.incompleteContacts && data.incompleteContacts.totalPersons > 1
      && data.incompleteContacts.totalOrganizations > 1
    ) {
      dashboardElements['createNew'] = <CreateNew data={data.incompleteContacts} />;
    }

    if(data.birthdays && data.birthdays.length > 0) {
      dashboardElements['current'] = <Current data={data.birthdays} />;
    }

    let left = [
      'current',
      'lastVisited',
      'found',
      'incomplete',
    ];

    let right = [
      'stats',
      'premium',
      'integrations',
      'journal',
      'startEdit',
    ];

    if(
      profileSettings
      && profileSettings.settings
      && profileSettings.settings.dashboard
      && profileSettings.settings.dashboard.left
      && profileSettings.settings.dashboard.right
    ) {
      left = profileSettings.settings.dashboard.left;
      right = profileSettings.settings.dashboard.right;
    }

    if(data
      && data.incompleteContacts
      && data.incompleteContacts.totalPersons < 1
      && data.incompleteContacts.totalOrganizations < 1
      && left.indexOf('createNew') === -1
      && right.indexOf('createNew') === -1
    ) {
      left.unshift('createNew');
    }

    if(left.indexOf('importDrop') === -1
      && right.indexOf('importDrop') === -1
    ) {
      left.push('importDrop');
    }

    if(left.indexOf('premium') === -1
      && right.indexOf('premium') === -1
    ) {
      right.push('premium');
    }
    
    const leftWrapped = [];
    const rightWrapped = [];

    let index;
    for(index in left) {
      if(left[index] in dashboardElements)
        leftWrapped.push(<Paper key={left[index]} className={classes.widgetWrapper} id={classes.widgetWrapper}>{dashboardElements[left[index]]}</Paper>)
    }

    for(index in right) {
      if(right[index] in dashboardElements)
        rightWrapped.push(<Paper key={right[index]} className={classes.widgetWrapper}>{dashboardElements[right[index]]}</Paper>)
    }


    return (
      <div key={'dashboardWrapper'}>
        <Grid key={'dashboard'} container className={classes.root} spacing={8}>
          <Grid key={'left'} item xs={12} sm={12} md={6}>
          {leftWrapped}
          </Grid>
          <Grid key={'right'} item xs={12} sm={12} md={6}>
          {rightWrapped}
          </Grid>
        </Grid>
        <DashboardJoyride />
      </div>
    );
  }
};
export default withTranslation("translations")(withStyles(styles)(inject('dashboardStore', 'generalStore', 'authStore', 'tutorialStore')(observer(Dashboard))));