// Handles all authentication actions except for the registration

import React from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';

// import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';

// Amplitude User Tracking
import Amplitude from 'react-amplitude';

// Actions
import history from './history';

import getContact from './getContact';
import search from './search';

withTranslation('translations');
const cookies = new Cookies();

let previousLocation = constants.path.login;

// Listen for navigation actions and check/restore login
if (window && !window.test) {
	history.listen(async (location, action) => {
		const token = Authentificator.getToken();

		if (
			(!token || store.authStore.hasLogin === false) &&
			location.pathname !== constants.path.login &&
			location.pathname !== constants.path.register &&
			location.pathname !== constants.path.resend &&
			location.pathname.indexOf(`${constants.path.register}`) === -1 &&
			location.pathname.indexOf(`${constants.path.registerStep2}`) === -1
		) {
			Authentificator.useToken(token);
			// We need a function to check & refresh tokens in our iam endpoint
		} else if (location.pathname === constants.path.logout) {
			store.authStore.setReturnTo(`${location.pathname}${location.search}${location.hash}`);
			history.push(constants.path.login);
			await Authentificator.doLogout();
		} else if (location.pathname === constants.path.notFound) {
			const isAdmin =
			(cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0)
				? true
				: false;

			if(isAdmin) {
				console.log('Ignoring change to not found page because is admin');
				if(previousLocation === constants.path.dashboard) {
					history.push(constants.path.admin);
				} else {
					history.push(previousLocation);
				}
			}
		} else if (location.pathname === constants.path.dashboard) {
			const isAdmin =
			(cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0)
				? true
				: false;

			if(isAdmin) {
				history.push(constants.path.admin);
			}
		}

		previousLocation = location.pathname;
	});
}

class Authentificator extends React.Component {
	// check if login exists and regenerate login if only cookie is set
	static check(force) {
		let token = false;
		if (force) {
			token = this.getToken();
			if (token === undefined) {
				store.authStore.setHasLogin(false);
				store.authStore.setToken('');
			}
		}

		const path = window.location.href.replace(/[a-z]{4,5}:\/\/[^/]+/gim, '');
		if (path !== '/' && path !== constants.path.logout) store.authStore.setReturnTo(path);

		if (store.authStore.hasLogin === false) {
			if (token === false) token = this.getToken();
			if (token !== undefined) {
				this.useToken(token);
			} else {
				if (
					path !== constants.path.login &&
					path !== constants.path.register &&
					path.indexOf(`${constants.path.register}/`) === -1 &&
					path.indexOf(`${constants.path.registerStep2}`) === -1
				) {
					if (path.indexOf(constants.path.login) === -1) {
						history.push(constants.path.login);
					}
				}
			}
		}
	}

	// Do a normal login with password and username
	static async doLogin(username, password) {
		store.generalStore.setLoading(true);
		let opts = { username, password };
		await trackPromise(
			fetch(`${constants.apiUrl}/iam/login?${Date.now()}`, {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: 'omit',
				body: JSON.stringify(opts)
			})
		)
			.then(function(response) {
				if (!response.ok) {
					// throw Error(response.statusText);
					return response.text();
				} else {
					return response.json();
				}
			})
			.then((res) => {
				if (res.token) {
					store.generalStore.addMessage(200, 'Login successful');

					// Connects the current user to the Amplitude Session
					Amplitude.setUserId(res.user);
					// Connects the current tenant of the user to the Amplitude Session
					Amplitude.setUserProperties({ tenant: res.tenant });

					if (window.location.href.indexOf('//localhost') !== -1) {
						cookies.set('token', res.token, { path: '/', maxAge: 60 * 60 * 24 * 29 });
					}

					if (window.location.href.indexOf('/dev-app.snazzyapps.de') !== -1) {
						cookies.set('token', res.token, {
							path: '/',
							domain: '.snazzyapps.de',
							maxAge: 60 * 60 * 24 * 29
						});
					} else {
						cookies.set('token', res.token, {
							path: '/',
							domain: '.snazzycontacts.com',
							maxAge: 60 * 60 * 24 * 29
						});
					}

					store.authStore.setHasLogin(true);
					store.authStore.setToken(res.token);
					store.authStore.setUserData(res);
					store.generalStore.setErrorMessage('');

					getContact.populateProfileSettings();

					// let path = constants.path.listPersons;
					let path = constants.path.dashboard;

					console.log('res.role', res.role);
					console.log('store.authStore.returnTo', store.authStore.returnTo);
					if (
						store.authStore.returnTo &&
						store.authStore.returnTo !== '' &&
						store.authStore.returnTo !== constants.path.login &&
						store.authStore.returnTo !== constants.path.logout &&
						store.authStore.returnTo !== constants.path.register &&
						store.authStore.returnTo.indexOf(constants.path.login) === -1 &&
						store.authStore.returnTo.indexOf(constants.path.registerStep2) === -1
					) {
						path = store.authStore.returnTo;
					} else if ('role' in res && res.role === 'ADMIN') {
						console.log('Setting admin token');
						cookies.set('admin', res.user, { path: '/', maxAge: 60 * 60 * 24 * 29 });
						path = constants.path.admin;
						// window.location.href = path;
					}

					store.generalStore.setLoading(false);
					this.useToken(res.token);
					history.push(path);
				} else {
					const parsedResponce = JSON.parse(res);
					if (parsedResponce.message === 'ENTITY_DISABLED') {
						store.generalStore.addMessage(400, 'Registration not confirmed');
						store.authStore.setHasLogin(false, '');
						store.authStore.setConfirmed(true);
						store.generalStore.setErrorMessage(i18n.t('Registration not confirmed'));
						store.generalStore.setLoading(false);
						history.push(constants.path.login);
					} else {
						store.generalStore.addMessage(400, 'Wrong pass or username');
						store.authStore.setHasLogin(false, '');
						store.generalStore.setErrorMessage(i18n.t('Wrong pass or username'));
						store.generalStore.setLoading(false);
						history.push(constants.path.login);
					}
				}
			})
			.catch(function(error) {
				store.generalStore.addMessage(500, 'Unkown error during login');
				history.push(constants.path.login);
				console.log(error);
				store.generalStore.setLoading(false);
			});
	}

	// Logout and delete userdata from browser storage
	static async doLogout() {
		if (store.authStore.token) {
			await trackPromise(
				fetch(`${constants.apiUrl}/iam/logout?${Date.now()}`, {
					method: 'post',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					},
					credentials: 'omit'
				})
			);
		}

		store.authStore.setHasLogin(false);
		store.authStore.setToken('');

		// Delete personal state vars
		store.generalStore.setProfile({});
		store.generalStore.setPerson({});
		store.generalStore.setOrganization({});
		store.generalStore.setList({});
		store.generalStore.setErrorMessage('');
		store.authStore.setUserData({});

		cookies.set('token', '', { path: '/', expires: new Date('01 Jan 1999 00:00:00 PDT') });
		cookies.remove('token', '', { domain: '.snazzycontacts.com', sameSite: false });
		cookies.remove('token', { domain: '.snazzycontacts.com', sameSite: true });
		cookies.remove('token', '', { domain: '.snazzyapps.de', sameSite: false });
		cookies.remove('token', { domain: '.snazzyapps.de', sameSite: true });
		cookies.remove('token', { sameSite: true });
		cookies.remove('token', { sameSite: false });
		cookies.set('admin', '', { path: '/', expires: new Date('01 Jan 1999 00:00:00 PDT') });
		cookies.remove('admin', { sameSite: false });
	}

	// Get token value from Cookie if set
	static getToken() {
		if (window.location.href.indexOf('//localhost') !== -1) {
			return cookies.get('token', { path: '/' });
		} else {
			return cookies.get('token', { path: '/', domain: '.snazzycontacts.com' });
		}
	}

	// Use token to login
	static async useToken(token) {
		store.authStore.setToken(token);
		await fetch(`${constants.apiUrl}/iam/user/me?${Date.now()}`, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				authorization: `Bearer ${token}`
			},
			credentials: 'omit'
		})
			.then(function(response) {
				if (!response.ok) {
					// throw Error(response.statusText);
					return response.text();
				} else {
					return response.json();
				}
			})
			.then((res) => {
				if (res.status && res.status === 'ACTIVE') {
					store.authStore.setHasLogin(true);
					const premium = res.permissions.includes('premium');

					store.authStore.setUserData({
						token,
						user: res._id,
						username: res.username,
						tenant: res.tenant,
						tenantRole:
							res.permissions.indexOf('all') > -1
								? 'ADMIN'
								: res.permissions.indexOf('tenant.all') > -1 ? 'TENANT_ADMIN' : 'USER',
						role: res.role,
						premium,
						permissions: res.permissions,
					});

					// console.log('store.authStore.userData', toJS(store.authStore.userData));

					getContact.populateProfileSettings();
					search.populateSavedSearches();

					// let path = constants.path.listPersons;
					let path = constants.path.dashboard;

					if (
						store.authStore.returnTo &&
						store.authStore.returnTo !== '' &&
						store.authStore.returnTo !== constants.path.login
					) {
						path = store.authStore.returnTo;
					} else if (res.role === 'ADMIN') {
						path = constants.path.admin;
						// window.location.href = path;
					}

					history.push(path);
				} else {
					store.authStore.setHasLogin(false, '');
					store.authStore.setToken('');
					store.authStore.setUserData({});
					history.push(constants.path.login);
				}
			})
			.catch(function(error) {
				history.push(constants.path.login);
			});
	}

	// change password
	static async changePassword(password) {
		const data = { password };

		return await trackPromise(
			fetch(`${constants.apiUrl}/iam/password/change`, {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					authorization: `Bearer ${store.authStore.token}`
				},
				body: JSON.stringify(data),
				credentials: 'omit'
			})
		)
			.then(function(response) {
				if (!response.ok) {
					// throw Error(response.statusText);
					return { error: response.text() };
				} else {
					let data;
					try {
						data = response.json();
						store.generalStore.addMessage(200, 'Password successfully changed');
					} catch (e) {
						store.generalStore.addMessage(500, 'Password could not be changed');
						data = { error: 'An error occured' };
					}
					return data;
				}
			})
			.then((res) => {
				return res;
			});
	}
}

export default withRouter(Authentificator);
