import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grade from '@material-ui/icons/Grade';
import Group from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import Contacts from '@material-ui/icons/Contacts';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// Mobx
import { inject, observer } from 'mobx-react';
import { extendObservable, action, decorate } from 'mobx';

// React Internationalization
import { withTranslation } from "react-i18next";

import constants from '../../../stores/constants';

const styles = theme => ({
    root: {
      background: 'transparent',
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        height: '65px',
        maxWidth: '640px',
        left: 'auto',
        right: 'auto',
        zIndex: 1000,
        backgroundColor: '#fff',
    },
    stickToBottomLeft: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        height: '65px',
        maxWidth: '645px',
        left: 'auto',
        right: 'auto',
        zIndex: 1000,
        marginLeft: 265,
        backgroundColor: '#fff',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    menuButton: {
        // color: '#1f7eb3 !important',
        color: constants.color.main,
        colorPrimary: constants.color.main,
    },
    icon: {
        color: constants.color.main,
        colorPrimary: constants.color.main,
    },
    menueLabel: {
      fontSize: 12,
    },
});

class FooterMenu extends Component {
    constructor(props) {
        super(props);

        extendObservable(this, {
            showAll: false,
        });

        this.onClickShowPersons = this.onClickShowPersons.bind(this);
        this.onClickShowOrganizations = this.onClickShowOrganizations.bind(this);
        this.onClickShowAll = this.onClickShowAll.bind(this);
        this.onClickShowFavorites = this.onClickShowFavorites.bind(this);


        window.addEventListener('resize', this.handleResize.bind(this));
        document.addEventListener("fullscreenchange", this.handleResize.bind(this));
        document.addEventListener("visibilitychange", this.handleResize.bind(this));
    }

    handleResize(){
      this.forceUpdate();
    }

    state = {
        anchorEl: null,
        value: ''
    };

    handleChange = (event, value) => {
        // this.setState({ value });
    };

    onClickShowOrganizations() {
        this.props.history.push('/list/organizations');
    }

    onClickShowPersons() {
        this.props.history.push('/list/persons');
    }

    onClickShowAll() {
        this.props.history.push('/list/all');
    }

    onClickShowFavorites() {
        this.props.history.push('/favorites');
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let value = this.props.history.location.pathname;
        value = value.split('/');
        value = value[value.length - 1];

        const currentClass = (window.innerWidth >= constants.breakpoints.medium)? classes.stickToBottomLeft : classes.stickToBottom;

        let currentStyle = {};
        if(window.innerWidth >= constants.breakpoints.medium) {
          if(window.innerWidth < constants.breakpoints.max) {
            currentStyle = {paddingRight: 'calc(100vw - 900px)'};
          } else {
            currentStyle = {paddingRight: 645};
          }
        };

        return (
            <div className={classes.root} style={currentStyle}>
                <BottomNavigation value={value} onChange={this.handleChange} showLabels className={currentClass}>
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowPersons} label={<span className={classes.menueLabel}>{t('Persons')}</span>} id="persons" value="persons" icon={<Group />} />
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowOrganizations} label={<span className={classes.menueLabel}>{t('Organizations')}</span>} id="organizations" value="organizations" icon={<BusinessIcon />} />
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowAll} label={<span className={classes.menueLabel}>{t('All')}</span>} id="all" value="all" icon={<Contacts />} />
                    <BottomNavigationAction classes={{ selected: classes.menuButton }} onClick={this.onClickShowFavorites} label={<span className={classes.menueLabel}>{t('Favorites')}</span>} id="favorites" value="favorites" icon={<Grade />} />
                </BottomNavigation>
            </div>
        )
    }
}
FooterMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

decorate(FooterMenu, {
    onClickShowPersons: action,
    onClickShowOrganizations: action,
    onClickShowAll: action,
    onClickShowFavorites: action,
    // onClickShowProfile: action
})

export default withStyles(styles)(withRouter(withTranslation("translations")(inject('personStore', 'organizationStore', 'generalStore')(observer(FooterMenu)))));
