import React from 'react';
import { withRouter } from 'react-router-dom';
import store from '../stores/';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { trackPromise } from 'react-promise-tracker';
import constants from '../stores/constants';
import Amplitude from 'react-amplitude';

const apiUrl = constants.apiUrl;

const sendRequest = async function (url, method, data) {
	try {
		let response;
		if (!_.isEmpty(data)) {
			response = await trackPromise(
				axios[method](url, data, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			);
		} else if (method === 'patch') {
			response = await trackPromise(
				axios[method](url, null, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${store.authStore.token}`
					}
				})
			);
		} else {
			response = await trackPromise(axios[method](url, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				}
			})
			);
		}

		if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}

		if (response.status === 502) {
			store.generalStore.addMessage(502, 'System temporarily unreachable');
		}

		return response;
	} catch (e) {
		console.log(e);
	}
};

class MessageHandler extends React.Component {
	static async markMessageAsSeen(id, message) {
		try {
			const newValues = {};
			const response = await sendRequest(`${apiUrl}/profile/notifications/${id}/seen`, 'patch');

			if (response.status === 200) {
				if (message === true) store.generalStore.addMessage(200, 'Profile settings updated');
				store.generalStore.setProfileSettings(newValues);
			}
		} catch (e) {
			if (message === true) store.generalStore.addMessage(404, 'Profile settings could not be updated');
			console.log(e);
		}
	}
}

export default MessageHandler;
