// Handles all authentication actions except for the registration

import React from "react";
import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker'

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';
// import history from './history';

import axios from 'axios';

withTranslation("translations");

class Groups extends React.Component {
    static async populateGroups() {
        try {

            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/group`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {

                function groupSorter(a, b) {
                    // label
                    // groupType
                    const x = a.label.toLowerCase();
                    const y = b.label.toLowerCase();

                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                }

                let data = response.data;
                data.sort(groupSorter);

                store.groupsStore.setGroups(response.data);
            } else {
                store.groupsStore.setGroups(404);
            }
        } catch (e) {
            store.groupsStore.setGroups(404);
            // return history.push('/not-found');
            // console.log(e);
        }
    }

    static async addGroup(label, description, permissions) {
        const values = { label, description, permissions };

        try {
            const response = await trackPromise(axios.post(
                `${constants.apiUrl}/group`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Group created');
                this.populateGroups();
            } else {
                store.generalStore.addMessage(500, 'Group could not be created');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Group could not be created');
            console.log(e);
        }
    }

    static async updateGroup(uid, label, description, permissions) {
        const values = { label, description, permissions };
        try {
            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/group/${uid}`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Group updated');
                this.populateGroups();
            } else {
                store.generalStore.addMessage(500, 'Group could not be updated');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Group could not be updated');
            console.log(e);
        }
    }

    static async mergeAintoB(a, b, aName, bName) {

      const data = {
        sourceUid: a,
        targetUid: b,
        sourceName: aName,
        targetName: bName,
      };

      try {
          const response = await trackPromise(axios.post(
              `${constants.apiUrl}/group/bulk/mergeIntoGroup`,
              data,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${store.authStore.token}`
                  }
              }
          ));

          if (response.status === 200) {
              store.generalStore.addMessage(200, 'Group will be merged');
              this.populateGroups();
          } else {
              store.generalStore.addMessage(500, 'Group could not be merged');
              console.log(response);
          }
      } catch (e) {
          store.generalStore.addMessage(500, 'Group could not be merged');
          console.log(e);
      }
    }

    static async deleteGroup(uid, label) {
        try {
            const response = await trackPromise(axios.delete(
                `${constants.apiUrl}/group/${uid}`,
                {
                    data: {
                      label,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Group deleted');
                this.populateGroups();
            } else {
                store.generalStore.addMessage(500, 'Group could not be deleted');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Group could not be deleted');
            console.log(e);
        }
    }
}

export default withRouter(Groups);
