import { observable, decorate, action } from 'mobx';
import { generateInvitation } from '../actions/invitation';
import { requestMembers, deleteMember, requestDeleteSelf, deleteSelf, disableMember, promoteMember, demoteMember, getProfiles } from '../actions/tenantMember'

class TenantStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    invitations = [];
    members = [];
    memberCount = 0;
    profiles = [];
    sorting = ['firstName', 'lastName'];

    // Set invitations in mobx store
    setInvitation = invitation => {
        // this.invitations = invitation;
        this.invitations.push(invitation);
    }

    // Set members of the tenant
    setMembers = (response) => {
        if (response) {
            this.members = response.data || [];
            this.memberCount = (response && response.meta)? response.meta.count : 0;
        };
    }

    // Update single members permissions in store
    updateMemberPermissions = (uid, permissions) => {
      for(let i=0; i<this.members.length;i+=1) {
        if(this.members[i].uid === uid) {
          this.members[i].permissions = permissions;
        }
      }
    }

    // Set members of the tenant
    setProfiles = (response) => {
        if (response) {
            this.profiles = response;
        };
    }

    // Get tenant members from the API
    getProfiles = async () => {
        const { token } = this.rootStore.authStore;
        const { tenant } = this.rootStore.authStore.userData;
        const data = await getProfiles(token, tenant);
        this.setProfiles(data);
    };



    // Generate Invitation
    generateInvitation = async (email, contactUid) => {
        const invitation = await generateInvitation(email, contactUid);

        if (invitation.msg === 'Invitation is still valid!') {
            return invitation;
        }
        // return [...this.invitations].pop();
        return invitation;

    };

    // Get tenant members from the API
    getMembers = async () => {
        const { token } = this.rootStore.authStore;
        const data = await requestMembers(token, true);
        this.setMembers(data);
    };

    // Sort members
    sortMembers = (primaryCriterion, secondaryCriterion) => {
        this.sorting = [primaryCriterion, secondaryCriterion];

        const sortedMembers = this.members.slice().sort(function (a, b) {

            if (primaryCriterion === 'firstName' && (!a[primaryCriterion] || a[primaryCriterion] === '')) primaryCriterion = secondaryCriterion; // sort next criteria if first is not set

            if (a[primaryCriterion].toUpperCase() > b[primaryCriterion].toUpperCase()) {
                return 1;
            }
            if (a[primaryCriterion].toUpperCase() < b[primaryCriterion].toUpperCase()) {
                return -1;
            }

            if (a[secondaryCriterion].toUpperCase() > b[secondaryCriterion].toUpperCase()) {
                return 1;
            }
            if (a[secondaryCriterion].toUpperCase() < b[secondaryCriterion].toUpperCase()) {
                return -1;
            }

            return 0;
        });
        this.members = sortedMembers;
    }

    deleteMember = (uid) => {
        const { token } = this.rootStore.authStore;
        deleteMember(token, uid)
            .then(action(res => {
                const filteredMembers = this.members.filter(member => member.uid !== uid);
                this.members.replace(filteredMembers);
            })).catch(e => console.log('ERROR: ', e));
        this.memberCount--;
        return this.members;
    }

    requestDeleteSelf = () => {
        const { token } = this.rootStore.authStore;
        return requestDeleteSelf(token)
            .then(action(res => {
            })).catch(e => console.log('ERROR: ', e));
    }

    deleteSelf = (deleteToken) => {
        const { token } = this.rootStore.authStore;
        return deleteSelf(token, deleteToken)
            .then(action(res => {
                return true;
            })).catch(e => console.log('ERROR: ', e));
    }

    disableMember = uid => {
        const { token } = this.rootStore.authStore;
        disableMember(token, uid)
            .then(action(res => {
                // console.log(res);
            })).catch(e => console.log('ERROR: ', e));

        const index = this.members.findIndex((obj => obj.meta.user === uid));
        this.members[index].status = 'DISABLED';
        return this.members;
    }

    promoteMember = uid => {
        const { token } = this.rootStore.authStore;
        promoteMember(token, uid)
            .then(action(res => {
                // console.log(res);
            })).catch(e => console.log('ERROR: ', e));

        const index = this.profiles.findIndex((obj => obj._id === uid));
        this.profiles[index].permissions.push('tenant.all');
        return this.members;
    }

    demoteMember = uid => {
        const { token } = this.rootStore.authStore;
        demoteMember(token, uid)
            .then(action(res => {
                // console.log(res);
            })).catch(e => console.log('ERROR: ', e));

        const index = this.profiles.findIndex((obj => obj._id === uid));
        this.profiles[index].permissions.splice(this.profiles[index].permissions.findIndex(elem => elem === 'tenant.all'), 1)
        return this.members;
    }
}

decorate(TenantStore, {
    invitations: observable,
    members: observable,
    memberCount: observable,
    sorting: observable,

    setInvitation: action,
    setMembers: action,
    sortMembers: action,
    deleteMember: action,
    disableMember: action,
    promoteMember: action,
    demoteMember: action,
    updateMemberPermissions: action,
})

export default TenantStore;
