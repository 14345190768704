// Handles all authentication actions except for the registration

import React from "react";
import { withRouter } from "react-router-dom";

// import { I18nextProvider } from 'react-i18next';
// import i18n from '../i18n';
import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker'

// Mobx Stores
import store from '../stores/';
import constants from '../stores/constants';
// import history from './history';

import axios from 'axios';

withTranslation("translations");

class Categories extends React.Component {
    static async populateCategories() {
        try {
            const response = await trackPromise(axios.get(
                `${constants.apiUrl}/category`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {

                function catSorter(a, b) {
                    // label
                    // categoryType
                    const x = a.label.toLowerCase();
                    const y = b.label.toLowerCase();

                    if (a.categoryType > b.categoryType) {
                        return -1;
                    } else if (a.categoryType === b.categoryType) {
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    } else {
                        return 1;
                    }
                }

                let data = response.data;
                data.sort(catSorter);

                store.categoriesStore.setCategories(response.data);
            } else {
                store.categoriesStore.setCategories(404);
            }
        } catch (e) {
            store.categoriesStore.setCategories(404);
            // return history.push('/not-found');
            // console.log(e);
        }
    }

    static async addCategory(label, description, type) {
        const values = { label, description, type };
        try {
            const response = await trackPromise(axios.post(
                `${constants.apiUrl}/category`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Category created');
                this.populateCategories();
            } else {
                store.generalStore.addMessage(500, 'Category could not be created');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Category could not be created');
            console.log(e);
        }
    }

    static async updateCategory(uid, label, description, type) {
        const values = { label, description, type };
        try {
            const response = await trackPromise(axios.put(
                `${constants.apiUrl}/category/${uid}`,
                values,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Category updated');
                this.populateCategories();
            } else {
                store.generalStore.addMessage(500, 'Category could not be updated');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Category could not be updated');
            console.log(e);
        }
    }

    static async mergeAintoB(type, a, b, aName, bName) {

      const data = {
        sourceUid: a,
        targetUid: b,
        sourceName: aName,
        targetName: bName,
      };

      try {
          const response = await trackPromise(axios.post(
              `${constants.apiUrl}/category/bulk/${type}/mergeIntoCategory`,
              data,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${store.authStore.token}`
                  }
              }
          ));

          if (response.status === 200) {
              store.generalStore.addMessage(200, 'Category will be merged');
              this.populateCategories();
          } else {
              store.generalStore.addMessage(500, 'Category could not be merged');
              console.log(response);
          }
      } catch (e) {
          store.generalStore.addMessage(500, 'Category could not be merged');
          console.log(e);
      }
    }

    static async deleteCategory(uid, label) {
        try {
            const response = await trackPromise(axios.delete(
                `${constants.apiUrl}/category/${uid}`,
                {
                    data: {
                      label,
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.authStore.token}`
                    }
                }
            ));

            if (response.status === 200) {
                store.generalStore.addMessage(200, 'Category deleted');
                this.populateCategories();
            } else {
                store.generalStore.addMessage(500, 'Category could not be deleted');
                console.log(response);
            }
        } catch (e) {
            store.generalStore.addMessage(500, 'Category could not be deleted');
            console.log(e);
        }
    }
}

export default withRouter(Categories);
