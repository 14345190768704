// renders a loading spinner while certain promises are pending

import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
// Material UI
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popper from '@material-ui/core/Popper';
import Collapse from '@material-ui/core/Collapse';

import constants from '../../stores/constants'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

function Spinner(props) {
  const { promiseInProgress } = usePromiseTracker();

  const sidebar = window.innerWidth >= constants.breakpoints.small

  let width = window.innerWidth >= constants.breakpoints.max ? `${constants.breakpoints.max - 5}px` : `${window.innerWidth}px`
  if (sidebar && window.innerWidth <= constants.breakpoints.medium) width = `${constants.breakpoints.small}px`

  let vOffset = window.innerWidth < 600 ? -3 : -1;
  if (props.scrolled) {
    if (sidebar) {
      vOffset += props.scrolled
    } else {
      vOffset = (/(\/relation)|(\/categories)$/).test(window.location) ? vOffset = (vOffset + props.scrolled - 45) : (vOffset += 45)
    }
  }

  const padding =  (window.innerWidth <= constants.breakpoints.medium)? 0: 50;

  return (
    <Popper
      style={{ zIndex: 1000, pointerEvents: 'none', marginTop: padding }}
      open={promiseInProgress}
      anchorEl={<div id="main-placeholder"></div>}
      disablePortal={true}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Collapse
          {...TransitionProps}
          id="menu-list-grow"
          timeout={{ exit: 750, enter: 0 }}
        >
          <LinearProgress style={{ width, height: '10px', marginBottom: '30px', marginTop: vOffset }} />
        </Collapse>
      )}
    </Popper>
  );
}

export default withStyles(styles)(Spinner);
