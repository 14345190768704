import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";

// Mobx Store
// import { observer } from "mobx-react";
// import { toJS } from "mobx";
// import store from "../../stores/";
import constants from "../../stores/constants";

// Material UI
// import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";


import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// import EditIcon from "@material-ui/icons/Edit";

// React Internationalization
import { withTranslation } from "react-i18next";

import logo from '../../icons/snazzylogo-dark.png';

const styles = theme => ({
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 60,
    height: 60
  },
  missingIcon: {
    color: constants.color.main,
    width: 40,
    height: 40,
    "margin-top": "14px",
    "margin-left": theme.spacing.unit * 1
  },
  missingTitle: {
    // backgroundColor: 'grey'
    float: "left",
    marginTop: theme.spacing.unit * 1
  },
  root: {
    marginTop: "20px"
  },
  // typography: {
  //     paddingLeft: 20,
  //     paddingTop: 10
  // },
  card: {
    paddingTop: 0,
    paddingBottom: 20
  },
  chip: {
    margin: theme.spacing.unit
  },
  editLink: {
    display: "block",
    // top: 110 + theme.spacing.unit,
    // right: theme.spacing.unit,
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
    // position: "fixed",
    position: "absolute",
    zIndex: 5
  },
  editLinkLocal: {
    display: "block",
    paddingTop: theme.spacing.unit,
    right: theme.spacing.unit,
    position: "fixed",
    zIndex: 5
  },
  editIcon: {
    fontSize: "30px",
    zIndex: 5
  },
  appBar: {
    zIndex: 0,
    boxShadow: "none",
    marginBottom: 20,
    backgroundColor: "rgba(245,245,245,0)"
  },
  appBarScrolled: {
    zIndex: 0,
    boxShadow: "none",
    marginBottom: 20,
    backgroundColor: "rgba(245,245,245,1)"
  },
  outer: {
    position: "relative",
    paddingBottom: 100
  },
  headerArea: {
    backgroundColor: "#fff",
    background:
      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(250,250,250,0.5) 30%, rgba(245,245,245,1) 100%)",
    backgroundPositionY: "-209px",
    paddingTop: 10,
    minHeight: 'calc(100vh - 105px)',
    maxHeight: 'calc(100vh - 105px)',
    marginTop: -20,
  },
  headerPlaceholder: {
    height: 200,
    width: "100%"
  },
  emptyClass: {
    paddingTop: theme.spacing.unit * 3
  },
  spacer: {
    width: "100%",
    height: theme.spacing.unit * 3
  },
  typography: {
    paddingTop: 10,
    paddingLeft: 20,
    fontSize: "14px",
    color: constants.color.heading,
    clear: "both"
  },
  divider: {
    marginBottom: theme.spacing.unit
  },
  lastUpdate: {
    paddingLeft: 20,
    paddingTop: 10,
    color: constants.color.heading,
    fontSize: "14px"
  },
  qrCode: {
    height: 196,
    width: 196,
    display: "inline-block",
    position: "relative !important",
    zIndex: "0 !important"
  },
  vCard: {
    float: "left",
    marginLeft: theme.spacing.unit * 2
  },
  vCardCenter: {},
  vCardText: {
    fontSize: "16px",
    lineHeight: "16px"
  },
  vCardIcon: {
    position: "relative",
    float: "left",
    marginTop: "3px",
    marginRight: theme.spacing.unit
  },
  favoriteIcon: {
    color: constants.color.main
  },
  notFavoriteIcon: {},
  appBarHolder: {},
  centerBlock: {
    marginTop: '33vh',
    height: 300,
    lineHeight: '300px',
  },
  logo: {
    height: 140,
    opacity: 0.2,
  }
});



const DetailEmpty = class DetailEmpty extends Component {
  _isMounted = false;
  // state = {
  // };

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {classes} = this.props;

    const headerClass = classes.headerArea;

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={16}
          className={classes.demo}
          justify="space-evenly"
          direction="column"
          alignItems="stretch"
        >
          <Grid item xs={"auto"}>
            <Paper className={headerClass}>
              <div className={classes.centerBlock}>
                <img src={logo} className={classes.logo} alt="SnazzyContacts" />
                <Typography variant="h6">

                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
    }
};

export default withTranslation("translations")(
  withStyles(styles)(withRouter(DetailEmpty))
);
