import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// React multilanguage
import { withTranslation } from "react-i18next";

// Components
import SingleCategory from './SingleCategory';

const styles = theme => ({
    fields: {
        margin: 30
    },
    contactValue: {
        position: 'absolute',
        marginLeft: 10
    },
    typography: {
        paddingTop: 10,
        paddingLeft: 20
    },
});

class Categories extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { categories } = this.props;
        return (
            (categories.length > 0 &&
                (
                    <React.Fragment>
                        {categories.map(category => (
                            <SingleCategory key={category.uid} category={category} />
                        ))}
                    </React.Fragment>
                )
            )
        );
    }
}

export default withStyles(styles)(withTranslation("translations")(Categories));
