// Render a list of search results

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';

import Button from "@material-ui/core/Button";

import PremiumIcon from '@material-ui/icons/LocalActivity';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import { Link } from "react-router-dom";

// Components


import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    textAlign: 'left',
    paddingTop: 10
  },
  text: {
    textAlign: 'left',
    lineHeight: '30px',
    marginTop: theme.spacing.unit * 2,
  },
  icon: {
    textAlign: 'center',
  },
  singlePremiumIcon: {
    fontSize: 80,
  },
  button: {
    lineHeight: '30px',
    textAlign: 'center',
  },
  alert: {
    fontSize: 48,
  },
  premiumButtonIcon: {
    marginRight: theme.spacing.unit,
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class Premium extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.data !== this.props.data) this.forceUpdate();
  // }

  render() {
    const { classes, t, data } = this.props;
      let body = [];
      let i = 1;

      if(data.premium) {
        body.push(
          <React.Fragment key={`premium${i}`}>
            <Grid key={`p${i}`} item xs={12} sm={12} md={12} className={classes.icon}>
              <Link to={`${constants.path.payment}`}>
                <PremiumIcon className={classes.singlePremiumIcon} />
              </Link>
            </Grid>
            <Grid key={`p${i}i`} item xs={12} sm={12} md={12} className={classes.text}>
              <Link to={`${constants.path.payment}`}>
                <b>{t('Congrats for being a premium user')}</b>
              </Link>
            </Grid>
            <Grid key={`p${i}x`} item xs={12} sm={12} md={12} className={classes.text}>
              <Link to={`${constants.path.payment}`}>
                {t('Did you now as premium user you can...')}
              </Link>
            </Grid>
          </React.Fragment>
        );
      } else {
        if(data.personsAmount > 450 || data.organizationsAmount > 450) {
          body.push(
            <React.Fragment key={`premium${i}`}>
              <Grid key={`p${i}`} item xs={12} sm={12} md={12} className={classes.button}>
                <Link to={`${constants.path.doPayment}`}>
                  <Button variant="contained" color="primary">
                    <PremiumIcon className={classes.premiumButtonIcon} /> {t("Go premium now")}
                  </Button>
                </Link>
              </Grid>
              <Grid key={`p${i}i`} item xs={2} sm={2} md={2} className={classes.text}>
                <Link to={`${constants.path.doPayment}`}>
                  <AnnouncementIcon className={classes.alert} />
                </Link>
              </Grid>
              <Grid key={`p${i}x`} item xs={10} sm={10} md={10} className={classes.text}>
                <Link to={`${constants.path.doPayment}`}>
                  {(data.personsAmount < 500 && data.organizationsAmount < 500)? t('You have almost reached a limit.') : t('You have reached a limit.')}
                </Link>
              </Grid>
            </React.Fragment>
          );
        } else {
          body.push(
            <React.Fragment key={`premium${i}`}>
              <Grid key={`p${i}`} item xs={12} sm={12} md={12} className={classes.button}>
                <Link to={`${constants.path.doPayment}`}>
                  <Button variant="contained" color="primary">
                    <PremiumIcon className={classes.premiumButtonIcon} /> {t("Go premium now")}
                  </Button>
                </Link>
              </Grid>
              <Grid key={`p${i}i`} item xs={12} sm={12} md={12} className={classes.text}>
                <Link to={`${constants.path.doPayment}`}>
                  {t('Go premium text')}
                </Link>
              </Grid>
            </React.Fragment>
          );
        }
      }


    // <Divider className={classes.divider} />
    // <Grid item xs={12} sm={12} md={12} className={classes.textLine}>
    //   Some other things good to now r now
    // </Grid>

    return (
      <div>
      <b>{t('Premium')}</b><br />
        <Grid container className={classes.root} spacing={8}>
          {body}
          {body.length === 0 &&
            <>{t('loading...')}</>
          }
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(Premium));
