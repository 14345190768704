// Render a list of search results

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';

import CakeIcon from '@material-ui/icons/Cake';

import { Link } from "react-router-dom";

// Components


import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    textAlign: 'left',
    paddingTop: 10
  },
  textLine: {
    textAlign: 'left',
    lineHeight: '30px',
  },
  icon: {
  },
  age: {
    lineHeight: '30px',
    textAlign: 'right',
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class Current extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.data !== this.props.data) this.forceUpdate();
  // }

  render() {
    const { classes, t, data } = this.props;

    const birthdays = [];

    const year = new Date().getFullYear();
    const length = (data) ? data.length : 0;

    for(let i=0; i<length; i+=1) {
      let age = data[i].birthday.split('.');
      if(age.length > 2) age = age[2];
      age = year - age;
      if(age < 0 | age > 200) age = '';

      birthdays.push(
        <React.Fragment key={`bday${i}`}>
          <Grid key={`b${i}`} item xs={1} sm={1} md={1} className={classes.age}>
            <Link to={`${constants.path.detailviewPerson}/${data[i].uid}`}>
              {age}
            </Link>
          </Grid>
          <Grid key={`b${i}i`} item xs={1} sm={1} md={1} className={classes.icon}>
            <Link to={`${constants.path.detailviewPerson}/${data[i].uid}`}>
              <CakeIcon />
            </Link>
          </Grid>
          <Grid key={`b${i}x`} item xs={10} sm={10} md={10} className={classes.textLine}>
            <Link to={`${constants.path.detailviewPerson}/${data[i].uid}`}>
              {data[i].birthday} {data[i].firstName} {data[i].lastName}
            </Link>
          </Grid>
        </React.Fragment>
      );
    }

    // <Divider className={classes.divider} />
    // <Grid item xs={12} sm={12} md={12} className={classes.textLine}>
    //   Some other things good to now r now
    // </Grid>

    return (
      <div id="birthdayData">
      <b>{t('Birthdays')}</b><br />
        <Grid container className={classes.root} spacing={8}>
          {birthdays}
          {length === 0 &&
            <>{t('Upcoming birthdays will show up here')}</>
          }
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(Current));
