import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';


// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


// import VirtualList from 'react-tiny-virtual-list';

import {VariableSizeList} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// components
import ResultGridItem from '../../templates/ResultGridItem';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';

import DeleteRequestBlock from '../../templates/DeleteRequestBlock';

// Actions
import Governance from '../../../actions/governance';

import constants from '../../../stores/constants';

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
      transition: '0.5s all ease-in-out',
    },
});


const baseItemSize = 160;

class DeleteRequests extends Component {
    _isMounted = false;
    _windowScroller: ?WindowScroller;
    scrollUpdateWasRequested = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
        status: 'open',
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    async componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }
        this.props.generalStore.setScrollToIndex(-1);

        await Governance.getDeleteRequests('open');

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }

    async componentDidUpdate(prevProps){
      if (this.state.status !== this.props.governanceStore.filter) {
        this.setState({status: this.props.governanceStore.filter})
        await Governance.getDeleteRequests(this.props.governanceStore.filter);
      }

      if(this.props.generalStore.scrollToIndex !== -1){
        // console.log('scrollToIndex', this.props.generalStore.scrollToIndex);
        // console.log('this.listRef', this.listRef);
        if(this.listRef.current) {
          console.log('Scrolling to', this.props.generalStore.scrollToIndex);
          this.listRef.current._listRef.scrollToItem(this.props.generalStore.scrollToIndex, 'start');
          // this.listRef.current._listRef.resetAfterIndex(0);
          this.props.generalStore.setScrollToIndex(-1)
        }
      }
      // this.forceUpdate();
    }

    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }

    onClickEntry(uid) {
      this.props.history.push(`/detailview/person/${uid}`)
    }

    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }



    renderRow(data) {
      const realIndex = data.index;

      let offsetIndex = realIndex - this.props.governanceStore.offset;

      const entry = toJS(this.props.governanceStore.deleteRequests[offsetIndex]);

      if(entry._id in this.props.governanceStore.itemSizes && this.props.governanceStore.itemSizes[entry._id] > baseItemSize) {
        entry.clustered = false;
      } else {
        entry.clustered = true;
      }

      if (this.props.governanceStore.deleteRequests && offsetIndex in this.props.governanceStore.deleteRequests) {
        return (
          <div key={data.index} style={data.style} className="row">
            <DeleteRequestBlock
              key={data.index}
              entry={entry}
              selectedIndex={this.state.selectedIndex}
              setItemSize={this.setItemSize}
            />
          </div>
        );
      } else {
        return (
          <div key={data.index} style={data.style} className="row">
            <DeleteRequestBlock
              key={data.index}
              entry={{ firstName: '..', lastName: '..' }}
              selectedIndex={this.state.selectedIndex}
              setItemSize={this.setItemSize}
            />
          </div>
        );
      }
    }

    itemSizeGetter(i) {
      const id = this.props.governanceStore.deleteRequests[i]._id;
      const itemSizes = toJS(this.props.governanceStore.itemSizes)

      if(i >=0 && id in itemSizes) {
        return itemSizes[id];
      }

      if(i<0) return 0;
      return baseItemSize;
    }

    async loadMore(startIndex, stopIndex) {
      if(this.scrollUpdateWasRequested) {
        console.log('loadMore scrollUpdateWasRequested asuming data is already there');
      } else {
        console.log('Could be loading more ', startIndex, '-->', stopIndex);
      }
      // console.log('Loading more', startIndex, '-->', stopIndex);
      // await this.props.governanceStore.setCurrentPersons(startIndex, this.scrollUpdateWasRequested);
    }

    isItemLoaded(index) {
      return false;
    }

    onScroll(scrollEvent) {
      if(typeof scrollEvent === 'object') {
        if(scrollEvent.scrollUpdateWasRequested) {
          this.scrollUpdateWasRequested = true;
        } else {
          this.scrollUpdateWasRequested = false;
        }
      }
    }

    setItemSize = (id, state, length) => {
      console.log('x', id, state, length);
      console.log(this.listRef);
      const newSize = (state === true)? baseItemSize * (length + 1) : baseItemSize;

      this.props.governanceStore.setItemSize(id, newSize);
      // this.listRef.current._listRef.scrollToItem(0, 'start');
      this.listRef.current._listRef.resetAfterIndex(0, true);
      // this.forceUpdate();
    }

    render() {
        const scrollToIndex = this.props.generalStore.scrollToIndex; // Make react react
        const filter = this.props.governanceStore.filter; // Make react react
        const itemSizes = this.props.governanceStore.itemSizes; // Make react react
        const { classes } = this.props;
        const { t } = this.props;
        const height = calculateVlistHeight()
        const items = toJS(this.props.governanceStore.deleteRequests);
        let counter = 0;

        const count = items.length;

        const context = this;
        if (items && items.length > 0) {
            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] entries with status [w]', { v: count, w: t(`deleteRequest ${this.props.governanceStore.filter}`) })}</React.Fragment>} />

                      <InfiniteLoader
                        isItemLoaded={this.isItemLoaded.bind(this)}
                        itemCount={count}
                        loadMoreItems={this.loadMore.bind(this)}
                        minimumBatchSize={100}
                        threshold={Math.ceil(100 / 2)}
                        ref={this.listRef}
                      >
                      {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                          height={height}
                          itemCount={count}
                          isItemLoaded={this.isItemLoaded.bind(this)}
                          itemSize={this.itemSizeGetter.bind(this)}
                          onItemsRendered={onItemsRendered}
                          overscanCount={10}
                          onScroll={this.onScroll.bind(this)}
                          ref={ref}
                        >
                          {this.renderRow.bind(context)}
                        </VariableSizeList>
                        )}
                      </InfiniteLoader>
                </div>
            )
        } else if (((items && items.length === 0) || !this.props.governanceStore.deleteRequests) && !this.state.isLoading) {
            return (
                <>
                  <InfoBar children={<React.Fragment>{t('[v] entries with status [w]', { v: count, w: t(`deleteRequest ${this.props.governanceStore.filter}`) })}</React.Fragment>} />
                  <SingleGridBlock children={<div style={{height: '100vh'}}>{t('No delete requests found')}</div>} />
                </>
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.governanceStore.deleteRequests) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div style={{height: '100vh'}} />} />);
        }
    }
}

DeleteRequests.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('governanceStore', 'generalStore')(observer(DeleteRequests)))));
