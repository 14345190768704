
import EventSource from 'eventsource';

import { toJS } from 'mobx';

import store from '../stores/';
import constants from '../stores/constants';

import getContact from './getContact';

export let messageHandler = null;

let shouldConnect = true;

const instanceId = Date.now();

function addToMessagesToStore(message) {
  // console.log('addToMessagesToStore', message);
  const settings = (
    store.generalStore.profileSettings
  )? toJS(store.generalStore.profileSettings) : {};

  // console.log('Orginal settings', settings);

  if(!settings.notifications) settings.notifications = [];

  settings.notifications.push({
    id: message.data.id,
    body: message.data,
    seen: false,
    timestamp: Date.now(),
  });

  // console.log('Settings after append', settings);
  this.props.generalStore.setProfileSettings(settings);
}

function handleVisibilityChange() {
  if (document.hidden) {
    // Nothing todo we only close if we receive a close command from another tab
  } else {
    broadcastMessage(`New window ${instanceId}`);
    shouldConnect = true;
    // if(messageHandler === null) console.log('Starting...');
    // if(messageHandler === null) console.log(time);
    if (messageHandler === null) startMessageListener();
    getContact.populateProfileSettings();
  }
}

document.addEventListener("visibilitychange", handleVisibilityChange, false);

export const broadcastMessage = function (message) {
  // console.log('Sending message!');
  // const today = new Date();
  // const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  // console.log(time);
  localStorage.setItem('message', message);
  localStorage.removeItem('message');
}


function receiveMessage(event) {
  if ('key' in event && event.key === 'message') {
    if (typeof event.newValue === 'string' && event.newValue.indexOf('New window') > -1 && event.newValue.indexOf(instanceId) === -1) {
      // console.log('Closing like requested');
      shouldConnect = false;
      // const today = new Date();
      // const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      // console.log(time);
      try {
        if (messageHandler !== null && 'readyState' in messageHandler && messageHandler.readyState !== 2) messageHandler.close();
      } catch (e) {
        console.log(e);
      }

      // try {
      //   if(messageHandler !== null && messageHandler.readyState !== 2) messageHandler.close();
      // } catch(e) {
      //   console.log(e);
      // }

      messageHandler = null;
    }
  }
}

window.addEventListener('storage', receiveMessage);

export const startMessageListener = function () {
  // if (window.location.hostname.indexOf('localhost') === -1 && window.location.hostname.indexOf('snazzyapps.de') === -1) {
if (window.location.hostname.indexOf('localhost') === -1) {
    if (shouldConnect === false) return false;

    if (store.authStore.userData.token === '' || store.authStore.userData.token === null) {
      // console.log('No token waiting with messages');
      setTimeout(startMessageListener, 500);
      return;
    }

    var eventSourceSettings = {
      withCredentials: false,
      headers: { 'Authorization': `Bearer ${store.authStore.token}` }
    };

    messageHandler = new EventSource(`${constants.messageApiUrl}/messages/`, eventSourceSettings);

    messageHandler.addEventListener("open", function (e) {
      // console.log('Open!');
      // console.log(e.data);
      // const today = new Date();
      // const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      // console.log(time);
    })

    messageHandler.addEventListener("message", function (e) {
      try {
        // const today = new Date();
        // const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        // console.log(time);
        // console.log(e.data);
        let json = false;
        try { json = JSON.parse(e.data); } catch (e) { json = false; }

        if (window.location.hostname.indexOf('snazzyapps.de') > -1) {
          console.log(e);
        }

        if (json !== false) {
          addToMessagesToStore(json)
          if ('error' in json) {
            store.generalStore.addMessage(500, e.error);
          } else if('uid' in json && 'type' in json && 'firstName' in json) {
            const linkData = {
              url: `${constants.path.detailviewPerson}/${json.uid}`,
              label: `${json.firstName} ${json.lastName}`,
            };

            let eventName = json.eventName;
            if('newContactDataCount' in json && json.newContactDataCount === 0) {
               eventName = 'NoNewSocialMediaEntriesFound';
            }

            store.generalStore.addMessage(200, eventName, 'MessageWithLink', linkData);
          } else if('data' in json) {
            store.generalStore.addMessage(200, json.data);
          } else {
            store.generalStore.addMessage(200, e.message);
          }
        } else {
          addToMessagesToStore({
            data: e.data,
          })
          if (e.data.indexOf('Error') > -1) {
            store.generalStore.addMessage(500, e.data);
          } else {
            store.generalStore.addMessage(200, e.data);
          }
        }

      } catch (e) {
        console.log(e);
      }
    })

    messageHandler.addEventListener("error", function (e) {
      console.log(e)
    })

  }
}

window.onerror = function (message, file, line, col, error) {
  console.log("Error occurred: " + message, file, line, col, error);

  return false;
};

window.addEventListener('unhandledrejection', function (e) {
  // if(e.name === 'AbortError'){}
  console.log('Unhandled');
  console.log(e);

  e.stopPropagation();
  e.preventDefault();
})
