import React from 'react';
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
// import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

// Mobx
import { inject, observer } from 'mobx-react';

// React Internationalization
import { withTranslation } from "react-i18next";

// Constants
import constants from '../../stores/constants';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const snackBarStyles = theme => ({
  success: {
    backgroundColor: constants.color.success,
  },
  error: {
    backgroundColor: constants.color.error,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: '4px',
  },
});

function CustomSnackbarContent(props) {
  const { classes, className, message, onClose, variant } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}

CustomSnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const CustomSnackbarContentWrapper = withStyles(snackBarStyles)(CustomSnackbarContent);

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class CustomizedSnackbar extends React.Component {
  _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }

  // handleClick = () => {
  //   this.props.generalStore.addMessage('404', 'hallo!','act!');
  // };

  handleClose = (event) => {
    this.props.generalStore.hideSnackBar();
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    clearTimeout(this.hideMe);
    this.hideMe = setTimeout(this.handleClose.bind(this),5000);

    const { classes, t } = this.props;

    let open;
    let message = '';
    let variant = 'success';
    if(this.props.generalStore.showSnackBar===false){
      open = false;
    } else {
      open = true;
    }

    if(this.props.generalStore.snackBarContent.text){

      let linkData = false;
      if(this.props.generalStore.snackBarContent.linkData){
        linkData = this.props.generalStore.snackBarContent.linkData;
        message = <div>
          {t(this.props.generalStore.snackBarContent.text)}<br/>
          <Link to={linkData.url} style={{
            width: '100%',
            display: 'block',
            clear: 'both',
            color: '#fff',
            fontWeight: 'bold'}}
          >{linkData.label}</Link>
        </div>;
      } else {
        message = t(this.props.generalStore.snackBarContent.text);
      }

      let code = this.props.generalStore.snackBarContent.code;

      if(code === 200){
        variant = 'success';
      } else if(code < 200) {
        variant = 'info';
      } else if(code >=400) {
        variant = 'error';
      } else {
        variant = 'warning';
      }
    }



    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{marginBottom: 60}}
          open={open}
          autoHideDuration={5000}
        >
        <CustomSnackbarContentWrapper
        variant={variant}
        className={classes.margin}
        message={message}
        onClose={this.handleClose.bind(this)}
        />
        </Snackbar>

      </div>
    );
  }
}

CustomizedSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation("translations")(withStyles(styles)(inject('generalStore')(observer(CustomizedSnackbar))));
