import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import { trackPromise } from 'react-promise-tracker';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const apiUrl = constants.apiUrl;

let cancelTokens = [];

let latestCall = '';
let latestOffsetCall = '';

// Get all organizations
export const getOrganizations = async (token, page, num, sort, offset) => {
	const call = `${page}-${num}-${sort}-${offset}`;
	if(call === latestCall) {
		console.log('getOrganizations called with same arguments more then once --> ignored!');
		console.log(call);
		return;
	}

	latestCall = call;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	//   if(!store.authStore.userData.role || store.authStore.userData.role === 'ADMIN') return false;
	// return fetch(`${apiUrl}/organization?page=${page}&num=${num}`, {
	//     method: 'get',
	//     cache: 'no-store',
	//     headers: {
	//         'Accept': 'application/json',
	//         'Content-Type': 'application/json',
	//         'Authorization': `Bearer ${token}`,

	//     },
	//     credentials: 'omit',
	// }).then(function (res) {
	//     //TODO: Add error handling
	//     if (res.status === 401) {
	//         store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
	//     }
	//     return res.json();
	// }).catch((e) => {
	//     store.generalStore.addMessage(500, 'Organizations could not be retrieved');
	// });

	let currentSort;
	if(sort) {
		if(sort === 'regular') {
			currentSort = 'nameAsc';
		} else if (sort === 'inverted') {
			currentSort = 'nameDesc';
		}
	}

	try {
		console.log('Before cancel', cancelTokens);
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		console.log('before setting new token', cancelTokens);
		cancelTokens.push(axios.CancelToken.source());
		console.log('after setting new');

		const response = await trackPromise(
			axios.get(`${apiUrl}/organization?page=${page}&num=${num}&sort=${currentSort}&offset=${offset}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
	} catch (e) {
		console.log('Error:', e);
		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;


		store.generalStore.addMessage(500, 'Organizations could not be retrieved');
		return history.push('/not-found');
	}
};

// Get current Organizations
export const getCurrentOrganizations = async (token, offset, num, sort) => {
	const offsetCall = `${offset}-${num}-${sort}`;
	console.log('Call', offsetCall);
	if(offsetCall === latestOffsetCall) {
		console.log('getCurrentOrganizations called with same arguments more then once --> ignored!');
		console.log(offsetCall);
		return;
	}

	latestCall = offsetCall;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	let currentSort;
	if(sort) {
		if(sort === 'regular') {
			currentSort = 'nameAsc';
		} else if (sort === 'inverted') {
			currentSort = 'nameDesc';
		}
	}

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/organization?offset=${offset}&num=${num}&sort=${currentSort}&language=${language}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Organizations could not be retrieved');

		// return history.push('/not-found');
	}
};


// Get Organizations starting from letter
export const getOrganizationsByLetter = async (token, letter, num, sort) => {
	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	let currentSort;
	if(sort) {
		if(sort === 'regular') {
			currentSort = 'nameAsc';
		} else if (sort === 'inverted') {
			currentSort = 'nameDesc';
		}
	}

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/organization?letter=${letter}&num=${num}&sort=${currentSort}&language=${language}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			const offsetCall = `${response.data.meta.offset}-${num}-${sort}`;
			console.log('Call (by letter)', offsetCall);
			latestCall = offsetCall;

			return response.data;
		} else if (response.status === 204) {
			return {
				data: [],
				meta: {
					offset: 0,
				}
			}
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Organizations could not be retrieved');

		// return history.push('/not-found');
	}
};


// Delete an organization
export const deleteOrganization = (token, uid, name) => {
	return trackPromise(
		fetch(`${apiUrl}/organization/${uid}`, {
			method: 'delete',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ name }),
			credentials: 'omit'
		})
	)
		.then(function (res) {
			if (res.status === 401) {
				store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
			} else {
				store.generalStore.changeOrganizationsAmount('decrease');
				store.generalStore.addMessage(200, 'Organization deleted');
			}
			return res.json();
			//TODO: Add error handling
		})
		.catch((e) => {
			store.generalStore.addMessage(500, 'Organizations could not be deleted');
		});
};
