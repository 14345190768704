import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

import { toJS } from 'mobx';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import EmptyStarIcon from '@material-ui/icons/StarBorder';

import Typography from '@material-ui/core/Typography';

// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'



// components
import ResultGridItem from '../../templates/ResultGridItem';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

// import VirtualList from 'react-tiny-virtual-list';

import {VariableSizeList} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// Actions
import ContactEditor from '../../../actions/editContact';

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
        transition: '0.5s all ease-in-out',
    },
    title: {
        margin: theme.spacing.unit * 2,
        paddingTop: '10vh',
        color: constants.color.main,
        fontSize: '1.7rem',
    },
    noFavorites: {
        marginTop: theme.spacing.unit * 3,
    },
    explain: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        maxWidth: '80%',
    },
    emptyStarIcon: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '40vw',
        height: '40vw',
        maxWidth: '200px',
        maxHeight: '200px',
        color: constants.color.main,
        display: 'block',
        marginTop: theme.spacing.unit * 3,
    },
});

class Favorites extends Component {
  _isMounted = false;
  _windowScroller: ?WindowScroller;
  scrollUpdateWasRequested = false;
  favoriteDataRequested = false;
  sorting = 'regular';

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    getFavoriteData() {
      const profileSettings = toJS(this.props.generalStore.profileSettings);

      if (
          typeof profileSettings === 'object'
          && 'settings' in profileSettings
          && 'favorites' in profileSettings.settings
          && Object.keys(profileSettings.settings.favorites).length !== 0
      ) {
          const uids = Object.keys(profileSettings.settings.favorites);
          this.favoriteDataRequested = `${uids.join(',')}-0`;
          this.props.personStore.setFavorites(uids, 0);
      }
    }

    componentDidMount() {
        if (this.favoriteDataRequested) {
            this.setState({ isLoading: true });
        }
        this.props.generalStore.setScrollToIndex(-1);

        this.props.personStore.setPersonsAndOrganizationsCount(-1);

        // this.getFavoriteData();

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }

    componentDidUpdate(prevProps) {
      if(this.favoriteDataRequested === false) {
        this.getFavoriteData();
      }

      if(this.sorting !== this.props.personStore.sortingAll) {
        if(this.listRef.current) this.listRef.current._listRef.resetAfterIndex(0);
      }

      if(this.props.generalStore.scrollToIndex !== -1){
        if(this.listRef.current) {
          this.listRef.current._listRef.scrollToItem(this.props.generalStore.scrollToIndex, 'start');
          // this.listRef.current._listRef.resetAfterIndex(0);
          this.props.generalStore.setScrollToIndex(-1)
        }
      }
    }

    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);

        this.props.personStore.setPersonsAndOrganizations([]);
        this.props.personStore.setPersonsAndOrganizationsCount(0);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }


    // onClickDelete(uid) {
    //     this.props.personStore.deletePerson(uid);
    // }

    toggleFavorite() {
        const profileSettings = toJS(this.props.generalStore.profileSettings);

        let favorites = {};
        if ('settings' in profileSettings && 'favorites' in profileSettings.settings) {
            favorites = profileSettings.settings.favorites;
        }

        if (this.cleanObject.uid in favorites) {
            delete favorites[this.cleanObject.uid];
        } else {
            if ('name' in this.cleanObject) {
                favorites[this.cleanObject.uid] = { name: this.cleanObject.name }
            } else {
                favorites[this.cleanObject.uid] = { firstName: this.cleanObject.firstName, lastName: this.cleanObject.lastName }
            }
        }

        ContactEditor.editFavorites(favorites, true);
        this.forceUpdate();
    }

    onClickEntry(entry) {
        if (entry.hasOwnProperty('name')) {
            this.props.history.push(`/detailview/organization/${entry.uid}`)
        } else if (entry.hasOwnProperty('firstName') || entry.hasOwnProperty('lastName')) {
            this.props.history.push(`/detailview/person/${entry.uid}`)
        }
    }

    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }

    renderRow(data) {
      const realIndex = data.index;

      let offsetIndex = realIndex - this.props.personStore.offset;

      if (this.props.personStore.personsAndOrganizations && offsetIndex in this.props.personStore.personsAndOrganizations) {
        const indexed = this.props.personStore.personsAndOrganizationsIndex[offsetIndex];

        return (
          <div key={data.index} style={data.style} className="row">
            {indexed && <ClusterLine key={indexed} text={indexed} />}
            <ResultGridItem
              key={this.props.personStore.personsAndOrganizations[offsetIndex].uid}
              entry={this.props.personStore.personsAndOrganizations[offsetIndex]}
              selectedIndex={this.state.selectedIndex}
            />
          </div>
        );
      } else {
        return (
          <div key={data.index} style={data.style} className="row">
            <ResultGridItem
              key={data.index}
              entry={{ firstName: '..', lastName: '..' }}
              selectedIndex={this.state.selectedIndex}
            />
          </div>
        );
      }
    }

    itemSizeGetter(i) {
      const indexed = this.props.personStore.personsAndOrganizationsIndex[i - this.props.personStore.offset];

      if (indexed) {
        return 138;
      } else {
        return 108;
      }
    }

    async loadMore(startIndex, stopIndex) {
      if(this.scrollUpdateWasRequested) {
        // console.log('loadMore scrollUpdateWasRequested asuming data is already there');
      } else {
        // console.log('Loading more', startIndex, '-->', stopIndex);

        const profileSettings = toJS(this.props.generalStore.profileSettings);

        if (
            typeof profileSettings === 'object'
            && 'settings' in profileSettings
            && 'favorites' in profileSettings.settings
            && Object.keys(profileSettings.settings.favorites).length !== 0
        ) {
            const uids = Object.keys(profileSettings.settings.favorites);

            const key = `${uids.join(',')}-${startIndex}`;
            if(this.favoriteDataRequested === key) {
              // console.log('Already loaded');
            } else {
              this.favoriteDataRequested = key;
              await this.props.personStore.setFavorites(uids, startIndex);
            }
        }
      }
      // console.log('Loading more', startIndex, '-->', stopIndex);
      // await this.props.personStore.setCurrentPersons(startIndex, this.scrollUpdateWasRequested);
    }

    isItemLoaded(index) {
      let offsetIndex = index - this.props.personStore.offset;
      if (
        this.props.personStore.persons
        && offsetIndex in this.props.personStore.persons
      ) return true;
      
      return false;
    }

    onScroll(scrollEvent) {
      if(typeof scrollEvent === 'object') {
        if(scrollEvent.scrollUpdateWasRequested) {
          this.scrollUpdateWasRequested = true;
        } else {
          this.scrollUpdateWasRequested = false;
        }
      }
    }

    render() {
        const scrollToIndex = this.props.generalStore.scrollToIndex; // Make react react
        const { classes } = this.props;
        const { t } = this.props;
        const height = calculateVlistHeight()
        const items = this.props.personStore.personsAndOrganizations;

        let counter = 0;

        const context = this;
        if (this.props.personStore.personsAndOrganizationsCount > 0 && this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizations.length > 0) {

            const count = ('personsAndOrganizationsCount' in this.props.personStore && this.props.personStore.personsAndOrganizationsCount !== null) ? this.props.personStore.personsAndOrganizationsCount : 0;
            return (
                <div>
                    <InfoBar children={<React.Fragment>{(count === 1) ? t('[v] favorite', { v: count }) : t('[v] favorites', { v: count })}</React.Fragment>} />

                      <InfiniteLoader
                        isItemLoaded={this.isItemLoaded.bind(this)}
                        itemCount={this.props.personStore.personsAndOrganizationsCount}
                        loadMoreItems={this.loadMore.bind(this)}
                        minimumBatchSize={this.props.personStore.personIncrementSize}
                        threshold={Math.ceil(this.props.personStore.personIncrementSize / 2)}
                        ref={this.listRef}
                      >
                      {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                          height={height}
                          itemCount={this.props.personStore.personsAndOrganizationsCount}
                          isItemLoaded={this.isItemLoaded.bind(this)}
                          itemSize={this.itemSizeGetter.bind(this)}
                          onItemsRendered={onItemsRendered}
                          overscanCount={10}
                          onScroll={this.onScroll.bind(this)}
                          ref={ref}
                        >
                          {this.renderRow.bind(context)}
                        </VariableSizeList>
                        )}
                      </InfiniteLoader>
                </div>
            )
        } else if (((this.props.personStore.personsAndOrganizations && this.props.personStore.personsAndOrganizationsCount === 0) || !this.props.personStore.personsAndOrganizations) && !this.state.isLoading) {
            return (
                    <SingleGridBlock className={classes.noFavorites} children={
                        <div style={{height: '100vh'}} >
                            <Typography className={classes.title} align="center" paragraph variant="h4">
                                {t('You have no favorites yet.')}
                            </Typography>
                            <div className={classes.explain}>{t('Click on the star icon next to a contacts name to add one.')}</div>
                            <EmptyStarIcon className={classes.emptyStarIcon} />
                        </div>} />
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.personStore.personsAndOrganizations) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div style={{height: '100vh'}} />} />);
        } else {
          return <div></div>;
        }
    }
}

Favorites.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('personStore', 'generalStore')(observer(Favorites)))));
