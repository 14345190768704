import store from '../stores/';

const updateServiceWorker = (registration) => {

    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
        registrationWaiting.postMessage({type: 'SKIP_WAITING'});

        registrationWaiting.addEventListener('statechange', e => {
            if (e.target.state === 'activated') {
              store.generalStore.addMessage(101, 'app updated, please reload', 'reload')
              // window.location.reload(true);
            }
          });
    }
}

export default updateServiceWorker