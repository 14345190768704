// This is our Joyride component for the onboarding tutorials
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";


// Mobx
import { inject, observer } from 'mobx-react';

// React Joyride
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';

// React Internationalization
import { withTranslation } from "react-i18next";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import ContactEditor from '../../actions/editContact'


import constants from '../../stores/constants';

interface State {
  run: boolean;
  steps: Step[];
  stepIndex: number;
  listView: string;
}

class ListJoyride extends Component<any, State> {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const steps = [
      {
        content: (
          <div>
            {t('See a list of all the contact persons')}
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        disableBeacon: true,
        spotlightClicks: true,
        target: '#persons',
        placement: 'bottom',
        title: t('List View'),
      },
      {
        content: (
          <div>
            {t('See a list of all the organizations')}
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        disableBeacon: true,
        spotlightClicks: true,
        target: '#organizations',
        placement: 'bottom',
        title: t('List View'),
      },
      {
        content: (
          <div>
            {t('See a list of all the contact persons and organizations')}
          </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        target: '#all',
        placement: 'bottom',
        title: t('List View'),
      },
      {
        content: (
          <div>
            {t('Here you can list your favorite contact persons and organizations')}
          </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        target: '#favorites',
        placement: 'bottom',
        title: t('List View'),
      },
      {
        content: (
          <div>
            {t('Here you can add new contacts')}
            <br />
            {t('Press the button to continue')}
          </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        target: '#addbutton',
        placement: 'top-end',
        title: t('Add new contacts'),
        hideFooter: true,
      },
      {
        content: (
          <div>
            {t('Please click ADD PERSON')}
          </div>
        ),
        disableBeacon: true,
        offset: 80,
        spotlightClicks: true,
        target: '#fabOpen',
        placement: 'top',
        title: t('Add new contacts'),
        hideBackButton: true,
        hideFooter: true,
      },
    ];

    const userStep = ('settings' in this.props.generalStore.profileSettings
      && 'listJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.listJoyride : 0;
    const fabOpen = this.props.tutorialStore.getValue('fabOpen');


    // Joyride Steps
    this.state = {
      stepIndex: userStep,
      listView: 'listPersons',
      run: false,
      steps: steps,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ('settings' in this.props.generalStore.profileSettings && this.state.run === false) {
      const userStep = ('settings' in this.props.generalStore.profileSettings
        && 'listJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.listJoyride : 0;

      const run = userStep < 100;

      if (this.state.stepIndex !== userStep) {
        this.setState({
          run: run,
          stepIndex: userStep,
        });
      } else {
        this.setState({
          run: run,
        });
      }
    }

  }

  componentDidUpdate() {
    if ('settings' in this.props.generalStore.profileSettings && this.state.run === false) {
      const userStep = ('settings' in this.props.generalStore.profileSettings
        && 'listJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.listJoyride : 0;
      const run = userStep < 100;

      if (this.state.stepIndex < userStep) {
        this.setState({
          run: run,
          stepIndex: userStep,
        });
      }

    }

    if (this.state.run === true && this.state.stepIndex < 5) {

      if (this.props.tutorialStore.getValue('fabOpen')) {
        this.setState(
          {
            //run: false,
            stepIndex: 5,
          }
        )
      }
    }
    else if (this.state.run === true && this.state.stepIndex === 5) {

      if (!(this.props.tutorialStore.getValue('fabOpen'))) {
        this.setState(
          {
            //run: false,
            stepIndex: 4,
          }
        )
      }
      else if (this.props.tutorialStore.getValue('addContact')) {
        this.setState(
          {
            stepIndex: 100,
            run: false,
          }
        )
        //this.props.tutorialStore.setTutorialState({ 'addContact': false });

      }
    }
  }

  handleJoyrideCallback(data: CallBackProps) {
    const { status, index, type, action } = data;
    const { listView } = this.state;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    const stepTypes = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const fabOpen = this.props.tutorialStore.getValue('fabOpen');
    const addContact = this.props.tutorialStore.getValue('addContact');

    if (finishedStatuses.includes(status)) {
      ContactEditor.editProfileSettings({ listJoyride: 100 });
      this.setState({
        run: false,
        //stepIndex: 0,
      });
      if (addContact) {
        this.props.tutorialStore.setTutorialState({ 'fabOpen': false });
        this.props.tutorialStore.setTutorialState({ 'addContact': false });
        //this.props.history.push(`${constants.path.create}/person`);
      }

    }
    else if (action === ACTIONS.CLOSE) {
      this.setState({
        run: false,
      });
    }
    else if (stepTypes.includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (!(listView === 'listPersons') && stepIndex === 0) {
        this.props.history.push(constants.path.listPersons);
        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState(
          {
            listView: 'listPersons',
            stepIndex: stepIndex,
          }
        );
      }
      else if (!(listView === 'listOrganizations') && stepIndex === 1) {
        this.props.history.push(constants.path.listOrganizations);

        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState(
          {
            listView: 'listOrganizations',
            stepIndex: stepIndex,
          }
        );
      }
      else if (!(listView === 'listAll') && stepIndex === 2) {
        this.props.history.push(constants.path.listAll);

        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState(
          {
            listView: 'listAll',
            stepIndex: stepIndex,
          });
      }
      else if (!(listView === 'listFavorites') && stepIndex === 3) {
        this.props.history.push(constants.path.listFavorites);

        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState(
          {
            listView: 'listFavorites',
            stepIndex: stepIndex,
          });
      }
      else if (!(fabOpen) && stepIndex === 4) {
        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState({
          listView: 'fabOpen',
          stepIndex: stepIndex,
        });
      }
      else if (fabOpen && stepIndex === 5) {
        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState({
          listView: 'fabOpen',
          stepIndex: stepIndex,
          //run: true,
        });
      }
      else if (addContact) {
        console.log('addContact');
        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState({
          listView: 'listPersons',
          stepIndex: 5,
          run: false,
        });
      }
      else {
        ContactEditor.editProfileSettings({ listJoyride: stepIndex });

        this.setState({
          listView: '',
          stepIndex: stepIndex,
        });
      }

    }
  }

  render() {
    const { classes } = this.props;

    const { steps, run, stepIndex } = this.state;

    const settings = this.props.generalStore.profileSettings; // keep to make react update on setting changes
    const tutorial = this.props.tutorialStore.tutorialState;
    const fabOpen = this.props.tutorialStore.getValue('fabOpen');

    return (
      <div className={classes.root}>
        <Joyride
          callback={this.handleJoyrideCallback.bind(this)}
          //debug={true}
          steps={steps}
          stepIndex={stepIndex}
          showProgress={true}
          showSkipButton={true}
          continuous={true}
          spotlightPadding={90}
          run={run}
          disableOverlay={true}
          scrollOffset={0}
          scrollToStep={0}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

      </div>
    )
  }
}
ListJoyride.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(withStyles)(withRouter(withTranslation("joyride")(inject('generalStore', 'tutorialStore')(observer(ListJoyride)))));
