import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import TimeIcon from '@material-ui/icons/AccessTime';
import ReplayIcon from '@material-ui/icons/Replay';



// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  avatarInactive: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.inactive,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  avatarInactiveCluster: {
    margin: 10,
    // color: "#fff",
    color: constants.color.main,
    // backgroundColor: constants.color.inactive,
    backgroundColor: '#fff',
    border: `2px solid ${constants.color.main}`,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  historyItem: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 200,
    height: 'auto',
    clear: 'both',
    borderLeft: '5px solid #eee',
    marginLeft: theme.spacing.unit * 2,
  },
  historyItemLast: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 30,
    height: 'auto',
    clear: 'both',
    borderLeft: '5px solid transparent',
    marginLeft: theme.spacing.unit * 2,
    marginTop: -12,
  },
  historyItemCluster: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 200,
    height: 'auto',
    clear: 'both',
    borderLeft: `5px double rgba(46, 139, 190, 0.7)`,
    marginLeft: theme.spacing.unit * 2,
  },
  historyItemLastCluster: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 30,
    height: 'auto',
    clear: 'both',
    borderLeft: '5px solid transparent',
    marginLeft: theme.spacing.unit * 2,
    marginTop: -12,
  },
  avatarHolder: {
    position: 'absolute',
    left: (theme.spacing.unit * 2 + 12) * -1,
    top: theme.spacing.unit * -2,
  },
  avatarHolderCluster: {
    position: 'absolute',
    left: (theme.spacing.unit * 2 + 13) * -1,
    top: 0,
  },
  contentHolder: {
    width: '80%',
    float: 'left',
    marginTop: -21,
    marginLeft: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 6,
  },
  contentHolderCluster: {
    width: '80%',
    float: 'left',
    marginTop: -5,
    marginLeft: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 6,
  },
  headInfo: {
    color: constants.color.heading,
    fontSize: '14px',
    textAlign: 'left',
    margin: 0,
    marginBottom: theme.spacing.unit,
    overflow: 'hidden',
  },
  mainInfo: {
    textAlign: 'left',
    margin: 0,
    maxHeight: 240,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeInfo: {
    marginTop: 20,
  },
  infoBubble: {
    backgroundColor: '#eee',
    borderRadius: 5,
    padding: theme.spacing.unit,
    overflow: 'hidden',
  },
  timeIcon: {
    fontSize: '14px',
    marginBottom: -2,
    marginRight: 2,
  },
  cssFix: {
    width: '100%',
    height: 10,
    clear: 'both',
  },
  valueItem: {
    overflowWrap: 'break-word'
  }
});

class HistoryItem extends Component {
  state = {
    avatarError: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError() {
    this.setState({ avatarError: true });
  }

  itemClick() {
    if (this.props.journal && this.props.journal === true) {
      console.log(this.props.data);
      if (this.props.data && 'uid' in this.props.data && this.props.data.uid !== '') {
        // const person = true;
        if (this.props.data.objectType && this.props.data.objectType !== '') {
          const path = (this.props.data.objectType === 'person') ? constants.path.detailviewPerson : constants.path.detailviewOrganization;
          this.props.history.push(`${path}/${this.props.data.uid}`);
        }
      }
    }
  }

  render() {
    const { classes, t, data, inCluster, journal, revertEvent } = this.props;

    let initials = (data.user && data.user.length > 0) ? data.user.split(' ') : [];
    if (initials.length > 1) {
      initials = `${initials[0][0]}${initials.pop()[0]}`;
    } else if (initials.length > 0) {
      initials = initials[0][0];
    } else {
      initials = '';
    }

    let items = [];

    let itemStyle = {};
    if(window.innerWidth < constants.breakpoints.small) {
      itemStyle = { fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis' };
    } else if(this.props.displayType === 'oih') {
      itemStyle.fontSize = '14px';
    }

    if(this.props.journal) {
      if('entryName' in data) {
        items.push(<Grid container key={`ke`} className={classes.mainInfo}>
          <Grid item xs={4} style={itemStyle}>
            {t('Entry')}
          </Grid>
          <Grid className={classes.valueItem} item xs={6} >
            <b>{data.entryName}</b>
          </Grid>
        </Grid>);
      } else if('sourceName' in data) {
        items.push(<Grid container key={`ke`} className={classes.mainInfo}>
          <Grid item xs={4} style={itemStyle}>
            {data.sourceName}
          </Grid>
          <Grid className={classes.valueItem} item xs={6} >
            {data.targetName}
          </Grid>
        </Grid>);
      }
    }

    if (typeof data.value === 'object') {
      items.push(<Grid container key={`k0`} className={classes.mainInfo}>
        <Grid item xs={10} style={itemStyle} >
          {t(data.operation)}
        </Grid>
        {revertEvent &&
          <Grid item xs={2}>
            <IconButton style={{ padding: 3 }} onClick={() => revertEvent(data.eventId)}>
              <ReplayIcon style={{ color: constants.color.main }} />
            </IconButton>
          </Grid>
        }
      </Grid>);


      if(Array.isArray(data.value)) {
        const subItems = [];
        if(data.value.length>0) {
          subItems.push(<b key={'b1'}>{data.value[0]}</b>);
          for(let i=1; i<data.value.length; i+=1) {
            subItems.push(<><br/><small key={`s${i}`}>({data.value[i]})</small></>);
          }

          items.push(<Grid container
            key={`ksub`} className={classes.mainInfoSmall}>
            <Grid className={classes.valueItem} item xs={12} >
              {subItems}
            </Grid>

          </Grid>);
        }
      } else {
        for (const key in data.value) {
          if (key === 'uid' || key === 'contextRef') continue;
          if (!data.value[key] || data.value[key].trim() === '') continue;
          items.push(<Grid container
            key={`k${key}`} className={classes.mainInfo}>
            <Grid item xs={4} style={itemStyle}>
              {t(key)}
            </Grid>
            <Grid className={classes.valueItem} item xs={6} >
              <b>{data.value[key]}</b>
            </Grid>

          </Grid>);

        }
      }

      if (revertEvent) {
        items.push(

        )
      }
    } else {
      if (!data.value || data.value === '') {
        let parts = t(data.operation).split(' ');

        if (parts.length > 1) {
          data.value = parts.pop();
          data.operation = parts.join(' ')
        }
      }

      items.push(<Grid container key={`k${data.operation}`} className={classes.mainInfo}>
        <Grid item xs={4} style={itemStyle}>
          {t(data.operation)}
        </Grid>
        <Grid className={classes.valueItem} item xs={6} >
          <b>{`${'categoryName' in data? data.categoryName : ''} ${data.value}`.trim()}</b>
        </Grid>
        {revertEvent &&
          <Grid item xs={2}>
            <IconButton style={{ padding: 3 }} onClick={() => revertEvent(data.eventId)}>
              <ReplayIcon style={{ color: constants.color.main }} />
            </IconButton>
          </Grid>
        }
      </Grid>);
    }

    let itemClass;
    if (inCluster) {
      itemClass = ('lastItem' in data) ? classes.historyItemLastCluster : classes.historyItemCluster;
    } else {
      itemClass = ('lastItem' in data) ? classes.historyItemLast : classes.historyItem;
    }

    let blockStyle = (this.props.contained)? {width: '100%'} : {};

    let timeInfo = {};
    let contentHolderStyle = {};
    let headInfo = {};
    if(window.innerWidth < constants.breakpoints.small) {
      timeInfo = { fontSize: '12px' };
      headInfo = { fontSize: '12px' };
      contentHolderStyle = { width: '85%' };
      blockStyle = { width: '95vw' };
    }

    const hint = (this.props.displayType && this.props.displayType === 'oih')? t('used by') : t('changed by');
    return (
      <div key={`grid-${data.user}`} className={itemClass} style={blockStyle}>
        <div className={inCluster ? classes.avatarHolderCluster : classes.avatarHolder}>
          <Avatar
            className={`relation-center ${(inCluster) ? classes.avatarInactiveCluster : classes.avatarInactive}`}
            src={this.state.avatarError ? null : data.image}
            onError={this.avatarError.bind(this)}
          >
            {initials}
          </Avatar>
        </div>
        <div className={inCluster ? classes.contentHolderCluster : classes.contentHolder} style={contentHolderStyle}>
          <Grid container className={classes.allInfo}>
            <Grid item xs={4} className={classes.timeInfo} style={timeInfo}>
              <TimeIcon className={classes.timeIcon} />
              {journal && journal === true &&
                <>{data.formatedDate.split(' ')[0]}</>
              }
              {!journal &&
                <>{data.formatedDate}</>
              }
            </Grid>
            <Grid item xs={8} className={classes.infoBubble}
              onClick={this.itemClick.bind(this)}
              style={(journal && journal === true) ? { cursor: 'pointer' } : {}}>
              <Grid container className={classes.headInfo} style={headInfo}>
                <Grid item xs={4} >
                  {(data.formatedDate === '') ? '' : hint}
                </Grid>
                <Grid item xs={8} >
                  {data.user}
                </Grid>
              </Grid>
              <Grid container className={classes.mainInfo}>
                {items}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.cssFix}></div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(withTranslation("translations")(HistoryItem))
);
