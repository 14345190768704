
// import moment from 'moment'
import getContact from "../../actions/getContact";
import { createNote, updateNote, deleteNote } from "../../actions/note"

import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/KeyboardArrowDown';

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';

import NoteItem from './NoteItem';

import insane from 'insane';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  noteBlock: {
    position: 'relative',
    // width: '90vw',
    // maxWidth: '90vw',
    // padding: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    clear: 'both',
  },
  contentHolder: {
    width: '85vw',
    maxWidth: '600px',
    float: 'right',
    textAlign: 'left',
  },
  addButtonHolder: {
    position: 'absolute',
    right: theme.spacing.unit,
    marginTop: theme.spacing.unit * -2,
  },
  addButton: {
    color: constants.color.main,
  },
  addButtonDisabled: {
    color: '#eee'
  },
  moreButton: {
    color: constants.color.main
  },
  typography: {
    paddingTop: 10,
    paddingLeft: theme.spacing.unit * 2.5,
    fontSize: "14px",
    color: constants.color.heading,
    clear: "both",
  },
  divider: {
    marginBottom: theme.spacing.unit,
  },
  dialogContent: {
    maxHeight: 80,
    overflow: 'hidden',
    maxWidth: 300,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  noteItems: {
    padding: theme.spacing.unit,
    maxWidth: '90vw',
  },
});

class NoteBlock extends Component {
  state = {
    avatarError: false,
    notes: false,
    max: 0,
    count: 0,
    editIndex: false,
    deleteDialog: false,
    selectedUid: '',
    selectedNoteContent: '',
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    let data;
    if ('notes' in this.props) {
      data = this.props.notes;
    } else {
      data = await getContact.getNotes(this.props.entryUid, 1, 10);
    }

    this.setState({ count: (this.state.count + data.notes.length), max: data.count, notes: data.notes });
  }

  async loadMoreNotes() {
    if ('notes' in this.props) {
      if ('callback' in this.props) {
        this.callback();
      }
    } else {
      const page = Math.ceil(this.state.count / 10);

      const data = await getContact.getNotes(this.props.entryUid, page + 1, 10);

      let allNotes = this.state.notes.concat(data.notes);
      this.setState({ count: (this.state.count + data.notes.length), notes: allNotes });
    }
  }

  avatarError() {
    this.setState({ avatarError: true });
  }

  addNote() {
    const notes = Array.isArray(this.state.notes) ? this.state.notes : [];
    notes.unshift({
      meta: {
        username: '',
      },
      entryName: '',
      type: '',
      uid: '',
      entryUid: '',
      content: '',
      date: '',
      isNew: true,

    })
    this.setState({
      notes,
      editIndex: 0,
      count: this.state.count + 1,
    })
  }

  async confirmChanges(content, uid) {
    const notes = this.state.notes;

    const index = this.state.editIndex;
    notes[index].content = content;

    this.setState({
      editIndex: false,
      notes,
    });

    const cleanContent = insane(content, {
      allowedTags: [],
    });


    if (!uid) {
      const result = await createNote(cleanContent, this.props.type, this.props.entryUid, this.props.entryName);
      if(result.meta){
        notes[index].meta = result.meta;
        notes[index].uid = result.payload.notes.uid;
        notes[index].entryUid = result.payload.uid;
        delete notes[index].isNew;
        this.setState({
          notes,
        })
      }
    } else {
      await updateNote(cleanContent, this.props.type, this.props.entryUid, this.props.entryName, uid);
    }
  }

  cancelEdit() {
    this.setState({
      editIndex: false
    })

    const notes = this.state.notes;
    if (notes[0].isNew) {
      notes.shift()
      this.setState({
        notes
      })
    }
  }

  enterEditMode(index) {
    this.setState({
      editIndex: index
    });
  }

  async deleteNote(noteUid) {
    const notes = this.state.notes;
    const index = notes.findIndex((element) => element.uid === noteUid);

    if (index !== -1) {
      notes.splice(index, 1)
    }

    this.setState({
      notes,
      editIndex: false,
      count: this.state.count -1,
    })

    await deleteNote(noteUid, this.props.entryUid);
  }


  handleDeleteOpen = (label, uid) => {
    this.setState({
      deleteDialog: true,
      selectedUid: uid,
      selectedNoteContent: label,
    });
  };

  handleDeleteCancel = () => {
    this.setState({ deleteDialog: false });
  };

  handleDeleteConfirm = async () => {
    this.deleteNote(this.state.selectedUid);
    this.setState({ selectedUid: '' });
    this.setState({ deleteDialog: false });
  };

  handleDeleteCancel = () => {
    this.setState({ selectedUid: '' });
    this.setState({ deleteDialog: false });
  };

  render() {
    const { classes, journal, t } = this.props;
    const passedData = this.state.notes;

    let data = passedData;

    if (!passedData || passedData === false || passedData === 'loading') {
      data = [
        {
          meta: {
            username: '',
          },
          entryName: '',
          type: '',
          uid: '',
          entryUid: '',
          content: '',
          date: '',
        },
      ];

      if (journal && journal === true) {
        let i;
        for (i = 0; i < 5; i++) {
          data.push({
            meta: {
              username: '',
            },
            entryName: '',
            type: '',
            uid: '',
            entryUid: '',
            content: '',
            date: '',
          });
        }
      }
    }

    const items = [];
    const length = data.length;

    if (length > 0) data[data.length - 1].lastItem = true;

    let i;
    let lastDate = '';
    let lastResult;
    for (i = 0; i < length; i++) {
      if (passedData === false || passedData === 'loading') {
        data[i].date = '';
        data[i].formatedDate = '';
      } else {
        if (lastDate === data[i].date) {
          data[i].formatedDate = lastResult;
          continue;
        }
        const date = new Date(data[i].updatedAt);

        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);

        if (journal && journal === true) {
          data[i].formatedDate = `${day}.${month}.${year} `;
        } else {
          const hour = `0${date.getHours()}`.slice(-2);
          const minute = `0${date.getMinutes()}`.slice(-2);
          data[i].formatedDate = `${day}.${month}.${year} ${hour}:${minute}`;
        }

        lastDate = data[i].updatedAt;
        lastResult = data[i].formatedDate;
      }
    }

    for (i = 0; i < length; i++) {
      items.push(
          <NoteItem
            journal={journal}
            confirmChanges={this.confirmChanges.bind(this)}
            cancelEdit={this.cancelEdit.bind(this)}
            deleteNote={this.handleDeleteOpen.bind(this)}
            editMode={this.state.editIndex === i}
            key={`h${i}`}
            data={data[i]}
            enterEditMode={this.enterEditMode.bind(this, i)}
            />
      );
    }

    if (length > 0) data[length - 1].lastItem = true;

    // if(length <= 0)  return false;
    let noEdit = false;
    if('noEdit' in this.props) {
      noEdit = this.props.noEdit;
    } else if(
      'premium' in this.props
      && this.props.premium === false
      &&  this.state.count > 0
    ){
      noEdit = true;
    }

    return (
      <div key={`grid-${data.user}`} className={classes.noteBlock}>
        <Typography
          className={classes.typography}
          variant="body1"
          align="left"
        >
          {t("Notes")}
          {noEdit === false &&
            <IconButton className={classes.addButtonHolder} onClick={this.addNote.bind(this)} disabled={this.state.editIndex !== false}>
              <AddIcon className={this.state.editIndex === false ? classes.addButton : classes.addButtonDisabled} />
            </IconButton>
          }
        </Typography>
        <Divider variant="middle" className={classes.divider} />
        <div className={classes.noteItems}>{items}</div>
        {(this.state.count < this.state.max && this.props.premium) &&
          <IconButton onClick={this.loadMoreNotes.bind(this)}><MoreIcon className={classes.moreButton} /></IconButton>
        }

        <Dialog
          open={this.state.deleteDialog}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Confirm Note Delete")}
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogContent}>{this.state.selectedNoteContent}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteConfirm} color="secondary">
              {t('Delete')}
            </Button>
            <Button onClick={this.handleDeleteCancel} color="primary" autoFocus>
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(NoteBlock)
);
