// Unifies all our stores

import GeneralStore from "./general";
import AuthStore from "./auth";
import PersonStore from "./personStore";
import OrganizationStore from "./organizationStore";
import TenantStore from "./tenantStore";
import CategoriesStore from "./categoriesStore";
import RelationsStore from "./relationsStore";
import GraphStore from "./graphStore";
import ConflictsStore from "./conflictsStore";
import TutorialStore from "./tutorialStore";
import WebhooksStore from "./webhooksStore";
import WebhookUrlsStore from "./webhookUrlsStore";
import OauthStore from "./oauthStore";
import IntegrationStore from "./integrationStore";
import DashboardStore from "./dashboardStore";
import GroupsStore from "./groupsStore";
import GovernanceStore from "./governanceStore";

import { configure } from "mobx";

configure({ enforceActions: "observed" });

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
    this.generalStore = new GeneralStore(this);
    this.personStore = new PersonStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.tenantStore = new TenantStore(this);
    this.categoriesStore = new CategoriesStore(this);
    this.relationsStore = new RelationsStore(this);
    this.conflictsStore = new ConflictsStore(this);
    this.tutorialStore = new TutorialStore(this);
    this.graphStore = new GraphStore(this);
    this.webhooksStore = new WebhooksStore(this);
    this.webhookUrlsStore = new WebhookUrlsStore(this);
    this.oauthStore = new OauthStore(this);
    this.integrationStore = new IntegrationStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.governanceStore = new GovernanceStore(this);
    this.groupsStore = new GroupsStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
