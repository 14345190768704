// Renders a single result of type person or organization in a listView

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';

import Checkbox from '@material-ui/core/Checkbox';

// React Internationalization
import { withTranslation } from 'react-i18next';

// Mobx Stores
import constants from '../../stores/constants';
// import store from '../../stores';

const styles = (theme) => ({
	control: {
		padding: theme.spacing.unit
	},
	avatar: {
		margin: 10,
		marginLeft: 0,
		color: '#fff',
		backgroundColor: constants.color.main,
		width: '8vw', //50,
		height: '8vw', //50,
		maxWidth: 50,
		maxHeight: 50,
		fontSize: 'calc(12px + .5vw)',
		float: 'left',
		[theme.breakpoints.up('sm')]: {
			fontSize: '16px'
		}
	},
	subTitles: {
		float: 'left',
		display: 'block',
		width: '100%',
		padding: 0,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		// marginLeft: theme.spacing.unit * 1,
		marginLeft: '-16px',
		maxHeight: 60,
		fontSize: 14,
	},
	link: {
		width: '80%',
		flexGrow: '1',
		display: 'flex',
	},
	actions: {
		float: 'right',
		display: 'block',
		// width: '70px',
		width: theme.spacing.unit * 8.8,
		paddingRight: 20
	},
	actionLink: {
		outline: 0,
		color: constants.color.main
	},
	actionDivider: {
		'border-right': `1px solid ${constants.color.main}`,
		display: 'inline-block',
		marginLeft: '5px',
		marginRight: '5px',
		height: '24px'
	},
	actionDividerInactive: {
		'border-right': `1px solid ${constants.color.main}`,
		display: 'inline-block',
		marginLeft: '5px',
		marginRight: '5px',
		height: '24px',
		borderColor: constants.color.inactive
	},
	inactive: {
		color: constants.color.inactive
	},
	listOrganizations: {
		fontSize: '12px',
		color: 'rgba(0, 0, 0, 0.54)'
	},
	name: {
		marginTop: theme.spacing.unit * 2.2,
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},
	organizationName: {
		position: 'relative',
		bottom: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 1,
		paddingTop: 7,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		// overflow: 'hidden'
	},
	grid: {
		height: theme.spacing.unit * 12
	},
	userProfile: {
		position: 'absolute',
		marginLeft: theme.spacing.unit * 0.5,
		marginTop: theme.spacing.unit * 0.5,
		color: constants.color.main
	},
	fullName: {
		marginLeft: theme.spacing.unit * -2
	},
	userIcon: {
		height: '20px',
		width: '20px',
		marginLeft: theme.spacing.unit * 0.7,
		marginTop: theme.spacing.unit * 0.2
	},
	flexContainer: {
		flexShrink: 0,
    flexGrow: 1,
	},
	checkContainer: {
		maxWidth: 100,
		zIndex: 3,
	},
	textContainer: {
		// flexGrow: 3,
		padding:0,
		marginLeft: theme.spacing.unit*1.5,
		marginTop: theme.spacing.unit,
	},
	scoreContainer: {
		padding:0,
		paddingTop: 5,
		width: 60,
		maxWidth: 60,
	},
	matchCountContainer: {
		width: 30,
		maxWidth: 30,
		flexGrow: 3,
	},
});

class GridItem extends Component {
	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	handleChange(id, value, event, x) {
		this.props.setCheckmark(id, value === false);
		this.forceUpdate();
	}

	handleActionsClick(event) {
		event.stopPropagation();
	}

	render() {
		const { classes, entry } = this.props;

		let fullName = '';
		let imageUrl;

		// console.log(toJS(entry));
		// entry.logo ? (imageUrl = entry.logo) : (imageUrl = entry.photo);

		if ('nameModified' in entry && typeof entry.nameModified !== 'undefined') {
			fullName = <React.Fragment>{entry.nameModified}</React.Fragment>;
		} else if('conflicts' in entry && 'incomingObject' in entry.conflicts && 'name' in entry.conflicts.incomingObject){
			fullName = entry.conflicts.incomingObject.name;
		}

		const date = (entry.conflicts && entry.conflicts.foundAt)? new Date(entry.conflicts.foundAt) : new Date();

		const foundDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()}`;

		const score = (entry.conflicts && entry.conflicts.bestScore) ? Math.ceil((entry.conflicts.bestScore)*10000) / 100 : 0;

		const check = (entry.checked)? entry.checked : false;

		const matchCount = ('conflicts' in entry && 'conflictsWith' in entry.conflicts)? Object.keys(entry.conflicts.conflictsWith).length : 0;

		return (
			<Grid className={classes.grid} item xs={12} key={entry._id}>
				<div className={classes.control} data-uid={entry._id}>
					<Grid container>
							<ListItem className={classes.flexContainer} key={this.props.id} button selected={this.props.selectedIndex === 0}>
								<ListItemText className={classes.checkContainer}>
									<Checkbox
										checked={check}
										onChange={this.handleChange.bind(this, this.props.id, check)}
										value={entry._id}
										inputProps={{
											'aria-label': 'secondary checkbox',
										}}
									/>
								</ListItemText>
								<Link to={`${constants.path.detailviewDuplicate}/${entry._id}`} className={classes.link}>
									<Avatar className={classes.avatar} src={imageUrl}>
										{fullName && fullName[0]}
									</Avatar>

									<ListItemText className={classes.textContainer}>
										<div className={classes.fullName}>{fullName}</div>

										<div className={classes.subTitles}>
											{foundDate}
										</div>
									</ListItemText>

									<ListItemText className={classes.matchCountContainer}>
									{matchCount}
									</ListItemText>

									<ListItemText className={classes.scoreContainer}>
									<LinearProgress
									value={score}
									variant='determinate'
									style={{height: 15}}
									/>
									</ListItemText>
								</Link>
							</ListItem>
					</Grid>
				</div>
			</Grid>
		);
	}
}

export default withStyles(styles)(withTranslation('translations')(withRouter(GridItem)));
