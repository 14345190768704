import store from '../stores'
import constants from '../stores/constants'
import getCategories from '../actions/getCategories'
import getGroups from '../actions/getGroups'
import _ from 'lodash'
// import { toJS } from 'mobx'

export function calculateVlistHeight() {
  const desktop = window.innerWidth >= constants.breakpoints.max;
  const medium = window.innerWidth >= constants.breakpoints.medium

  let height = window.innerHeight - 210;
  if (!desktop && store.generalStore.scrolled) {
    if (medium) {
      height = window.innerHeight - 100
    } else {
      height = window.innerHeight - 70
    }
  }

  return height;
}
export async function formatEvents(events, uid) {
  const formatted = []

  if (_.isEmpty(store.categoriesStore.categories)) {
    await getCategories.populateCategories();
  }

  if (_.isEmpty(store.groupsStore.groups)) {
    await getGroups.populateGroups();
  }

  // const x0 = Date.now();
  // const categories = toJS(store.categoriesStore.categories);
  // const persons = toJS(store.personStore.persons);
  // const organizations = toJS(store.organizationStore.organizations);
  const categoriesHash = (typeof store.categoriesStore.categories === 'object')? store.categoriesStore.categories.reduce((map, obj) => { map[obj.uid] = { firstName: obj.firstName, lastName: obj.lastName }; return map }, {}) : {};

  const groupsHash = (typeof store.groupsStore.groups === 'object')? store.groupsStore.groups.reduce((map, obj) => { map[obj.uid] = { label: obj.label }; return map }, {}): {};
  const personsHash = (typeof store.personStore.persons === 'object')? store.personStore.persons.reduce((map, obj) => { map[obj.uid] = { firstName: obj.firstName, lastName: obj.lastName }; return map }, {}) : {};
  const organizationsHash = (typeof store.organizationStore.organizations === 'object')? store.organizationStore.organizations.reduce((map, obj) => { map[obj.uid] = { name: obj.name }; return map }, {}) : {};
  // console.log('only hashing', Date.now() - x0);

  for (const ev of events) {
    const formEvent = {
      operation: ev.eventName.toLowerCase(),
      value: '',
      uid: ev.payload.uid || '',
      eventId: ev.eventId || '',
      date: ev.timeStamp || '',
      user: ev.meta.username || '',
      objectType: '',
    }

    if('entryUid' in ev.payload) formEvent.entryUid = ev.payload.entryUid;
    if('entryName' in ev.payload) formEvent.entryName = ev.payload.entryName;

    if (ev.eventName.indexOf('Person') > -1) {
      formEvent.objectType = 'person';
    } else if (ev.eventName.indexOf('Organization') > -1) {
      formEvent.objectType = 'organization';
    }

    if (ev.eventName === ('PersonCreated')) {
      formEvent.value = (ev.payload.firstName + ' ' + ev.payload.lastName).trim()
    } else if (ev.eventName === ('PersonDeleted')) {
      formEvent.value = (ev.payload.fullName)
    } else if (ev.eventName === ('OrganizationCreated')) {
      formEvent.value = (ev.payload.name)
    } else if (ev.eventName === ('OrganizationDeleted')) {
      formEvent.value = (ev.payload.name)
    } else if (ev.eventName === 'PersonContactDataCreated' || ev.eventName === 'OrganizationContactDataCreated') {
      formEvent.operation = 'contactdatacreated';
      formEvent.value = ev.payload.contactData[0];
    } else if (ev.eventName === 'PersonContactDataDeleted' || ev.eventName === 'OrganizationContactDataDeleted') {
      formEvent.operation = 'contactdatadeleted';
      formEvent.value = 'deleted';
    } else if (ev.eventName === 'PersonAddressCreated' || ev.eventName === 'OrganizationAddressCreated') {
      formEvent.operation = 'addresscreated';
      formEvent.value = ev.payload.addresses[0];
    } else if (ev.eventName === 'PersonAddressDeleted' || ev.eventName === 'OrganizationAddressDeleted') {
      formEvent.operation = 'addressdeleted';
      formEvent.value = 'deleted';
    } else if (ev.eventName === 'PersonFirstNameUpdated') {
      formEvent.operation = 'firstname'
      formEvent.value = ev.payload.firstName;
    } else if (ev.eventName === 'PersonLastNameUpdated') {
      formEvent.operation = 'lastname'
      formEvent.value = ev.payload.lastName;
    } else if (ev.eventName === 'PersonMiddleNameUpdated') {
      formEvent.operation = 'middlename'
      formEvent.value = ev.payload.middleName;
    } else if (ev.eventName === ('NoteCreated') || ev.eventName === ('NoteUpdated')) {
      formEvent.value = ev.payload.notes.content || ''
    } else if (ev.eventName === ('Deleted')) {
      formEvent.value = 'deleted'
    }

    else if ((/^(Person)\w+(Updated)$/).test(ev.eventName)) {

      let field

      if (ev.payload.addresses) {
        field = ev.eventName.substr(13, (ev.eventName.length - 20)).toLowerCase()
        formEvent.value = ev.payload.addresses[0][field]
      } else if (ev.payload.contactData) {
        field = ev.eventName.substr(17, (ev.eventName.length - 24)).toLowerCase()
        formEvent.value = ev.payload.contactData[0][field]
      } else {
        field = ev.eventName.substr(6, (ev.eventName.length - 13));
        field = `${field[0].toLowerCase()}${field.substr(1)}`;
        formEvent.value = ev.payload[field];
      }

      formEvent.operation = field;

    } else if ((/^(Organization)\w+(Updated)$/).test(ev.eventName)) {

      let field

      if (ev.payload.addresses) {
        field = ev.eventName.substr(19, (ev.eventName.length - 26)).toLowerCase()
        formEvent.value = ev.payload.addresses[0][field]
      } else if (ev.payload.contactData) {
        field = ev.eventName.substr(23, (ev.eventName.length - 30)).toLowerCase()
        formEvent.value = ev.payload.contactData[0][field]
      } else {
        field = ev.eventName.substr(12, (ev.eventName.length - 19)).toLowerCase()
        formEvent.value = ev.payload[field];
      }

      formEvent.operation = field;

    } else if (
      ev.eventName === 'CategoryPersonAdded'
      || ev.eventName === 'CategoryOrganizationAdded'
      || ev.eventName === 'CategoryAddressAdded'
      || ev.eventName === 'CategoryContactDataAdded'
    ) {

      formEvent.operation = 'categoryadded';
      formEvent.uid = ev.payload.member.uid;
      const catUid = ev.payload.uid;

      formEvent.value = ev.payload.categoryName;

      // if (_.isEmpty(categories)) {
      //   await getCategories.populateCategories();
      // }

      if('categoryName' in ev.payload) {
        formEvent.value = ev.payload.categoryName;
      } else {
        // const category = _.find(categories, cat => cat.uid === catUid);
        const category = (catUid in categoriesHash) ? categoriesHash[catUid] : null;

        if (category) formEvent.value = category.label;
        else formEvent.value = 'notfound';
      }
    } else if (ev.eventName === 'CategoryPersonRemoved' || ev.eventName === 'CategoryOrganizationRemoved') {
      if('categoryName' in ev.payload) formEvent.categoryName = ev.payload.categoryName;

      formEvent.operation = 'categoryremoved';
      formEvent.uid = ev.payload.member.uid;
      const catUid = ev.payload.uid;

      // if (_.isEmpty(categories)) {
      //   await getCategories.populateCategories();
      // }

      // const category = _.find(categories, cat => cat.uid === catUid);
      const category = (catUid in categoriesHash) ? categoriesHash[catUid] : null;

      if (category) formEvent.value = category.label;
      else formEvent.value = 'notfound'

    } else if (ev.eventName === 'CategoryDeleted' || ev.eventName === 'CategoryCreated') {
      formEvent.operation = ev.eventName.toLowerCase();
      formEvent.value = ev.payload.label || '';
    } else if (ev.eventName === 'RelationTypeDeleted' || ev.eventName === 'RelationTypeCreated') {
      formEvent.operation = ev.eventName.toLowerCase();
      formEvent.value = ev.payload.label || '';
    } else if ((/^(Relation)\w+(Created)$/).test(ev.eventName)) {

      if('sourceName' in ev.payload) formEvent.sourceName = ev.payload.sourceName;
      if('targetName' in ev.payload) formEvent.targetName = ev.payload.targetName;

      formEvent.operation = 'relationcreated';
      formEvent.value = ev.payload.label;
      if (ev.payload.uids) formEvent.uid = ev.payload.uids[0];

      // Check if partner is in stores
      let partnerUid;
      if (uid) {
        partnerUid = (ev.payload.uids[0] === uid) ? ev.payload.uids[1] : ev.payload.uids[0]
      } else if (ev.payload.uids) {
        partnerUid = ev.payload.uids[0]
      }

      // let partner = _.find(persons, pers => pers.uid === partnerUid)
      let partner = (partnerUid in personsHash) ? personsHash[partnerUid] : null;

      if (partner) {
        formEvent.value = formEvent.value + ` (${(partner.firstName + ' ' + partner.lastName).trim()})`
      } else {
        partner = (partnerUid in organizationsHash) ? organizationsHash[partnerUid] : null;
        // partner = _.find(organizations, org => org.uid === partnerUid)
        if (partner) {
          formEvent.value = formEvent.value + ` (${partner.name})`
        }
      }

    } else if ((/^(Relation)\w+(Deleted)$/).test(ev.eventName)) {

      if('sourceName' in ev.payload) formEvent.sourceName = ev.payload.sourceName;
      if('targetName' in ev.payload) formEvent.targetName = ev.payload.targetName;

      formEvent.operation = 'relationdeleted';
      formEvent.value = ev.payload.label || '';
      if (ev.payload.uids) formEvent.uid = ev.payload.uids[0];

      // Check if partner is in stores
      let partnerUid;
      if (uid) {
        partnerUid = (ev.payload.uids[0] === uid) ? ev.payload.uids[1] : ev.payload.uids[0]
      } else if (ev.payload.uids) {
        partnerUid = ev.payload.uids[0]
      }

      // let partner = _.find(persons, pers => pers.uid === partnerUid);
      let partner = (partnerUid in personsHash) ? personsHash[partnerUid] : null;
      if (partner) {
        formEvent.value = formEvent.value + ` (${(partner.firstName + ' ' + partner.lastName).trim()})`
      } else {
        // partner = _.find(organizations, org => org.uid === partnerUid)
        partner = (partnerUid in organizationsHash) ? organizationsHash[partnerUid] : null;
        if (partner) {
          formEvent.value = formEvent.value + ` (${partner.name})`
        }
      }
    } else if ((/^(Group)\w+(Added|Removed)$/).test(ev.eventName)) {
      formEvent.operation = (ev.eventName.indexOf('Added') > -1)? 'groupadded': 'groupremoved';

      const groupUid = ev.payload.uid;
      if('groupLabel' in ev.payload) {
        formEvent.value = ev.payload.groupLabel;
      } else {
        const groups = (groupUid in groupsHash) ? groupsHash[groupUid] : null;
        if (groups) formEvent.value = groups.label;
        else formEvent.value = 'notfound';
      }
    }

    formatted.push(formEvent)
  }

  return formatted;
}
