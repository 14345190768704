// General storage for variables that are needed on several pages

import Cookies from 'universal-cookie';

import { decorate, observable, action } from 'mobx';
import i18n from '../i18n/index';

const cookies = new Cookies();

let lang = cookies.get('lang', { path: '/', domain: '.snazzycontacts.com' });

class Store {
	language;
	counter;
	menue;
	profile;
	profileSettings;
	savedSearches;
	person;
	organization;
	list;
	loading;
	query;
	searchResults;
	numberOfResults;
	searchType;
	errorMessage;
	context;
	scrolled;
	invites;
	selectedPartner;
	uploading;
	showUploadDialog;
	scrollToIndex;
	showSnackBar;
	snackBarContent;
	showBanner;
	bannerContent;
	snackQueue;
	bannerQueue;
	fileList;
	importFileList;
	backendData;
	listState;
	showMenue;
	selectedDetailItem;
	listState;
	entriesAmount;
	personsAmount;
	organizationsAmount;
	searchPending;

	constructor() {
		this.language = lang ? lang : 'de';
		cookies.set('lang', this.language, { path: '/' });
		this.menue = false;
		this.list = [];
		this.person = null;
		this.organization = null;
		this.profile = null;
		this.profileSettings = {};
		this.savedSearches = {};
		this.loading = false;
		this.list = [];
		this.errorMessage = '';
		this.errorDataHeader = '';
		this.errorData = '';
		this.person = null;
		this.query = '';
		this.searchResults = [];
		this.quickSearchResults = [];
		this.numberOfResults = -1;
		this.searchType = 'standard';
		this.searchPending = false;

		this.notesSearchResults = [];
		this.quickNotesSearchResults = [];
		this.numberOfNotesResults = -1;
		this.notesSearchType = 'standard';

		this.scrolled = 0;
		this.invites = {};
		this.selectedPartner = {};
		this.uploading = 0; //false;
		this.showUploadDialog = false;
		this.scrollToIndex = -1;
		this.showSnackBar = false;
		this.snackBarContent = {};
		this.showBanner = false;
		this.bannerContent = {};
		this.snackQueue = [];
		this.bannerQueue = [];
		this.fileList = [];
		this.importFileList = [];
		this.backendData = {};
		this.exporting = 0;
		this.showMenue = 'innerWidth' in window && window.innerWidth > 900;
		this.listState = 'personsAndOrganizations';
		this.entriesAmount = 0;
		this.personsAmount = 0;
		this.organizationsAmount = 0;
		// this.selectedDetailItem = {};

		setInterval(this.checkSnackQueue.bind(this), 5000);
		setInterval(this.checkBannerQueue.bind(this), 5000);
	}

	toggleShowMenue() {
		console.log(this.showMenue);
		this.showMenue = !this.showMenue;
		console.log(this.showMenue);
	}

	setLanguage(lng) {
		this.language = lng;
		cookies.set('lang', '', { path: '/', expires: new Date('01 Jan 1999 00:00:00 PDT') });
		cookies.remove('lang', '', { path: '/' });
		cookies.set('lang', this.language, { path: '/' });
	}

	setMenue(currentMenu) {
		this.menue = currentMenu;
	}

	setProfile(currentProfile) {
		this.profile = currentProfile;
	}

	setProfileSettings(currentSettings, message) {
		this.profileSettings = JSON.parse(JSON.stringify(currentSettings));
	}

	setSavedSearches(data) {
		this.savedSearches = data;
	}

	setPerson(currentPerson) {
		this.person = currentPerson;
	}

	setOrganization(currentOrganization) {
		this.organization = currentOrganization;
	}

	setList(currentList) {
		this.list = currentList;
	}

	setLoading(isLoading) {
		this.loading = isLoading;
	}

	setQuery(qry) {
		this.query = qry;
	}

	setNotesQuery(qry) {
		this.query = qry;
	}

	setSearchResults(results) {
		this.searchResults = results;
	}

	setQuickSearchResults(results) {
		this.quickSearchResults = results;
	}

	setNumberOfResults(x) {
		this.numberOfResults = x;
	}

	setSearchType(x) {
		this.searchType = x;
	}

	setNotesSearchResults(results) {
		this.notesSearchResults = results;
	}

	setQuickNotesSearchResults(results) {
		this.quickNotesSearchResults = results;
	}

	setSearchPending(value) {
		this.searchPending = (value === true);
	}

	setNumberOfNotesResults(x) {
		this.numberOfNotesResults = x;
	}

	setNotesSearchType(x) {
		this.notesSearchType = x;
	}

	setErrorMessage(x) {
		this.errorMessage = x;
	}

	setErrorDataHeader(x) {
		this.errorDataHeader = x;
	}

	setErrorData(x) {
		this.errorData = x;
	}

	setContext(context) {
		this.context = context;
	}

	setScrolled(x) {
		this.scrolled = x;
	}

	setInvites(x) {
		this.invites = x;
	}

	setSelectedPartner(value) {
		this.selectedPartner = value;
	}

	setUploading(value) {
		this.uploading = value;
		let flag = undefined;
		if (value !== 0 && value < 100) flag = ' ';
		window.onbeforeunload = function() {
			return flag;
		};
	}

	setExporting(value) {
		this.exporting = value;
		let flag = undefined;
		if (value !== 0 && value < 100) flag = ' ';
		window.onbeforeunload = function() {
			return flag;
		};
	}

	setShowUploadDialog(value) {
		this.showUploadDialog = value;
	}

	setProfilePhoto(value) {
		this.profile.photo = value;
	}

	organizationInList(id) {
		// Will check whether the organization object is already in the list
		// If so, directly return that organization
		if (this.list && this.list.some && this.list.some((e) => e.uid === id)) {
			return this.list.find((organization) => organization.uid === id);
		}
		return false;
	}

	personInList(id) {
		// Will check whether the person object is already in the list
		// If so, directly return that person
		if (this.list && this.list.some && this.list.some((e) => e.uid === id)) {
			return this.list.find((person) => person.uid === id);
		}
		return false;
	}

	setScrollToIndex(i) {
		this.scrollToIndex = i;
	}

	//showSnackBar
	//showBanner
	addMessage(rawCode, text, type, linkData) {
		const code = parseInt(rawCode);
		const translated = i18n.t(text);

		if (typeof type === 'undefined') {
			this.snackQueue.push({ code, text: translated, type, linkData });
		} else {
			if (code === 200) {
				this.snackQueue.push({ code, text: translated, type, linkData });
			} else {
				this.bannerQueue.push({ code, text: translated, type, linkData });
			}
		}

		this.checkSnackQueue();
		this.checkBannerQueue();
	}

	hideSnackBar() {
		this.showSnackBar = false;
	}

	hideBanner() {
		this.showBanner = false;
	}

	setFileList(list) {
		this.fileList = list;
	}

	setImportFileList(list) {
		this.importFileList = list;
	}

	setBackendData(data) {
		this.backendData = data;
	}

	checkSnackQueue() {
		// Is called periodically to decide if we need to show a snack message
		// this.showSnackBar = false;
		if (this.snackQueue.length > 0) {
			let current = this.snackQueue.shift();
			this.showSnackBar = true;
			this.snackBarContent = current;
		}
	}

	checkBannerQueue() {
		// Is called periodically to decide if we need to show a banner
		// this.showBanner = false;
		if (this.bannerQueue.length > 0) {
			let current = this.bannerQueue.shift();
			this.showBanner = true;
			this.bannerContent = current;
		}
	}

	resetErrorMessage() {
		this.errorMessage = '';
	}

	setSelectedDetailItem(data) {
		this.selectedDetailItem = data;
	}

	setListState(value) {
		this.listState = value;
	}

	setEntriesAmount(value) {
		this.entriesAmount = value;
	}

	setPersonsAmount(value) {
		this.personsAmount = value;
	}

	setOrganizationsAmount(value) {
		this.organizationsAmount = value;
	}

	changePersonsAmount(operation) {
		if (operation === 'increase') {
			this.personsAmount += 1;
			this.entriesAmount += 1;
		} else {
			this.personsAmount -= 1;
			this.entriesAmount -= 1;
		}
	}

	changeOrganizationsAmount(operation) {
		if (operation === 'increase') {
			this.organizationsAmount += 1;
			this.entriesAmount += 1;
		} else {
			this.organizationsAmount -= 1;
			this.entriesAmount -= 1;
		}
	}
}

decorate(Store, {
	language: observable,
	menue: observable,
	hasLogin: observable,
	token: observable,
	organization: observable,
	person: observable,
	profile: observable,
	profileSettings: observable,
	savedSearches: observable,
	list: observable,
	loading: observable,
	searchResults: observable,
	quickSearchResults: observable,
	searchPending: observable,
	numberOfResults: observable,
	searchType: observable,
	notesSearchResults: observable,
	quickNotesSearchResults: observable,
	numberOfNotesResults: observable,
	noteSearchType: observable,
	errorMessage: observable,
	erroDataHeader: observable,
	errorData: observable,
	context: observable,
	scrolled: observable,
	invites: observable,
	selectedPartner: observable,
	uploading: observable,
	showUploadDialog: observable,
	scrollToIndex: observable,
	showSnackBar: observable,
	snackBarContent: observable,
	showBanner: observable,
	bannerContent: observable,
	fileList: observable,
	importFileList: observable,
	backendData: observable,
	exporting: observable,
	showMenue: observable,
	selectedDetailItem: observable,
	listState: observable,
	entriesAmount: observable,
	personsAmount: observable,
	organizationsAmount: observable,

	setQuery: action.bound,
	setNotesQuery: action.bound,

	setLanguage: action.bound,
	organizationInList: action.bound,
	personInList: action.bound,
	setProfile: action.bound,
	setProfileSettings: action.bound,
	setSavedSearches: action.bound,
	setPerson: action.bound,
	setOrganization: action.bound,
	setMenue: action.bound,
	setHasLogin: action.bound,
	setToken: action.bound,
	setLoading: action.bound,
	setSearchResults: action.bound,
	setQuickSearchResults: action.bound,
	setNumberOfResults: action.bound,
	setNotesSearchResults: action.bound,
	setQuickNotesSearchResults: action.bound,
	setNumberOfNotesResults: action.bound,
	setSearchType: action.bound,
	setNotesSearchType: action.bound,
	setSearchPending: action.bound,
	setErrorMessage: action.bound,
	resetErrorMessage: action.bound,
	setErrorDataHeader: action.bound,
	setErrorData: action.bound,
	setContext: action.bound,
	setScrolled: action.bound,
	setInvites: action.bound,
	setSelectedPartner: action.bound,
	setUploading: action.bound,
	setShowUploadDialog: action.bound,
	setProfilePhoto: action.bound,
	setScrollToIndex: action.bound,
	addMessage: action.bound,
	checkSnackQueue: action.bound,
	checkBannerQueue: action.bound,
	hideSnackBar: action.bound,
	hideBanner: action.bound,
	setFileList: action.bound,
	setImportFileList: action.bound,
	setBackendData: action.bound,
	setExporting: action.bound,
	toggleShowMenue: action.bound,
	setSelectedDetailItem: action.bound,
	setListState: action.bound,
	setEntriesAmount: action.bound,
	setPersonsAmount: action.bound,
	setOrganizationsAmount: action.bound,
	changePersonsAmount: action.bound,
	changeOrganizationsAmount: action.bound
});

// const store = new Store();

export default Store;
