import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import QRCode from 'qrcode';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = constants.apiUrl;

// Get a single vCard formated entry as text
export const getVcard = async (uid, type) => {
    try {
      const token = store.authStore.token;

      const response = await trackPromise(axios.get(
          `${apiUrl}/export/vcard/${type}/${store.generalStore.language}/${uid}`,
          {
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              }
          }
      ));

      if (response.status === 200) {
          return response.data;
      } else if (response.status === 401) {
          store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
      }
    } catch (e) {
        // console.log(e);
        store.generalStore.addMessage(500, 'vCard could not be retrieved');
        return history.push('/not-found');
    }
};

// Create a qrCode from a string
export const createQRCode = async (text) => {
  // const segs = [{ data: text, mode: 'byte' }];

  try {
    const code = await QRCode.toDataURL(text, {color: {
      dark: constants.color.main,
      light: '#0000',
    }});

    return code;
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Get a single vCard formated entry as qrCode
export const createVCardQRCode = async (uid, type, callback) => {
  const data = await getVcard(uid, type);
  const code = await createQRCode(data.vCard);
  callback(code);
};


// Get download link for a single vCard based on the entry
export const getVCardDownloadLink = (uid, type) => {
  return `${apiUrl}/export/vcard/${type}/${store.generalStore.language}/${uid}?download=true`;
};
