// Render a list of search results

import React, { Component } from 'react';

import { Link } from "react-router-dom";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from "@material-ui/core/Button";

import EditIcon from "@material-ui/icons/Edit";

// React Internationalization
import { withTranslation } from "react-i18next";


// Components

import constants from '../../../stores/constants';


const styles = theme => ({
  root: {
    textAlign: 'left',
  },

});

class StartEdit extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  render() {
    const { classes, t } = this.props;

    return (
      <div>
        <Grid container className={classes.root} spacing={8}>
          <Grid item xs={12} sm={12} md={12}>
            <Link to={constants.path.dashboardEditor} id="editlink" className={classes.editLinkStyle}>
               <Button variant="contained" color="primary" id="startEdit">
                 <EditIcon className={classes.editIcon} /> {t("Edit the dashboard now")}
               </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(StartEdit));
