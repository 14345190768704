import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = constants.apiUrl;

// Create a whole new note
export const createNote = async (content, type, entryUid, entryName) => {
	const { token } = store.authStore

	const note = {
		type,
		uid: entryUid,
		entryName,
		notes: {
			content
		}
	}

	try {
		const response = await trackPromise(
			axios.post(`${apiUrl}/note`,
				note,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					}
				})
		);

		if (response.status === 200) {
			store.generalStore.addMessage(200, 'Note created');
			return response.data;
		}
		store.generalStore.addMessage(500, 'Note could not be created');
		return false;
	} catch (e) {
		// console.log(e);
		store.generalStore.addMessage(500, 'Note could not be created');
	}
};

// Update an existing note
export const updateNote = async (content, type, entryUid, entryName, noteUid) => {
	const { token } = store.authStore

	const note = {
		type,
		uid: entryUid,
		entryName,
		notes: {
			content,
			uid: noteUid
		}
	}

	try {
		const response = await trackPromise(
			axios.put(`${apiUrl}/note`,
				note,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					}
				})
		);

		if (response.status === 200) {
			store.generalStore.addMessage(200, 'Note updated');
			return response.data;
		}
		store.generalStore.addMessage(500, 'Note could not be updated');
		return false;
	} catch (e) {
		// console.log(e);
		store.generalStore.addMessage(500, 'Note could not be updated');
	}
};

// Delete an existing note
export const deleteNote = async (noteUid, entryUid) => {
	const { token } = store.authStore

	const note = {
		uid: entryUid,
		notes: {
			uid: noteUid
		}
	}

	try {
		const response = await trackPromise(
			axios.delete(`${apiUrl}/note`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					data: note
				})
		);

		if (response.status === 200) {
			store.generalStore.addMessage(200, 'Note deleted');
			return response.data;
		}
		store.generalStore.addMessage(500, 'Note could not be deleted');
		return false;
	} catch (e) {
		// console.log(e);
		store.generalStore.addMessage(500, 'Note could not be deleted');
	}
};

