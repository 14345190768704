// Render a list of search results

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import DuplicatesIcon from '@material-ui/icons/RecentActors';
import DoubleIcon from '@material-ui/icons/ChevronRight';

import { Link } from "react-router-dom";

// Components

import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  textLine: {
    textAlign: 'left',
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  icon: {},
  text: {
    lineHeight: '28px',
  },
});

class Found extends Component {
  // _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }
  //
  // componentDidMount() {
  //     this._isMounted = true;
  // }

  render() {
    const { classes, t, data } = this.props;

    let duplicates = '';
    let relations = '';

    if(data) {
      duplicates = data.duplicates;
      relations = data.relations;
    }

    return (
      <div>
        <b id="foundData">{t('Improve your database fast')}</b><br/><br/>
        <Grid item xs={12} sm={12} md={12} className={classes.textLine}>
          <Link to={constants.path.listDuplicates}>
            <Grid container className={classes.root} spacing={8}>
              <Grid item xs={1} sm={1} md={1} className={classes.icon}>
                <DuplicatesIcon />
              </Grid>
              <Grid item xs={8} sm={8} md={8} className={classes.text}>
                {duplicates} {t('possible duplicates found')}
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12} md={12} className={classes.textLine}>
          <Link to={constants.path.listRelationSuggestions}>
            <Grid container className={classes.root} spacing={8}>
              <Grid item xs={1} sm={1} md={1} className={classes.icon}>
                <DoubleIcon />
              </Grid>
              <Grid item xs={8} sm={8} md={8} className={classes.text}>
                {relations} {t('possible relations detected')}
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(Found));
