import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const apiUrl = constants.apiUrl;

export const counter = async (token) => {
	try {
		if ('role' in store.authStore.userData && store.authStore.userData.role === 'ADMIN') return;

		if(!token || token === '') {
			return setTimeout(()=>{counter(store.authStore.token)},300);
		}


		const response = await axios.get(`${apiUrl}/counter`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		store.generalStore.setEntriesAmount(response.data.all);
		store.generalStore.setPersonsAmount(response.data.persons);
		store.generalStore.setOrganizationsAmount(response.data.organizations);
	} catch (error) {
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Entries could not be retrieved');

		if (!('role' in store.authStore.userData) || store.authStore.userData.role !== 'ADMIN') return history.push('/not-found');
		return;
	}
};
