// This is our Joyride component for the onboarding tutorials
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";


// Mobx
import { inject, observer } from 'mobx-react';

// React Joyride
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';

// React Internationalization
import { withTranslation } from "react-i18next";

// Material UI
import { withStyles } from '@material-ui/core/styles';

import ContactEditor from '../../actions/editContact'


import constants from '../../stores/constants';

interface State {
  run: boolean;
  steps: Step[];
  stepIndex: number;
  listView: string;
}

class DashboardJoyride extends Component<any, State> {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const steps = [
      {
        content: (
          <div>
            {t('Drop data to import here')}
          </div>
        ),
        disableBeacon: true,
        offset: 0,
        spotlightClicks: true,
        target: '#importDrop',
        placement: 'left',
        title: t('Dashboard'),
      },
      {
        content: (
          <div>
            {t('Complete your incomplete entries')}
          </div>
        ),
        offset: 0,
        disableBeacon: true,
        spotlightClicks: true,
        target: '#incompleteData',
        placement: 'left',
        title: t('Dashboard'),
      },
      {
        content: (
          <div>
            {t('Manage your integrations')}
            <br/>
            {t('Connect Snazzy Contacts with other apps')}
          </div>
        ),
        disableBeacon: true,
        offset: 0,
        spotlightClicks: true,
        target: '#integrationData',
        placement: 'left',
        title: t('Dashboard'),
      },
      {
        content: (
          <div>
            {t('Improve your database')}
            <br/>
            {t('Snazzy Contacts finds new duplicates and relations for you everyday')}
          </div>
        ),
        disableBeacon: true,
        offset: 0,
        spotlightClicks: true,
        target: '#foundData',
        placement: 'left',
        title: t('Dashboard'),
      },
      {
        content: (
          <div>
            {t('Edit your Dashboard here')}
          </div>
        ),
        disableBeacon: true,
        offset: 0,
        spotlightClicks: true,
        target: '#startEdit',
        placement: 'right',
        title: t('Dashboard'),
      },
    ];

    const userStep = ('settings' in this.props.generalStore.profileSettings
      && 'DashboardJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.dashboardJoyride : 0;


    // Joyride Steps
    this.state = {
      stepIndex: userStep,
      listView: 'goPremium',
      run: false,
      steps: steps,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ('settings' in this.props.generalStore.profileSettings && this.state.run === false) {
      const userStep = ('settings' in this.props.generalStore.profileSettings
        && 'dashboardJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.dashboardJoyride : 0;

      const run = userStep < 100;

      if (this.state.stepIndex !== userStep) {
        this.setState({
          run: run,
          stepIndex: userStep,
        });
      } else {
        this.setState({
          run: run,
        });
      }
    }

  }

  componentDidUpdate() {
    if ('settings' in this.props.generalStore.profileSettings && this.state.run === false) {
      const userStep = ('settings' in this.props.generalStore.profileSettings
        && 'dashboardJoyride' in this.props.generalStore.profileSettings.settings) ? this.props.generalStore.profileSettings.settings.dashboardJoyride : 0;
      const run = userStep < 100;

      if (this.state.stepIndex < userStep) {
        this.setState({
          run: run,
          stepIndex: userStep,
        });
      }

    }
/*
    if (this.state.run === true && this.state.stepIndex < 5) {

      if (this.props.tutorialStore.getValue('fabOpen')) {
        this.setState(
          {
            //run: false,
            stepIndex: 5,
          }
        )
      }
    }
    else if (this.state.run === true && this.state.stepIndex === 5) {

      if (!(this.props.tutorialStore.getValue('fabOpen'))) {
        this.setState(
          {
            //run: false,
            stepIndex: 4,
          }
        )
      }
      else if (this.props.tutorialStore.getValue('addContact')) {
        this.setState(
          {
            stepIndex: 100,
          }
        )
        this.props.tutorialStore.setTutorialState({ 'addContact': false });

      }
    }
*/
  }

  handleJoyrideCallback(data: CallBackProps) {
    const { status, index, type, action } = data;
    const { dashboardView } = this.state;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    const stepTypes = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

    if (finishedStatuses.includes(status)) {
      ContactEditor.editProfileSettings({ dashboardJoyride: 100 });
      this.setState({
        run: false,
        stepIndex: 0,
      });
/*      if (fabOpen) {
        this.props.tutorialStore.setTutorialState({ 'fabOpen': false });
        this.props.tutorialStore.setTutorialState({ 'savedContact': false });
        this.props.history.push(`${constants.path.create}/person`);
      }*/

    }
    else if (action === ACTIONS.CLOSE) {
      this.setState({
        run: false,
      });
    }
    else if (stepTypes.includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

        ContactEditor.editProfileSettings({ dashboardJoyride: stepIndex });

        this.setState({
          listView: '',
          stepIndex: stepIndex,
        });
      //}

    }
  }


  render() {
    const { classes } = this.props;

    const { steps, run, stepIndex } = this.state;

    const settings = this.props.generalStore.profileSettings; // keep to make react update on setting changes
    const tutorial = this.props.tutorialStore.tutorialState;

    return (
      <div className={classes.root}>
        <Joyride
          callback={this.handleJoyrideCallback.bind(this)}
          steps={steps}
          //debug={true}
          stepIndex={stepIndex}
          showProgress={true}
          showSkipButton={true}
          continuous={true}
          spotlightPadding={0}
          run={run}
          disableOverlay={true}
          scrollOffset={10}
          //disableScrollParentFix={true}
          //scrollToStep={0}
          scrollToFirstStep={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

      </div>
    )
  }
}
DashboardJoyride.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(withStyles)(withRouter(withTranslation("joyride")(inject('generalStore', 'tutorialStore')(observer(DashboardJoyride)))));
