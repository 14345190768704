import React from "react";

import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import { trackPromise } from 'react-promise-tracker';

const apiUrl = constants.apiUrl;

class Duplicates extends React.Component {
  // Search for entries with duplicate name
  static async searchDuplicates(data){
    let terms = [];
    if(data.name){
      terms.push(`name=${encodeURIComponent(data.name)}`);
    } else {
      if(data.firstName) terms.push(`firstname=${encodeURIComponent(data.firstName)}`);
      if(data.lastName) terms.push(`lastname=${encodeURIComponent(data.lastName)}`);
    }

    terms = terms.join('&')

    let response;
    try {
      response = await trackPromise(axios.get(
          `${apiUrl}/search/duplicates?${terms}`,
          {
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${store.authStore.token}`
              }
          }
      ));

      if (response.status === 200 || response.status === 404) {
          store.generalStore.setBackendData(response.data);
          return response.data;
      }else if(response.status === 204){
        store.generalStore.setBackendData([-1,"No results found"]);
        return [-1,"No results found"];
      }

    } catch (e) {
      if(e && typeof e === 'object' && ('response' in e) && typeof e.response === 'object' && ('status' in e.response) && e.response.status === 404) {
        store.generalStore.setBackendData(e.response.data);
        return e.response.data;
      }
    }


  }

  // Get all duplicates
  static async getDuplicates(token, page, num) {
      try {
          const response = await trackPromise(axios.get(
              `${apiUrl}/duplicate/report?page=${page}&num=${num}`,
              {
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  }
              }
          ));

          if (response.status === 200) {
              return response.data;
          }else if(response.status === 204){
            return {data:[], meta:{count: 0}};
          } else if (response.status === 401) {
              store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
          }
      } catch (e) {
          // console.log(e);
          store.generalStore.addMessage(500, 'Duplicates could not be retrieved');
          return history.push('/not-found');
      }
  };


  static async populateDuplicate(id, forceRefresh) {
      forceRefresh = true; // we need to refresh always otherwise there can be conflicts

      if (forceRefresh) { store.conflictsStore.setDuplicate(null); }

      let response;
      try {
          response = await trackPromise(axios.get(
              `${apiUrl}/duplicate/report/${id}`,
              {
                  validateStatus: false,
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${store.authStore.token}`
                  }
              }
          ));

          if (response.status === 200) {
              store.conflictsStore.setDuplicate(response.data);
          } else if (response.status === 401) {
              store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
              store.conflictsStore.setDuplicate(404);
          } else if (response.status === 410) {
              store.generalStore.addMessage(200, 'Conflict already solved');
              store.conflictsStore.deleteDuplicateEntry(id);
              history.push(constants.path.listDuplicates);
          } else {
              store.conflictsStore.setDuplicate(404);
          }
      } catch (e) {
        if(response && response.status && response.status === 410) {
          store.generalStore.addMessage(200, 'Conflict already solved');
          store.conflictsStore.deleteDuplicateEntry(id);
          history.push(constants.path.listDuplicates);
        } else {
          store.conflictsStore.setDuplicate(404);
          console.log(e);
        }
      }
  }

  static async resolve(action, id, incomingUid, targetUid, type, result) {
    const values = { action, incomingUid, targetUid, type, result };

    try {
        const response = await trackPromise(axios.put(
            `${apiUrl}/duplicate/resolve/${id}`,
            values,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.conflictsStore.deleteDuplicateEntry(id);
            history.push(constants.path.listDuplicates);
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
          store.generalStore.addMessage(500, 'Error: Resolution could not be applied');
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Error: '+e);
        console.log(e);
    }
  }

  static async resolveBulk(action, ids) {
    const values = { action, ids };

    try {
        const response = await trackPromise(axios.put(
            `${apiUrl}/duplicate/resolve/bulk`,
            values,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.authStore.token}`
                }
            }
        ));

        if (response.status === 200) {
            store.conflictsStore.setCheckedAll(false);
            store.conflictsStore.deleteDuplicateEntries(ids);
            return true;
        } else if (response.status === 401) {
            store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
        } else {
          store.generalStore.addMessage(500, 'Error: Resolutions could not be applied');
        }
    } catch (e) {
        store.generalStore.addMessage(500, 'Error: '+e);
        console.log(e);
    }
    return false;
  }
}

export default Duplicates;
