import { observable, decorate, action, toJS } from 'mobx';
import duplicates from '../actions/duplicates';

class ConflictsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    allChecked = false;
    selections = 0;
    duplicate = {};
    duplicates = [];
    duplicatesIndex = {};
    duplicatesHash = {};

    sorting = 'regular';
    duplicateCount = 0;

    // Set duplicates in mobx store
    setDuplicates = duplicates => {
        this.duplicates = duplicates;
        if (duplicates && duplicates.length > 1) {
            this.sortDuplicates(this.sorting);
        }
    }

    setDuplicate = duplicate => {
        this.duplicate = duplicate;
    }

    deleteDuplicateEntry(id) {
      const allEntries = JSON.parse(JSON.stringify(this.duplicates))
      const result = allEntries.filter(e => e._id !== id);
      this.duplicates = result;
      this.duplicateCount = result.length;
    }

    deleteDuplicateEntries(ids) {
      const duplicateLength = this.duplicates.length;
      const hash = {};
      for (let i=0; i<ids.length; i+=1) {
        hash[ids[i]] = 1;
      }

      const allEntries = JSON.parse(JSON.stringify(this.duplicates))

      const result = allEntries.filter(e => !(e._id in hash));
      this.duplicates = result;
      this.duplicateCount = result.length;
    }

    setChecked(id, value){
      this.duplicates[id].checked = value;
      if(value === true){
        this.selections += 1;
      } else {
        this.selections -= 1;
      }
    }

    setCheckedAll(value){
      this.allChecked = value;
      let key;
      let c=0;
      for(key in this.duplicates) {
        this.duplicates[key].checked = value;
        c+=1;
      }

      if(value === true){
        this.selections = c;
      } else {
        this.selections = 0;
      }
    }

    // Set duplicates count in mobx store
    setDuplicateCount = count => {
        this.duplicateCount = count;
    }

    // Fetch all duplicates
    getDuplicates = async () => {
        const { token } = this.rootStore.authStore;

        let page = 2;
        const num = 10000
        const res = await duplicates.getDuplicates(token, 1, num);

        if (res) {
            this.setDuplicates(res.data);
            if (res.meta) {
                this.setDuplicateCount(res.meta.count);
                // (this.sorting !== 'lastUpdate') && this.indexDuplicates();
                // this.indexDuplicates();
            }

            if (res.meta.count > num) {
                for (let i = (num + 1); i < res.meta.count; i += num) {
                    const nextRes = await duplicates.getDuplicates(token, page, num)
                    const newList = nextRes.data.concat(toJS(this.duplicates))
                    this.setDuplicates(newList)
                    page++;
                }
            }
        }

        return this.duplicates;
    }

    indexDuplicates() {
        const sorting = this.sorting;

        const hash = {};
        const shortHash = {};
        const duplicateLength = this.duplicates.length;
        let i;
        let j;
        let c;
        let char;
        let code;

        for (i = 0; i < duplicateLength; i += 1) {
            if (!this.duplicates[i]) continue;
            char = this.duplicates[i].name.substr(0, 1).toUpperCase();

            if (char === '') continue;
            const currentCode = char.charCodeAt(0);
            if (currentCode < 64 || currentCode > 91) continue;
            code = currentCode;

            // is first starting with this letter
            if (char in hash === false) {
                if (sorting === 'regular') {
                    //  check if previous anchors exist to close gaps
                    for (j = code - 1; j > 64; j -= 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        }
                    }
                } else {
                    //  reverse sorting / inverted
                    for (j = code + 1; j < 91; j += 1) {
                        c = String.fromCharCode(j);
                        if (c in hash === false) {
                            hash[c] = i;
                        }
                    }
                }
                hash[char] = i;
                shortHash[char] = i;

            }
        }

        if (i >= duplicateLength) i = duplicateLength - 1;

        if (sorting === 'inverted') {
            if (code > 64) {
                for (i = code - 1; i > 64; i -= 1) {
                    c = String.fromCharCode(i);
                    hash[c] = i;
                }
            }
        } else {
            if (code < 91) {
                for (i = code + 1; i < 91; i += 1) {
                    c = String.fromCharCode(i);
                    hash[c] = i;
                }
            }
        }

        this.duplicatesHash = hash;

        let duplicatesIndex = {};
        const keys = Object.keys(shortHash);
        const length = keys.length;

        for (i = 0; i < length; i++) {
            if (!duplicatesIndex[shortHash[keys[i]]]) duplicatesIndex[shortHash[keys[i]]] = keys[i];
        }

        this.duplicatesIndex = duplicatesIndex;
    }

    // Sort duplicates
    sortDuplicates = (order) => {
        let sortedOrgs = [];

        if (order === 'regular') {
            this.sorting = 'regular';
            sortedOrgs = this.duplicates.slice().sort(function (a, b) {
                if (a.conflicts.bestScore > b.conflicts.bestScore) {
                    return -1;
                }
                if (a.conflicts.bestScore < b.conflicts.bestScore) {
                    return 1;
                }
                return 0;
            });
        } else if (order === 'inverted') {
            this.sorting = 'inverted';
            sortedOrgs = this.duplicates.slice().sort(function (a, b) {
                if (a.conflicts.bestScore > b.conflicts.bestScore) {
                    return 1;
                }
                if (a.conflicts.bestScore < b.conflicts.bestScore) {
                    return -1;
                }
                return 0;
            });
        } else if (order === 'lastUpdate') {
            this.sorting = 'lastChecked';
            sortedOrgs = this.duplicates.slice().sort(function (a, b) {
                return b.lastChecked - a.lastChecked;
            });
        }

        if (order === 'lastUpdate') {
            this.duplicates = sortedOrgs;
            this.duplicatesIndex = {}
        } else {
            this.duplicates = sortedOrgs;
            // this.indexDuplicates();
        }

    }
}

decorate(ConflictsStore, {
    duplicate: observable,
    duplicates: observable,
    duplicateCount: observable,
    duplicatesIndex: observable,
    duplicatesHash: observable,
    selections: observable,

    setDuplicateCount: action,
    setDuplicates: action,
    setDuplicate: action,
    deleteDuplicateEntry: action,
    deleteDuplicateEntries: action,
    getDuplicates: action,
    sortDuplicates: action,
    setChecked: action,
    setCheckedAll: action,
})

export default ConflictsStore;
