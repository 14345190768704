// Render a list of search results

import React, { Component } from 'react';

// Mobx Stores
import { observer, inject } from "mobx-react";

// Material UI
import { withStyles } from '@material-ui/core/styles';

// import HistoryIcon from '@material-ui/icons/History';

import { formatEvents } from '../../../utils/helpers';

// React Internationalization
import { withTranslation } from "react-i18next";



// Components
import HistoryBlock from "../../templates/HistoryBlock";

// Actions

// Stores
// import constants from '../../../stores/constants';



const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  textLine: {
    textAlign: 'left',
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  }
});

class Journal extends Component {
  _isMounted = false;
  state = {
    events: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  async componentDidMount() {
    // this.props.generalStore.setListState('journal');
    // this._isMounted = true;

    // const events = await getContact.getAllEvents(10);

    let events = this.props.data;
    if(events) {
      events = await formatEvents(events);
      if(events) this.setState({events});
    }
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.data !== this.props.data) {
      let events = this.props.data;
      if(events) {
        events = await formatEvents(events);
        if(events) this.setState({events});
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;

    let style = {};
    // if(window.innerWidth < constants.breakpoints.small)
    //   style = { marginLeft: 0 };

    return (
      <div key={'journal'} style={style} id="journalData">
        <b>{t('Journal of changes')}</b><br/>
        <HistoryBlock journal={true} contained={true} data={this.state.events} revertEvent={false} />
      </div>
    );
  }
};

export default withTranslation("translations")(withStyles(styles)(inject("generalStore")(observer(Journal))));
