import React from 'react';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router'
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ExpandLess';
import ArrowDropUp from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';

// Mobx Stores
import store from '../../../stores/';
import constants from '../../../stores/constants';

// React Internationalization
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    // right: '5px',
    float: 'right',
    marginTop: theme.spacing.unit * -1,
    marginRight: theme.spacing.unit * 1,
  },
  toolTip: {
    right: '35px',
    // right: theme.spacing.unit * 3.5,
    marginTop: theme.spacing.unit * -1,
    zIndex: 35
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  menuButton: {
    '&:hover': {
      backgroundColor: '#fff',
    }
  }
});

let Admin = false;

class MenuListComposition extends React.Component {
  state = {
    open: false,
    deleteDialog: false
  };

  async componentDidMount(){
    if(Admin === false && 'role' in store.authStore.userData
      && store.authStore.userData.role === 'ADMIN'
    ){
      Admin = await import(/* webpackChunkName: "admin/admin" */ '../../admin/ContextMenu');

      this.forceUpdate();
    }
  }

  async componentWillUpdate(){
    if(Admin === false && 'role' in store.authStore.userData
      && store.authStore.userData.role === 'ADMIN'
    ){
      Admin = await import(/* webpackChunkName: "admin/admin" */ '../../admin/ContextMenu');

      this.forceUpdate();
    }
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  handleSortPersonFirstName = event => {
    this.handleClose()
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersons('firstName', 'lastName');
  };

  handleSortPersonLastName = event => {
    this.handleClose();
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersons('lastName', 'firstName');
  };

  handleLastUpdatedPerson = event => {
    this.handleClose();
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersons('lastUpdate', null);
  };

  handleLastUpdatedOrganization = event => {
    this.handleClose();
    store.generalStore.setScrollToIndex(0);
    store.organizationStore.sortOrganizations('lastUpdate');
  };

  handleSortProfileFirstName = event => {
    this.handleClose()
    store.tenantStore.sortMembers('firstName', 'lastName');
  };

  handleSortProfileLastName = event => {
    this.handleClose();
    store.tenantStore.sortMembers('lastName', 'firstName');
  };

  handleSortOrgName = event => {
    this.handleClose()
    store.generalStore.setScrollToIndex(0);
    store.organizationStore.sortOrganizations('regular');
  };

  handleSortOrgNameInverted = event => {
    this.handleClose()
    store.generalStore.setScrollToIndex(0);
    store.organizationStore.sortOrganizations('inverted');
  };

  handleSortAllName = event => {
    this.handleClose();
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersonsAndOrganizations('regular');
  };

  handleSortAllNameInverted = event => {
    this.handleClose();
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersonsAndOrganizations('inverted');
  };

  handleLastUpdatedAll = event => {
    this.handleClose()
    store.generalStore.setScrollToIndex(0);
    store.personStore.sortPersonsAndOrganizations('lastUpdate');
  };

  handleSortAllNameFavorites = event => {
    this.handleClose();
    store.personStore.setOffset(0);
    store.personStore.sortPersonsAndOrganizations('regular', true);
  };

  handleSortAllNameInvertedFavorites = event => {
    this.handleClose();
    store.personStore.setOffset(0);
    store.personStore.sortPersonsAndOrganizations('inverted', true);
  };

  handleLastUpdatedFavorites = event => {
    this.handleClose();
    store.personStore.setOffset(0);
    store.personStore.sortPersonsAndOrganizations('lastUpdate', true);
  };

  handleSortNewestDuplicates = event => {
    this.handleClose()
    store.conflictsStore.sortDuplicates('lastUpdate');
  };

  handleSortDuplicatesScore = event => {
    this.handleClose()
    store.conflictsStore.sortDuplicates('regular');
  };

  handleSortDuplicatesScoreInverted = event => {
    this.handleClose()
    store.conflictsStore.sortDuplicates('inverted');
  };

  handleIntegrationsAll = event => {
    this.handleClose()
    store.integrationStore.setFilter('all');
  };

  handleIntegrationsInbound = event => {
    this.handleClose()
    store.integrationStore.setFilter('inbound');
  };

  handleIntegrationsOutbound = event => {
    this.handleClose()
    store.integrationStore.setFilter('outbound');
  };

  handleDeleteRequestOpen = event => {
    this.handleClose()
    store.governanceStore.setFilter('open');
  }

  handleDeleteRequestIncomplete = event => {
    this.handleClose()
    store.governanceStore.setFilter('incomplete');
  }

  handleDeleteRequestClosed = event => {
    this.handleClose()
    store.governanceStore.setFilter('closed');
  }

  onClickEdit = () => {
    this.handleClose()
    const match = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.detailview}/:type/:id/`,
      exact: true,
      strict: false
    })

    this.props.history.push(`/edit/${match.params.type}/${match.params.id}`)
    // console.log(`Redirect to edit view with id: ${match.params.id}`);
  };

  onClickEditProfile = () => {
    this.handleClose();
    this.props.history.push(`/edit/profile/me`);
  };


  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteCancel = () => {
    this.setState({ deleteDialog: false });
  };

  handleDeleteConfirm = () => {
    const match = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.detailview}/:type/:id/`,
      exact: true,
      strict: false
    })

    const oldLenPers = store.personStore.persons.length;
    const oldLenOrgs = store.organizationStore.organizations.length;
    let counter = 0;
    this.setState({ deleteDialog: false });
    if (match.params.type === 'person') {
      store.personStore.deletePerson(match.params.id);
    } else if (match.params.type === 'organization') {
      store.organizationStore.deleteOrganization(match.params.id);
    }
    const interval = setInterval(function (oldLenPers, oldLenOrgs) {
      if (match.params.type === 'person') {
        if (oldLenPers !== store.personStore.persons.length) {
          this.props.history.push(constants.path.listPersons);
          clearInterval(interval);
        }
      } else if (match.params.type === 'organization') {
        if (oldLenOrgs !== store.organizationStore.organizations.length) {
          this.props.history.push(constants.path.listOrganizations);
          clearInterval(interval);
        }
      }

      counter++;

      if (counter >= 20) {
        if (match.params.type === 'person') {
          this.props.history.push(constants.path.listPersons);
        } else {
          this.props.history.push(constants.path.listOrganizations);
        }
        clearInterval(interval);
      }

    }
      .bind(this),
      100, oldLenPers, oldLenOrgs, counter
    )
  };

  render() {
    const { t } = this.props;
    let showSorting = false;

    const personListContext = [
      <MenuItem key='First' onClick={this.handleSortPersonFirstName}>{t('Sort by First Name')}</MenuItem>,
      <MenuItem key='Last' onClick={this.handleSortPersonLastName}>{t('Sort by Last Name')}</MenuItem>,
      <MenuItem key='LastUpdated' onClick={this.handleLastUpdatedPerson}>{t('Last updated')}</MenuItem>
    ];

    const profileListContext = [
      <MenuItem key='First' onClick={this.handleSortProfileFirstName}>{t('Sort by First Name')}</MenuItem>,
      <MenuItem key='Last' onClick={this.handleSortProfileLastName}>{t('Sort by Last Name')}</MenuItem>
    ];

    const organizationListContext = [
      <MenuItem key='Regular' onClick={this.handleSortOrgName}>{t('Sort by Name')}</MenuItem>,
      <MenuItem key='Inverted' onClick={this.handleSortOrgNameInverted}>{t('Sort by Name Inverted')}</MenuItem>,
      <MenuItem key='LastUpdated' onClick={this.handleLastUpdatedOrganization}>{t('Last updated')}</MenuItem>,
    ];

    const allListContext = [
      <MenuItem key='Regular' onClick={this.handleSortAllName}>{t('Sort by Name')}</MenuItem>,
      <MenuItem key='Inverted' onClick={this.handleSortAllNameInverted}>{t('Sort by Name Inverted')}</MenuItem>,
      <MenuItem key='LastUpdated' onClick={this.handleLastUpdatedAll}>{t('Last updated')}</MenuItem>,
    ];

    const favoritesListContext = [
      <MenuItem key='Regular' onClick={this.handleSortAllNameFavorites}>{t('Sort by Name')}</MenuItem>,
      <MenuItem key='Inverted' onClick={this.handleSortAllNameInvertedFavorites}>{t('Sort by Name Inverted')}</MenuItem>,
      <MenuItem key='LastUpdated' onClick={this.handleLastUpdatedFavorites}>{t('Last added')}</MenuItem>,
    ];

    const duplicatesViewContext = [
      <MenuItem key='Regular' onClick={this.handleSortDuplicatesScore}>{t('Highest score')}</MenuItem>,
      <MenuItem key='Inverted' onClick={this.handleSortDuplicatesScoreInverted}>{t('Lowest score')}</MenuItem>,
      <MenuItem key='LastUpdated' onClick={this.handleSortNewestDuplicates}>{t('Newest duplicates')}</MenuItem>,
    ];

    const detailViewContext = [
      <MenuItem key='editContact' onClick={this.onClickEdit}> <EditIcon />  {t('edit')} </MenuItem>,
      <MenuItem key='deleteContact' onClick={this.handleDeleteOpen}> <DeleteIcon /> {t('delete')}  </MenuItem>,
    ];

    const profileViewContext = [
      <MenuItem key='editContact' onClick={this.onClickEditProfile}> <EditIcon />  {t('edit')} </MenuItem>,
    ];

    const integrationsViewContext = [
      <MenuItem key='all' onClick={this.handleIntegrationsAll}>{t('All')}</MenuItem>,
      <MenuItem key='inbound' onClick={this.handleIntegrationsInbound}>{t('Inbound')}</MenuItem>,
      <MenuItem key='outbound' onClick={this.handleIntegrationsOutbound}>{t('Outbound')}</MenuItem>
    ];

    const deleteRequestsViewContext = [
      <MenuItem key='open' onClick={this.handleDeleteRequestOpen}>{t('deleteRequest open')}</MenuItem>,
      <MenuItem key='incomplete' onClick={this.handleDeleteRequestIncomplete}>{t('deleteRequest incomplete')}</MenuItem>,
      <MenuItem key='closed' onClick={this.handleDeleteRequestClosed}>{t('deleteRequest closed')}</MenuItem>
    ];

    const adminViewContext = (Admin !== false)? <Admin.default handleClose={this.handleClose.bind(this)} /> : [];

    const { classes } = this.props;
    const { open } = this.state;
    const path = this.props.history.location.pathname;

    const personsView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.listPersons}`,
      exact: true,
      strict: false
    });

    const organizationsView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.listOrganizations}`,
      exact: true,
      strict: false
    });

    const allView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.listAll}`,
      exact: true,
      strict: false
    });

    const favoritesView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.listFavorites}`,
      exact: true,
      strict: false
    });

    const duplicatesView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.listDuplicates}`,
      exact: true,
      strict: false
    });

    const integrationsView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.integrationsList}`,
      exact: true,
      strict: false
    });

    const deleteRequestsView = matchPath(this.props.history.location.pathname, {
      path: `${constants.path.deleteRequests}`,
      exact: true,
      strict: false
    });

    if (
      personsView || organizationsView
      || allView || favoritesView || duplicatesView || integrationsView
      || deleteRequestsView
    ) {
      showSorting = true;
    }

    const icons = (integrationsView || deleteRequestsView) ? <><FilterListIcon /></> : <><ArrowDropDown /><ArrowDropUp /></>
    const sort = <IconButton onClick={this.handleToggle} className={classes.menuButton} aria-owns={open ? 'menu-list-grow' : undefined} aria-haspopup="true" color="inherit" aria-label="Menu">
      {/* <SortByAlpha /> */}
      {icons}
    </IconButton>;



    return (
      <div className={classes.root}>
        {showSorting && (sort)}
        <Popper open={open} className={classes.toolTip} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {path.includes('list/persons') ? personListContext : null}
                    {path.includes('list/organizations') ? organizationListContext : null}
                    {path.includes('list/all') ? allListContext : null}
                    {path.includes('list/members') ? profileListContext : null}
                    {path.includes('detailview') ? detailViewContext : null}
                    {path.includes('profile') ? profileViewContext : null}
                    {path.includes(constants.path.admin) ? adminViewContext : null}
                    {path.includes(constants.path.listFavorites) ? favoritesListContext : null}
                    {path.includes(constants.path.listDuplicates) ? duplicatesViewContext : null}
                    {path.includes(constants.path.integrationsList) ? integrationsViewContext : null}
                    {path.includes(constants.path.deleteRequests) ? deleteRequestsViewContext : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Dialog
          open={this.state.deleteDialog}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{t("Confirm Delete")}</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleDeleteConfirm} color="secondary">
              Delete
                </Button>
            <Button onClick={this.handleDeleteCancel} color="primary" autoFocus>
              Cancel
                </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(MenuListComposition)));
