import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

// React multilanguage
import { withTranslation } from "react-i18next";

// Mobx
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import RelationsBlock from "../templates/RelationsBlock";
import RelationsCenterBlock from "../templates/RelationsCenterBlock";

import constants from "../../stores/constants";

const styles = theme => ({
  root: {
    width: "100%",
    padding: theme.spacing.unit,
    boxSizing: "border-box"
  },
  gridItem: {
    // border: "1px solid red"
  },
  gridCenter: {
    // border: "1px solid green",
    flexGrow: 0
  },
  gridItemRight: {
    // border: "1px solid red"
  },
  typography: {
    paddingLeft: 20,
    paddingTop: 6,
    color: constants.color.heading,
    fontSize: "14px"
  },
  divider: {
    backgroundColor: `1px solid ${constants.color.heading}`
  }
});

class RelationsGraph extends Component {
  svg = null;
  svgPosition = null;
  scrolledBelow = false;

  state = {
    activePosition: ""
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  setActivePosition(activePosition) {
    this.setState({
      activePosition
    });
  }

  componentDidMount() {
    this.props.graphStore.setGraph(this.props.entry);

    this.createSVG(true);
    if ("requestAnimationFrame" in window) {
      window.requestAnimationFrame(this.callback.bind(this));
    } else {
      setTimeout(
        function () {
          this.callback.bind(this);
        }.bind(this),
        500
      );
    }

    window.onresize = this.callback.bind(this);

    if (window.innerWidth >= constants.breakpoints.max) {
      try {
        const el = document.getElementById('DV2')
        el.onscroll = this.scrolled.bind(this, true);
      } catch(e) {
        console.log(e);
      }
    } else {
      window.onscroll = this.scrolled.bind(this, false);
    }
  }

  scrolled(desktop) {
    // const el = document.getElementsByClassName('withI18nextTranslation-App--displayPanel-2');
    if (desktop) {
      this.callback()
    } else {
      if (
        (!this.scrolledBelow && window.scrollY > 170) ||
        (this.scrolledBelow && window.scrollY < 170)
      ) {
        this.scrolledBelow = !this.scrolledBelow;
        this.callback();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.entry) !== JSON.stringify(this.props.entry))
      this.props.graphStore.setGraph(this.props.entry);

    if (
      prevProps.tabValue !== this.props.tabValue ||
      JSON.stringify(prevProps.relations) !==
      JSON.stringify(this.props.relations)
    )
      window.requestAnimationFrame(this.callback.bind(this));
  }

  createSVG(force) {
    try {
      this.svg = document.getElementById("svgStage");

      const marginTop =
        "getComputedStyle" in window
          ? parseInt(
            window
              .getComputedStyle(document.body)
              .getPropertyValue("margin-top"),
            10
          )
          : 108;

      const element = document.getElementsByClassName("relationsContainer")[0];
      const rect = element ? element.getBoundingClientRect() : {};

      this.svgPosition = {
        left: rect.left + window.scrollX,
        top: rect.top - marginTop + window.scrollY,
        width: parseInt(rect.width, 10),
        height: parseInt(rect.height, 10)
      };

      if (this.svg === null) {
        this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        this.svg.setAttribute("id", "svgStage");
        this.svg.setAttribute(
          "style",
          `position:absolute; top:${this.svgPosition.top}px; left:${this.svgPosition.left}px; width:${this.svgPosition.width}px; height: ${this.svgPosition.height}px; pointer-events:none;`
        );
        this.svg.setAttributeNS(
          "http://www.w3.org/2000/xmlns/",
          "xmlns:xlink",
          "http://www.w3.org/1999/xlink"
        );
        document.body.appendChild(this.svg);
      } else if (force) {
        this.svg.innerHTML = "";
        this.svg.setAttribute(
          "style",
          `position:absolute; top:${this.svgPosition.top}px; left:${this.svgPosition.left}px; width:${this.svgPosition.width}px; height: ${this.svgPosition.height}px; pointer-events:none;`
        );
      }
      return this.svg;
    } catch(e) {
      console.log(e);
    }
  }

  destroySVG() {
    if (this.svg !== null) this.svg.remove();
  }

  callback() {
    try {
      const marginTop =
        "getComputedStyle" in window
          ? parseInt(
            window
              .getComputedStyle(document.body)
              .getPropertyValue("margin-top"),
            10
          )
          : 108;

      this.createSVG(true);

      const centerElement = document.getElementsByClassName("relation-center")[0];
      if (!centerElement) return false;

      const centerRect = centerElement.getBoundingClientRect();
      const centerHeight = centerRect.height;
      const centerWidth = centerRect.width;
      const centerTop =
        centerRect.top + window.scrollY + centerHeight / 2 - marginTop;
      const centerLeft = centerRect.left + window.scrollX + centerWidth / 2;

      const elements = document.getElementsByClassName("relation-avatar");

      for (const index in elements) {
        if (
          typeof elements[index] === "object" &&
          "getBoundingClientRect" in elements[index]
        ) {
          const rect = elements[index].getBoundingClientRect();

          const height = rect.height;
          const width = rect.width;
          const top = rect.top + window.scrollY + height / 2 - marginTop;
          const left = rect.left + window.scrollX + width / 2;

          let currentCenterLeft;
          let currentLeft;
          if (centerLeft < left) {
            currentCenterLeft = centerLeft + 25;
            currentLeft = left - 35;
          } else {
            currentCenterLeft = centerLeft - 25;
            currentLeft = left + 23;
          }

          const baseForce = window.innerWidth > 600 ? 0.7 : 0.8;
          let force = baseForce + (top - centerTop) / 500;
          if (force > 1) force = 1;

          let shift = (top - centerTop) / 2;
          if (shift > 100) shift = 100;

          this.drawPath(
            currentCenterLeft,
            centerTop,
            currentLeft,
            top,
            force,
            "#e0e0e0",
            shift
          );
        }
      }
    } catch(e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this.destroySVG();
  }

  drawLine(x1, y1, x2, y2, color) {
    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttribute("stroke", color);
    line.setAttributeNS(null, "stroke-width", 4);
    line.setAttributeNS(null, "shape-rendering", "geometricPrecision");
    this.svg.append(line);
  }

  drawPath(x1, y1, x2, y2, force, color, shift) {
    x1 = x1 - this.svgPosition.left;
    x2 = x2 - this.svgPosition.left;
    y1 = y1 - this.svgPosition.top;
    y2 = y2 - this.svgPosition.top;

    const originalX2 = x2;

    this.svg = this.createSVG();
    const shape = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );

    if (shift) {
      if (x1 > x2) {
        x2 = x2 + shift;
        if (x1 < x2) x2 = x1 - 1;
      } else {
        x2 = x2 - shift;
        if (x1 > x2) x2 = x1 + 1;
      }
    }

    const delta = (x2 - x1) * force;

    var cx1 = x1 + delta;
    var cy1 = y1;
    var cx2 = x2 - delta;
    var cy2 = y2;

    const path = `M ${x1} ${y1} C ${cx1} ${cy1} ${cx2} ${cy2} ${x2} ${y2}`;

    shape.setAttributeNS(null, "d", path);
    shape.setAttributeNS(null, "fill", "none");

    shape.setAttributeNS(null, "stroke", color);
    // shape.setAttributeNS(null, "stroke-opacity", 0.8);
    shape.setAttributeNS(null, "stroke-width", 4);

    shape.setAttributeNS(null, "shape-rendering", "geometricPrecision");

    this.svg.appendChild(shape);

    if (shift) {
      this.drawLine(x2, y2, originalX2, y2, color);
    }

    const triangle = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    triangle.setAttributeNS(null, "fill", color);
    triangle.setAttributeNS(null, "stroke", color);

    // const arrowLeft = [ [ 0, 50 ],
    //          [ 100, 0 ],
    //          [ 100, 100 ], ];

    const arrowRight = [
      [100, 50],
      [0, 100],
      [0, 0]
    ];

    // const arrow = (x1 < x2)? arrowRight : arrowLeft ;

    let x0;
    let y0;
    if (x1 < x2) {
      x0 = x2;
      y0 = y2;
      if (shift) x0 = x0 + shift;
      if (x0 > originalX2) x0 = originalX2;
    } else {
      x0 = x1 - 15;
      y0 = y1;
    }

    const scale = 0.15;
    for (const value of arrowRight) {
      const point = this.svg.createSVGPoint();
      point.x = x0 + 2 + value[0] * scale;
      if (x1 > x2) {
        point.x += 2;
      } else {
        point.x -= 5;
      }
      point.y = y0 - 50 * scale + value[1] * scale;
      triangle.points.appendItem(point);
    }

    this.svg.appendChild(triangle);
  }

  redraw() {
    this.forceUpdate();
  }

  render() {
    const { classes, t } = this.props;

    const center = this.props.graphStore.graph.parentNode;

    let positions = {
      left: { isClustered: false, nodes: [] },
      bottomLeft: { isClustered: false, nodes: [] },
      right: { isClustered: false, nodes: [] },
      bottomRight: { isClustered: false, nodes: [] }
    };

    for (const type in this.props.graphStore.graph.types) {

      if (type === "organizationToOrganization" || type === "personToPerson") {
        const nodes = toJS(this.props.graphStore.graph.types[type].nodes);

        const standard = [];
        const reversed = [];

        for (const index in nodes) {
          if (nodes[index].source === center.uid) {
            standard.push(nodes[index]);
          } else {
            reversed.push(nodes[index]);
          }
        }

        const isClusteredStandard = standard.length > 3;

        if (this.props.graphStore.graph.types[type].position in positions) {
          positions[this.props.graphStore.graph.types[type].position].nodes = positions[this.props.graphStore.graph.types[type].position].nodes.concat(standard);
          if (positions[this.props.graphStore.graph.types[type].position].nodes.length > 3) positions[this.props.graphStore.graph.types[type].position].isClustered = true;
        } else {
          positions[this.props.graphStore.graph.types[type].position] = {
            isClustered: isClusteredStandard,
            nodes: standard
          };
        }

        const isClusteredReversed = reversed.length > 3;

        if ('bottomLeft' in positions) {
          positions["bottomLeft"].nodes = positions["bottomLeft"].nodes.concat(reversed);
          if (positions["bottomLeft"].nodes.length > 3) positions["bottomLeft"].isClustered = true;
        } else {
          positions["bottomLeft"] = {
            isClustered: isClusteredReversed,
            nodes: reversed
          };
        }
      } else {
        positions[this.props.graphStore.graph.types[type].position] = toJS(
          this.props.graphStore.graph.types[type]
        );
      }
    }

    const showLeft =
      positions.left.nodes.length > 0 || positions.bottomLeft.nodes.length > 0;
    const showRight =
      positions.right.nodes.length > 0 ||
      positions.bottomRight.nodes.length > 0;

    const { types } = this.props.graphStore.graph;
    let showCenterBlock = false;

    for (const type in types) {
      if (types[type].position !== "ignored") {
        showCenterBlock = true;
      }
    }

    // Generate cluster type
    let leftCluster;
    let leftClusterBottom;
    let rightCluster;
    let rightClusterBottom;

    function generateClusterType(type, position) {
      let clusterType;
      if (
        position === "left" &&
        (type === "PersonToPerson" || type === "PersonToOrganization")
      ) {
        clusterType = "person";
      } else if (
        position === "left" &&
        (type === "OrganizationToPerson" ||
          type === "OrganizationToOrganization")
      ) {
        clusterType = "organization";
      } else if (
        position === "right" &&
        (type === "PersonToPerson" || type === "OrganizationToPerson")
      ) {
        clusterType = "person";
      } else if (
        position === "right" &&
        (type === "PersonToOrganization" ||
          type === "OrganizationToOrganization")
      ) {
        clusterType = "organization";
      }
      return clusterType;
    }

    for (const position in positions) {
      if (position === "left" && positions[position].nodes.length > 0) {
        const { relationType } = positions[position].nodes[0];
        leftCluster = generateClusterType(relationType, "left");
      }
      if (position === "bottomLeft" && positions[position].nodes.length > 0) {
        const { relationType } = positions[position].nodes[0];
        leftClusterBottom = generateClusterType(relationType, "left");
      }
      if (position === "right" && positions[position].nodes.length > 0) {
        const { relationType } = positions[position].nodes[0];
        rightCluster = generateClusterType(relationType, "right");
      }
      if (position === "bottomRight" && positions[position].nodes.length > 0) {
        const { relationType } = positions[position].nodes[0];
        rightClusterBottom = generateClusterType(relationType, "right");
      }
    }

    return (
      <React.Fragment>
        <Typography className={classes.typography} variant="body1" align="left">
          {t("Relationships")}
        </Typography>
        <Divider variant="middle" className={classes.divider} />
        <Grid
          container
          key="relationsGrid"
          className={`relationsContainer ${classes.root}`}
        >
          {showLeft && (
            <Grid
              item
              xs={showRight ? 5 : 10}
              sm={5}
              className={classes.gridItem}
              key={`grid-left`}
            >
              <Grid
                item
                xs={5}
                sm={5}
                className={classes.gridItem}
                key={`grid-left`}
              >
                {positions.left.nodes.length > 0 && (
                  <RelationsBlock
                    callback={this.callback.bind(this)}
                    redraw={this.redraw.bind(this)}
                    center={center}
                    isClustered={positions.left.isClustered}
                    entries={positions.left.nodes}
                    position={"left"}
                    clusterType={leftCluster}
                    setActivePosition={this.setActivePosition.bind(this)}
                    activePosition={this.state.activePosition}
                  />
                )}

                {positions.bottomLeft.nodes.length > 0 && (
                  <RelationsBlock
                    callback={this.callback.bind(this)}
                    redraw={this.redraw.bind(this)}
                    center={center}
                    isClustered={positions.bottomLeft.isClustered}
                    entries={positions.bottomLeft.nodes}
                    position={"bottomLeft"}
                    clusterType={leftClusterBottom}
                    setActivePosition={this.setActivePosition.bind(this)}
                    activePosition={this.state.activePosition}
                  />
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={2}
            sm={2}
            className={classes.gridItemCenter}
            key={`grid-center`}
          >
            <RelationsCenterBlock
              center={center}
              showCenterBlock={showCenterBlock}
            />
          </Grid>
          {showRight && (
            <Grid
              item
              xs={showLeft ? 5 : 10}
              sm={5}
              className={classes.gridItemRight}
              key={`grid-right`}
            >
              {positions.right.nodes.length > 0 && (
                <RelationsBlock
                  callback={this.callback.bind(this)}
                  redraw={this.redraw.bind(this)}
                  center={center}
                  isClustered={positions.right.isClustered}
                  entries={positions.right.nodes}
                  position={"right"}
                  clusterType={rightCluster}
                  setActivePosition={this.setActivePosition.bind(this)}
                  activePosition={this.state.activePosition}
                />
              )}

              {positions.bottomRight.nodes.length > 0 && (
                <RelationsBlock
                  callback={this.callback.bind(this)}
                  redraw={this.redraw.bind(this)}
                  center={center}
                  isClustered={positions.bottomRight.isClustered}
                  entries={positions.bottomRight.nodes}
                  position={"bottomRight"}
                  clusterType={rightClusterBottom}
                  setActivePosition={this.setActivePosition.bind(this)}
                  activePosition={this.state.activePosition}
                />
              )}
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(
    inject("graphStore")(observer(RelationsGraph))
  )
);
