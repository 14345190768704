
// import moment from 'moment'

import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Mobx
import constants from "../../stores/constants";
// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import HistoryItem from './HistoryItem';
import HistoryCluster from './HistoryCluster';

// React multilanguage
import { withTranslation } from "react-i18next";

const styles = theme => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  avatarInactive: {
    margin: 10,
    color: "#fff",
    backgroundColor: constants.color.inactive,
    width: 30,
    height: 30,
    lineHeight: "30px",
    // float: 'left',
    display: "inline-block",
    fontSize: 16
  },
  historyBlock: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    padding: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: 100,
  },
  avatarHolder: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  contentHolder: {
    width: '85vw',
    maxWidth: '620px',
    float: 'right',
    textAlign: 'left',
  },
  upArrow: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '20px solid #eee',

    position: 'absolute',
    left: (theme.spacing.unit + 5) * -1,
    top: theme.spacing.unit * -2,
  },
  arrowHead: {
    position: 'relative',
    width: '100vw',
    maxWidth: 640,
    minHeight: 50,
    borderLeft: '5px solid #eee',
    marginLeft: theme.spacing.unit * 2,
  },
});

class HistoryBlock extends Component {
  state = {
    avatarError: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  avatarError(){
    this.setState({avatarError: true});
  }

  render() {
    const { classes, journal } = this.props;
    const passedData = this.props.data;

    let data = passedData;

    if(passedData === false || passedData === 'loading') {
      data = [
        {
          user: '',
          operation: '',
          value: '',
          date: "",
        },
      ];

      if(journal && journal === true) {
        let i;
        for(i=0;i<5;i++) {
          data.push({
                    user: '',
                    operation: '',
                    value: '',
                    date: "",
                  });
        }
      }
    }

    const items = [];
    const length = (data)? data.length : 0;

    if(length > 0) data[data.length -1].lastItem = true;


    // const t0 = Date.now();
    let i;
    let lastDate;
    let lastResult;
    for(i=0; i<length; i++){
      if(passedData === false || passedData === 'loading') {
        data[i].date = '';
        data[i].formatedDate = '';
      } else {
          // const date = new Date(parseInt(data[i].date));
          // const momentParsedDate = moment(date);
          // data[i].formatedDate = momentParsedDate.format("DD.MM.YYYY HH:mm");
          if(lastDate === data[i].date) {
            data[i].formatedDate = lastResult;
            continue;
          }

          const date = new Date(parseInt(data[i].date));
          const year = date.getFullYear();
          const month = `0${date.getMonth()+1}`.slice(-2);
          const day = `0${date.getDate()}`.slice(-2);

          if(journal && journal === true) {
            data[i].formatedDate = `${day}.${month}.${year} `;
          } else {
            const hour = `0${date.getHours()}`.slice(-2);
            const minute = `0${date.getMinutes()}`.slice(-2);
            data[i].formatedDate = `${day}.${month}.${year} ${hour}:${minute}`;
          }

          lastDate = data[i].date;
          lastResult = data[i].formatedDate;
      }
    }

    // const timePassed0 = Date.now() - t0;
    // console.log('clustering (timeformat)', timePassed0);

    let clusters = [];

    let clusterId = 0;
    let lastClusterDate;

    const lowRange = 360000;
    let currentRange = lowRange;

    let currentDay;
    for(i=0; i<length; i++){
        if(journal && journal === true) {
          currentDay = (data[i].formatedDate)? data[i].formatedDate.split(' ')[0] : '';

          if(!lastClusterDate) lastClusterDate = currentDay;
          if(lastClusterDate !== currentDay || ((passedData === false || passedData === 'loading') && i > 0) ) {
            clusterId += 1;
          }
        } else {
          const diff = (lastClusterDate)? lastClusterDate - data[i].date : 0;
          if ( diff > currentRange || (length < 4 && i>0)) {
            clusterId += 1;
            if(clusterId >= 5) currentRange = 24 * lowRange;
          }
        }

        if(clusters.length < (clusterId + 1)) clusters.push([]);
        clusters[clusterId].push(data[i]);

        lastClusterDate = (journal && journal === true)? currentDay: data[i].date;
    }

    // const timePassed = Date.now() - t0;
    // console.log('clustering', timePassed);
    // console.log(clusters);

    // const tx = Date.now();

    const clusterLength = clusters.length;
    for(i=0; i<clusterLength; i++){
      if(clusters[i].length === 1) {
        items.push(<HistoryItem displayType={this.props.displayType} contained={this.props.contained} journal={journal} key={`h${i}`} data={clusters[i][0]} revertEvent={this.props.revertEvent ? this.props.revertEvent.bind(this) : false} />);
      } else {
        items.push(<HistoryCluster displayType={this.props.displayType} contained={this.props.contained} journal={journal} key={`hc${i}`} data={clusters[i]} revertEvent={this.props.revertEvent ? this.props.revertEvent.bind(this) : false} />);
      }
    }
    if(clusterLength > 0) clusters[clusterLength - 1].lastItem = true;

    // const timePassedR = Date.now() - tx;
    // console.log('Render preparation', timePassedR);

    let blockStyle = (this.props.contained)? {width: '100%'} : {};

    if(window.innerWidth < constants.breakpoints.small) {
      blockStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        width: '95vw',
      };
    }

    return (
      <div key={`grid-${data.user}`} className={classes.historyBlock} style={blockStyle}>
        <div className={classes.arrowHead}>
          <div className={classes.upArrow}></div>
        </div>
        {items}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("translations")(HistoryBlock)
);
