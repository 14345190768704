import axios from 'axios';
import constants from '../stores/constants';
import store from '../stores/';
import history from './history';
import { trackPromise } from 'react-promise-tracker';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const apiUrl = constants.apiUrl;

// const controller = new AbortController();
// const { signal } = controller;

let cancelTokens = [];

let latestCall = '';
let latestOffsetCall = '';

// Get all persons
export const getPersons = async (token, page, num, sort, offset) => {
	const call = `${page}-${num}-${sort}-${offset}`;
	if(call === latestCall) {
		console.log('getPersons called with same arguments more then once --> ignored!');
		console.log(call);
		return;
	}

	latestCall = call;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;
	// return fetch(`${apiUrl}/person?num=${num}&page=${page}`, {
	//     method: 'get',
	//     cache: 'no-store',
	//     headers: {
	//         'Accept': 'application/json',
	//         'Content-Type': 'application/json',
	//         'Authorization': `Bearer ${token}`,

	//     },
	//     credentials: 'omit',
	// }).then(function (res) {
	//     //TODO: Add error handling
	//     if (res.status === 401) {
	//         store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
	//     }

	//     return res.json();
	// }).catch((e) => {
	//     console.log('ERROR: ', e);
	//     store.generalStore.addMessage(500, 'Persons could not be retrieved');
	// });

	try {
		console.log('Before cancel', cancelTokens);
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		console.log('before setting new token', cancelTokens);
		cancelTokens.push(axios.CancelToken.source());
		console.log('after setting new');

		const response = await trackPromise(
			axios.get(`${apiUrl}/person?num=${num}&page=${page}&sort=${sort}&offset=${offset}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		return history.push('/not-found');
	}
};

// Get current persons
export const getCurrentPersons = async (token, offset, num, sort) => {
	const offsetCall = `${offset}-${num}-${sort}`;
	console.log('Call', offsetCall);
	if(offsetCall === latestOffsetCall) {
		console.log('getCurrentPersons called with same arguments more then once --> ignored!');
		console.log(offsetCall);
		return;
	}

	latestCall = offsetCall;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/person?offset=${offset}&num=${num}&sort=${sort}&language=${language}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		// return history.push('/not-found');
	}
};

// Get current persons and organizations
// export const getCurrentPersonsAndOrganizations = async (token, num, sort, offsetPerson, offsetOrganization) => {
export const getCurrentPersonsAndOrganizations = async (token, offset, num, sort) => {
	// const offsetCall = `${offsetPerson}-${offsetOrganization}-${num}-${sort}`;
	const offsetCall = `all-${offset}-${num}-${sort}`;
	if(offsetCall === latestOffsetCall) {
		console.log('getCurrentPersonsAndOrganizations called with same arguments more then once --> ignored!');
		console.log(offsetCall);
		return;
	}

	latestCall = offsetCall;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			// axios.get(`${apiUrl}/all?num=${num}&sort=${sort}&language=${language}&offsetPerson=${offsetPerson}&offsetOrganization=${offsetOrganization}`, {
			axios.get(`${apiUrl}/all?num=${num}&sort=${sort}&language=${language}&offset=${offset}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		return history.push('/not-found');
	}
};

// Get current persons
export const getCurrentGroupEntries = async (token, offset, num, sort, group) => {
	const offsetCall = `${offset}-${num}-${sort}`;
	if(offsetCall === latestOffsetCall) {
		return;
	}

	latestCall = offsetCall;

	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	try {
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/group/${group}?offset=${offset}&num=${num}&sort=${sort}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		return history.push('/not-found');
	}
};



// Get persons starting from letter
export const getPersonsByLetter = async (token, letter, num, sort) => {
	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/person?letter=${letter}&num=${num}&sort=${sort}&language=${language}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			const offsetCall = `${response.data.meta.offset}-${num}-${sort}`;
			console.log('Call (by letter)', offsetCall);
			latestCall = offsetCall;

			return response.data;
		} else if (response.status === 204) {
			return {
				data: [],
				meta: {
					offset: 0,
				}
			}
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		// return history.push('/not-found');
	}
};

// Get persons starting from letter
export const getPersonsAndOrganizationsByLetter = async (token, letter, num, sort) => {
	if (store.authStore.userData.role && store.authStore.userData.role === 'ADMIN') return false;

	try {
		const language = store.generalStore.language;
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		const response = await trackPromise(
			axios.get(`${apiUrl}/all?letter=${letter}&num=${num}&sort=${sort}&language=${language}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
		);

		if (response.status === 200) {
			const offsetCall = `${response.data.meta.offset}-${num}-${sort}`;
			console.log('Call (by letter)', offsetCall);
			latestCall = offsetCall;

			return response.data;
		} else if (response.status === 204) {
			return {
				data: [],
				meta: {
					offset: 0,
				}
			}
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
		latestCall = '';
	} catch (e) {
		latestCall = '';
		console.log('Error:', e);

		if(typeof(e) === 'object' && e.message === undefined) {
			console.log('Asuming cancel error');
			return;
		}
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Persons could not be retrieved');

		return history.push('/not-found');
	}
};

// Get persons and organizations
export const getPersonsAndOrganizations = async (token, page, num) => {
	//   if(!store.authStore.userData.role || store.authStore.userData.role === 'ADMIN') return false;
	// return fetch(`${apiUrl}/all?page=${page}&num=${num}`, {
	//     method: 'get',
	//     cache: 'no-store',
	//     headers: {
	//         'Accept': 'application/json',
	//         'Content-Type': 'application/json',
	//         'Authorization': `Bearer ${token}`,

	//     },
	//     credentials: 'omit',
	// }).then(function (res) {
	//     //TODO: Add error handling
	//     if (res.status === 401) {
	//         store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
	//     }
	//     return res.json();
	// }).catch((e) => {
	//     // console.log('ERROR: ', e);
	//     store.generalStore.addMessage(500, 'Contacts could not be retrieved');
	// });

	try {
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		const response = await trackPromise(
			axios.get(`${apiUrl}/all?page=${page}&num=${num}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			})
		);

		if (response.status === 200) {
			return response.data;
		} else if (response.status === 401) {
			store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
		}
	} catch (e) {
		// console.log(e);
		if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

		store.generalStore.addMessage(500, 'Contacts could not be retrieved');
		return history.push('/not-found');
	}
};


// Get persons and organizations with given category
export const getByCategory = (categoryId, token, page, num) => {
	let query = [];
	if (page !== null) query.push(`page=${page}`);
	if (num !== null) query.push(`num=${num}`);
	query = (query.length > 0) ? `?${query.join('&')}` : '';
	return trackPromise(
		fetch(`${apiUrl}/category/${categoryId}${query}`, {
			method: 'get',
			cache: 'no-store',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			credentials: 'omit'
		})
	)
		.then(function (res) {
			//TODO: Add error handling
			if (res.status === 401) {
				store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
			}

			return res.json();
		})
		.catch((e) => {
			// console.log('ERROR: ', e);
			if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

			store.generalStore.addMessage(500, 'Contacts could not be retrieved');
		});
};


// Get persons and organizations with given category
export const getCurrentCategoryMembers = async (categoryId, token, offset, num, sort) => {
	let query = [];
	if (offset !== null) query.push(`offset=${offset}`);
	if (num !== null) query.push(`num=${num}`);
	if (sort !== null) query.push(`sort=${sort}`);
	const language = store.generalStore.language;
	query.push(`language=${language}`);
	query = (query.length > 0) ? `?${query.join('&')}` : '';

	try {
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		return trackPromise(
			axios.get(`${apiUrl}/category/${categoryId}${query}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				cancelToken: cancelTokens[cancelTokens.length - 1].token,
			})
			.then(function (res) {
				//TODO: Add error handling
				if (res.status === 401) {
					store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
				}

				if (res.status === 204) {
					return {
						members: [],
						meta: {
							offset: 0,
						}
					}
				}

				return res.data;
			})
			.catch((e) => {
				// console.log('ERROR: ', e);
				if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

				store.generalStore.addMessage(500, 'Contacts could not be retrieved');
			})
		);
		} catch (error) {
			console.log(error);
			store.generalStore.addMessage(500, 'Contacts could not be retrieved');
		}
};


// Get persons starting from letter
export const getCategoryMembersByLetter = async (categoryId, token, letter, num, sort) => {
		let query = [];
		if (letter !== null) query.push(`letter=${letter}`);
		if (num !== null) query.push(`num=${num}`);
		if (sort !== null) query.push(`sort=${sort}`);
		const language = store.generalStore.language;
		query.push(`language=${language}`);
		query = (query.length > 0) ? `?${query.join('&')}` : '';
		try {
			for(let i=0; i<cancelTokens.length; i+=1) {
				await cancelTokens[i].cancel();
				cancelTokens = [];
			}
			cancelTokens.push(axios.CancelToken.source());

			return trackPromise(
				axios.get(`${apiUrl}/category/${categoryId}${query}`, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					cancelToken: cancelTokens[cancelTokens.length - 1].token,
				})
				.then(function (res) {
					if (res.status === 401) {
						store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
					}

					if (res.status === 204) {
						return {
							members: [],
							meta: {
								offset: 0,
							}
						}
					}

					return res.data;
				})
				.catch((e) => {
					// console.log('ERROR: ', e);
					if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

					store.generalStore.addMessage(500, 'Contacts could not be retrieved');
				})
			);
			} catch (error) {
				console.log(error);
				store.generalStore.addMessage(500, 'Contacts could not be retrieved');
			}
	};


// Get persons and organizations with given uids
export const getContactsByIds = async (token, uids, offset, num, sort) => {
	if(!uids || uids.length === 0) return false;

	let query = [];
	if (offset !== null) query.push(`offset=${offset}`);
	if (num !== null) query.push(`num=${num}`);
	if (sort !== null) query.push(`sort=${sort}`);
	const language = store.generalStore.language;
	query.push(`language=${language}`);
	query = (query.length > 0) ? `?${query.join('&')}` : '';

	try {
		for(let i=0; i<cancelTokens.length; i+=1) {
			await cancelTokens[i].cancel();
			cancelTokens = [];
		}
		cancelTokens.push(axios.CancelToken.source());

		return trackPromise(
			axios.post(`${apiUrl}/getContactsByIds${query}`,
				{ uids: uids.join(',') },
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					cancelToken: cancelTokens[cancelTokens.length - 1].token,
				})
			.then(function (res) {
				//TODO: Add error handling
				if (res.status === 401) {
					store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
				}

				if (res.status === 204) {
					return {
						data: [],
						meta: {
							offset: 0,
						}
					}
				}

				return res.data;
			})
			.catch((e) => {
				if(typeof(e) === 'object' && e.message === undefined) {
					// console.log('Asuming cancel error');
					return;
				}
				// console.log('ERROR: ', e);
				if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;
				store.generalStore.addMessage(500, 'Contacts could not be retrieved');
			})
		);
		} catch (error) {
			console.log(error);
			store.generalStore.addMessage(500, 'Contacts could not be retrieved');
		}
};


// Get persons and organizations with given group
export const getByGroup = (groupId, token, page, num) => {
	let query = [];
	if (page) query.push(`page=${page}`);
	if (num) query.push(`num=${num}`);
	query = (query.length > 0) ? `?${query.join('&')}` : '';
	return trackPromise(
		fetch(`${apiUrl}/group/${groupId}${query}`, {
			method: 'get',
			cache: 'no-store',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			credentials: 'omit'
		})
		.then(function (res) {
			//TODO: Add error handling
			if (res.status === 401) {
				store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
			} else if (res.status === 403){
				return [];
			}
			return res.json();
		})
		.catch((e) => {
			// console.log('ERROR: ', e);
			if (cookies && 'get' in cookies && cookies.get('admin') && cookies.get('admin').length > 0) return;

			store.generalStore.addMessage(500, 'Contacts could not be retrieved');
		})
	);
};


// Delete a person
export const deletePerson = (token, uid, fullName) => {
	return trackPromise(
		fetch(`${apiUrl}/person/${uid}`, {
			method: 'delete',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ fullName }),
			credentials: 'omit'
		})
	)
		.then(function (res) {
			if (res.status === 401) {
				store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
			} else {
				store.generalStore.changePersonsAmount('decrease');
				store.generalStore.addMessage(200, 'Person deleted');
			}
			return res.json();
			//TODO: Add error handling
		})
		.catch((e) => {
			// console.log('ERROR: ', e);
			store.generalStore.addMessage(500, 'Person could not be deleted');
		});
};

// scan for contact data of a person
export const scanContactData = (uid, entryName) => {
	return trackPromise(
		fetch(`${apiUrl}/suggestion/scanContactData`, {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${store.authStore.token}`
			},
			body: JSON.stringify({ uid, entryName }),
			credentials: 'omit'
		})
	)
		.then(function (res) {
			if (res.status === 401) {
				store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
			} else {
				store.generalStore.addMessage(200, 'Scan started');
			}
			return res.text;
			//TODO: Add error handling
		})
		.catch((e) => {
			console.log('ERROR: ', e);
			store.generalStore.addMessage(500, 'Scan could not be started');
		});
};

// scan for contact data of a person
export const confirmOrDeclineSuggestion = (uid, contact, command) => {

	if (command === 'delete') {

		return trackPromise(
			fetch(`${apiUrl}/person/contactData/${uid}/${contact.uid}`, {
				method: 'delete',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				},
				credentials: 'omit'
			})
		)
			.then(function (res) {
				if (res.status === 401) {
					store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
				} else {
					store.generalStore.addMessage(200, 'contactdatadeleted');
				}
				return true;
			})
			.catch((e) => {
				console.log('ERROR: ', e);
				store.generalStore.addMessage(500, 'Contact Data could not be deleted');
				return false;
			});


	} else if (command === 'confirm') {

		contact.type = contact.type.slice(0, contact.type.indexOf('|auto|'))

		return trackPromise(
			fetch(`${apiUrl}/person/${uid}`, {
				method: 'put',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.authStore.token}`
				},
				body: JSON.stringify({ dto: { contactData: [contact] } }),
				credentials: 'omit'
			})
		)
			.then(function (res) {
				if (res.status === 401) {
					store.generalStore.addMessage(401, 'Login expired', 'Redirect to Login');
				} else {
					store.generalStore.addMessage(200, 'Contact Data confirmed');
				}
				return true;
				//TODO: Add error handling
			})
			.catch((e) => {
				console.log('ERROR: ', e);
				store.generalStore.addMessage(500, 'Contact Data could not be confirmed');
				return false
			});


	}


};
