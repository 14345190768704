// Renders a single result of type person or organization in a listView

import React, { Component } from "react";

import Select from "react-select";

import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

// import { toJS } from "mobx";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";

// import Checkbox from "@material-ui/core/Checkbox";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";

import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";

import InfoIcon from "@material-ui/icons/Info";
import DoneIcon from "@material-ui/icons/CheckCircle";
import IgnoreIcon from "@material-ui/icons/Cancel";

import SwapIcon from "@material-ui/icons/SwapHoriz";

import ArrowDownIcon from "../../icons/baseline-arrow_drop_down-24px.svg";

// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx Stores
import constants from "../../stores/constants";
// import store from '../../stores';

const styles = theme => ({
  controlMain: {
    padding: theme.spacing.unit
  },
  avatar: {
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    color: "#fff",
    backgroundColor: constants.color.main,
    width: "8vw", //50,
    height: "8vw", //50,
    maxWidth: 50,
    maxHeight: 50,
    fontSize: "calc(12px + .5vw)",
    float: "left",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px"
    },
    cursor: "pointer"
  },
  subTitles: {
    float: "left",
    display: "block",
    width: "100%",
    padding: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // marginLeft: theme.spacing.unit * 1,
    // marginLeft: '-16px',
    fontSize: 14,
    // position: 'absolute',
    // top: 0,
    marginBottom: theme.spacing.unit
  },
  link: {
    width: "80%",
    flexGrow: "1",
    display: "flex"
  },
  actions: {
    float: "right",
    display: "block",
    // width: '70px',
    width: theme.spacing.unit * 8.8,
    paddingRight: 20
  },
  actionLink: {
    outline: 0,
    color: constants.color.main
  },
  actionDivider: {
    "border-right": `1px solid ${constants.color.main}`,
    display: "inline-block",
    marginLeft: "5px",
    marginRight: "5px",
    height: "24px"
  },
  actionDividerInactive: {
    "border-right": `1px solid ${constants.color.main}`,
    display: "inline-block",
    marginLeft: "5px",
    marginRight: "5px",
    height: "24px",
    borderColor: constants.color.inactive
  },
  inactive: {
    color: constants.color.inactive
  },
  listOrganizations: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  name: {
    marginTop: theme.spacing.unit * 2.2,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  organizationName: {
    position: "relative",
    bottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
    paddingTop: 7,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
    // overflow: 'hidden'
  },
  grid: {
    // height: 350,
  },
  userProfile: {
    position: "absolute",
    marginLeft: theme.spacing.unit * 0.5,
    marginTop: theme.spacing.unit * 0.5,
    color: constants.color.main
  },
  fullName: {
    // marginLeft: theme.spacing.unit * -2,
    // marginTop: theme.spacing.unit * 2,
    // float: 'left',
    clear: "both"
  },
  fullNameLeft: {
    // marginLeft: theme.spacing.unit * -2,
    // marginTop: theme.spacing.unit * 2,
    // textAlign: 'right',
    // marginRight: theme.spacing.unit * 1.5,
    // float: 'left',
    clear: "both"
  },
  userIcon: {
    height: "20px",
    width: "20px",
    marginLeft: theme.spacing.unit * 0.7,
    marginTop: theme.spacing.unit * 0.2
  },
  flexContainer: {
    flexShrink: 0,
    flexGrow: 1
  },
  checkContainer: {
    minWidth: 90,
    padding: 0
  },
  textContainer: {
    flexGrow: 0,
    width: 150,
    padding: 0,
    marginLeft: theme.spacing.unit * 1.5,
    marginTop: theme.spacing.unit
  },
  centerContainer: {
    flexShrink: 0,
    width: 160,
    padding: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  scoreContainer: {
    padding: 0,
    paddingTop: 30,
    width: 60,
    maxWidth: 60
  },
  matchCountContainer: {
    width: 30,
    maxWidth: 30,
    flexGrow: 3
  },
  connect: {
    width: "95%",
    height: 5,
    marginTop: 30,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: "5px",
    backgroundColor: "#1f7eb3"
  },

  titleIconHolder: {
    "text-align": "center"
  },
  titleIcon: {
    color: constants.color.main,
    width: 25,
    height: 25,
    "margin-top": theme.spacing.unit,
    "margin-left": theme.spacing.unit * 1
  },
  control: {
    zIndex: 200
  },
  error: {
    "&::placeholder": {
      color: "red"
    }
  },
  input: {
    display: "flex",
    padding: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  // paper: {
  //   position: "absolute",
  //   zIndex: 1,
  //   marginTop: theme.spacing.unit,
  //   left: 0,
  //   right: 0
  // },
  addRelation: {
    width: "100%",
    "max-width": "420px",
    margin: 0
  },
  addRelationError: {
    width: "100%",
    "max-width": "420px",
    margin: 0,
    "border-bottom": `4px solid ${constants.color.error}`,
    color: constants.color.error
  },
  inputLabel: {
    position: "absolute",
    top: 0,
    "font-size": "1rem",
    "line-height": "1px",
    margin: 0
  },
  fullTitle: {
    // backgroundColor: 'grey'
    float: "left",
    // marginTop: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: 0
  },
  description: {
    // padding: '10px'
  },
  fieldLine: {
    paddingTop: 0,
    paddingBottom: 0
  },
  firstFieldLine: {
    paddingBottom: 0
  },
  gridContainer: {
    padding: theme.spacing.unit
  },
  gridItem: {
    padding: theme.spacing.unit
  },
  gridItemIcons: {
    padding: theme.spacing.unit,
    // backgroundColor: constants.color.main,
    borderRadius: "5px",
    color: constants.color.main
  },
  unions: {
    color: constants.color.main,
    marginTop: theme.spacing.unit * 1.5,
    fontWeight: "bold"
  },
  iconsType: {
    marginTop: theme.spacing.unit * 1.3
  },
  iconsDiv: {
    backgroundColor: constants.color.main,
    margin: " 0 auto",
    color: "white",
    width: "50%",
    borderRadius: "5px"
  },
  infoPopup: {
    minHeight: 150,
    minWidth: 120,
    padding: theme.spacing.unit
  },

  toolTip: {
    // left: '5px',
    top: 80,
    minWidth: 100,
    minHeight: 120,
    zIndex: 1
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },

  infoContainer: {
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing.unit * 2
  },

  infoIcon: {
    color: constants.color.main,
    float: "right"
  },

  addEnabled: {
    color: constants.color.success,
    float: "left"
  },
  addDisabled: {
    color: constants.color.inactive,
    float: "left"
  },
  ignore: {
    color: constants.color.inactive,
    marginLeft: "-10px"
  },
  ignoreSelected: {
    color: constants.color.delete,
    marginLeft: "-10px"
  },
  swap: {
    // display: 'block',
    // clear: 'both',
  },
  ist: {
    fontSize: "12px",
    float: "left"
  },
  from: {
    fontSize: "12px",
    float: "right",
    marginRight: theme.spacing.unit
  },

  avatarContainer: {
    display: "inline-block"
  },
  explanation: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  score: {
    width: 100,
    display: "inline-block",
    marginTop: theme.spacing.unit
  },
  selectionButtons: {
    maxWidth: 100,
    float: "left"
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          style: {
            fontSize: 16,
            marginLeft: "-10px",
            width: "calc(100% + 12px)"
          }
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const DropdownIndicator = props => {
  return (
    <img
      src={ArrowDownIcon}
      style={{ padding: 8, cursor: "pointer" }}
      alt="▾"
    />
  );
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  DropdownIndicator,
  Control,
  Menu,
  NoOptionsMessage,
  Option
};

class GridItem extends Component {
  state = {
    label: "",
    errorLabel: false,
    errorType: false,
    selectedType: null,
    deleteDialog: false,
    deleteData: {},
    open: false
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  handleSelectionChange(id, value, event, x) {
    this.props.setCheckmark(id, value);

    if (value === "add" && this.state.selectedType === null) {
      this.setState({ errorType: true });
    } else {
      this.setState({ errorType: false });
    }
    this.forceUpdate();
  }

  handleChange = name => value => {
    this.setState({
      [name]: value
    });

    if (name === "selectedType" && value !== null) {
      this.setState({ errorType: false });
    } else if (
      value === null &&
      "checked" in this.props.entry &&
      this.props.entry.checked === "add"
    ) {
      this.setState({ errorType: true });
    }

    if (name === "selectedType") {
      this.props.setRelationType(this.props.id, value);
      if (
        value !== null &&
        (!this.props.entry.checked || this.props.entry.checked !== "add")
      )
        this.props.setCheckmark(this.props.id, "add");
    }
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  handleActionsClick(event) {
    event.stopPropagation();
  }

  handleContainerClick(a, b) {
    // console.log(a, b);
  }

  handleInfoToggle = event => {
    this.anchorEl = event.currentTarget;
    this.setState(state => ({ open: !state.open }));
  };

  handleDetailShow(event, value, entryType) {
    this.props.handleDetailShow(event, value, entryType);
  }

  handleSwap(event, value) {
    const { entry } = this.props;
    let currentType;
    if (
      "incomingObject" in entry &&
      entry.incomingObject.entryType === "person"
    ) {
      if (
        "targetObject" in entry &&
        entry.targetObject.entryType === "person"
      ) {
        currentType = "PersonToPerson";
      } else {
        currentType = "PersonToOrganization";
      }
    } else {
      if (
        "targetObject" in entry &&
        entry.targetObject.entryType === "person"
      ) {
        currentType = "OrganizationToPerson";
      } else {
        currentType = "OrganizationToOrganization";
      }
    }

    if (
      (currentType === "OrganizationToPerson" ||
        currentType === "PersonToOrganization") &&
      this.state.selectedType !== null
    ) {
      this.setState({
        selectedType: null,
        errorType: true
      });
      // this.handleSelectionChange(this.props.id, 'add');
      this.props.setRelationType(this.props.id, null);
    }

    this.props.handleSwap(event, value);
  }

  render() {
    const { classes, theme, entry, t, relationTypes } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
          color: "red !important"
        }
      })
    };

    const allTypes = [];

    let currentType;
    if (
      "incomingObject" in entry &&
      entry.incomingObject.entryType === "person"
    ) {
      if (
        "targetObject" in entry &&
        entry.targetObject.entryType === "person"
      ) {
        currentType = "PersonToPerson";
      } else {
        currentType = "PersonToOrganization";
      }
    } else {
      if (
        "targetObject" in entry &&
        entry.targetObject.entryType === "person"
      ) {
        currentType = "OrganizationToPerson";
      } else {
        currentType = "OrganizationToOrganization";
      }
    }

    let index;
    for (index in relationTypes) {
      if (relationTypes[index].type === currentType)
        allTypes.push({
          label: relationTypes[index].label,
          value: relationTypes[index].uid
        });
    }

    let fullName = "";
    let fullNameTarget = "";
    let imageUrl;
    let imageUrlTarget;
    let incomingObjectUid = "";
    let targetObjectUid = "";
    let incomingObjectType = "";
    let targetObjectType = "";

    if ("incomingObject" in entry) {
      imageUrl = entry.incomingObject.image;
      incomingObjectUid = entry.incomingObject.uid;
      incomingObjectType = entry.incomingObject.entryType;
    }

    if ("targetObject" in entry) {
      imageUrlTarget = entry.targetObject.image;
      targetObjectUid = entry.targetObject.uid;
      targetObjectType = entry.targetObject.entryType;
    }

    // console.log(toJS(entry));
    // entry.logo ? (imageUrl = entry.logo) : (imageUrl = entry.photo);

    if ("nameModified" in entry && typeof entry.nameModified !== "undefined") {
      fullName = <React.Fragment>{entry.nameModified}</React.Fragment>;
    } else if ("incomingObject" in entry && "name" in entry.incomingObject) {
      fullName = entry.incomingObject.name;
    }

    if (
      "nameTargetModified" in entry &&
      typeof entry.nameTargetModified !== "undefined"
    ) {
      fullNameTarget = (
        <React.Fragment>{entry.nameTargetModified}</React.Fragment>
      );
    } else if ("targetObject" in entry && "name" in entry.targetObject) {
      fullNameTarget = entry.targetObject.name;
    }

    const date =
      entry.relationSuggestions && entry.foundAt
        ? new Date(entry.foundAt)
        : new Date();

    const foundDate = `${date
      .getDate()
      .toString()
      .padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear()}`;

    const score = entry.score ? Math.ceil(entry.score * 10000) / 100 : 0;

    // const check = entry.checked ? entry.checked : false;

    // className={this.state.selectedType? classes.addEnabled : classes.addDisabled}

    const addChecked =
      "checked" in entry && entry.checked === "add" ? true : false;
    const ignoreChecked =
      "checked" in entry && entry.checked === "ignore" ? true : false;

    // date
    // score

    // explanation
    const ident = [];
    const similar = [];
    if (entry.explanation) {
      let key;
      for (key in entry.explanation) {
        let index;
        for (index in entry.explanation[key]) {
          if (entry.explanation[key][index] === 1) {
            ident.push(t(key));
          } else if(key === 'lastName' && entry.explanation[key][index] === 0.3) {
            ident.push(t(key));
          } else {
            similar.push(t(key));
          }
        }
      }
    }

    const explanationIdent =
      ident.length > 0 ? `${t("Ident")}: ${ident.join(", ")}` : false;
    const explanationSimilar =
      similar.length > 0 ? `${t("Similar")}: ${similar.join(", ")}` : false;

    // const selectionMissing = addChecked && this.state.relationType === null;

    const infoStyle = window.innerWidth >= 600 ? { position: "absolute" } : {};
    return (
      <div className={classes.controlMain} data-uid={entry._id}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={2}
            className={classes.gridItem}
            key="selectionContainer"
          >
            <div className={classes.selectionButtons}>
              <IconButton
                className={
                  addChecked ? classes.addEnabled : classes.addDisabled
                }
                onClick={this.handleSelectionChange.bind(
                  this,
                  this.props.id,
                  "add"
                )}
              >
                <DoneIcon />
              </IconButton>
              <IconButton
                className={
                  ignoreChecked ? classes.ignoreSelected : classes.ignore
                }
                onClick={this.handleSelectionChange.bind(
                  this,
                  this.props.id,
                  "ignore"
                )}
              >
                <IgnoreIcon />
              </IconButton>
            </div>
            <IconButton
              onClick={this.handleInfoToggle.bind(this)}
              className={classes.infoIcon}
              style={infoStyle}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            className={classes.gridItem}
            key="leftContainer"
          >
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={imageUrl}
                onClick={this.handleDetailShow.bind(
                  this,
                  incomingObjectUid,
                  incomingObjectType
                )}
              >
                {fullName && fullName[0]}
              </Avatar>
            </div>
            <div className={classes.fullNameLeft}>{fullName}</div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            className={classes.gridItem}
            key="centerContainer"
          >
            <div className={classes.ist}>{t("ist")}</div>
            <div className={classes.from}>{t("fromRelation")}</div>

            <Select
              id="type-select"
              error={this.state.errorType}
              className={
                this.state.errorType
                  ? classes.addRelationError
                  : classes.addRelation
              }
              classes={classes}
              styles={selectStyles}
              options={allTypes}
              components={components}
              value={this.state.selectedType}
              onChange={this.handleChange("selectedType")}
              placeholder={t("Select the type")}
              isClearable
              isSearchable={false}
            />

            <IconButton
              className={classes.swap}
              onClick={this.handleSwap.bind(this, this.props.id)}
            >
              <SwapIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            className={classes.gridItem}
            key="rightContainer"
          >
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={imageUrlTarget}
                onClick={this.handleDetailShow.bind(
                  this,
                  targetObjectUid,
                  targetObjectType
                )}
              >
                {fullNameTarget && fullNameTarget[0]}
              </Avatar>
            </div>
            <div className={classes.fullName}>{fullNameTarget}</div>
          </Grid>
        </Grid>

        <Popper
          open={this.state.open}
          anchorEl={this.anchorEl}
          className={classes.toolTip}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div className={classes.infoPopup}>
                    <div className={classes.subTitles}>{foundDate}</div>

                    <div>
                      {(explanationIdent || explanationSimilar) && (
                        <div className={classes.explanation}>
                          {t("Explanation")}
                        </div>
                      )}
                      {explanationIdent && explanationIdent}
                      {explanationIdent && <br />}
                      {explanationSimilar && explanationSimilar}
                    </div>
                    <div className={classes.score}>
                      <LinearProgress
                        value={score}
                        variant="determinate"
                        style={{ height: 15 }}
                      />
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("translations")(withRouter(GridItem))
);
