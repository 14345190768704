// renders a infobar above listView

import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// Components
import ContextMenu from '../navigation/top/ContextMenu';

// Mobx Store
import constants from '../../stores/constants';

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
    right: '5px',

    'box-shadow': '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',

  },
  rootLite: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 1,
    right: '5px',
  },
  container: {
    position: 'relative',
  },
  fab: {
    margin: theme.spacing.unit * 2,
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
  },
  sort: {
    // paddingLeft: theme.spacing.unit * 1,
    // paddingRight: theme.spacing.unit * 1,
    float: 'right',
    marginTop: theme.spacing.unit * -1,
    marginRight: theme.spacing.unit * 1,
    color: constants.color.main,
  },

  results: {
    marginLeft: theme.spacing.unit * 1,
    color: constants.color.main,
  }
});

function InfoBar(props) {

  return (
    <div className={props.classes.container}>
      {/* Lower div was prevously a Paper */}
      <div className={props.level ? props.classes.root : props.classes.rootLite} elevation={1}>
        <Typography className={props.classes.results} align="left" color="primary" variant="subtitle1" >
          {props.children}
          {!props.noSorting &&
          <Tooltip title="Sort">
            <ContextMenu />
          </Tooltip>
          }
        </Typography>
      </div>
    </div>

  );
}

export default withStyles(styles)(InfoBar);
