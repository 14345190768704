
import moment from 'moment'

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

// React Internationalization
import { withTranslation } from "react-i18next";

// Mobx
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// components
import ResultGridHeaderDuplicates from '../../templates/ResultGridHeaderDuplicates';
import ResultGridItemDuplicates from '../../templates/ResultGridItemDuplicates';
import SingleGridBlock from '../../templates/SingleGridBlock';
import InfoBar from '../../templates/InfoBar';
import ClusterLine from '../../templates/ClusterLine';
import constants from '../../../stores/constants';

import VirtualList from 'react-tiny-virtual-list';

import DuplicatesIcon from '@material-ui/icons/RecentActors';

// Actions
import duplicates from '../../../actions/duplicates';

// Helpers
import { calculateVlistHeight } from '../../../utils/helpers'

// let ModuleA = false;

const styles = theme => ({
    root: {
        flexGrow: 1,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    linear: {
        colorPrimary: constants.color.main,
        colorSecondary: constants.color.light,
        color: constants.color.main,
        //backgroundColor: 'rgb(66,159,210)'
    },
    counter: {
        color: constants.color.main,
    },
    animated: {
      transition: '0.5s all ease-in-out',
    },

    title: {
        margin: theme.spacing.unit * 2,
        paddingTop: '10vh',
        color: constants.color.main
    },
    notFound: {
        marginTop: theme.spacing.unit * 5,
        color: constants.color.main,
        height: '30vw',
        width: '30vw',
        maxWidth: '187px',
        maxHeight: '187px',
    }
});

class Duplicates extends Component {
    _isMounted = false;

    state = {
        spacing: '16',
        anchorEl: null,
        isLoading: true,
        scrollToIndex: -1,
    };

    handleChange = key => (event, value) => {
        if (this._isMounted)
            this.setState({
                [key]: value,
            });
    };

    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        // if (this._isMounted) {
        //     this.setState({ isLoading: true });
        // }

        // if(true){
        //   ModuleA = await import(/* webpackChunkName: "my-folder/my-component" */ './moduleA');
        //   import(/* webpackChunkName: "my-folder/my-component" */ './moduleB');
        // }

        this.props.generalStore.setScrollToIndex(-1);

        await this.props.conflictsStore.getDuplicates();

        this._isMounted = true;

        window.addEventListener('resize', this.resize.bind(this));
    }
    componentWillUnmount() {
        this.props.generalStore.setScrollToIndex(-1);
        //
        // this.props.conflictsStore.setduplicates([]);
        // this.props.conflictsStore.setduplicatesCount(0);

        if (this._isMounted) {
            this._isMounted = false;
        }

        window.removeEventListener('resize', this.resize.bind(this));
    }

    onClickDelete(uid) {
        this.props.conflictsStore.deletePerson(uid);
    }


    handleClick = event => {
        if (this._isMounted)
            this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        if (this._isMounted)
            this.setState({ anchorEl: null });
    };

    resize() {
        this.forceUpdate();
    }

    setCheckmark(id, value) {
      this.props.conflictsStore.setChecked(id, value);
    }

    setCheckmarkAll(id, value) {
      this.props.conflictsStore.setCheckedAll(value);
      this.forceUpdate();
    }

    async doAction(action){
      let base = toJS(this.props.conflictsStore.duplicates);

      let ids = [];
      const length = base.length;

      for(let i=0; i<length;i+=1){
        if(base[i] && 'checked' in base[i] && base[i].checked === true) {
          ids.push(base[i]._id);
        }
      }

      const result = await duplicates.resolveBulk(action, ids);
    }

    renderRow(data) {

        const realIndex = data.index;

        if (this.props.conflictsStore.duplicates[realIndex]) {

            // console.log(this.props.conflictsStore.personsIndex);
            const indexed = this.props.conflictsStore.duplicatesIndex[realIndex];

            let name = ('name' in this.props.conflictsStore.duplicates[realIndex]) ? this.props.conflictsStore.duplicates[realIndex].name : this.props.conflictsStore.duplicates[realIndex].firstName;
            if (typeof name === 'undefined') name = '';
            let char = name.substr(0, 1).toUpperCase();

            return (
                <div key={data.index} style={data.style} className="row">
                    {indexed &&
                        <ClusterLine key={char} text={char} />
                    }
                    <ResultGridItemDuplicates
                        key={this.props.conflictsStore.duplicates[data.index].uid}
                        id={data.index}
                        entry={this.props.conflictsStore.duplicates[data.index]}
                        selectedIndex={this.state.selectedIndex}
                        setCheckmark={this.setCheckmark.bind(this)}
                    />
                </div>
            )
        } else {
            return (
                <div key={data.index} />
            )
        }
    }

    itemSizeGetter(i) {

        const indexed = this.props.conflictsStore.duplicatesIndex[i];

        if (indexed) {
            return 138;
        } else {
            return 108;
        }

    }

    onScroll(pos) {
        // this.props.generalStore.setScrolled(pos);
        if (pos > 0) {
            if (this.props.generalStore.scrolled <= 0) {
                this.props.generalStore.setScrolled(pos);
            }
        } else {
            if (this.props.generalStore.scrolled > 0) {
                this.props.generalStore.setScrolled(pos);
                this.props.generalStore.setScrollToIndex(0);
            }
        }

    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        // const { duplicates } = this.props.conflictsStore.duplicates;
        const height = calculateVlistHeight()

        let counter = 0;

        // if(ModuleA !== false) return (<ModuleA.default />);

        if (this.props.conflictsStore.duplicates && this.props.conflictsStore.duplicates.length > 0) {

            return (
                <div>
                    <InfoBar children={<React.Fragment>{t('[v] potential duplicates', { v: this.props.conflictsStore.duplicateCount })}</React.Fragment>} />
                    {this.props.conflictsStore.selections > 0 &&
                    <React.Fragment>
                      <Button component="span" onClick={this.doAction.bind(this, 'keepBoth')} className={classes.button}>
                          {t('ignore duplicates')}
                      </Button>
                      <Button component="span" onClick={this.doAction.bind(this, 'mergeAuto')} className={classes.button}>
                          {t('automatic dedupe')}
                      </Button>
                    </React.Fragment>
                    }
                    <ResultGridHeaderDuplicates
                      setCheckmarkAll={this.setCheckmarkAll.bind(this)}
                      checked={this.props.conflictsStore.allChecked}
                    />

                    <VirtualList
                        width='100%'
                        className={classes.animated}
                        height={height}
                        itemCount={this.props.conflictsStore.duplicateCount || 0}
                        itemSize={this.itemSizeGetter.bind(this)}
                        renderItem={this.renderRow.bind(this)}
                        scrollToIndex={(this.props.generalStore.scrollToIndex < 0) ? null : this.props.generalStore.scrollToIndex}
                        scrollToAlignment={'center'}
                        scrollOffset={0}
                        onScroll={this.onScroll.bind(this)}
                        overscanCount={10}
                    />
                </div>
            )
        } else if (((this.props.conflictsStore.duplicates && this.props.conflictsStore.duplicates.length === 0) || !this.props.conflictsStore.duplicates) && !this.state.isLoading) {
            const lastRun = moment(new Date(`${moment().format('YYYY-MM-DD')}T00:30:00`));
            const nextRun = moment(new Date(`${moment().add(1, 'days').format('YYYY-MM-DD')}T00:30:00`));
            const current = moment();
            const beforeHours = moment.duration(current.diff(lastRun)).asHours();
            const inHours = moment.duration(nextRun.diff(current)).asHours();

            return (
              <>
              <InfoBar noSorting={true} children={<React.Fragment>{t('Last scan [x] hours ago.', { x: Math.ceil(beforeHours)})}<br/>{t('Next scan in [x] hours.', { x: Math.ceil(inHours)})}</React.Fragment>} />
              <Typography className={classes.title} align="center" paragraph variant="h4">
                  {t('At the moment there are no detected duplicates')}
              </Typography>
              <DuplicatesIcon className={classes.notFound} />
              </>
            )
        } else if (this.state.isLoading) {
            let c = setInterval(() => {
                if (counter >= 6) {
                    if (this.props.conflictsStore.duplicates) {
                        if (this._isMounted) this.setState({ isLoading: false });
                        clearInterval(c);
                    }
                } else {
                    counter++;
                }
            }, 200);

            return (<SingleGridBlock children={<div />} />);
        }
    }
}

Duplicates.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation("translations")(withRouter(inject('conflictsStore', 'generalStore')(observer(Duplicates)))));
