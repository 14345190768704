// Render a list with alphabetic links to the list results

import React from 'react';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

// Components
import constants from '../../stores/constants';

const styles = theme => ({
  abc: {
    // position: 'fixed',
    position: 'absolute',
    width: '40px',
    right: 10,
    top: 20,
    'z-index': 30,
    transition: '0.5s all ease-in-out',
  },
  abcLink: {
    width: '100%',
    display: 'block',
    clear: 'both',
    'text-decoration': 'none',
    'font-size': 'calc(50vh / 26)',
    'line-height': 'calc(70vh / 26)',
    color: '#333',
    cursor: 'pointer',
  }
});

async function handleClick(char, props) {
  console.log(char);
  let value = this.props.history.location.pathname;
  value = value.split('/');

  const secondValue = (value.length === 3)? value[value.length - 2] : false;
  value = value[value.length - 1];

  let index;

  if (value === 'persons') {
    if (props.personStore.persons.length === 0) return;
    index = toJS(props.personStore.personsHash);
  } else if (value === 'organizations') {
    if (props.organizationStore.organizations.length === 0) return;
    index = toJS(props.organizationStore.organizationsHash);
  } else if (value === 'all') {
    if (props.personStore.personsAndOrganizations.length === 0) return;
    index = toJS(props.personStore.personsAndOrganizationsHash);
  // } else if (value === 'group') {
  //   if (props.organizationStore.personsAndOrganizations.length === 0) return;
  //   index = toJS(props.personStore.personsAndOrganizationsHash);
  } else if (value === 'favorites') {
    if (props.personStore.personsAndOrganizations.length === 0) return;
    index = toJS(props.personStore.personsAndOrganizationsHash);
  } else {
    if(secondValue === 'category') {
      if (props.personStore.personsAndOrganizations.length === 0) return;
      index = toJS(props.personStore.personsAndOrganizationsHash);
    } else {
      if (props.personStore.personsAndOrganizations.length === 0) return;
      index = toJS(props.personStore.personsAndOrganizationsHash);
    }
  }

  const newPosition = index[char] ? index[char] + 1 : null;

  console.log('Flag 1');
  if (isNaN(newPosition) && value === 'favorites') return;
  console.log('Flag 2');

  if(value === 'favorites') return props.generalStore.setScrollToIndex(newPosition);

  let result;

  let c = 0;

  let code = char.toLowerCase().charCodeAt(0);
  let direction = ((code-97) > 12) ? 'up' : 'down';
  let currentChar = char;

  while (!result || (secondValue === 'category' && result.dataBefore.length === 0)) {
    if(c>0) {
      if(direction === 'up') {
        code -= 1;
      } else {
        code += 1;
      }
      if(code < 97 || code > 122) break;
      currentChar = String.fromCharCode(code);
    }

    if (value === 'persons') {
      result = await props.personStore.setPersonsByLetter(currentChar);
    } else if (value === 'organizations') {
      result = await props.organizationStore.setOrganizationsByLetter(currentChar);
    } else if (secondValue === 'category') {
      result = await props.personStore.setCategoryMembersByLetter(value, currentChar);
    } else if (value === 'all') {
      result = await props.personStore.setPersonsAndOrganizationsByLetter(currentChar);
    // } else if (value === 'group') {
    //   result = await props.personStore.setPersonsAndOrganizationsByLetter(currentChar);
    }
    c += 1;
  }

  console.log('Result', result);
  if(result && 'offset' in result) {
    if(isNaN(result.offset) || result.offset < 0) {
      console.log('Offset error:', result.offset);
    } else {
      props.generalStore.setScrollToIndex(result.offset);
    }
  }
}

function Results(props) {

  const { classes } = props;

  let i;
  const data = [];
  let c;
  for (i = 65; i <= 90; i++) {
    c = String.fromCharCode(i);
    // data.push(<a key={c} className={classes.abcLink} href={`#${c}`}>{c}</a>);
    // console.log(props.generalStore.scrollToIndex);
    data.push(<div key={c} className={classes.abcLink} onClick={handleClick.bind(this, c, props)}>{c}</div>);
  }

  // const currentPos = (window.innerWidth < constants.breakpoints.max)? {}: {right: 'unset', left: 850};

  const medium = window.innerWidth >= constants.breakpoints.medium;
  const currentPos = { top: (!medium && this.props.generalStore.scrolled > 0 ? 100 : 20)};
  return (
    <div className={classes.abc} id="abc" style={currentPos}>
      {data}
    </div>
  );
}

export default withStyles(styles)(withRouter(inject('personStore', 'organizationStore', 'generalStore')(observer(Results))));
